import { useMemo } from "react";
import { AgCharts } from "ag-charts-react";
import { orderBy } from "lodash";
import dayjs from "dayjs";
import type {
  AgCartesianSeriesTooltipRendererParams,
  AgChartOptions,
} from "ag-charts-community";
import { BE_DATE_FORMAT, DATE_WITH_YEAR } from "../../../../utilities/dates/dates.constants";
import { MissedOpportunityInvoiceDateType } from "./MissedOpportunities.types";
import { rxChartTheme } from "../rxChart.theme";
import { currencyFormatter } from "../utils/performanceReportFormatters";

export function renderTooltip({
  datum,
  xKey,
  yKey,
  yName,
}: AgCartesianSeriesTooltipRendererParams) {
  const title = dayjs(datum[xKey]).format(DATE_WITH_YEAR);
  return {
    title: title,
    content: `${yName}: ${currencyFormatter(datum[yKey])}`,
  };
}

const OPTIONS: AgChartOptions = {
  theme: rxChartTheme,
  title: {
    text: "Estimated Total Savings by Invoice Date",
    textAlign: "left",
  },
  height: 450,
  series: [
    {
      type: "bar",
      xKey: "invoiceDate",
      yKey: "total",
      yName: "Total",
      fill: "#a989c5",
      strokeWidth: 1,
      stroke: "transparent",
      tooltip: {
        renderer: renderTooltip,
      },
    },
  ],
  axes: [
    {
      type: "time",
      position: "bottom",
      label: {
        format: "%b%e %Y",
        rotation: -45,
      },
      title: {
        text: "Invoice Date",
        enabled: true,
      },
    },
    {
      type: "number",
      position: "left",
    },
  ],
};

export function OpportunityInvoiceDateBarChart({
  data,
}: {
  data: MissedOpportunityInvoiceDateType[];
}) {
  const reportData = useMemo(() => {
    if(!data) return [];

    const newData = data.map((v) => {
      const parsedDate = dayjs(v["invoiceDate"], BE_DATE_FORMAT).toDate();
      return { ...v, invoiceDate: parsedDate };
    });

    return orderBy(newData, "invoiceDate");
  }, [data]);

  return <AgCharts options={{ data: reportData, ...OPTIONS }} />;
}
