import React, { useEffect, useState } from "react";
import groupBy from "lodash/groupBy";
import {
  getAdminAccountOptions,
  getAdminAccounts,
} from "../../../../../../services/accounts";
import { useRequest } from "../../../../../../services/request/useRequest";
import {
  AccountOptions,
  UserBusinessRoles,
} from "../../../../../../utilities/types";
import { Text } from "../../../../../../components/rxLibrary/typography";

type PharmacyOption = {
  label: string;
  options: { value: number; label: JSX.Element }[];
};

type ExistingAccount = { id: number; email: string };

export type AdminAccountFormBusinessRole = string | null;

const BUSINESS_ROLE_OPTION_BUYING_REPORTING =
  UserBusinessRoles.BUYING + "_" + UserBusinessRoles.REPORTING;

const BUSINESS_ROLE_OPTIONS = [
  {
    value: BUSINESS_ROLE_OPTION_BUYING_REPORTING,
    label: "Buying & Reporting",
  },
  {
    value: UserBusinessRoles.MANUFACTURING,
    label: "Manufacturing",
  },
];

export function formatAdminAccountFormBusinessRoles(
  values: string[] | null
): AdminAccountFormBusinessRole {
  if (!values?.length || values.length > 2) return null;
  if (values.length === 2) {
    if (
      (values.includes(UserBusinessRoles.BUYING),
      values.includes(UserBusinessRoles.REPORTING))
    ) {
      return BUSINESS_ROLE_OPTION_BUYING_REPORTING;
    }
    return null;
  }

  return values[0] ?? null;
}

export function formatAdminAccountFormBusinessRolesToArray(
  value: string | null
): string[] {
  if (!value) return [];
  if (value === BUSINESS_ROLE_OPTION_BUYING_REPORTING) {
    return [UserBusinessRoles.BUYING, UserBusinessRoles.REPORTING];
  }
  return [value];
}

function formatPharmacyOptions(
  pharmacies: AccountOptions["pharmacy"]
): PharmacyOption[] {
  const pharmaciesByOrgId = groupBy(pharmacies, "pharmacyOrganizationId");
  const organizationIds = Object.keys(pharmaciesByOrgId);
  const options = organizationIds
    .map((organizationId) => {
      const pharmacies = pharmaciesByOrgId[organizationId];
      const organizationName = pharmacies[0].pharmacyOrganizationName;
      const pharmacyOptions = [...pharmacies]
        .sort((a, b) => a.pharmacyName.localeCompare(b.pharmacyName))
        .map(({ pharmacyId, pharmacyName }) => ({
          value: pharmacyId,
          label: (
            <span>
              <Text color="grey-1">{organizationName}:</Text>
              <Text> {pharmacyName}</Text>
              <Text color="grey-1"> {pharmacyId}</Text>
            </span>
          ),
        }));
      return { label: organizationName, options: pharmacyOptions };
    })
    .sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

  return options;
}

export function useAdminAccountOptions(pharmacyIds?: number[]) {
  const [pharmacyOptions, setPharmacyOptions] = useState<PharmacyOption[]>([]);
  const [existingAccounts, setExistingAccounts] = useState<ExistingAccount[]>(
    []
  );
  const [defaultBuyingPharmacyOptions, setDefaultBuyingPharmacyOptions] =
    useState<PharmacyOption[]>([]);

  const { data } = useRequest({
    dataKey: "accountOptions",
    request: getAdminAccountOptions,
  });
  const pharmacies = data?.pharmacy || [];

  // TODO - move accounts to the BE
  const { data: accounts } = useRequest({
    dataKey: "accounts",
    request: getAdminAccounts,
  });

  useEffect(() => {
    if (!pharmacies) {
      setPharmacyOptions([]);
      return;
    }

    const options = formatPharmacyOptions(pharmacies);
    setPharmacyOptions(options);
  }, [pharmacies]);

  useEffect(() => {
    if (!accounts) {
      setExistingAccounts([]);
      return;
    }

    const mappedAccounts =
      accounts?.map(({ id, email }) => ({ id, email })) ?? [];
    setExistingAccounts(mappedAccounts);
  }, [accounts]);

  useEffect(() => {
    if (!pharmacies || !pharmacyIds?.length) {
      setDefaultBuyingPharmacyOptions([]);
      return;
    }

    const filteredPharmacies = pharmacies.filter((v) => {
      return pharmacyIds.includes(v.pharmacyId);
    });
    const options = formatPharmacyOptions(filteredPharmacies);
    setDefaultBuyingPharmacyOptions(options);
  }, [pharmacies, pharmacyIds]);

  return {
    pharmacyOptions,
    businessRoleOptions: BUSINESS_ROLE_OPTIONS,
    defaultBuyingPharmacyOptions,
    existingAccounts,
  };
}
