import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Popconfirm, Select } from "antd";
import { UpsertAdminPharmacyEmailSubscription } from "../../../../../../utilities/types";
import { Button } from "../../../../../../components/rxLibrary/buttons";
import {
  OnSubmit,
  useHandleSubmit,
} from "../../../../../../components/antd/form/useHandleSubmit";
import { FormSkeleton } from "../../../../../../components/antd/form/FormSkeleton";
import { FormGrid } from "../../../../../../components/antd/form/FormGrid";
import { AdminManagementHeader } from "../../components/AdminManagementHeader";
import { useAdminPharmacyEmailSubscriptionOptions } from "./useAdminPharmacyEmailSubscriptionOptions";
import { PharmacyLabel } from "../../components/PharmacyLabel";

export function AdminPharmacyEmailSubscriptionForm({
  isUpdateForm,
  initialValues,
  onSubmit,
}: {
  isUpdateForm?: boolean;
  initialValues?: UpsertAdminPharmacyEmailSubscription;
  onSubmit: OnSubmit<UpsertAdminPharmacyEmailSubscription>;
}) {
  const { form, isSubmitting, handleSubmit } = useHandleSubmit({ onSubmit });
  const { pharmacyOptions } = useAdminPharmacyEmailSubscriptionOptions();

  const title = isUpdateForm
    ? `Edit Pharmacy Email Subscription`
    : "Create Pharmacy Email Subscription";
  const submitBtn = isUpdateForm
    ? "Update Pharmacy Email Subscription"
    : "Create Pharmacy Email Subscription";

  const loading = isUpdateForm ? !initialValues || isSubmitting : false;
  if (loading) {
    return <FormSkeleton text="Loading Pharmacy Email Subscription" />;
  }

  return (
    <>
      <AdminManagementHeader
        breadcrumbs={[
          {
            title: (
              <Link to="/admin/pharmacy/email/subscription">
                Pharmacy Email Subscription
              </Link>
            ),
          },
          { title },
        ]}
      />

      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSubmit}
        disabled={isSubmitting}
        scrollToFirstError
      >
        <FormGrid>
          <Form.Item
            name="pharmacyId"
            label={<PharmacyLabel />}
            rules={[{ required: true }]}
          >
            <Select
              options={pharmacyOptions}
              optionFilterProp="label"
              showSearch
              allowClear
            />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[{ type: "email" }, { required: true }]}
          >
            <Input />
          </Form.Item>
        </FormGrid>

        <Popconfirm title="Sure to continue?" onConfirm={form.submit}>
          <Button disabled={isSubmitting}>{submitBtn}</Button>
        </Popconfirm>
      </Form>
    </>
  );
}
