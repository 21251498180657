import type { ReactNode } from "react";
import { Text } from "../../../../../../components/rxLibrary/typography";

export function DownloadCsvWrapper({
  text = "Export table",
  children,
}: {
  text?: string;
  children: ReactNode;
}) {
  return (
    <div tw="space-y-2">
      <Text block>{text}</Text>

      <div tw="flex items-center">{children}</div>
    </div>
  );
}
