import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import { useBuyingPharmacy } from "../../../../../contexts/BuyingPharmacyContext";
import EnhancedClientConfigContext from "../../../../../enhanced/EnhancedClientConfigContext";
import EnhancedClientContext from "../../../../../enhanced/EnhancedClientContext";
import {
  NdcOffering,
  NdcOfferingSet,
} from "../../../../../enhanced/result_types";
import {
  ListCategoryEnum,
} from "../../../../../utilities/types";
import { formatNdc } from "../../../../../utilities/ndc/formatNdc";
import { useCompare } from "../../../useCompare/useCompare";
import {
  splitCards,
  buildOfferingSetForCard,
  appendAdditionalEnhancedItems,
} from "./useCompareResults.utils";
import {areTherapeuticallySubstitutable} from "../../../../../utilities/drugInfo/drugInfo";

export type NdcResults = {
  topCards: NdcOfferingSet[];
  origMatch: NdcOfferingSet;
  bottomCards: NdcOfferingSet[];
  totalCardCount: number;
  mainNdcOffering?: NdcOffering;
};

export type CompareResultsData = ReturnType<typeof useCompareResults>;

export function useCompareResults(enhancementExcludedSuppliers?: string[]) {
  const { ndc, quoteState } = useCompare();

  const { suggestedDrug, itemListsByNDC, relatedDrugsByNDC } = quoteState;

  const { currentBuyingPharmacy, getSupplierByName } = useBuyingPharmacy();
  const { enhancedClientActive } = useContext(EnhancedClientConfigContext);
  const { enhancedSupplierConnectionStatuses, currentEnhancementState } =
    useContext(EnhancedClientContext);

  const formattedNdc = useMemo(() => formatNdc(ndc), [ndc]);

  const isEmpty = useMemo(() => {
    return !itemListsByNDC.some((i) => i.items.length);
  }, [itemListsByNDC]);

  const offeringSets = useMemo(() => {
    if (isEmpty) return [];
    const offeringRecords = itemListsByNDC
        .map((item) =>
            buildOfferingSetForCard(
                item,
                enhancedClientActive,
                enhancedSupplierConnectionStatuses,
                currentEnhancementState.enhancedSupplierItems,
                currentBuyingPharmacy,
                enhancementExcludedSuppliers,
                getSupplierByName
            )
        )
        .reduce<Record<string, NdcOfferingSet>>((acc, offeringSet) => {
          const { ndc, category } = offeringSet;
          if (
              !acc[ndc] ||
              category === ListCategoryEnum.NDC_MATCH ||
              category === ListCategoryEnum.ALTERNATIVE_NDC
          ) {
            acc[ndc] = offeringSet;
          }
          return acc;
        }, {});

    return appendAdditionalEnhancedItems(
        currentBuyingPharmacy,
        offeringRecords,
        enhancedClientActive,
        currentEnhancementState.enhancedAdditionalItemsByNDC,
        getSupplierByName,
        relatedDrugsByNDC
    );
  }, [
      isEmpty,
      itemListsByNDC,
      relatedDrugsByNDC,
      enhancedClientActive,
      currentBuyingPharmacy,
      currentEnhancementState,
      getSupplierByName,
      enhancementExcludedSuppliers,
      enhancedSupplierConnectionStatuses,
  ]);

  const originalSplit = useMemo(() => {
    return splitCards(offeringSets, suggestedDrug);
  }, [offeringSets, suggestedDrug])

  const topCardTeeCode = useMemo(() => {
    return originalSplit.topCards[0]?.featured.item.drugInfo.teeCode;
  }, [originalSplit])

  const showTeeCode = useMemo(() => {
    const drugInfo = originalSplit.topCards[0]?.featured.item.drugInfo;
    if (!drugInfo || !drugInfo.isRx) return false;
    const itemThatsNotEquivalent = offeringSets.find(o => {
        if (!o.featured?.item.drugInfo) return false;
        return !areTherapeuticallySubstitutable(drugInfo, o.featured.item.drugInfo)
    });
    return !!itemThatsNotEquivalent;
  }, [originalSplit, offeringSets]);

  const [filterUnequivalentTeeCodes, setFilterUnequivalentTeeCodes] = useState<boolean>(true);
  const toggleTeeCodeFilterValue = useCallback(() => {
    setFilterUnequivalentTeeCodes((prev) => !prev);
  }, []);

  useEffect(() => {
    const shouldFilter = topCardTeeCode && topCardTeeCode.startsWith("A");
    setFilterUnequivalentTeeCodes(!!shouldFilter);
  }, [topCardTeeCode]);

  const ndcResults = useMemo(() => {
    if (isEmpty) return undefined;
    const { topCards, bottomCards, mainNdcOffering } = splitCards(
        offeringSets.filter((o) => {
          return !filterUnequivalentTeeCodes || o.featured?.item.drugInfo.teeCode === topCardTeeCode;
        }),
        suggestedDrug
    );

    return {
      topCards,
      origMatch: topCards[0],
      bottomCards,
      totalCardCount: topCards.length + bottomCards.length,
      mainNdcOffering,
    };
  }, [isEmpty, offeringSets, suggestedDrug, topCardTeeCode, filterUnequivalentTeeCodes]);

  return {
    formattedNdc,
    isEmpty,
    ndcResults,
    showTeeCode,
    teeCodeFilterValue: filterUnequivalentTeeCodes,
    toggleTeeCodeFilterValue,
  };
}
