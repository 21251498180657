import React, { createContext, useEffect, useMemo, useState } from "react";
import uniq from "lodash/uniq";
import sortBy from "lodash/sortBy";
import type {
  InvoiceHistoryHistory,
  InvoiceHistoryHistoryItem,
} from "../../../utilities/types";
import { SortDirectionEnum } from "../../../components/rxLibrary/selects/SortBySelect";
import { ExpandableTable } from "../../../components/rxLibrary/tables";
import { InvoiceTableHeader } from "./InvoiceTableHeader";
import { InvoiceTableRow } from "./InvoiceTableRow";
import { InvoiceRowWrapper } from "./InvoiceRowWrapper";
import type { MultiSelectCheckboxesOption } from "../../../components/rxLibrary/selects/MultiSelectCheckboxes";

type InvoiceFilterOption = MultiSelectCheckboxesOption<string>;

export const SORT_INVOICE_OPTIONS = [
  { label: "Item Name", value: "itemName" },
  { label: "NDC", value: "ndc" },
  { label: "UPC", value: "upc" },
  { label: "Quantity", value: "quantity" },
  { label: "Unit Price", value: "unitPrice" },
  { label: "Total Price", value: "totalPrice" },
  { label: "PO Number", value: "purchaseOrderNumber" },
  { label: "Invoice #", value: "invoiceNumber" },
];

export const InvoiceHeaderContext = createContext<
  Omit<ReturnType<typeof useInvoice>, "items"> | undefined
>(undefined);

function useInvoice(baseItems: InvoiceHistoryHistoryItem[]) {
  const [items, setItems] = useState(baseItems);
  const [filters, setFilters] = useState<string[]>([]);
  const [filterOptions, setFilterOptions] = useState<InvoiceFilterOption[]>();
  const [sort, setSort] = useState(SORT_INVOICE_OPTIONS[0].value);
  const [sortDirection, setSortDirection] = useState(SortDirectionEnum.ASC);

  useEffect(() => {
    const purchaseOrderNumbers = uniq(
      baseItems.map((item) => item.purchaseOrderNumber)
    );
    if (purchaseOrderNumbers.length <= 1) {
      setFilterOptions(undefined);
      return;
    }

    const newFilterOptions = purchaseOrderNumbers.map(
      (purchaseOrderNumber) => ({
        label: purchaseOrderNumber || "None",
        value: purchaseOrderNumber || "",
      })
    );
    setFilterOptions(newFilterOptions);
  }, [baseItems]);

  useEffect(() => {
    const filteredItems = baseItems.filter((item) => {
      if (filters.length === 0) return true;
      return filters.includes(item.purchaseOrderNumber || "");
    });

    const sortedItems = sortBy(filteredItems, [
      (item: any) => {
        if (["unitPrice", "totalPrice", "quantity"].includes(sort)) {
          return Number(item[sort]);
        }
        return item[sort];
      },
    ]);
    if (sortDirection === SortDirectionEnum.DESC) sortedItems.reverse();

    setItems(sortedItems);
  }, [sort, filters, baseItems, sortDirection]);

  return {
    sort,
    items,
    filters,
    filterOptions,
    sortDirection,
    setSort,
    setFilters,
    setSortDirection,
  };
}

export function Invoice({
  id,
  invoice,
}: {
  id: string;
  invoice: InvoiceHistoryHistory;
}) {
  const { items, ...invoiceHeader } = useInvoice(invoice.items);

  const data = useMemo(() => [{ id, items }], [id, items]);

  return (
    <InvoiceHeaderContext.Provider value={invoiceHeader}>
      <ExpandableTable
        blueToggle
        items={data}
        header={<InvoiceTableHeader invoice={invoice} />}
        Row={InvoiceTableRow}
        RowWrapper={InvoiceRowWrapper}
      />
    </InvoiceHeaderContext.Provider>
  );
}
