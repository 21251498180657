import { Route, Switch } from "react-router-dom";
import { RedirectHomePage } from "../../../../../components/RedirectHomePage";
import { CreateAdminPageAlert } from "./CreateAdminPageAlert";
import { EditAdminPageAlert } from "./EditAdminPageAlert";
import { AdminPageAlerts } from "./AdminPageAlerts";

const PATH = "/admin/page-alert";
const PATH_UPDATE = `${PATH}/:id`;
const PATH_CREATE = `${PATH}/new`;

export function PageAlertRoutes() {
  return (
    <Switch>
      <Route path={PATH} component={AdminPageAlerts} exact />
      <Route path={PATH_CREATE} component={CreateAdminPageAlert} exact />
      <Route path={PATH_UPDATE} component={EditAdminPageAlert} exact />
      <Route component={RedirectHomePage} />
    </Switch>
  );
}
