import { ColDef, ColGroupDef } from "ag-grid-community";
import { manufacturerReportCurrencyFormatter } from "../utils/manufacturerReportFormatters";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName: "Pharmacy",
    children: [
      { field: "Pharmacy Name", pinned: "left" },
      { field: "Pharmacy NPI", pinned: "left", hide: true },
    ],
  },
  {
    headerName: "Prescription",
    children: [
      // { field: "DAW Code", hide: true },
      { field: "Rx Written Date", hide: true },
      { field: "Dispensed Same as Prescribed Drug", hide: true },
      { field: "Dispensed Drug Name" },
      { field: "Dispensed NDC" },
      { field: "Was Dispensed As Competitor Drug" },
      { field: "Dispense Status" },
      { field: "Dispensed Quantity" },
      // {
      //   field: "Processed Prescribed NDC",
      //   headerName: "Prescribed NDC",
      //   hide: true,
      // },
      // { field: "Was Prescribed As Competitor Drug", hide: true },
      // { field: "Prescribed Drug Is Brand", hide: true },
      { field: "Fill Adjudication Date" },
      { field: "Sale Date", hide: true },
      { field: "Days Supply", hide: true },
      { field: "Total Refills Prescribed", hide: true },
      { field: "Refill Number" },
      { field: "Refills Remaining" },
    ],
  },
  {
    headerName: "Prescriber",
    children: [
      { field: "Prescriber Name" },
      { field: "Prescriber NPI" },
      { field: "Prescriber Street Address", hide: true },
      { field: "Prescriber City", hide: true },
      { field: "Prescriber State", hide: true },
      { field: "Prescriber Zip" },
    ],
  },
  {
    headerName: "Claim Info",
    children: [
      // { field: "Was Coupon Used" },
      { field: "is_covered", headerName: "Is Covered" },
      { field: "Last OCC", headerName: "OCC" },
      // {
      //   field: "Final Rejection Codes",
      //   headerName: "3rd Party Primary Payer Final Rejections",
      //   tooltipField: "Final Rejection Codes",
      //   hide: true,
      // },
      {
        headerName: "Pharmacy Gross Revenue for Dispensed & In Progress",
        field: "Pharmacy Gross Revenue",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      // {
      //   headerName: "Patient Pay After Coupon for Dispensed & In Progress",
      //   field: "Patient Pay Amount",
      //   valueFormatter: (params) =>
      //     manufacturerReportCurrencyFormatter(params.value),
      // },
      // {
      //   headerName: "3rd-Party Contribution for Dispensed & In Progress",
      //   field: "Third Party Contribution",
      //   valueFormatter: (params) =>
      //     manufacturerReportCurrencyFormatter(params.value),
      // },
      // {
      //   field: "Manufacturer Contribution",
      //   headerName: "Manuf. Contribution for Dispensed & In Progress",
      //   valueFormatter: (params) =>
      //     manufacturerReportCurrencyFormatter(params.value),
      // },
      // {
      //   headerName:
      //     "Patient Pay Before Coupon for Dispensed & In Progress, for Covered Fills",
      //   field:
      //     "Patient Pay - Before Coupon for Dispensed & In Progress for Covered Fills",
      //   valueFormatter: (params) =>
      //     manufacturerReportCurrencyFormatter(params.value),
      //   hide: true,
      // },
      // {
      //   field: "Pharmacy Copay Buydown for Dispensed & In Progress",
      //   valueFormatter: (params) =>
      //     manufacturerReportCurrencyFormatter(params.value),
      //   hide: true,
      // },
      {
        headerName: "Copay Before Coupon for Dispensed & In Progress",
        field: "Copay (Before Coupon)",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        hide: true,
      },
      {
        headerName: "Coinsurance Before Coupon for Dispensed & In Progress",
        field: "Coinsurance (Before Coupon)",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        hide: true,
      },
      {
        headerName:
          "Deductible Applied Before Coupon for Dispensed & In Progress",
        field: "Deductible Applied (Before Coupon)",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        hide: true,
      },
      {
        headerName:
          "Deductible Remaining Before Coupon for Dispensed & In Progress",
        field: "Deductible Remaining (Before Coupon)",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        hide: true,
      },
      {
        headerName:
          "Deductible Accumulated Before Coupon for Dispensed & In Progress",
        field: "Deductible Accumulated (Before Coupon)",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        hide: true,
      },
      {
        field: "Patient Coverage Gap (Before Coupon)",
        headerName:
          "Patient Coverage Gap Before Coupon for Dispensed & In Progress",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        hide: true,
      },
      {
        field: "Other Payer Recognized Amount (Before Coupon)",
        headerName:
          "Other Payer Recognized Amount Before Coupon for Dispensed & In Progress",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        hide: true,
      },
    ],
  },
  {
    headerName:
      "Unapplied Claim Info - Third-Party Primary Payer Data for Abandoned & Transferred Fills",
    children: [
      {
        field: "Pharmacy Gross Revenue for Abandoned & Transferred",
        headerName: "Pharmacy Gross Revenue for Abandoned & Transferred",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
        hide: true,
      },
      {
        field: "Patient Pay - Before Coupon for Abandoned & Transferred",
        headerName:
          "Patient Pay Before Coupon for Abandoned & Transferred, for Covered Fills",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
        hide: true,
      },
      {
        field: "Third Party Contribution for Abandoned & Transferred",
        headerName: "3rd-Party Contribution for Abandoned & Transferred",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
        hide: true,
      },
      {
        field: "Third Party Contribution > $0 for Abandoned & Transferred",
        headerName:
          "3rd-Party Contribution when >$0 for Abandoned & Transferred",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
        hide: true,
      },
      {
        field: "Coverage Rate w/ Contribution > $0 for Abandoned & Transferred",
        headerName:
          "Coverage Rate w/ Contribution > $0 for Abandoned & Transferred",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
        hide: true,
      },
    ],
  },
  {
    headerName: "Primary Payer",
    children: [
      {
        field: "Primary Payer Name",
      },
      {
        field: "Primary Plan Type",
      },
      { field: "Primary BIN" },
      { field: "Primary PCN" },
      { field: "Primary Group ID" },
    ],
  },
  {
    headerName: "Secondary Payer",
    children: [
      {
        field: "Secondary Payer Name",
      },
      {
        field: "Secondary Plan Type",
      },
      { field: "Secondary BIN" },
      { field: "Secondary PCN" },
      { field: "Secondary Group ID" },
    ],
  },
  {
    headerName: "Tertiary Payer",
    children: [
      {
        field: "Tertiary Payer Name",
        hide: true,
      },
      {
        field: "Tertiary Plan Type",
        hide: true,
      },
      { field: "Tertiary BIN", hide: true },
      { field: "Tertiary PCN", hide: true },
      { field: "Tertiary Group ID", hide: true },
    ],
  },
];
