import React, { useCallback, useMemo } from "react";
import {
  PrescriptionQuantityItem,
  PurchaseQuantityMethodEnum,
} from "../../../../../utilities/types";
import { uomDescription } from "../../../../../utilities/helpers";

export function PurchaseQuantityManualOption({
  prescription,
  updatePurchaseQuantity,
}: {
  prescription: PrescriptionQuantityItem;
  updatePurchaseQuantity: (
    rxNumbers: string[],
    purchaseQuantityMethod: PurchaseQuantityMethodEnum,
    num?: string
  ) => void;
}) {
  const { drug, rxNumbers, quantityToBuy, useQuantityInput } = prescription;

  const label = useMemo(() => {
    const uom = uomDescription(0, drug, false, true);
    return `Specify # of ${uom}`;
  }, [drug]);

  const updatePrescriptionsPurchaseQuantity = useCallback(
    (value: string) => {
      const newPurchaseQuantity = value === "" ? "0" : value;
      updatePurchaseQuantity(
        rxNumbers,
        PurchaseQuantityMethodEnum.Manual,
        newPurchaseQuantity
      );
    },
    [rxNumbers, updatePurchaseQuantity]
  );

  const handleOnBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      updatePrescriptionsPurchaseQuantity(value);
    },
    [updatePrescriptionsPurchaseQuantity]
  );

  const handleKeyPressed = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== "Enter") return;

      const value = (e.target as HTMLInputElement).value;
      updatePrescriptionsPurchaseQuantity(value);
    },
    [updatePrescriptionsPurchaseQuantity]
  );

  return (
    <div tw="flex items-center space-x-2">
      <p>{label}</p>

      {!!useQuantityInput && (
        <input
          defaultValue={quantityToBuy}
          onBlur={handleOnBlur}
          onKeyDown={handleKeyPressed}
          className="input--primary text-center"
          type="number"
        />
      )}
    </div>
  );
}
