import { IDropDownItem } from "../../components/DropDownListQty";
import type {
  DispensesStats,
  DispensesStatsData,
  DrugInfo,
  DrugWithStats,
} from "../types";

const DESCRIBED_UNITS = ["gram", "milliliter"];

export const getDispensesStatsGroup = (
  data: DrugWithStats,
  type: string
): DispensesStatsData => {
  const states: DispensesStats | undefined = data.dispensesStats;
  const obj =
    type === "last30Days"
      ? states?.last30Days
      : type === "last90Days"
      ? states?.last90Days
      : states?.last180Days;

  return obj || { numberOfPrescriptions: 0, numberOfUnits: 0 };
};

export const getQuantity = (drugInfo: DrugInfo) => {
  let quantity = 0;
  if (drugInfo.uom === "Each") {
    if (drugInfo.unitQuantity === 1) {
      if (drugInfo.unitSize === 1) {
        quantity = 1;
      } else {
        quantity = drugInfo.unitSize;
      }
    } else {
      quantity = drugInfo.unitQuantity;
    }
  } else {
    quantity = drugInfo.unitQuantity;
  }

  return quantity.toString();
};

export const getPackSizeList = (
  data: DrugWithStats[],
  manufacturer?: string
): IDropDownItem[] => {
  const items = manufacturer
    ? data.filter(
        (item) => item.drug.manufacturer === manufacturer
      )
    : data;
  const arr = items.map((item) => {
    const quantity = getQuantity(item.drug);

    return {
      label: quantity,
      value: quantity,
    };
  });

  const uniqueNames: string[] = [];
  return arr
    .filter((item: IDropDownItem) => {
      const isDuplicate = uniqueNames.includes(item.label);
      if (!isDuplicate) {
        uniqueNames.push(item.label);
        return true;
      }
      return false;
    })
    .sort((a: IDropDownItem, b: IDropDownItem) => {
      const firstLabel = parseInt(a.label);
      const secondLabel = parseInt(b.label);

      if (firstLabel < secondLabel) {
        return -1;
      }
      if (firstLabel > secondLabel) {
        return 1;
      }

      return 0;
    });
};

export function drugInfoHasValidUnits(drug?: DrugInfo): boolean {
  if (!drug) return false

  const { uom, unitSize } = drug;
  const isValid = !!uom && !!unitSize && DESCRIBED_UNITS.includes(uom.toLocaleLowerCase())

  return isValid
}

export function getUnitSizeFromDrugInfo(drug?: DrugInfo): string | void {
  if (drugInfoHasValidUnits(drug)) {
    return drug?.unitSize?.toString();
  }
}

/*
    If an NDC's TEE code does not start with the letter A, it cannot be substituted by anything else
    If an NDC's TEE code is A1, it can only be substituted with other NDCs with a TEE code of A1 and the same GPI
    If an NDC's TEE code is A2, it can only be substituted with other NDCs with a TEE code of A2 and the same GPI
    If an NDC's TEE code is A3, it can only be substituted with other NDCs with a TEE code of A3 and the same GPI
    If an NDC's TEE code is A4, it can only be substituted with other NDCs with a TEE code of A4 and the same GPI
    If in the future there is a TEE code with A5 or any higher number, follow the same logic as above
    If an NDC's TEE code is any of AA, AB, AN, AO, AP, AT, then it can be substituted with any NDC that has the same GPI and a TEE code that starts with A
 */
export function areTherapeuticallySubstitutable(original: DrugInfo, replacement: DrugInfo ) {
  if (original.ndc === replacement.ndc) return true;
  if (original.genericProductIdentifier !== replacement.genericProductIdentifier) return false;
  if (original.uom !== replacement.uom) return false;
  if (original.isRx !== replacement.isRx) return false;

  if (!original.isRx) {
    if ([original, replacement].every(d =>
      (d.teeCode[0] === 'A' && /[a-zA-Z]/.test(d.teeCode[1])) ||
      (d.teeCode === 'NR' || d.teeCode === 'NA')
    )) {
      return true;
    }
  } else {
    if ([original, replacement].every(d =>
      d.teeCode === 'NR' || d.teeCode === 'NA'
    )) {
      return true;
    }
  }
  if (original.teeCode[0] !== 'A' || replacement.teeCode[0] !== 'A') return false;
  if (/\d/.test(original.teeCode[1])) return original.teeCode[1] === replacement.teeCode[1];
  if (['AA', 'AB', 'AN', 'AO', 'AP', 'AT'].includes(original.teeCode)) return true;

  return false;
}
