import React from "react";
import type { ShippingConfig } from "../../../../../../utilities/types";
import { currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits } from "../../../../../../utilities/numbers/currency";

export function MinOrder(item: string | ShippingConfig) {
  let arr = [];
  let fee = 0;
  let minOrder = 0;
  let minGenericOrder = 0;
  let minGenericOrderText = "";
  let long = false;

  if (typeof item === "string") {
    arr = item.split("|");
    fee = (arr[0] && Number(arr[0])) || 0;
    minOrder = (arr[1] && Number(arr[1])) || 0;

    // If it's the IPC supplier, we would show not only the amount but also some texts for the minship
    if (arr[2]?.length > 15) minGenericOrderText = arr[2];

    minGenericOrder = (arr[2] && Number(arr[2])) || 0;
  } else {
    fee = item.fee || 0;
    minOrder = item.minOrder || 0;
    minGenericOrder = item.minGenericOrder || 0;
    long = true;
  }

  return (
    <>
      {minGenericOrder === 0 && fee === 0 && minOrder === 0 ? (
        long ? (
          <p>No min. Order</p>
        ) : (
          <p>{minGenericOrderText || "None"}</p>
        )
      ) : (
        long && <p>Min. Order:</p>
      )}
      {minGenericOrder > 0 && (
        <p>
          {currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(
            minGenericOrder
          )}{" "}
          generics
        </p>
      )}
      {minOrder > 0 && (
        <p>{currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(minOrder)}</p>
      )}
      {(minGenericOrder > 0 || minOrder > 0) && fee > 0 && <p> or </p>}
      {fee > 0 && (
        <p>{currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(fee)} fee</p>
      )}
    </>
  );
}
