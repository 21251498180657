import { AgGridReact } from "ag-grid-react";
import { ROW_CLASS_RULES } from "../utils/reportsConstants";
import { ReportWrapper } from "../utils/ReportWrapper";
import { useReport } from "../utils/useReport/useReport";
import {
  ATTEMPTED_FILLS_EXPORT_CSV_CONFIG,
  MISSED_OPPORTUNITIES_EXPORT_CSV_CONFIG,
} from "../utils/csv/csv.constants";
import { COLUMN_DEFS } from "./PharmacyReport.constants";
import { PharmacyReportDetailsCellRendered } from "./PharmacyReportDetailsCellRendered/PharmacyReportDetailsCellRendered";
import {
  ATTEMPTED_FILLS_COLUMN_DEFS,
  MISSED_FILLS_COLUMN_DEFS,
} from "./PharmacyReportDetailsCellRendered/PharmacyReportDetailsCellRendered.constants";

const MULTIPLE_DOWNLOAD_CSV_WITH_CHILDREN_BUTTONS_CONFIG = [
  {
    ...ATTEMPTED_FILLS_EXPORT_CSV_CONFIG,
    colDefs: ATTEMPTED_FILLS_COLUMN_DEFS.flatMap((col: any) => col?.children),
  },
  {
    ...MISSED_OPPORTUNITIES_EXPORT_CSV_CONFIG,
    colDefs: MISSED_FILLS_COLUMN_DEFS.flatMap((col: any) => col?.children),
  },
];

export function PharmacyReport({ data }: { data: never[] }) {
  const report = useReport({
    columnDefs: COLUMN_DEFS,
    multipleDownloadCsvWithChildrenButtonsConfig:
      MULTIPLE_DOWNLOAD_CSV_WITH_CHILDREN_BUTTONS_CONFIG,
  });

  return (
    <ReportWrapper>
      <AgGridReact
        {...report}
        masterDetail
        detailRowAutoHeight
        pagination
        rowData={data}
        rowSelection="multiple"
        rowClassRules={ROW_CLASS_RULES}
        detailRowHeight={400}
        detailCellRenderer={PharmacyReportDetailsCellRendered}
        rowGroupPanelShow="always"
      />
    </ReportWrapper>
  );
}
