import { useCallback, useEffect, useRef, useState } from "react";
import { Pharmacy, RequestState, Supplier } from "../../../utilities/types";
import { getQueryParamLikeArray } from "../../../utilities/queryParams/getQueryParamLikeArray";
import { getQueryParams } from "../../../utilities/queryParams/getQueryParams";
import { getQueryParamFirstValue } from "../../../utilities/queryParams/getQueryParamFirstValue";
import { removeNonAlphanumericChars } from "../../../utilities/strings/removeNonAlphanumericChars";
import { getPdfInvoice } from "../../../services/invoices";
import { Button } from "../../../components/rxLibrary/buttons";
import { IconPrint, IconSave } from "../../../components/rxLibrary/icons";
import { FullPageLoader } from "../../../components/loaders/FullPageLoader";
import { UnexpectedError } from "../../../components/errors/UnexpectedError";
import { ManufacturerStoreWrapper } from "../ManufacturerStoreWrapper";

export function Invoices({
  supplier,
  pharmacy,
}: {
  supplier: Supplier;
  pharmacy: Pharmacy;
}) {
  const { id: statePharmacyId, name: pharmacyName } = pharmacy;
  const { id: supplierId, displayName: supplierName } = supplier;

  const invoiceRef = useRef<HTMLIFrameElement>(null);
  const [invoiceData, setInvoiceData] = useState<{
    requestState: RequestState;
    invoiceUrl?: string;
    invoiceFilename?: string;
  }>();

  const {
    requestState = RequestState.PENDING,
    invoiceUrl,
    invoiceFilename,
  } = invoiceData ?? {};

  const printInvoice = useCallback(() => {
    const invoicePdf = invoiceRef.current?.contentWindow;
    if (!invoicePdf) return;
    invoicePdf.focus();
    invoicePdf.print();
  }, []);

  useEffect(() => {
    let invoicePdfUrl: string | void;
    window.scrollTo(0, 0);
    init();

    return () => {
      if (invoicePdfUrl) URL.revokeObjectURL(invoicePdfUrl);
    };

    async function init() {
      try {
        const fileIds = getQueryParamLikeArray("fileId");
        if (!fileIds.length) throw new Error("Missing file ids");

        const queryParams = getQueryParams();
        const pharmacyId = Number(
          getQueryParamFirstValue(queryParams["pharmacyId"]) ?? statePharmacyId
        );
        const response = await getPdfInvoice({
          fileIds,
          pharmacyId,
          supplierId,
          retries: 3,
        });
        if (!response?.data) return;

        invoicePdfUrl = window.URL.createObjectURL(response.data);

        const purchaseOrderNumber = getQueryParamFirstValue(
          queryParams["purchaseOrderNumber"]
        );
        const invoiceNumbersStr =
          purchaseOrderNumber &&
          "-" + removeNonAlphanumericChars(purchaseOrderNumber);
        const supplierNameStr = removeNonAlphanumericChars(supplierName);
        const pharmacyNameStr = removeNonAlphanumericChars(pharmacyName);
        const invoiceFilename = `Invoice-${supplierNameStr}-${pharmacyNameStr}${invoiceNumbersStr}.pdf`;

        setInvoiceData({
          requestState: RequestState.COMPLETED,
          invoiceUrl: invoicePdfUrl,
          invoiceFilename: invoiceFilename,
        });
      } catch (error) {
        console.error("Error getting the invoice", error);
        setInvoiceData({ requestState: RequestState.ERROR });
      }
    }
  }, [statePharmacyId, pharmacyName, supplierId, supplierName]);

  if (requestState === RequestState.PENDING) {
    return <FullPageLoader text="Loading Invoice" />;
  }

  if (requestState === RequestState.ERROR || !invoiceUrl) {
    return <UnexpectedError />;
  }

  return (
    <ManufacturerStoreWrapper>
      <div tw="flex justify-center space-x-2 mb-4">
        <a href={invoiceUrl} download={invoiceFilename}>
          <Button variant="secondary" icon={<IconSave />}>
            Download Invoice
          </Button>
        </a>

        <Button variant="secondary" icon={<IconPrint />} onClick={printInvoice}>
          Print Invoice
        </Button>
      </div>

      <iframe
        tw="w-full min-h-[200px] h-[calc(100dvh - 30px)]"
        src={invoiceUrl}
        ref={invoiceRef}
      />
    </ManufacturerStoreWrapper>
  );
}
