import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import isString from "lodash/isString";
import isEmpty from "lodash/isEmpty";
import { FormInstance, FormProps, message } from "antd";
import { BackendResponse } from "../../../services/request_legacy/config/backend-api";

export type OnSubmit<T extends Record<string, any>> = (
  values: T
) => Promise<
  | undefined
  | { redirectTo?: string; response: BackendResponse<Record<string, any>> }
>;

type HandleOnSubmitFn<T extends Record<string, any>> = NonNullable<
  FormProps<T>["onFinish"]
>;

type FieldData = Parameters<FormInstance["setFields"]>[0][number];

export function useHandleSubmit<
  T extends Record<string, any>,
  F extends Record<string, any> = T
>({
  onSubmit,
  formatRedirectTo = (redirectTo: string) => redirectTo,
  formatSubmitValues = (values) => values as any as T,
}: {
  onSubmit: OnSubmit<T>;
  formatRedirectTo?: (redirectTo: string, data: { submitData: T }) => string;
  formatSubmitValues?: (values: F, rawValue: F) => T;
}) {
  const [form] = useForm<F>();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit: HandleOnSubmitFn<F> = useCallback(
    async (values) => {
      setIsSubmitting(true);

      try {
        const cleanValues = Object.keys(values).reduce((acc, key) => {
          const valueRaw = values[key];
          // convert to null the empty strings
          const value =
            isString(valueRaw) && isEmpty(valueRaw) ? null : valueRaw;
          return { ...acc, [key]: value };
        }, {} as typeof values);

        const submitData = formatSubmitValues(cleanValues, values);
        const result = await onSubmit(submitData);
        const { response, redirectTo } = result ?? {};

        if (!response?.error) {
          message.success("Success");
          if (redirectTo) {
            const newRedirectTo = formatRedirectTo(redirectTo, { submitData });
            history.push(newRedirectTo);
          }
        } else {
          const errorMessage = isString(response?.details) ? response?.details : "Error executing operation";
          message.error(errorMessage);

          // Format know errors and attach them to the form fields
          const fieldsWithErrors = response?.details?.reduce<FieldData[]>(
            (acc, error) => {
              const errorType = error?.errorType;
              if (errorType === "request-validation") {
                const { message, jsonPath } = error;
                const name = jsonPath.replace("$.data.", "");
                const value = form.getFieldValue(name);
                acc.push({ name, value, errors: [message] });
              } else if (errorType === "unique-constraint") {
                const result = /Key \((.+)\)=\((.+)\) already exists\./gm.exec(
                  error.message
                );
                const name = result?.[1];
                const fieldValue = result?.[2];

                if (name && fieldValue) {
                  const value = form.getFieldValue(name);
                  const message = `${fieldValue} already exits`;
                  acc.push({ name, value, errors: [message] });
                }
              }

              return acc;
            },
            []
          );

          if (fieldsWithErrors?.length) {
            // update fields with errors
            form.setFields(fieldsWithErrors);
          }
        }
      } catch (error) {
        console.error(error);
      }
      setIsSubmitting(false);
    },
    [form, onSubmit, formatRedirectTo, formatSubmitValues]
  );

  return { form, isSubmitting, handleSubmit };
}
