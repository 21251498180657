import React from "react";
import { CSVLink } from "react-csv";
import { AgGridReact } from "ag-grid-react";
import ExportOutlined from "@ant-design/icons/ExportOutlined";
import type { ExportToolPanelProps } from "../utils/useReport/useReportSideBar";
import { Button } from "../../../../../components/rxLibrary/buttons";
import { ROW_CLASS_RULES } from "../utils/reportsConstants";
import { ReportWrapper } from "../utils/ReportWrapper";
import { useReport } from "../utils/useReport/useReport";
import { ButtonExportCsvWithChildren } from "../utils/csv/DownloadCsvWithChildrenButton";
import { DownloadCsvButton } from "../utils/csv/DownloadCsvButton";
import { DownloadCsvWrapper } from "../utils/csv/DownloadWrapper";
import {
  CSV_HEADERS,
  COLUMN_DEFS,
  DETAIL_CELL_RENDERER_PARAMS,
} from "./AllClaimWithFillSummaryDispensedInProgress.constants";

function ExportToolPanel({
  gridRef,
  exportToolPanelProps: { data },
  detailCellRendererParams,
  handleExport,
}: ExportToolPanelProps<{ data: never[] }>) {
  return (
    <>
      <DownloadCsvButton handleExport={handleExport} />

      <DownloadCsvWrapper text="Export all fields and claims">
        <CSVLink data={data} headers={CSV_HEADERS} filename="export-all.csv">
          <Button icon={<ExportOutlined />} variant="tertiary">
            Export
          </Button>
        </CSVLink>
      </DownloadCsvWrapper>

      <ButtonExportCsvWithChildren
        gridRef={gridRef}
        detailCellRendererParams={detailCellRendererParams}
      />
    </>
  );
}

export function AllClaimWithFillSummaryDispensedInProgress({
  data,
}: {
  data: never[];
}) {
  const report = useReport({
    columnDefs: COLUMN_DEFS,
    exportToolPanelProps: { data },
    detailCellRendererParams: DETAIL_CELL_RENDERER_PARAMS,
    ExportToolPanel,
  });

  return (
    <ReportWrapper>
      <AgGridReact
        {...report}
        masterDetail
        detailRowAutoHeight
        pagination
        rowData={data}
        rowSelection="multiple"
        rowClassRules={ROW_CLASS_RULES}
        rowGroupPanelShow="always"
      />
    </ReportWrapper>
  );
}
