import { useMemo } from "react";
import Typography from "antd/es/typography";
import { formatNdc } from "../../../../../../../utilities/ndc/formatNdc";
import { Text } from "../../../../../../../components/rxLibrary/typography";

export function CompareCardDetailsNdc({ ndc }: { ndc: string }) {
  const formattedNdc = useMemo(() => formatNdc(ndc), [ndc]);

  return (
    <p tw="text-lg font-light">
      NDC: {formattedNdc}{" "}
      <Typography.Text
        copyable={{
          text: ndc,
          icon: [
            <Text key="1" weight="bold" color="black-1" tw="underline">
              Copy
            </Text>,
            <Text key="2" weight="bold" color="black-1" tw="underline">
              Copied!
            </Text>,
          ],
          tooltips: [null, null],
        }}
      />
    </p>
  );
}
