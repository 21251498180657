import React, { useCallback, ReactNode } from "react";
import { Tooltip } from "antd";
import pluralize from "pluralize";
import { Button } from "../../../../components/rxLibrary/buttons";
import { Text } from "../../../../components/rxLibrary/typography";
import { IconBell } from "../../../../components/rxLibrary/icons";

export function ShoppingRxPrescriptionModalCancel({
  isOpen,
  close,
  softClose,
  itemsCount,
  children,
}: {
  isOpen: boolean;
  close: () => void;
  softClose: () => void;
  itemsCount: number;
  children: ReactNode;
}) {
  const handleOpenChangeTooltipError = useCallback(
    (open: boolean) => !open && softClose(),
    [softClose]
  );

  if (!isOpen) return <>{children}</>;

  return (
    <Tooltip
      open
      color="white"
      trigger="contextMenu"
      placement="top"
      onOpenChange={handleOpenChangeTooltipError}
      title={
        <div tw="w-[220px] p-[20px] pb-[10px]">
          <IconBell tw="mx-auto mb-[10px]" color="red-1" />

          <Text block center tw="mb-[13px]">
            {`Caution! You will lose the ${itemsCount} ${
              itemsCount > 1 ? "Rxs" : "Rx"
            } you added if you continue.`}{" "}
            <br />
            Are you sure?
          </Text>

          <Button tw="mx-auto mb-[5px]" variant="text-1" onClick={close} block>
            Yes, cancel
          </Button>

          <Button tw="mx-auto" variant="text-1" onClick={softClose} block>
            No, don&apos;t cancel
          </Button>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
}
