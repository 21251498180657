import type {
  ItemPurchaseDetails,
  Prescription,
} from "../../../utilities/types";
import { isItemTypePms } from "../../../utilities/prescriptions/itemType/isItemTypePms";
import { ItemTypeEnum } from "../../../utilities/prescriptions/itemType/itemTypeEnum";
import {
  fetchPmsPrescriptions,
  fetchPrescriptionsByRxNumber,
} from "../../../services/prescriptions";

export async function getPmsPrescriptions({
  pharmacyId,
  useCache,
  prescriptions,
}: {
  pharmacyId: number;
  useCache?: boolean;
  prescriptions?: Prescription[];
}) {
  const refreshPmsPrescriptions = useCache !== undefined || !prescriptions;
  if (refreshPmsPrescriptions) {
    const _useCache = prescriptions ? useCache : false;
    const newPrescriptionsPms = await fetchPmsPrescriptions(
      pharmacyId,
      _useCache
    );
    return newPrescriptionsPms;
  }

  const prescriptionsPms = prescriptions.filter((item) => {
    return item.itemType === ItemTypeEnum.PMS;
  });
  return prescriptionsPms;
}

export async function getNoPmsPrescriptions({
  cart,
  useCache,
  pharmacyId,
  prescriptions,
}: {
  cart: ItemPurchaseDetails[];
  pharmacyId: number;
  useCache?: boolean;
  prescriptions?: Prescription[];
}) {
  const cartRxNumbersNoPms = cart
    .filter((item) => !isItemTypePms(item))
    .map((item) => item.id);

  let noPmsPrescriptions;
  if (useCache === false || !prescriptions) {
    noPmsPrescriptions = await fetchPrescriptionsByRxNumber(
      pharmacyId,
      cartRxNumbersNoPms
    );
  } else {
    const cartRxNumbersNoPmsSet = new Set(cartRxNumbersNoPms);
    // get the No PMS prescriptions that are in the cart to avoid fetch them again
    const prescriptionsNoPmsInCart = prescriptions.filter((item) => {
      return cartRxNumbersNoPmsSet.has(item.rxNumber);
    });

    const prescriptionsRxNumbersSet = new Set(
      prescriptions.map((item) => item.rxNumber)
    );
    const rxNumbersNoPmsMissingInTheCart = cartRxNumbersNoPms.filter((item) => {
      return !prescriptionsRxNumbersSet.has(item);
    });

    // get the No PMS prescriptions that are in the cart but not in the initial prescriptions
    const prescriptionsNoPmsNoInCart = await fetchPrescriptionsByRxNumber(
      pharmacyId,
      rxNumbersNoPmsMissingInTheCart
    );

    noPmsPrescriptions = [
      ...prescriptionsNoPmsInCart,
      ...prescriptionsNoPmsNoInCart,
    ];
  }

  noPmsPrescriptions = noPmsPrescriptions.map((item) => ({
    ...item,
    itemType: ItemTypeEnum.NoPMS,
  }));

  return noPmsPrescriptions;
}

/**
 * only pass useCache if you want to force the fetch of the PMS prescriptions
 */
export async function fetchPrescriptionsFromCart({
  cart,
  useCache,
  pharmacyId,
  prescriptions,
}: {
  cart: ItemPurchaseDetails[];
  pharmacyId: number;
  useCache?: boolean;
  prescriptions?: Prescription[];
}) {
  const [pmsPrescriptions, noPmsPrescriptions] = await Promise.all([
    getPmsPrescriptions({
      useCache,
      pharmacyId,
      prescriptions,
    }),
    getNoPmsPrescriptions({
      cart,
      useCache,
      pharmacyId,
      prescriptions,
    }),
  ]);

  const noPmsRxNumberSet = new Set(noPmsPrescriptions.map((i) => i.rxNumber));
  const onlyPmsPrescriptions = pmsPrescriptions.filter((item) => {
    return !noPmsRxNumberSet.has(item.rxNumber);
  });

  const newPrescriptions = [...onlyPmsPrescriptions, ...noPmsPrescriptions];

  return newPrescriptions;
}
