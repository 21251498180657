import React, { useCallback, useState } from "react";
import "twin.macro";
import { message } from "antd";
import MailOutlined from "@ant-design/icons/MailOutlined";
import { Button } from "../../../../../../../components/rxLibrary/buttons";
import { adminAccountSendResetPasswordEmail } from "../../../../../../../services/accounts";

export function SendResetPasswordEmailButton({ userId }: { userId: number }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(async () => {
    setIsLoading(true);
    try {
      await adminAccountSendResetPasswordEmail({ id: userId });
      message.success("Password reset email sent");
    } catch (error) {
      console.error(
        `Error sending reset password email to user id: ${userId}`,
        error
      );
      message.error("Error sending reset password email");
    }
    setIsLoading(false);
  }, [userId]);

  return (
    <Button
      icon={<MailOutlined rev={undefined} />}
      variant="secondary"
      disabled={isLoading}
      onClick={handleClick}
    >
      Send Reset Password Email
    </Button>
  );
}
