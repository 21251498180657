import React, { useCallback, useEffect, ReactNode, useState } from "react";
import { Tooltip } from "antd";
import pluralize from "pluralize";
import { openChat } from "../../../../utilities/chat/openChat";
import { beep } from "../../../../utilities/sound/beep";
import { Button } from "../../../../components/rxLibrary/buttons";
import { Text } from "../../../../components/rxLibrary/typography";
import { IconBell } from "../../../../components/rxLibrary/icons";
import { ShoppingRxPrescriptionModalTooltipErrorEnum } from "./ShoppingRxPrescriptionModal.constants";

type TooltipError = { errorType: string; extra?: any };

function ShoppingRxPrescriptionModalTooltipErrorContent({
  tooltipError,
  close,
}: {
  tooltipError: TooltipError;
  close: () => void;
}) {
  const { errorType } = tooltipError;

  useEffect(() => {
    const shouldBeep =
      errorType === ShoppingRxPrescriptionModalTooltipErrorEnum.SEARCHING ||
      errorType === ShoppingRxPrescriptionModalTooltipErrorEnum.NO_RX_NUMBER;

    if (shouldBeep) beep().catch(console.error);
    document.addEventListener("keydown", close);
    const timeoutId = setTimeout(close, 4000);

    return () => {
      document.removeEventListener("keydown", close);
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderErrorContent() {
    if (errorType === ShoppingRxPrescriptionModalTooltipErrorEnum.DUPLICATED) {
      return (
        <Text block center>
          This Rx is already on the list.
        </Text>
      );
    }

    if (
      errorType === ShoppingRxPrescriptionModalTooltipErrorEnum.NO_RX_NUMBER
    ) {
      return (
        <Text block>
          This is not an Rx Number.
          <br />
          If your label has multiple barcodes, one of them will be the Rx
          number.
        </Text>
      );
    }

    if (
      errorType ===
      ShoppingRxPrescriptionModalTooltipErrorEnum.NOT_FOUND_IN_MEDISPAN
    ) {
      const ndcs = tooltipError.extra?.ndcs ?? [];
      return (
        <Text block center>
          {pluralize("NDC", ndcs.length)} {ndcs.join(", ")} for this Rx is not
          available in Medispan. Consider updating the dispensed NDC in the PMS.
        </Text>
      );
    }

    return (
      <>
        <Text block>
          {"Something went wrong - your item wasn't added to the list."}
          <br />
          1. Try adding again
          <br />
          2. If this doesn’t work, please
        </Text>

        <Button tw="mb-[20px]" variant="text-3" onClick={openChat} block>
          Chat with Us
        </Button>

        <Button tw="mx-auto" variant="text-1" onClick={close} block>
          OK
        </Button>
      </>
    );
  }

  return (
    <div tw="w-[220px] p-[20px] pb-[10px]">
      <IconBell tw="mx-auto mb-[7px]" color="red-1" />
      {renderErrorContent()}
    </div>
  );
}

export function useShoppingRxPrescriptionModalTooltipError() {
  const [tooltipError, _setTooltipError] = useState<TooltipError>();

  const setTooltipError = useCallback((errorType?: string, extra?: any) => {
    const value = errorType ? { errorType, extra } : undefined;
    _setTooltipError(value);
  }, []);

  const cleanTooltipError = useCallback(() => {
    setTooltipError();
  }, [setTooltipError]);

  return { tooltipError, setTooltipError, cleanTooltipError };
}

export function ShoppingRxPrescriptionModalTooltipError({
  isOpen,
  children,
  tooltipError,
  onClose,
  cleanTooltipError,
}: {
  isOpen: boolean;
  children: ReactNode;
  tooltipError?: TooltipError;
  onClose?: () => void;
  cleanTooltipError: () => void;
}) {
  const closeTooltipError = useCallback(() => {
    cleanTooltipError();
    onClose?.();
  }, [onClose, cleanTooltipError]);

  const handleOpenChangeTooltipError = useCallback(
    (open: boolean) => !open && closeTooltipError(),
    [closeTooltipError]
  );

  if (!isOpen || !tooltipError) return <>{children}</>;
  return (
    <Tooltip
      color="white"
      trigger="contextMenu"
      placement="bottom"
      onOpenChange={handleOpenChangeTooltipError}
      title={
        <ShoppingRxPrescriptionModalTooltipErrorContent
          tooltipError={tooltipError}
          close={closeTooltipError}
        />
      }
      open
    >
      {children}
    </Tooltip>
  );
}
