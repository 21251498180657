import merge from "lodash/merge";
import { v4 as uuidv4 } from "uuid";
import { BACKEND_URL, X_CLIENT_SESSION_ID } from "../../../utilities/config";

export type BackendResponse<T> = {
  data?: T;
  error?: boolean;
  details?: Record<string, string>[];
};

/**
 * @deprecated please use useRequestClient instead
 **/
export async function backendFetch<T extends Record<string, unknown> | unknown>(
  url: string,
  options = {}
) {
  const response = await fetch(
    `${BACKEND_URL}${url}`,
    merge(
      {
        mode: "cors",
        headers: { "X-Client-Session-Id": X_CLIENT_SESSION_ID, "X-Client-Request-Id": uuidv4()},
      },
      options
    ) as RequestInit
  );

  return response.json() as BackendResponse<T>;
}

/**
 * @deprecated please use useRequestClient instead
 **/
export async function backendPost(url: string, payload: string, options = {}) {
  return fetch(
    `${BACKEND_URL}${url}`,
    merge(
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "X-Client-Session-Id": X_CLIENT_SESSION_ID,
          "X-Client-Request-Id": uuidv4()
        },
        body: payload,
      },
      options
    ) as RequestInit
  );
}
