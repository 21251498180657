import { OrderHistoryOrder } from "../utilities/types";
import { requestClient, RequestClientConfig } from "./request/requestClient";

export function getOrderHistory({
  pharmacyId,
  supplierId,
  ...config
}: RequestClientConfig<{
  pharmacyId: number;
  supplierId: number;
}>) {
  return requestClient<{ orders: OrderHistoryOrder[] }>({
    ...config,
    url: "/order-history",
    params: { pharmacyId, supplierId },
  });
}
