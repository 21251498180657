import { Form, Input } from "antd";
import uniq from "lodash/uniq";
import { Button } from "../../../../../../components/rxLibrary/buttons";
import { IconPlus } from "../../../../../../components/rxLibrary/icons";
import {
  Popover,
  useIsPopoverOpen,
} from "../../../../../../components/rxLibrary/popover";

export function PharmaciesLabelWithAddByIdPopover({
  pharmacyOptions,
}: {
  pharmacyOptions?: {
    value: number;
    label: string;
  }[];
}) {
  const parentForm = Form.useFormInstance();
  const { isPopoverOpen, closePopover, handleOpenPopoverChange } =
    useIsPopoverOpen();

  if (!pharmacyOptions) return "Pharmacies";

  return (
    <div tw="flex items-center space-x-4">
      <span>Pharmacies</span>

      <Popover
        open={isPopoverOpen}
        onOpenChange={handleOpenPopoverChange}
        content={() => (
          <div tw="p-4">
            <Form
              autoComplete="off"
              layout="vertical"
              onFinish={({ idsStr }) => {
                const allPharmacyIds = new Set(
                  pharmacyOptions.map((o) => o.value)
                );

                const pharmacyIds = parentForm.getFieldValue("pharmacyIds") || [];
                const newIds = idsStr
                  .replace(/\s/gi, "")
                  .split(",")
                  .map(Number)
                  .filter((v: any) => allPharmacyIds.has(v));
                if (!newIds.length) {
                  closePopover();
                  return;
                }

                const newPharmacyIds = uniq([...pharmacyIds, ...newIds]);
                parentForm.setFieldValue("pharmacyIds", newPharmacyIds);
                closePopover();
              }}
            >
              <Form.Item
                label="Pharmacy IDs"
                name="idsStr"
                tooltip="If an ID is invalid or already added it will be ignored"
                extra="Separate multiple with a comma"
              >
                <Input placeholder="1,2,3" />
              </Form.Item>

              <Button icon={<IconPlus />} type="submit">
                Add
              </Button>
            </Form>
          </div>
        )}
      >
        <Button icon={<IconPlus />} variant="tertiary">
          Add Pharmacies by ID
        </Button>
      </Popover>
    </div>
  );
}
