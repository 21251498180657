import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getUser } from "../services/request_legacy/user";
import { authService } from "../services/libs/authenticationService";
import { User, UserBusinessRoles } from "../utilities/types";
import { useCookie } from "../utilities/useCookie";
import { setLocalStorageValue } from "../utilities/localStorage/setLocalStorageValue";
import { LAST_SELECTED_ROLE_KEY } from "../utilities/localStorage/constants";
import { getLocalStorageValue } from "../utilities/localStorage/getLocalStorageValue";
import { ErrorMessage } from "../components/errors/ErrorMessage";

type AppContext = {
  user?: User;
  isDemoMode: boolean;
  adminSelectedRole: UserBusinessRoles | null;
  userHasRole?: (role: UserBusinessRoles) => boolean;
  enableDemoMode: () => void;
  setAdminSelectedRole: (newSelectedRole: UserBusinessRoles) => void;
};

const AppContext = createContext<AppContext>({
  isDemoMode: false,
  adminSelectedRole: null,
  enableDemoMode: () => null,
  setAdminSelectedRole: () => null,
});

export default AppContext;

export function AppContextProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [user, setUser] = useState<User>();
  const [hasLoggingError, setHasLoggingError] = useState(false);
  const [adminSelectedRole, _setAdminSelectedRole] =
    useState<UserBusinessRoles | null>(null);
  const [demoMode, updateDemoMode] = useCookie("demoMode", "false");

  const isDemoMode = useMemo(() => {
    return demoMode === "true";
  }, [demoMode]);

  const enableDemoMode = useCallback(() => {
    updateDemoMode("true", 0);
  }, [updateDemoMode]);

  const setAdminSelectedRole = useCallback(
    (newSelectedRole: UserBusinessRoles) => {
      _setAdminSelectedRole(newSelectedRole);
      setLocalStorageValue(LAST_SELECTED_ROLE_KEY, newSelectedRole);
    },
    []
  );

  const _userHasRole = useCallback(
    (role: UserBusinessRoles) => {
      if (!user) return false;

      if (user.is_admin) {
        if (role === UserBusinessRoles.MANUFACTURING) {
          return adminSelectedRole === UserBusinessRoles.MANUFACTURING;
        }
        return true;
      }

      const hasRole = user.business_roles.includes(role);
      return hasRole;
    },
    [user, adminSelectedRole]
  );
  // only make the function available if the user is set to avoid race conditions
  const userHasRole = user ? _userHasRole : undefined;

  useEffect(() => {
    (async () => {
      const token = await authService.getAccessTokenSilently();
      const { data, error } = await getUser(token);

      if (error) {
        setHasLoggingError(true);
        return;
      }

      if (!data?.user) return;

      const gtmDataLayer = window.dataLayer;
      if (gtmDataLayer) gtmDataLayer.push({ user_account: data.user.id });

      if (data.user.is_admin) {
        const lastSelectedRole = getLocalStorageValue(
          LAST_SELECTED_ROLE_KEY,
          UserBusinessRoles.BUYING
        );
        // It is important that the role is defined before the user to avoid race conditions when validating the role.
        _setAdminSelectedRole(lastSelectedRole);
      }

      setUser(data.user);
    })();
  }, []);

  console.log("Rendering AppContext");
  return (
    <AppContext.Provider
      value={{
        user,
        isDemoMode,
        adminSelectedRole,
        userHasRole,
        enableDemoMode,
        setAdminSelectedRole,
      }}
    >
      {hasLoggingError ? (
        <ErrorMessage>
          Uh oh. This is us, not you! Please use the orange chat button below to
          let us know you are having trouble logging in.
        </ErrorMessage>
      ) : (
        children
      )}
    </AppContext.Provider>
  );
}
