import type {
  AdminSupplierMappingAndEdi,
  SupplierMappingAndEdiOptions,
  UpsertAdminSupplierMappingAndEdi,
  SupplierMappingAndEdiOptionsBySupplier,
  AdminSupplierMappingAndEdiBySupplierId,
} from "../utilities/types";
import { backendFetch } from "./request_legacy/config/backend-api";
import { requestClient, RequestClientConfig } from "./request/requestClient";

export async function createAdminSupplierMappingAndEdi({
  supplierId,
  pharmacyId,
  pharmacyIsaCode,
  accountId,
  isSupplierMappingActive,
  billToPharmacyId,
  ...config
}: RequestClientConfig<UpsertAdminSupplierMappingAndEdi>) {
  return requestClient<{ supplierMappingAndEdi: AdminSupplierMappingAndEdi }>({
    ...config,
    url: "/admin/supplier-mapping-and-edi",
    method: "POST",
    data: {
      supplierId,
      pharmacyId,
      accountId,
      pharmacyIsaCode,
      billToPharmacyId,
      isSupplierMappingActive,
    },
  });
}

export function getAdminSupplierMappingAndEdiOptionsBySupplier({
  supplierId,
  ...config
}: RequestClientConfig<{ supplierId: number }>) {
  return requestClient<{
    supplierMappingAndEdiOptionsBySupplier: SupplierMappingAndEdiOptionsBySupplier;
  }>({
    ...config,
    url: `/admin/supplier-mapping-and-edi/options/${supplierId}`,
  });
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export function getAdminSupplierMappingAndEdiOptions(token: string) {
  return backendFetch<{
    supplierMappingAndEdiOptions: SupplierMappingAndEdiOptions;
  }>("/admin/supplier-mapping-and-edi/options", {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });
}

export async function getAdminSupplierMappingAndEdiBySupplierId({
  supplierId,
  ...config
}: RequestClientConfig<{ supplierId: number | string }>) {
  return requestClient<{
    supplierMappingAndEdi: AdminSupplierMappingAndEdiBySupplierId[];
  }>({
    ...config,
    url: `/admin/supplier-mapping-and-edi/supplier/${supplierId}`,
  });
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export function getAdminSupplierMappingAndEdiByPharmacyId(
  token: string,
  pharmacyId: number
) {
  return backendFetch(
    `/admin/supplier-mapping-and-edi/pharmacy/${pharmacyId}`,
    {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    }
  );
}
