import sumBy from "lodash/sumBy";
import { AgGridReact } from "ag-grid-react";
import type { GridOptions, GridReadyEvent } from "ag-grid-community";
import { ROW_CLASS_RULES } from "../utils/reportsConstants";
import { ReportWrapper } from "../utils/ReportWrapper";
import { useReport } from "../utils/useReport/useReport";
import { setupPinnedTopRowData } from "../utils/setupPinnedTopRowData";
import { COLUMN_DEFS } from "./PharmacyDataLicensing.constants";

const getRowStyle: GridOptions["getRowStyle"] = (params) => {
  if (params?.data?.pinnedRow) return { "font-weight": "bold" };
  return undefined;
};

function getPinnedRowData(data?: any[]) {
  if (!data?.length) return;

  const attemptsSum = sumBy(data, "attempted");
  const totalPaidSum = sumBy(data, "total_paid");
  const feeStatic = data[0]?.fee;

  return [
    {
      pivot: false,
      pinnedRow: true,
      attempted: attemptsSum,
      fee: feeStatic,
      total_paid: totalPaidSum,
    },
  ];
}

function onGridReady(event: GridReadyEvent) {
  setupPinnedTopRowData(event, getPinnedRowData);
}

export function PharmacyDataLicensing({ data }: { data: never[] }) {
  const report = useReport({
    onGridReady,
    columnDefs: COLUMN_DEFS,
  });

  return (
    <ReportWrapper>
      <AgGridReact
        {...report}
        masterDetail
        detailRowAutoHeight
        pagination
        rowData={data}
        rowSelection="multiple"
        rowClassRules={ROW_CLASS_RULES}
        rowGroupPanelShow="always"
        getRowStyle={getRowStyle}
      />
    </ReportWrapper>
  );
}
