import type { CatalogItemsResponse } from "../utilities/types";
import { requestClient, RequestClientConfig } from "./request/requestClient";

export async function getCatalogItems({
  supplierId,
  pharmacyId,
  ...config
}: RequestClientConfig<{
  pharmacyId: number;
  supplierId: number;
}>) {
  return requestClient<{ catalogItems: CatalogItemsResponse }>({
    ...config,
    url: `/v2/catalog-items`,
    params: { pharmacy_id: pharmacyId, supplier_id: supplierId },
  });
}
