import {
  useEffect,
  ReactNode,
  useState,
  useContext,
  createContext,
} from "react";
import { notificationsService } from "../../services/libs/notificationsService/notificationsService";
import { X_CLIENT_SESSION_ID } from "../../utilities/config";
import { useBuyingPharmacy } from "../BuyingPharmacyContext";

const ShoppingNotificationsContext = createContext<
  ReturnType<typeof useShoppingNotificationsContext> | undefined
>(undefined);

function useShoppingNotificationsContext() {
  const [activePrescriptionsPulledEvent, setActivePrescriptionsPulledEvent] =
    useState(1);
  const [activeCartUpdatedEvent, setActiveCartUpdatedEvent] = useState(0);
  const [newPrescriptionsPulledEvent, setNewPrescriptionsPulledEvent] =
    useState(false);
  const [newCartUpdatedEvent, setNewCartUpdatedEvent] = useState(false);

  const { currentBuyingPharmacy } = useBuyingPharmacy();
  const { cartNotificationsChannel } = currentBuyingPharmacy || {};

  useEffect(() => {
    if (!cartNotificationsChannel) return;

    const channel = notificationsService.subscribe(cartNotificationsChannel);

    channel.bind("prescriptions_pulled", (data: any) => {
      if (data.originatingClientSessionId === X_CLIENT_SESSION_ID) return;

      console.log("prescriptions_pulled", data);
      setNewPrescriptionsPulledEvent(true);
    });

    channel.bind("cart_updated", (data: any) => {
      if (data.originatingClientSessionId === X_CLIENT_SESSION_ID) return;

      console.log("cart_updated", data);
      setNewCartUpdatedEvent(true);
    });

    return () => {
      notificationsService.unsubscribe(cartNotificationsChannel);
    };
  }, [cartNotificationsChannel]);

  useEffect(() => {
    if (!newCartUpdatedEvent) return;
    setActiveCartUpdatedEvent((prev) => prev + 1);
    setNewCartUpdatedEvent(false);
  }, [newCartUpdatedEvent]);

  useEffect(() => {
    if (!newPrescriptionsPulledEvent) return;
    setActivePrescriptionsPulledEvent((prev) => prev + 1);
    setNewPrescriptionsPulledEvent(false);
  }, [newPrescriptionsPulledEvent]);

  return { activeCartUpdatedEvent, activePrescriptionsPulledEvent };
}

export function ShoppingNotificationsContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const context = useShoppingNotificationsContext();
  return (
    <ShoppingNotificationsContext.Provider value={context}>
      {children}
    </ShoppingNotificationsContext.Provider>
  );
}

export function useShoppingNotifications() {
  const context = useContext(ShoppingNotificationsContext);
  if (context === undefined) {
    throw new Error(
      "useShoppingNotifications must be used within a ShoppingNotificationsContextProvider"
    );
  }
  return context;
}
