import { RefObject, useState } from "react";
import { CSVLink } from "react-csv";
import ExportOutlined from "@ant-design/icons/ExportOutlined";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import type { AgGridReact } from "ag-grid-react";
import type { IDetailCellRendererParams } from "ag-grid-community";
import { Button } from "../../../../../../components/rxLibrary/buttons";
import { buildKeyHeaderMap, KeyHeaderMapping } from "./keyHeaderMap";
import { DownloadCsvWrapper } from "./DownloadWrapper";

function formatRowHeaders(
  row: any,
  keyHeaderMapping: KeyHeaderMapping[]
): Record<string, any> {
  const newRow = keyHeaderMapping.reduce<Record<string, any>>(
    (acc, { key, header, valueFormatter }) => {
      let value = row[key];
      if (valueFormatter) {
        if (typeof valueFormatter === "string") {
          value = valueFormatter;
        } else if (typeof valueFormatter === "function") {
          value = valueFormatter({ value });
        }
      }

      if (value !== null && value !== "" && value !== undefined) {
        acc[header] = value;
      }
      return acc;
    },
    {}
  );
  return newRow;
}

function formatHeaders({
  rowsWithChildrenData,
  columnsKeyHeaderMapping,
  childrenColumnsKeyHeaderMapping,
}: {
  rowsWithChildrenData: Record<string, any>[];
  columnsKeyHeaderMapping: KeyHeaderMapping[];
  childrenColumnsKeyHeaderMapping: KeyHeaderMapping[];
}) {
  const tableHeaders = uniq(
    columnsKeyHeaderMapping.map((mapping) => mapping.header)
  );
  // only include the headers that have data
  const tableHeadersWithData = tableHeaders.filter((header) => {
    const columnHasData = rowsWithChildrenData.some((row) => {
      const value = row[header];
      return value !== null && value !== "" && value !== undefined;
    });
    return columnHasData;
  });

  const tableHeadersSet = new Set(tableHeadersWithData);
  const childTableHeaders = childrenColumnsKeyHeaderMapping.map(
    (mapping) => mapping.header
  );
  // remove the duplicated child headers that are also in the main table
  const uniqueChildTableHeaders = childTableHeaders.filter(
    (header) => !tableHeadersSet.has(header)
  );

  const headers = [...tableHeadersWithData, ...uniqueChildTableHeaders];
  return headers;
}

function formatCsvDataWithChildren({
  gridRef,
  childrenKey = "claim",
  keyHeaderMap,
  detailCellRendererParams,
}: {
  gridRef: RefObject<AgGridReact>;
  childrenKey?: string;
  keyHeaderMap?: KeyHeaderMapping[];
  detailCellRendererParams?: Partial<IDetailCellRendererParams>;
}) {
  const visibleColumns = gridRef.current?.columnApi
    ?.getAllDisplayedColumns()
    ?.filter((col) => col.isPinned());
  const columnsKeyHeaderMapping = visibleColumns?.map(({ colDef }: any) =>
    buildKeyHeaderMap(colDef)
  );

  const childrenColumnsKeyHeaderMapping =
    keyHeaderMap ??
    detailCellRendererParams?.detailGridOptions?.columnDefs?.map((colDef) =>
      buildKeyHeaderMap(colDef)
    );

  const gridModel = gridRef.current?.api?.getModel();
  gridModel?.doRowsToDisplay();
  const data = gridModel?.rowsToDisplay
    ?.map((row: any) => row?.data)
    ?.filter(Boolean);

  if (
    !data?.length ||
    !columnsKeyHeaderMapping ||
    !childrenColumnsKeyHeaderMapping
  ) {
    return;
  }

  const hasChildren = data.some((row: any) => row[childrenKey]?.length);
  if (!hasChildren) return;

  const rowsWithChildrenData = data.flatMap((row: any) => {
    const children = row[childrenKey];
    if (!children?.length) return [];

    const rowWithTableHeaders = formatRowHeaders(row, columnsKeyHeaderMapping);
    const rows = children
      .map((child: any) => {
        const childWithTableHeaders = formatRowHeaders(
          child,
          childrenColumnsKeyHeaderMapping
        );
        if (isEmpty(childWithTableHeaders)) return;

        const rowWithChildData = {
          ...rowWithTableHeaders,
          ...childWithTableHeaders,
        };
        return rowWithChildData;
      })
      .filter(Boolean);
    return rows;
  });
  if (!rowsWithChildrenData.length) return;

  const headers = formatHeaders({
    rowsWithChildrenData,
    columnsKeyHeaderMapping,
    childrenColumnsKeyHeaderMapping,
  });

  return { data: rowsWithChildrenData, headers };
}

export function ButtonExportCsvWithChildren({
  text = "Export table with all the child grids",
  gridRef,
  filename = "export-with-child-grids.csv",
  childrenKey,
  keyHeaderMap,
  detailCellRendererParams,
}: {
  gridRef: RefObject<AgGridReact>;
  text?: string;
  filename?: string;
  childrenKey?: string;
  keyHeaderMap?: KeyHeaderMapping[];
  detailCellRendererParams?: Partial<IDetailCellRendererParams>;
}) {
  const [{ data = [], headers = [] } = {}, setCsvData] = useState<{
    data: any[];
    headers: string[];
  }>();

  function generateCsvData(event: any, done: (v: boolean) => void) {
    const expandedNodes: string[] = [];
    // collapse all the expanded nodes before generating the csv data
    gridRef.current?.api?.forEachNode((node) => {
      if (node.expanded) {
        node.setExpanded(false);
        if (node?.id) expandedNodes.push(node?.id);
      }
    });

    const csvData = formatCsvDataWithChildren({
      gridRef,
      childrenKey,
      keyHeaderMap,
      detailCellRendererParams,
    });

    // expand the nodes that were expanded before generating the csv data
    expandedNodes.forEach((nodeId) => {
      const node = gridRef.current?.api?.getRowNode(nodeId);
      if (node) {
        node.setExpanded(true);
      }
    });

    setCsvData(csvData);

    done(true);
  }

  return (
    <DownloadCsvWrapper text={text}>
      <CSVLink
        data={data}
        headers={headers}
        filename={filename}
        asyncOnClick={true}
        onClick={generateCsvData}
      >
        <Button icon={<ExportOutlined />} variant="tertiary">
          Export
        </Button>
      </CSVLink>
    </DownloadCsvWrapper>
  );
}
