import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import dayjs from "dayjs";
import keyBy from "lodash/keyBy";
import type { Manufacturer } from "../../../utilities/types";
import AppContext from "../../../contexts/AppContext";
import { LAST_MANUFACTURER_REPORT_ID_KEY } from "../../../utilities/localStorage/constants";
import { setLocalStorageValue } from "../../../utilities/localStorage/setLocalStorageValue";
import { useReplaceQueryParams } from "../../../utilities/queryParams/useReplaceQueryParams";
import { BE_DATE_FORMAT } from "../../../utilities/dates/dates.constants";
import { FullPageLoader } from "../../../components/loaders/FullPageLoader";
import { ReportError } from "../../../components/errors/ReportError";
import ManufacturersContext from "../../../contexts/ManufacturersContext";
import {
  ManufacturerReport,
  ManufacturerReportItem,
  BaseManufacturerReport,
  MANUFACTURER_REPORTS,
} from "../Manufacturer.constants";
import { getInitialManufacturerReport } from "./useManufacturerReport.getInitialManufacturerReport";

type UseManufacturerReportContextProviderReturn = ReturnType<
  typeof useManufacturerReportContextProvider
>;

const ManufacturerReportContext = createContext<
  | (Omit<UseManufacturerReportContextProviderReturn, "report" | "reports"> & {
      report: NonNullable<UseManufacturerReportContextProviderReturn["report"]>;
      reports: NonNullable<
        UseManufacturerReportContextProviderReturn["reports"]
      >;
    })
  | undefined
>(undefined);

function getInitialReportDates(): [dayjs.Dayjs, dayjs.Dayjs] {
  return [dayjs().subtract(7, "day"), dayjs().subtract(1, "day")];
}

function formatManufacturerReport(
  reportsById: Record<number, Manufacturer["reports"][number]>,
  item: BaseManufacturerReport,
  key: string
): ManufacturerReport {
  if ("children" in item) {
    const children = item.children.map((child, index) => {
      const childKey = `${key}.children[${index}]`;
      return formatManufacturerReport(reportsById, child, childKey);
    });
    return { ...item, key, children };
  }

  const report = reportsById[item.reportId];
  return { ...item, label: report.title, key };
}

function filterReportsByPermissions(
  reports: BaseManufacturerReport[],
  reportsById: Record<number, Manufacturer["reports"][number]>
): BaseManufacturerReport[] {
  const filteredReports = reports.reduce<typeof reports>((items, item) => {
    if ("children" in item) {
      const children = filterReportsByPermissions(item.children, reportsById);

      if (!children.length) return items;

      const newItem = { ...item, children: children };
      items.push(newItem);
      return items;
    }

    const report = reportsById[item.reportId];
    if (report) items.push(item);
    return items;
  }, []);

  return filteredReports;
}

function useManufacturerReportContextProvider() {
  const replaceQueryParams = useReplaceQueryParams();

  const [report, _setReport] = useState<ManufacturerReportItem>();
  const [reports, setReports] = useState<ManufacturerReport[]>();
  const [reportDates, _setReportDates] = useState(getInitialReportDates());
  const { manufacturer } = useContext(ManufacturersContext);
  const { id: manufacturerId, reports: manufacturerReports } =
    manufacturer ?? {};

  const { user } = useContext(AppContext);
  const isAdmin = user?.is_admin;
  const [shouldRenderReport, setShouldRenderReport] = useState(false);

  // When report.reportId changes, we need to update the reportDates
  // for the Pharmacy Data Licensing Payments Report (ID 58)
  useEffect(() => {
    if (!report) return;
    const { reportId } = report;
    if (reportId === 58) {
      _setReportDates([
        dayjs("01/01/2024", "MM/DD/YYYY"),
        dayjs().subtract(1, "day"),
      ]);
    }
  }, [report]);

  const renderReport = useCallback(() => {
    setShouldRenderReport(true);
  }, []);

  const setReport = useCallback(
    (newReport: ManufacturerReportItem) => {
      setShouldRenderReport(!isAdmin);
      _setReport(newReport);
    },
    [isAdmin]
  );

  const setReportDates = useCallback(
    (newReportDates: [dayjs.Dayjs, dayjs.Dayjs]) => {
      setShouldRenderReport(!isAdmin);
      _setReportDates(newReportDates);
    },
    [isAdmin]
  );

  useEffect(() => {
    if (isAdmin === undefined) return;
    setShouldRenderReport(!isAdmin);
  }, [isAdmin]);

  useEffect(() => {
    if (!manufacturerReports) {
      _setReport(undefined);
      setReports(undefined);
      return;
    }

    const reportsById = keyBy(manufacturerReports, "id");
    const filteredReports = filterReportsByPermissions(
      MANUFACTURER_REPORTS,
      reportsById
    );
    const formattedReports = filteredReports.map((item, index) => {
      return formatManufacturerReport(reportsById, item, `[${index}]`);
    });

    const initialReport = getInitialManufacturerReport(formattedReports);
    _setReport(initialReport);
    setReports(formattedReports);
  }, [manufacturerReports]);

  useEffect(() => {
    if (!report) return;

    const { reportId } = report;
    const [startDate, endDate] = reportDates;

    setLocalStorageValue(LAST_MANUFACTURER_REPORT_ID_KEY, reportId);
    const newQueryParams: Record<string, string> = {
      reportId: reportId.toString(),
      startDate: startDate.format(BE_DATE_FORMAT),
      endDate: endDate.format(BE_DATE_FORMAT),
    };
    if (manufacturerId) {
      newQueryParams.manufacturerId = manufacturerId.toString();
    }

    replaceQueryParams(newQueryParams);
  }, [report, reportDates, manufacturerId, replaceQueryParams]);

  return {
    report,
    reports,
    reportDates,
    shouldRenderReport,
    setReport,
    renderReport,
    setReportDates,
  };
}

export function ManufacturerReportContextProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const value = useManufacturerReportContextProvider();
  const { report, reports, ...otherProps } = value;

  if (!reports) return <FullPageLoader text="Loading Reports" />;
  if (!report) return <ReportError />;

  return (
    <ManufacturerReportContext.Provider
      value={{
        ...otherProps,
        report,
        reports,
      }}
    >
      {children}
    </ManufacturerReportContext.Provider>
  );
}

export function useManufacturerReport() {
  const context = useContext(ManufacturerReportContext);
  if (!context) {
    throw new Error(
      "useManufacturerReport must be used within a ManufacturerReportContextProvider"
    );
  }
  return context;
}
