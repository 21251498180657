import { useMemo, ComponentProps } from "react";
import tw from "twin.macro";
import type { TextProps } from "./typography/Text";
import { Text } from "./typography";

const StyledLink = tw(Text.withComponent("a"))`underline`;

export function SimpleLink({
  noStyle,
  openInNewTab,
  ...props
}: ComponentProps<"a"> & { noStyle?: boolean; openInNewTab?: boolean } & TextProps) {
  const openInNewTabProps = useMemo(() => {
    return openInNewTab ? { target: "_blank", rel: "noopener noreferrer" } : {};
  }, [openInNewTab]);

  const L = noStyle ? "a" : StyledLink;
  return <L {...openInNewTabProps} {...props} />;
}

export function EmailLink({ email, ...props }: { email?: string } & TextProps) {
  const href = useMemo(() => {
    return !!email && `mailto:${email}`;
  }, [email]);

  if (!href) return null;
  return (
    <StyledLink {...props} href={href}>
      {email}
    </StyledLink>
  );
}
