export type KeyHeaderMapping = {
  key: string;
  header: string;
  valueFormatter?: string | ((params: { value: any }) => any);
};

export function buildKeyHeaderMap(colDef: any): KeyHeaderMapping {
  const { field, headerName, valueFormatter } = colDef;
  const header = headerName ?? field;
  return { key: field, header, valueFormatter };
}
