import dayjs from "dayjs";
import { Text } from "../../rxLibrary/typography";
import { Box } from "../../rxLibrary/box";
import { Switch } from "../../rxLibrary/switch";
import {
  DateRangePicker,
  DateRangePickerValue,
  usePresetRangesWithHighlight,
} from "../../rxLibrary/dateRangePicker";
import {
  MultiSelectCheckboxes,
  MultiSelectCheckboxesSelectedTags,
} from "../../rxLibrary/selects/MultiSelectCheckboxes";
import { useRefillOpportunities } from "../useRefillOpportunities";

function getDateRangesPresets(): {
  label: string;
  value: DateRangePickerValue;
}[] {
  return [
    {
      label: "Last 180 Days",
      value: [dayjs().subtract(180, "day"), dayjs()],
    },
    {
      label: "Last 90 Days",
      value: [dayjs().subtract(90, "day"), dayjs()],
    },
    {
      label: "Last 60 Days",
      value: [dayjs().subtract(60, "day"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().subtract(30, "day"), dayjs()],
    },
    {
      label: "Now to end-of-week",
      value: [dayjs(), dayjs().endOf("week")],
    },
    {
      label: "Next 7 Days",
      value: [dayjs(), dayjs().add(7, "day")],
    },
  ];
}

export function RefillOpportunitiesTableHeader() {
  const {
    dates,
    manufacturerFilters,
    manufacturerOptions,
    showOnlyCoveredFills,
    showOnlyPaidOutreaches,
    reportRefillOpportunity,
    setDates,
    setManufacturerFilters,
    setShowOnlyCoveredFills,
    setShowOnlyPaidOutreaches,
  } = useRefillOpportunities();
  const presets = usePresetRangesWithHighlight(dates, getDateRangesPresets);

  const { pharmacyIsInPaidOutreachProgram } = reportRefillOpportunity ?? {};

  const showManufacturerFilter =
    manufacturerFilters &&
    !!manufacturerOptions &&
    manufacturerOptions.length > 1;

  const showManufacturerTags =
    showManufacturerFilter && manufacturerFilters.length !== 0;

  return (
    <div>
      <Box
        tw="rounded-t-lg flex items-center justify-between p-[24px] border"
        bgColor="grey-5"
        borderColor="grey-3"
      >
        <div tw="flex items-center space-x-[40px]">
          {showManufacturerFilter && (
            <MultiSelectCheckboxes
              title="Select Manufacturers to Show"
              filters={manufacturerFilters}
              options={manufacturerOptions}
              setFilters={setManufacturerFilters}
            />
          )}

          <DateRangePicker
            label="Date Range"
            value={dates}
            onChange={setDates}
            presets={presets}
            minDate={dayjs("01/01/2024", "MM/DD/YYYY")}
            maxDate={dayjs().add(7, "day")}
          />
        </div>

        <div>
          <div tw="flex items-center space-x-1">
            <Switch
              checked={showOnlyCoveredFills}
              onChange={setShowOnlyCoveredFills}
            />
            <Text size="medium">Show only covered Rxs</Text>
          </div>

          {pharmacyIsInPaidOutreachProgram && (
            <div tw="flex items-center space-x-1">
              <Switch
                checked={showOnlyPaidOutreaches}
                onChange={setShowOnlyPaidOutreaches}
              />
              <Text size="medium">Show only eligible for $5 outreach pay</Text>
            </div>
          )}
        </div>
      </Box>

      {showManufacturerTags && (
        <Box
          tw="flex items-center px-[24px] py-4 my-1"
          bgColor="grey-5"
          borderColor="grey-3"
        >
          <MultiSelectCheckboxesSelectedTags
            filters={manufacturerFilters}
            options={manufacturerOptions}
            setFilters={setManufacturerFilters}
          />
        </Box>
      )}
    </div>
  );
}
