import React, { useEffect, useRef, useState } from "react";
import { Global, css } from "@emotion/react";
import { CHAT_BUTTON_Z_INDEX, IS_ELECTRON } from "../utilities/config";
import { useBuyingPharmacy } from "../contexts/BuyingPharmacyContext";
import { Text } from "./rxLibrary/typography";
import { Box } from "./rxLibrary/box";

export function PharmacyLoader() {
  const { currentBuyingPharmacyId } = useBuyingPharmacy();
  const [isVisible, setIsVisible] = useState(false);
  const isFirstRenderRef = useRef(true);

  useEffect(() => {
    if (!IS_ELECTRON) return;

    if (!currentBuyingPharmacyId) return;
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }

    setIsVisible(true);
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 20000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [currentBuyingPharmacyId]);

  if (!isVisible) return null;
  return (
    <Box
      bgColor="grey-4"
      tw="fixed top-0 left-0 w-full h-full flex items-center justify-center z-30"
    >
      <div tw="flex flex-col items-center">
        <img tw="mb-4" src="/assets/circle_loader.gif" />
        <Text size="x-large" color="orange-1">
          Changing Pharmacies
        </Text>
      </div>

      <Global
        styles={css`
          html,
          body {
            margin: 0;
            height: 100%;
            overflow: hidden;
          }
          header {
            position: relative;
            z-index: ${CHAT_BUTTON_Z_INDEX};
          }
        `}
      />
    </Box>
  );
}
