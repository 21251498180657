import React, { ReactNode, useMemo } from "react";
import type {
  ItemInCart,
  ItemInModal,
} from "../../../../../../utilities/types";
import { formatManufacturerInfo } from "../../../../../../utilities/prescriptions/formatManufacturerInfo";
import { formatDrugNameWithDetails } from "../../../../../../utilities/drugInfo/formatDrugNameWithDetails";
import { formatDrugDispenseQuantity } from "../../../../../../utilities/drugInfo/formatDrugDispenseQuantity";
import { Box } from "../../../../../../components/rxLibrary/box";
import { Switch } from "../../../../../../components/rxLibrary/switch";
import {
  getTextCss,
  Text,
} from "../../../../../../components/rxLibrary/typography";
import { PrescriptionTags } from "../../../../../../components/shopping/PrescriptionTags/PrescriptionTags";
import { ShoppingRxItemPanelInventory } from "./ShoppingRxItemPanelInventory";
import { DrugModal } from "../../../../../../components/DrugModal";
import { useModal } from "../../../../../../components/rxLibrary/modals";

export function ShoppingRxItemPanel({
  item,
  children,
  isInventory,
  itemNotePopover,
  updateItemPackSize,
  updateItemManufacturer,
}: {
  item: ItemInCart | ItemInModal;
  children: ReactNode;
  isInventory?: boolean;
  itemNotePopover?: false | JSX.Element;
  updateItemPackSize: (value: boolean) => void;
  updateItemManufacturer: (value: boolean) => void;
}) {
  const { isModalOpen, openModal, closeModal } = useModal();

  const {
    drug,
    packSize: hasPackSize,
    manufactutrer: hasManufacturer,
    noManufacturerPreference,
  } = item;
  const displayManufacturer = !(isInventory && noManufacturerPreference);
  const packQuantity =
    isInventory && "packQuantity" in item ? item.packQuantity : undefined;

  const drugDetails = useMemo(() => formatDrugNameWithDetails(drug), [drug]);

  const manufacturerInfo = useMemo(() => {
    return formatManufacturerInfo(drug, noManufacturerPreference);
  }, [drug, noManufacturerPreference]);

  const quantityDescription = useMemo(() => {
    return formatDrugDispenseQuantity(drug);
  }, [drug]);

  return (
    <>
      <Box
        tw="flex rounded shadow-[0_0_1px_1px_#00000014] pt-[8px] pb-[11px] h-[87px]"
        bgColor="white-1"
      >
        <Box tw="border-r flex-1 px-[10px]" borderColor="grey-2">
          <button
            tw="hover:underline cursor-pointer block text-left"
            css={getTextCss({
              weight: "bold",
            })}
            onClick={openModal}
          >
            {drugDetails}
          </button>

          <Text block>
            {quantityDescription} | {manufacturerInfo}
          </Text>
          <Text block>
            <ShoppingRxItemPanelInventory
              item={item}
              isInventory={isInventory}
            />
          </Text>
        </Box>

        {children}

        <Box tw="flex flex-col justify-between w-[135px] pl-[10px] pr-[4px]">
          <div tw="space-y-[2px]">
            <div tw="flex items-center">
              <div tw="flex items-center space-x-1">
                <Switch checked={hasPackSize} onChange={updateItemPackSize} />
                <Text>Pack size</Text>
              </div>
              <div>{itemNotePopover}</div>
            </div>

            {displayManufacturer && (
              <div tw="flex items-center space-x-1">
                <Switch
                  checked={hasManufacturer}
                  onChange={updateItemManufacturer}
                />
                <Text>Manufacturer</Text>
              </div>
            )}
          </div>

          <div>
            <PrescriptionTags
              item={item}
              isInventory={isInventory}
              packQuantity={packQuantity}
              small
            />
          </div>
        </Box>
      </Box>

      {isModalOpen && <DrugModal drugInfo={drug} onClose={closeModal} />}
    </>
  );
}
