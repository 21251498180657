import { ItemInCart, ItemNote } from "../../utilities/types";
import { IconNote } from "../rxLibrary/icons";
import { IconButton } from "../rxLibrary/buttons";
import { Popover, useIsPopoverOpen } from "../rxLibrary/popover";
import { PrescriptionNotePopover } from "./PrescriptionNotePopover";

export function usePrescriptionNote({
  prescription,
  updatePrescriptionNote,
}: {
  prescription: ItemInCart;
  updatePrescriptionNote: (prescription: ItemInCart, note?: ItemNote) => void;
}) {
  const {
    isPopoverOpen: isNotePopoverOpen,
    openPopover: openPrescriptionNotePopover,
    closePopover: closePrescriptionNotePopover,
    handleOpenPopoverChange: handleOpenPrescriptionNotePopoverChange,
  } = useIsPopoverOpen();

  const hasPrescriptionNote = !!prescription.note;
  const renderPrescriptionNotePopover =
    isNotePopoverOpen || hasPrescriptionNote;

  const prescriptionNotePopover = renderPrescriptionNotePopover && (
    <Popover
      placement="bottomLeft"
      trigger={["contextMenu", "click"]}
      open={isNotePopoverOpen}
      onOpenChange={handleOpenPrescriptionNotePopoverChange}
      content={() => (
        <PrescriptionNotePopover
          prescription={prescription}
          close={closePrescriptionNotePopover}
          updatePrescriptionNote={updatePrescriptionNote}
        />
      )}
    >
      {hasPrescriptionNote && (
        <IconButton onClick={openPrescriptionNotePopover}>
          <IconNote />
        </IconButton>
      )}
    </Popover>
  );

  return {
    hasPrescriptionNote,
    prescriptionNotePopover,
    openPrescriptionNotePopover,
  };
}
