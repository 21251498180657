import React from "react";
import "twin.macro";
import DispensedItem from "../../../../../../components/DispensedItem";
import { Text } from "../../../../../../components/rxLibrary/typography";
import { CatalogInfo, DrugInfo } from "../../../../../../utilities/types";
import { Image } from "../../../../../../components/Image";
import { DrugModal } from "../../../../../../components/DrugModal";
import { useModal } from "../../../../../../components/rxLibrary/modals";

export function ShoppingListTableRowDrugDetailsCol({
  drug,
  catalogInfo,
}: {
  drug: DrugInfo;
  catalogInfo?: CatalogInfo;
}) {
  const { isModalOpen, openModal, closeModal } = useModal();

  return (
    <>
      <div tw="flex items-start">
        <Image
          tw="w-[52px] mr-2 object-contain cursor-pointer"
          src={drug.imageURI}
          onClick={openModal}
        />

        <Text>
          <DispensedItem
            drug={drug}
            catalogInfo={catalogInfo}
            onTitleClick={openModal}
          />
        </Text>
      </div>

      {isModalOpen && <DrugModal drugInfo={drug} onClose={closeModal} />}
    </>
  );
}
