import type {
  PageAlert,
  AdminPageAlert,
  UpsertAdminPageAlert,
  AdminPageAlertOptions,
} from "../utilities/types";
import { requestClient, RequestClientConfig } from "./request/requestClient";

export async function getPageAlerts(config?: RequestClientConfig) {
  return requestClient<{ pageAlerts: PageAlert[] }>({
    ...config,
    url: "/page-alert",
  });
}

export async function getAdminPageAlerts(
  config?: RequestClientConfig<Record<string, never>>
) {
  return requestClient<{ pageAlerts: AdminPageAlert[] }>({
    ...config,
    url: "/admin/page-alert",
  });
}

export async function getAdminPageAlert({
  id,
  ...config
}: RequestClientConfig<{
  id: number | string;
}>) {
  return requestClient<{ pageAlert: AdminPageAlert }>({
    ...config,
    url: `/admin/page-alert/${id}`,
  });
}

export async function getAdminPageAlertsOptions(config?: RequestClientConfig) {
  return requestClient<{ pageAlertOptions: AdminPageAlertOptions }>({
    ...config,
    url: "/admin/page-alert/options",
  });
}

export async function createAdminPageAlert({
  enabled,
  message,
  pages,
  pharmacyIds,
  electronOnly,
  blacklistedPages,
  electronMaxVersion,
  ...config
}: RequestClientConfig<UpsertAdminPageAlert>) {
  return requestClient<{ pageAlert: AdminPageAlert }>({
    ...config,
    url: "/admin/page-alert",
    method: "POST",
    data: {
      enabled,
      message,
      pages,
      pharmacyIds,
      electronOnly,
      blacklistedPages,
      electronMaxVersion,
    },
  });
}

export async function updateAdminPageAlert({
  id,
  enabled,
  message,
  pages,
  pharmacyIds,
  electronOnly,
  blacklistedPages,
  electronMaxVersion,
  ...config
}: RequestClientConfig<UpsertAdminPageAlert>) {
  return requestClient<{ pageAlert: AdminPageAlert }>({
    ...config,
    url: `/admin/page-alert/${id}`,
    method: "PUT",
    data: {
      enabled,
      message,
      pages,
      pharmacyIds,
      electronOnly,
      blacklistedPages,
      electronMaxVersion,
    },
  });
}

export async function deleteAdminPageAlert({
  id,
  ...config
}: RequestClientConfig<{ id: number | string }>) {
  return requestClient<void>({
    ...config,
    url: `/admin/page-alert/${id}`,
    method: "DELETE",
  });
}
