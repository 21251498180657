import { useMemo } from "react";
import { Text } from "../../components/rxLibrary/typography";
import { usePerformance } from "./usePerformance";

export function PerformanceFooter() {
  const { reports, reportId } = usePerformance();

  /**
   * If report is returned from API as not visible, it means it is admin report
   * Standard users will only get visible reports
   */
  const isAdminReport = useMemo(() => {
    const report = reports.find((r) => r.id === reportId);

    if (report && !report.visible) {
      return true;
    }

    return false;
  }, [reports, reportId]);

  if (!isAdminReport) {
    return;
  }

  return (
    <Text center block>
      * This report will not appear for non-admin users from this pharmacy
    </Text>
  );
}
