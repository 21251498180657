import { useMemo } from "react";
import type {
  AgCartesianSeriesTooltipRendererParams,
  AgChartOptions,
} from "ag-charts-community";
import { AgCharts } from "ag-charts-react";
import dayjs from "dayjs";
import type { DailyRevenueTransactionsType } from "./overviewReport.types";
import { BE_DATE_FORMAT, DATE_WITH_YEAR } from "../../../../utilities/dates/dates.constants";
import { numberFormatter } from "../utils/performanceReportFormatters";
import { rxChartTheme } from "../rxChart.theme";

function renderTooltip({
  datum,
  xKey,
  yKey,
  yName,
}: AgCartesianSeriesTooltipRendererParams) {
  const title = dayjs(datum[xKey]).format(DATE_WITH_YEAR);
  return {
    title: title,
    content: `${yName}: ${numberFormatter(datum[yKey])}`,
  };
}

const OPTIONS: AgChartOptions = {
  theme: rxChartTheme,
  title: {
    text: "Daily Transactions",
  },
  series: [
    {
      type: "bar",
      xKey: "date",
      yKey: "transactions",
      yName: "Transactions",
      fill: "rgb(55, 176, 103)",
      strokeWidth: 1,
      stroke: "transparent",
      tooltip: {
        renderer: renderTooltip,
      },
    },
  ],
  axes: [
    {
      type: "time",
      position: "bottom",
      label: {
        format: "%b%e %Y",
        rotation: -45,
      },
      title: {
        text: "Transactions",
      },
    },
    {
      type: "number",
      position: "left",
    },
  ],
};

export function DailyTransactionBarChart({
  data,
}: {
  data?: DailyRevenueTransactionsType[];
}) {
  const reportData = useMemo(
    () =>
      data?.map((v) => {
        const parsedDate = dayjs(v["date"], BE_DATE_FORMAT).toDate();
        return { ...v, date: parsedDate };
      }),
    [data]
  );

  return <AgCharts options={{ data: reportData, ...OPTIONS }} />;
}
