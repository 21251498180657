import { useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import EnhancedClientConfigContext from "../../enhanced/EnhancedClientConfigContext";
import { Box } from "../rxLibrary/box";
import { IconBell } from "../rxLibrary/icons";
import { Text } from "../rxLibrary/typography";
import { WideContainer } from "../containers/WideContainer";
import "../../scss/enhAppWidthCheck.scss";

const PAGE_ALERT_WINDOWWIDTH_WHITELISTED_PATHS = [
  "/selectDrug",
  "/shoppingList",
  "/shoppingListTwo",
  "/shopping-list-add-rx",
  "/supplierAccounts",
  "/search",
];

export function PageAlertWindowWidth() {
  const { pathname } = useLocation();

  const { enhancedClientActive } = useContext(EnhancedClientConfigContext);

  const monitorPageWidth = useMemo(() => {
    if (!enhancedClientActive) return false;

    const firstPath = pathname.split("/").find((s: string) => s.length);

    if (!firstPath) {
      return false;
    }

    return PAGE_ALERT_WINDOWWIDTH_WHITELISTED_PATHS.includes(`/${firstPath}`);
  }, [enhancedClientActive, pathname]);

  if (!monitorPageWidth) return null;

  return (
    <Box
      className="enhAppWidthCheck"
      tw="relative mb-[3px] z-50"
      bgColor="red-1"
    >
      <WideContainer tw="flex items-center justify-center py-[15px] space-x-[8px]">
        <div>
          <IconBell color="white-1" />
        </div>

        <Text color="white-1" weight="bold" size="large">
          Your window is too small to search supplier sites. Please maximize
          DaylightRx.
        </Text>
      </WideContainer>
    </Box>
  );
}
