import React from "react";

export function CutOffTime(item: string) {
  const items = item.split("|");

  return (
    <>
      {items.map((item, index) => {
        return <p key={index}>{item}</p>;
      })}
    </>
  );
}