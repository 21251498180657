import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { ParsedSearchTypeEnum, parseSearch } from "../../../utilities/search";
import { Suppliers } from "../../../components/suppliers/Suppliers";
import { Box } from "../../../components/rxLibrary/box";
import { NDCInput } from "../../../components/NDCInput";

export function CompareSearchBar({
  ndc,
  disabled,
  dispensed,
  textSearch,
  dispensedValue,
}: {
  ndc: string;
  disabled?: boolean;
  dispensed?: boolean;
  textSearch?: boolean;
  dispensedValue?: string | null;
}) {
  const history = useHistory();
  const form = useForm({ defaultValues: { ndc } });

  const onSubmit = form.handleSubmit(({ ndc: valueRaw }: { ndc: string }) => {
    const parsedSearch = parseSearch(valueRaw);
    const value = parsedSearch.value;
    const searchByNdc = parsedSearch.type === ParsedSearchTypeEnum.NDC;
    const path = searchByNdc
      ? `/compare?ndc=${value}`
      : `/selectDrug?search=${value}`;
    history.push(path);

    if (searchByNdc && value === ndc) {
      // Reload the page if the user searches for the same NDC to force a new search
      window.location.reload();
    }
  });

  const text = useMemo(() => {
    if (!dispensed) return;

    if (dispensedValue) {
      return (
        <>
          Dispensed <strong tw="font-bold">{dispensedValue}</strong>, including
          substitutable equivalents, in the last 30 days
        </>
      );
    }

    if (textSearch) return "Not dispensed in 30+ days";

    return "Drug group has not been dispensed in the last 30 days";
  }, [dispensed, dispensedValue, textSearch]);

  return (
    <>
      <Suppliers />

      <Box tw="p-[25px]" bgColor="grey-4" className="text-center mt-2">
        <div className="flex font-300 justify-center flex-col items-center">
          <div tw="max-w-[600px] w-full mb-2">
            <FormProvider {...form}>
              <form onSubmit={onSubmit} tw="flex items-center">
                <NDCInput disabled={disabled} name="ndc" required />
              </form>
            </FormProvider>
          </div>

          {!!text && <p tw="text-lg">{text}</p>}
        </div>
      </Box>
    </>
  );
}
