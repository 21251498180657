import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import tw from "twin.macro";
import EnhancedClientContext, {
  EnhancedStockValidationAvailability,
} from "../../../../../../enhanced/EnhancedClientContext";
import EnhancedClientConfigContext from "../../../../../../enhanced/EnhancedClientConfigContext";
import EnhancedClientCommandContext from "../../../../../../enhanced/EnhancedClientCommandContext";
import {
  NdcOffering,
  NdcOfferingSet,
} from "../../../../../../enhanced/result_types";
import { useBuyingPharmacy } from "../../../../../../contexts/BuyingPharmacyContext";
import {
  TaggedItem,
  ShippingConfig,
  ListCategoryEnum,
  SuggestedNdcReason,
} from "../../../../../../utilities/types";
import { tryWriteClipboard } from "../../../../../../utilities/clipboard";
import { shallowLinksOnlyForSupplier } from "../../../../../../utilities/search";
import { formatNdc } from "../../../../../../utilities/ndc/formatNdc";
import { getDrugMeasureDisplay } from "../../../../../../utilities/drugInfo/getDrugMeasureDisplay";
import {
  currencyWithFractionOfPenny,
  currencyWithCentsUnlessGreaterThanOrEqualToOneThousand,
} from "../../../../../../utilities/numbers/currency";
import { formatDrugDispenseQuantity } from "../../../../../../utilities/drugInfo/formatDrugDispenseQuantity";
import { formatDrugNameWithDetails } from "../../../../../../utilities/drugInfo/formatDrugNameWithDetails";
import { Container } from "../../../../../../components/containers/Container";
import AccordionPanel from "../../../../../../components/AccordionPanel";
import { IconDown } from "../../../../../../components/rxLibrary/icons";
import { ndcOfferingCostPerDose } from "../../ndcOfferingCostPerDose";
import { CompareCardDetails } from "./CompareCardDetails/CompareCardDetails";
import { useHandleNdcLinkClick } from "./useHandleNdcLinkClick";
import { useObtainEnhancedClientDeepLink } from "./useObtainEnhancedClientDeepLink";
import { useDetermineEcommerceByOffering } from "./useDetermineEcommerce";
import { CompareCardItemDispensation } from "./CompareCardItemDispensation";
import { CompareCardItemProjection } from "./CompareCardItemProjection";
import { getShippingConfig } from "./getShippingConfig";
import { MinOrder } from "./MinOrder";
import { CutOffTime } from "./CutOffTime";
import { LowerLabel } from "./LowerLabel";

const FeatureStyles = {
  alt: tw`bg-[#7e7e7e]`,
  primeblue: tw`bg-primeblue`,
};

const ButtonStyles = {
  alt: tw`hover:bg-gradient-to-t from-[#6f7073] to-[#95979c] hover:text-white`,
  primeblue: tw`hover:bg-gradient-to-t from-[#4670e3] to-[#3d57cf] hover:text-white`,
};

function FeaturedShipping({ featured }: { featured: NdcOffering }) {
  const { currentBuyingPharmacy } = useBuyingPharmacy();
  const [shippingData, setShippingData] = useState<ShippingConfig | null>(null);

  let supplierName;
  useEffect(() => {
    if (currentBuyingPharmacy) {
      supplierName = featured.item.catalogInfo.supplierDisplayName;
      const d = getShippingConfig(
        supplierName,
        currentBuyingPharmacy?.suppliers
      );

      setShippingData(d);
    }
  }, [currentBuyingPharmacy]);

  return (
    <>
      {shippingData && (
        <div tw="border-t border-white p-[10px] text-white w-[100%]">
          <p tw="font-bold">
            {featured.item.catalogInfo.supplierDisplayName} Info
          </p>
          <div tw="mt-[8px]">{MinOrder(shippingData)}</div>
          <>
            {!!shippingData?.cutoffTimes && shippingData.cutoffTimes !== "" && (
              <>
                <p tw="mt-[16px]">Cutoff time:</p>
                {CutOffTime(shippingData.cutoffTimes.toString())}
              </>
            )}
          </>
        </div>
      )}
    </>
  );
}

function FeaturedPanel({
  featuredResult,
  isBelowTheFold,
  shippingPanelCallback,
  shippingPanel,
}: {
  featuredResult: NdcOffering;
  isBelowTheFold?: boolean;
  shippingPanelCallback: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void;
  shippingPanel: boolean;
}) {
  const { enhancedClientActive, enhancedClientVersion } = useContext(
    EnhancedClientConfigContext
  );
  const { showSupplier } = useContext(EnhancedClientCommandContext);
  const { unskipSupplier } = useContext(EnhancedClientContext);
  const handleLinkClick = useHandleNdcLinkClick(featuredResult);

  const featuredItem = featuredResult.item;
  const featuredDrugInfo = featuredItem.drugInfo;
  const quantityDesc = formatDrugDispenseQuantity(featuredDrugInfo);
  const ndc = featuredDrugInfo.ndc;

  const bgStyle = isBelowTheFold ? FeatureStyles.alt : FeatureStyles.primeblue;
  const btnStyle = isBelowTheFold ? ButtonStyles.alt : ButtonStyles.primeblue;

  const cta =
    featuredResult.supplierData &&
    featuredResult.supplierData.availability ===
      EnhancedStockValidationAvailability.BACKORDERED
      ? "Back Order"
      : `Get at ${featuredItem.catalogInfo.supplierDisplayName}`;
  const isEcommerce = useDetermineEcommerceByOffering(featuredResult);
  const deepLink = useObtainEnhancedClientDeepLink(featuredResult);
  const isShallow = shallowLinksOnlyForSupplier(
    featuredItem.catalogInfo.supplier,
    enhancedClientVersion
  );

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      tryWriteClipboard(ndc);
      if (isShallow && enhancedClientActive) {
        e.preventDefault();
        showSupplier(featuredResult.item.catalogInfo.supplier);
      } else {
        handleLinkClick(e);
      }
    },
    [enhancedClientActive, showSupplier, isShallow, handleLinkClick]
  );

  return (
    <div
      tw="p-[24px] flex text-white items-center rounded-tr-lg rounded-br-lg flex-col self-stretch w-[220px]"
      css={bgStyle}
    >
      {featuredResult.loginRequired ? (
        <div
          tw="flex mb-[24px] mt-[24px] justify-around"
          css={{ width: "100%" }}
        >
          <p tw="text-[20px] font-medium">Login to see price</p>
        </div>
      ) : (
        <div tw="flex justify-between mb-[24px]" css={{ width: "100%" }}>
          <div tw="flex flex-col max-w-[78px]">
            <p tw="text-[22px] font-medium">
              <sup style={{ top: "-4px" }}>$</sup>
              {currencyWithCentsUnlessGreaterThanOrEqualToOneThousand(
                featuredResult.price,
                true
              )}
            </p>
            {featuredResult.supplierData?.isRebatedItem && (
              <p tw="text-[13px] font-medium">w/ Rebate</p>
            )}
            {featuredResult.supplierData &&
              featuredResult.supplierData.availability ===
                EnhancedStockValidationAvailability.UNAVAILABLE && (
                <p tw="text-[13px] font-medium">Not in Stock</p>
              )}
            {featuredResult.supplierData &&
              featuredResult.supplierData.availability ===
                EnhancedStockValidationAvailability.PENDING && (
                <p tw="text-[13px] font-medium">Unverified</p>
              )}
            <LowerLabel>{quantityDesc}</LowerLabel>
          </div>
          <div tw="flex flex-col items-center max-w-[78px]">
            <p tw="text-[22px] font-medium">
              <sup style={{ top: "-4px" }}>$</sup>
              {currencyWithFractionOfPenny(
                ndcOfferingCostPerDose(featuredResult),
                true
              )}
            </p>
            <LowerLabel>
              Per {getDrugMeasureDisplay(featuredResult.item.drugInfo)}
            </LowerLabel>
          </div>
        </div>
      )}

      {/* 7443 */}
      {/* {featuredItem.catalogInfo && <div className="flex justify-start strech mb-12"><ExpirationDate textOnly catalogInfo={featuredItem.catalogInfo} /></div>} */}

      {featuredResult.loginRequired ? (
        <a
          tw="w-[100%] rounded border-white border text-white py-2 px-2 text-base font-bold text-center cursor-pointer"
          css={btnStyle}
          onClick={(e) => {
            e.preventDefault();
            unskipSupplier(featuredItem.catalogInfo.supplier);
          }}
        >
          Login to {featuredItem.catalogInfo.supplierDisplayName}
        </a>
      ) : (
        <a
          href={isEcommerce ? deepLink : featuredItem.catalogInfo.link}
          tw="w-[100%] rounded border-white border text-white py-2 px-2 text-base font-bold text-center"
          css={btnStyle}
          className={isShallow ? `catalog-link-shallow` : `catalog-link`}
          data-supplier={featuredItem.catalogInfo.supplier}
          data-supplier-id={featuredItem.catalogInfo.supplierId}
          data-drug-descriptive-name={formatDrugNameWithDetails(
            featuredItem.drugInfo
          )}
          data-drug-manufacturer-name={featuredItem.drugInfo.manufacturer}
          data-is-ecommerce={isEcommerce}
          data-ndc={ndc}
          data-deeplink={deepLink}
          onClick={onClick}
        >
          {cta}
        </a>
      )}

      <button
        onClick={() => shippingPanelCallback(!shippingPanel)}
        tw="flex items-center mt-[12px] hover:text-white"
      >
        {!shippingPanel && (
          <>
            <span>Shipping Info</span>
            <IconDown tw="ml-2" />
          </>
        )}
        {shippingPanel && (
          <>
            <span>Collapse</span>
            <IconDown tw="ml-2 rotate-180" />
          </>
        )}
      </button>

      {/* Shipping Info */}
      <AccordionPanel open={shippingPanel}>
        <FeaturedShipping featured={featuredResult} />
      </AccordionPanel>
    </div>
  );
}

function quantityLabel(item: TaggedItem, match: TaggedItem | undefined) {
  if (!match) return "quantity";

  const itemCount = item.drugInfo.unitQuantity * item.drugInfo.unitSize;
  const matchCount = match.drugInfo.unitQuantity * match.drugInfo.unitSize;

  return `${itemCount > matchCount ? "larger" : "smaller"} quantity`;
}

function quantityManufacturerLabel(item: TaggedItem, match?: TaggedItem) {
  return `other manufacturer ${quantityLabel(item, match)}`;
}

function Savings({
  ndcOffering,
  mainNdcOffering,
}: {
  ndcOffering: NdcOffering;
  mainNdcOffering?: NdcOffering;
}) {
  const savings = useMemo(() => {
    if (!mainNdcOffering) return 0;

    const mainItemCost = ndcOfferingCostPerDose(mainNdcOffering);
    const featuredItemCost = ndcOfferingCostPerDose(ndcOffering);
    const percentage = ((mainItemCost - featuredItemCost) / mainItemCost) * 100;

    if (percentage < 0) return 0;
    if (percentage <= 0.5) return percentage;
    if (percentage < 1) return 1;

    const savingsPercentage = Math.round(percentage);
    return savingsPercentage;
  }, [ndcOffering, mainNdcOffering]);

  const displayLessThan = savings > 0 && savings <= 0.5;

  return (
    <div tw="flex flex-col items-center justify-center">
      {displayLessThan && <p tw="leading-none text-[9px]">Less than</p>}
      <p tw="leading-none">
        {displayLessThan ? 1 : savings}
        <sup tw="text-[13px] font-light" style={{ top: "-2px" }}>
          %
        </sup>
      </p>
    </div>
  );
}

function TagPanel({
  category,
  ndcOffering,
  isBelowTheFold,
  mainNdcOffering,
}: {
  ndcOffering: NdcOffering;
  category?: ListCategoryEnum;
  isBelowTheFold?: boolean;
  mainNdcOffering?: NdcOffering;
}) {
  const differentManufacturer =
    category === ListCategoryEnum.DIFFERENT_MANUFACTURERS;
  const differentQuantity = category === ListCategoryEnum.DIFFERENT_QUANTITIES;
  const differentManufacturerQuantity =
    category === ListCategoryEnum.DIFFERENT_MANUFACTURER_AND_QUANTITY;
  const alternatePackaging = ndcOffering.item.alternatePackaging;

  return (
    <div tw="p-[24px] flex justify-between items-start flex-col self-stretch w-[220px] border-l border-dashed border-black">
      {!isBelowTheFold && (
        <div>
          {/* NDC Match */}
          {category === ListCategoryEnum.NDC_MATCH && (
            <div tw="flex flex-row items-center">
              <img
                src="/assets/pages/compare/ndc.svg"
                alt="NDC Match"
                tw="w-[56px] h-[56px]"
              />
              <span
                tw="uppercase text-primeblue text-base font-bold ml-[8px] leading-5"
                style={{ wordSpacing: "100vw" }}
              >
                NDC Match
              </span>
            </div>
          )}

          {/* Suggested NDC */}
          {category === ListCategoryEnum.SUGGESTED_NDC && (
            <div tw="flex flex-row items-center">
              <img
                src="/assets/pages/compare/ndc.svg"
                alt="NDC Match"
                tw="w-[56px] h-[56px]"
              />
              {ndcOffering.item.suggestedReason === "order_frequency" && (
                <span tw="uppercase text-primeblue text-base font-bold ml-[8px] leading-5">
                  {ListCategoryEnum.LAST_PURCHASED}
                </span>
              )}
              {ndcOffering.item.suggestedReason ===
                "prescription_frequency" && (
                <span
                  tw="uppercase text-primeblue text-base font-bold ml-[8px] leading-5"
                  style={{ wordSpacing: "100vw" }}
                >
                  {ListCategoryEnum.LAST_DISPENSED}
                </span>
              )}
              {ndcOffering.item.suggestedReason !== "prescription_frequency" &&
                ndcOffering.item.suggestedReason !== "order_frequency" && (
                  <span tw="uppercase text-primeblue text-base font-bold ml-[8px] leading-5">
                    {ListCategoryEnum.SUGGESTED_NDC}
                  </span>
                )}
            </div>
          )}

          {/* NOT NDC */}
          {category !== ListCategoryEnum.NDC_MATCH &&
            category !== ListCategoryEnum.SUGGESTED_NDC && (
              <div tw="flex flex-row items-center">
                <div
                  tw="bg-no-repeat bg-center bg-auto flex items-center justify-center text-xl text-white w-[56px] min-w-[56px] h-[56px]"
                  style={{
                    backgroundImage: "url(/assets/pages/compare/lower.svg)",
                  }}
                >
                  <Savings
                    ndcOffering={ndcOffering}
                    mainNdcOffering={mainNdcOffering}
                  />
                </div>

                <span tw="uppercase text-primeblue text-base font-bold ml-[8px]">
                  Lower Unit Price
                </span>
              </div>
            )}
        </div>
      )}

      {/* both sections */}
      {category !== ListCategoryEnum.NDC_MATCH &&
      category !== ListCategoryEnum.SUGGESTED_NDC ? (
        <p
          tw="text-[13px] text-primeblue uppercase mt-[8px] min-h-[40px]"
          id={`tagInfo${ndcOffering.item.drugInfo.ndc}`}
        >
          {differentQuantity && (
            <span>
              {quantityLabel(ndcOffering.item, mainNdcOffering?.item)} (
              {ndcOffering.item.drugInfo.unitSize *
                ndcOffering.item.drugInfo.unitQuantity}
              )
            </span>
          )}
          {differentManufacturerQuantity && (
            <span>
              {quantityManufacturerLabel(
                ndcOffering.item,
                mainNdcOffering?.item
              )}{" "}
              (
              {ndcOffering.item.drugInfo.unitSize *
                ndcOffering.item.drugInfo.unitQuantity}
              )
            </span>
          )}
          {differentManufacturer && <span>{category}</span>}{" "}
          {alternatePackaging && (
            <span>{ListCategoryEnum.ALTERNATIVE_PACKAGING}</span>
          )}
        </p>
      ) : (
        <p
          tw="text-[13px] text-primeblue uppercase mt-[8px] min-h-[40px]"
          id={`tagInfo${ndcOffering.item.drugInfo.ndc}`}
        ></p>
      )}
      <div
        tw="mt-[24px] border-t border-[#cfcfcf] pt-[8px] w-[100%] min-h-[53px]"
        id={`tag${ndcOffering.item.drugInfo.ndc}`}
      >
        {category === ListCategoryEnum.NDC_MATCH ||
        category === ListCategoryEnum.SUGGESTED_NDC ? (
          <div>
            <CompareCardItemProjection item={ndcOffering.item} />
          </div>
        ) : differentManufacturerQuantity ? (
          <div tw="flex flex-col">
            <div>
              <CompareCardItemDispensation item={ndcOffering.item} />
            </div>
            <div>
              <CompareCardItemProjection item={ndcOffering.item} />
            </div>
          </div>
        ) : (
          <>
            {differentManufacturer && (
              <CompareCardItemDispensation item={ndcOffering.item} />
            )}
            {differentQuantity && (
              <CompareCardItemProjection item={ndcOffering.item} />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export function CompareCard({
  ndcOfferings,
  isBelowTheFold,
  totalCardCount,
  mainNdcOffering,
  showTeeCodes,
}: {
  ndcOfferings: NdcOfferingSet;
  totalCardCount: number;
  isBelowTheFold?: boolean;
  mainNdcOffering?: NdcOffering;
  showTeeCodes: boolean;
}) {
  const ndcOffering = ndcOfferings.featured;
  const [openPanel, setOpenPanel] = useState<boolean>(false);

  if (
    !ndcOffering ||
    (ndcOffering.supplierData &&
      (ndcOffering.supplierData.availability ===
        EnhancedStockValidationAvailability.UNAVAILABLE ||
        ndcOffering.supplierData.availability ===
          EnhancedStockValidationAvailability.PENDING))
  ) {
    if (
      ndcOfferings.category === ListCategoryEnum.NDC_MATCH ||
      ndcOfferings.category === ListCategoryEnum.SUGGESTED_NDC
    ) {
      if (!ndcOffering) {
        return (
          <Container tw="text-center pt-12 text-lg">
            Unfortunately none of your catalogs currently carry{" "}
            <span tw="font-medium">{formatNdc(ndcOfferings.ndc)}</span>.
            {totalCardCount > 1 && " Please see other options below."}
          </Container>
        );
      } else {
        return (
          <Container tw="text-center pt-12 text-lg">
            Unfortunately none of your catalogs currently carry this NDC.
            {totalCardCount > 1 && " Please see other options below."}
          </Container>
        );
      }
    } else {
      return null;
    }
  }

  return (
    <div tw="rounded-lg border border-[#dadada] shadow-card bg-white mb-4">
      <div tw="flex justify-end items-center">
        <div tw="p-[24px] flex justify-between self-stretch flex-col w-[580px]">
          <CompareCardDetails
            item={ndcOffering.item}
            openPanel={openPanel}
            ndcOffering={ndcOffering}
            ndcOfferings={ndcOfferings}
            shippingPanel={openPanel}
            showTeeCode={showTeeCodes}
          />
        </div>

        <TagPanel
          ndcOffering={ndcOffering}
          category={ndcOfferings.category}
          isBelowTheFold={isBelowTheFold}
          mainNdcOffering={mainNdcOffering}
        />

        <FeaturedPanel
          featuredResult={ndcOffering}
          isBelowTheFold={isBelowTheFold}
          shippingPanel={openPanel}
          shippingPanelCallback={setOpenPanel}
        />
      </div>
    </div>
  );
}
