import { useCreateForm_legacy } from "../../../../../components/antd/form/useCreateForm";
import { createAdminPharmacyEmailSubscription } from "../../../../../services/pharmacyEmailSubscriptions";
import { WideContainer } from "../../../../../components/containers/WideContainer";
import { AdminPharmacyEmailSubscriptionForm } from "./AdminPharmacyEmailSubscriptionForm/AdminPharmacyEmailSubscriptionForm";

export function CreateAdminPharmacyEmailSubscription() {
  const onSubmit = useCreateForm_legacy({
    redirectTo: "/admin/pharmacy/email/subscription",
    create: createAdminPharmacyEmailSubscription,
  });

  return (
    <WideContainer tw="pb-[200px]">
      <AdminPharmacyEmailSubscriptionForm onSubmit={onSubmit} />
    </WideContainer>
  );
}
