import { useMemo, RefObject } from "react";
import type { AgGridReact } from "ag-grid-react";
import { buildKeyHeaderMap, KeyHeaderMapping } from "./keyHeaderMap";
import { ButtonExportCsvWithChildren } from "./DownloadCsvWithChildrenButton";

export type MultipleDownloadCsvWithChildrenButtonsConfig = {
  text?: string;
  colDefs?: any[];
  filename?: string;
  childrenKey?: string;
  keyHeaderMap?: KeyHeaderMapping[];
};

export function MultipleDownloadCsvWithChildrenButtons({
  gridRef,
  config,
}: {
  gridRef: RefObject<AgGridReact>;
  config?: MultipleDownloadCsvWithChildrenButtonsConfig[];
}) {
  const buttonsConfig = useMemo(() => {
    return config
      ?.map(({ colDefs, keyHeaderMap, ...c }) => {
        const newKeyHeaderMap =
          keyHeaderMap ?? colDefs?.map(buildKeyHeaderMap).filter(Boolean);

        if (!newKeyHeaderMap?.length) return;
        return { ...c, keyHeaderMap: newKeyHeaderMap };
      })
      .filter(Boolean);
  }, [config]);

  if (!buttonsConfig?.length) return null;
  return buttonsConfig.map((buttonConfig, i) => {
    return (
      <ButtonExportCsvWithChildren
        key={i}
        text={buttonConfig?.text}
        gridRef={gridRef}
        filename={buttonConfig?.filename}
        childrenKey={buttonConfig?.childrenKey}
        keyHeaderMap={buttonConfig?.keyHeaderMap}
      />
    );
  });
}
