import { AgGridReact } from "ag-grid-react";
import {
  TOP_COVERAGE_RATE_COLUMN_DEFS,
  TOP_FILL_OPPORTUNITIES_COLUMN_DEFS,
  TOP_PBM_REJECTION_RESOLUTION_COLUMN_DEFS,
  TOP_PRIOR_AUTH_COLUMN_DEFS,
  BOTTOM_COVERAGE_RATE_COLUMN_DEFS,
  BOTTOM_FILL_OPPORTUNITIES_COLUMN_DEFS,
  BOTTOM_PBM_REJECTION_RESOLUTION_COLUMN_DEFS,
  BOTTOM_PRIOR_AUTH_COLUMN_DEFS,
} from "./PharmacySummary.constants";
import { ROW_CLASS_RULES } from "../utils/reportsConstants";
import { MultiReportRowWrapper } from "../utils/MultiReportRowWrapper";
import { useReport } from "../utils/useReport/useReport";

export function PharmacySummaryReport({ data }: { data: never[] }) {
  const topCoverageRateReport = useReport({
    columnDefs: TOP_COVERAGE_RATE_COLUMN_DEFS,
  });
  const topFillOpportunitiesReport = useReport({
    columnDefs: TOP_FILL_OPPORTUNITIES_COLUMN_DEFS,
  });
  const topPbmRejectionResolutionReport = useReport({
    columnDefs: TOP_PBM_REJECTION_RESOLUTION_COLUMN_DEFS,
  });
  const topPriorAuthReport = useReport({
    columnDefs: TOP_PRIOR_AUTH_COLUMN_DEFS,
  });
  const bottomCoverageRateReport = useReport({
    columnDefs: BOTTOM_COVERAGE_RATE_COLUMN_DEFS,
  });
  const bottomFillOpportunitiesReport = useReport({
    columnDefs: BOTTOM_FILL_OPPORTUNITIES_COLUMN_DEFS,
  });
  const bottomPbmRejectionResolutionReport = useReport({
    columnDefs: BOTTOM_PBM_REJECTION_RESOLUTION_COLUMN_DEFS,
  });
  const bottomPriorAuthReport = useReport({
    columnDefs: BOTTOM_PRIOR_AUTH_COLUMN_DEFS,
  });
  const allReports: any[] = [
    topCoverageRateReport,
    bottomCoverageRateReport,
    topPbmRejectionResolutionReport,
    bottomPbmRejectionResolutionReport,
    topFillOpportunitiesReport,
    bottomFillOpportunitiesReport,
    topPriorAuthReport,
    bottomPriorAuthReport,
  ];

  return (
    <MultiReportRowWrapper>
      {allReports.map((report) => (
        <AgGridReact
          {...report}
          masterDetail
          detailRowAutoHeight
          pagination
          rowData={data}
          rowSelection="multiple"
          rowClassRules={ROW_CLASS_RULES}
          rowGroupPanelShow="never"
          key={report.columnDefs[0].headerName}
        />
      ))}
    </MultiReportRowWrapper>
  );
}
