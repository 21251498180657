import React, { useCallback } from "react";
import tw from "twin.macro";
import { formatNdc } from "../../../utilities/ndc/formatNdc";
import { Container } from "../../../components/containers/Container";
import { SimpleLink } from "../../../components/rxLibrary/Link";
import { QuoteStateErrorDetailEnum } from "../Compare.constants";

const Wrapper = tw(Container)`text-center text-lg`;

export function CompareQuoteStateError({
  ndc,
  errorDetail,
}: {
  ndc: string;
  errorDetail?: QuoteStateErrorDetailEnum;
}) {
  const handleReload = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    window.location.replace(window.location.href);
  }, []);

  if (errorDetail === QuoteStateErrorDetailEnum.NOT_FOUND) {
    return (
      <Wrapper>
        <span tw="font-medium">{formatNdc(ndc)}</span> is not a common NDC.
        Please search for the drug name instead
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      Please{" "}
      <SimpleLink href="#" onClick={handleReload}>
        try that search again
      </SimpleLink>
      . If you continue to have problems, please contact us via the chat below.
    </Wrapper>
  );
}
