import React from "react";
import "twin.macro";
import type { CatalogInfo, DrugInfo } from "../../../../utilities/types";
import { isFoamDrug } from "../../../../utilities/drugInfo/isFoamDrug";
import { Text } from "../../../rxLibrary/typography";
import { IconFoam, IconSnowflake } from "../../../rxLibrary/icons";
import DispensedItem from "../../../DispensedItem";
import { Image } from "../../../Image";
import { DrugModal } from "../../../DrugModal";
import { useModal } from "../../../rxLibrary/modals";

export function DrugDetailsCol({
  drug,
  className,
  catalogInfo,
  refrigerated,
  originalInvoiceDate,
  v2 = false,
  hideManufacturer = false,
  isMayneSupplier = false,
}: {
  drug: DrugInfo;
  v2?: boolean;
  className?: string;
  catalogInfo?: CatalogInfo;
  // TODO: Check if remove when we implement the BE refrigerated items
  refrigerated?: boolean;
  // TODO: Check if remove when we implement foam items
  isMayneSupplier?: boolean;
  hideManufacturer?: boolean;
  originalInvoiceDate?: string;
}) {
  const { isModalOpen, openModal, closeModal } = useModal();

  const showQuantityDescription = !!catalogInfo;
  const showManufacturer = !!catalogInfo && !hideManufacturer;
  const foam = isMayneSupplier && isFoamDrug(drug);

  return (
    <>
      <div className={className} tw="w-[365px] border-r px-[12px]">
        <div tw="flex space-x-2">
          <Image
            tw="h-[45px] w-[66px] cursor-pointer"
            src={drug.imageURI}
            onClick={openModal}
          />

          <div tw="w-full">
            <DispensedItem
              drug={drug}
              catalogInfo={catalogInfo}
              showManufacturer={showManufacturer}
              originalInvoiceDate={originalInvoiceDate}
              showQuantityDescription={showQuantityDescription}
              v2={v2}
              onTitleClick={openModal}
            />

            {!!refrigerated && (
              <div tw="flex items-center space-x-1">
                <IconSnowflake color="blue-3" />
                <Text block>Refrigerated item</Text>
              </div>
            )}

            {foam && (
              <div tw="flex items-center space-x-1">
                <IconFoam color="blue-3" />
                <Text block>Foam can item</Text>
              </div>
            )}
          </div>
        </div>
      </div>

      {isModalOpen && <DrugModal drugInfo={drug} onClose={closeModal} />}
    </>
  );
}
