import { v4 as uuidv4 } from "uuid";

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL as string;
if (!BACKEND_URL) {
  throw new Error("REACT_APP_BACKEND_URL is not defined");
}

export const PUSHER_APP_KEY = process.env.REACT_PUSHER_APP_KEY as string;
if (!PUSHER_APP_KEY) {
  throw new Error("REACT_PUSHER_APP_KEY is not defined");
}

export const ENVIRONMENT = (():
  | "development"
  | "staging"
  | "production"
  | "test" => {
  const env = process.env.NODE_ENV ?? "development";
  if (env === "test" || env === "development") return env;

  const PRODUCTION_ORIGIN_URLS = [
    "https://streamlinerx.netlify.app",
    "https://app.daylightrx.com",
  ];
  if (PRODUCTION_ORIGIN_URLS.includes(window.location.origin)) {
    return "production";
  }

  return "staging";
})();

export const IS_DEVELOPMENT = ENVIRONMENT === "development";
export const IS_PRODUCTION = ENVIRONMENT === "production";
export const IS_TEST = ENVIRONMENT === "test";

export const IS_DEV_MODE = process.env.REACT_APP_DEV_MODE === "1";

export const DOWNLOAD_APP_INSTALLER_URL =
  "https://daylightrx-app-updates-cwekdubhioqloph.s3.amazonaws.com/DaylightRx+Setup+Latest.exe";

// Supplier Ids
export const SUPPLIER_ID_FFF = 22;
export const SUPPLIER_ID_JOURNEY = 31;
export const SUPPLIER_ID_MAYNE = 35;
export const SUPPLIER_ID_TOP_RX = 12;
export const SUPPLIER_ID_SMITH_DRUG = 47;
export const SUPPLIER_ID_INTEGRAL_RX = 16;
export const SUPPLIER_ID_BRAEBURN = 71;

export const MAYNE_MIN_FOAM_ITEMS_QTY = 6;
export const BRAEBURN_MIN_ITEMS_QTY = 2;
// Pharmacy Ids
export const TEST_PHARMACY_ID = 7;

// Prime Rx Pharmacy Ids
export const LOFT_CHEMIST_PHARMACY_ID = 184;
export const FRANKS_SPECIALTY_PHARMACY_ID = 67;

export const IS_ELECTRON = IS_TEST
  ? false
  : navigator.userAgent.indexOf("daylightrx") !== -1;

export const X_CLIENT_SESSION_ID = uuidv4();

export const PAGE_ALERTS_BLACKLISTED_PATHS = [
  "/admin",
  "/shoppingListPrint",
  "/supplierNavigation",
  "/supplierBrowserBar",
  "/supplierSafetyScreen",
  "/download",
];

export const CHAT_BUTTON_Z_INDEX = 40;
