import { WideContainer } from "../../../../../components/containers/WideContainer";
import {
  getAdminPageAlert,
  updateAdminPageAlert,
} from "../../../../../services/pageAlerts";
import { useEditForm } from "../../../../../components/antd/form/useEditForm";
import { AdminPageAlertForm } from "./AdminPageAlertForm/AdminPageAlertForm";

export function EditAdminPageAlert() {
  const { values, onSubmit } = useEditForm({
    name: "pageAlert",
    update: updateAdminPageAlert,
    fetchValues: getAdminPageAlert,
  });

  return (
    <WideContainer tw="pb-[200px]">
      <AdminPageAlertForm
        isUpdateForm
        initialValues={values}
        onSubmit={onSubmit}
      />
    </WideContainer>
  );
}
