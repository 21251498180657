import ExportOutlined from "@ant-design/icons/ExportOutlined";
import { Button } from "../../../../../../components/rxLibrary/buttons";
import { DownloadCsvWrapper } from "./DownloadWrapper";

export function DownloadCsvButton({
  text,
  handleExport,
}: {
  text?: string;
  handleExport: () => void;
}) {
  return (
    <DownloadCsvWrapper text={text}>
      <Button
        icon={<ExportOutlined />}
        variant="tertiary"
        onClick={handleExport}
      >
        Export
      </Button>
    </DownloadCsvWrapper>
  );
}
