import { ReactNode, createContext, useMemo, useState } from "react";
import sortBy from "lodash/sortBy";
import isInteger from "lodash/isInteger";
import type { Manufacturer } from "../utilities/types";
import { getQueryParams } from "../utilities/queryParams/getQueryParams";
import { useRequest } from "../services/request/useRequest";
import { getManufacturers } from "../services/manufacturers";
import { getQueryParamFirstValue } from "../utilities/queryParams/getQueryParamFirstValue";

type ManufacturersContext = {
  manufacturers?: Manufacturer[];
  manufacturer?: Manufacturer;
  setManufacturerId: (id: Manufacturer["id"]) => void;
};

const ManufacturersContext = createContext<ManufacturersContext>({
  manufacturers: [],
  setManufacturerId: () => {},
});

export default ManufacturersContext;

function formatter(manufacturers: Manufacturer[]) {
  return sortBy(manufacturers, "name");
}

function getInitialManufacturerId() {
  const { manufacturerId: manufacturerIdQP } = getQueryParams();
  const manufacturerIdStr = getQueryParamFirstValue(manufacturerIdQP);
  if (!manufacturerIdStr) return;

  const manufacturerId = parseInt(manufacturerIdStr);
  if (isInteger(manufacturerId)) return manufacturerId;
}

export function ManufacturersContextProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const [manufacturerId, setManufacturerId] = useState(
    getInitialManufacturerId
  );

  const { formattedData: manufacturers } = useRequest({
    dataKey: "manufacturers",
    request: getManufacturers,
    formatter,
  });

  const manufacturer = useMemo(() => {
    const value = manufacturers?.find((m) => m.id === manufacturerId);
    return value ?? manufacturers?.[0];
  }, [manufacturerId, manufacturers]);

  return (
    <ManufacturersContext.Provider
      value={{
        manufacturer,
        manufacturers,
        setManufacturerId,
      }}
    >
      {children}
    </ManufacturersContext.Provider>
  );
}
