import React, { useCallback, useContext, useMemo, ReactNode } from "react";
import EnhancedClientCommandContext from "../../../enhanced/EnhancedClientCommandContext";
import { IS_ELECTRON } from "../../../utilities/config";
import { useBuyingPharmacy } from "../../../contexts/BuyingPharmacyContext";

export function EnhancedShowSupplierButton({
  supplierId,
  children,
}: {
  children: ReactNode;
  supplierId?: number;
}) {
  const { showSupplier } = useContext(EnhancedClientCommandContext);
  const { getSupplierById } = useBuyingPharmacy();

  const supplier = useMemo(() => {
    return getSupplierById(supplierId);
  }, [supplierId, getSupplierById]);

  const openSupplier = useCallback(() => {
    if (supplier) showSupplier(supplier.name);
  }, [supplier, showSupplier]);

  if (IS_ELECTRON && supplier) {
    return (
      <button tw="underline" onClick={openSupplier}>
        {children}
      </button>
    );
  }

  return <>{children}</>;
}
