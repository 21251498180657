export const ATTEMPTED_FILLS_EXPORT_CSV_CONFIG = {
  text: "Export table with Covered Attempted Fills",
  filename: "export-attempted-fills.csv",
  childrenKey: "attempted_fills_json",
};

export const MISSED_OPPORTUNITIES_EXPORT_CSV_CONFIG = {
  text: "Export table with Covered Missed Opportunities",
  filename: "export-missed-opportunities.csv",
  childrenKey: "missed_fills_json",
};
