import {
  useBuyingPharmacy
} from "../../../../../../contexts/BuyingPharmacyContext";
import { NdcOffering } from "../../../../../../enhanced/result_types";

export function useObtainEnhancedClientDeepLink(ndcOffering: NdcOffering): string | undefined {
  const { getSupplierById } = useBuyingPharmacy();
  const ndc = ndcOffering.item.drugInfo.ndc;
  const supplierId = ndcOffering.item.catalogInfo.supplierId;

    const supplier = getSupplierById(supplierId);

    if (supplier?.isEcommerceEnabled) {
      return `${window.location.origin}/manufacturer-store/${supplierId}?ndc=${ndc}`;
    } else {
      return ndcOffering.supplierData?.deeplink;
    }
}
