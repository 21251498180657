import React, { useMemo } from "react";
import { DrugInfo } from "../../../../../utilities/types";
import { formatNdc } from "../../../../../utilities/ndc/formatNdc";
import { formatDrugNameWithDetails } from "../../../../../utilities/drugInfo/formatDrugNameWithDetails";
import {
  getTextCss,
  Text,
} from "../../../../../components/rxLibrary/typography";
import { DrugModal } from "../../../../../components/DrugModal";
import { useModal } from "../../../../../components/rxLibrary/modals";

export function ShoppingListColumnDispensedItem({
  drug,
  displayNdc = false,
}: {
  drug: DrugInfo;
  displayNdc?: boolean;
}) {
  const { isModalOpen, openModal, closeModal } = useModal();

  const formattedNdc = useMemo(() => {
    if (displayNdc) return `NDC: ${formatNdc(drug.ndc)}`;
  }, [displayNdc, drug.ndc]);

  const drugDetails = useMemo(() => {
    return formatDrugNameWithDetails(drug);
  }, [drug]);

  return (
    <>
      <button
        tw="hover:underline cursor-pointer block text-left"
        css={getTextCss({
          weight: "bold",
        })}
        onClick={openModal}
      >
        {drugDetails}
      </button>

      {!!formattedNdc && <Text block>{formattedNdc}</Text>}

      {isModalOpen && <DrugModal drugInfo={drug} onClose={closeModal} />}
    </>
  );
}
