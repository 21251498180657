import React, { createContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import semver from "semver";
import posthog from "posthog-js";
import { IS_ELECTRON } from "../utilities/config";

type EnhancedClientConfigContext = {
  enhancedClientActive: boolean;
  enhancedClientVersion: string;
  enhancedClientSessionUUID: string;
  enhancedClientDevMode: boolean;
  enhancedShoppingListActive: boolean;
  isAppOutOfDate: boolean;
};

const EnhancedClientConfigContext = createContext<EnhancedClientConfigContext>({
  enhancedClientActive: false,
  enhancedClientVersion: "",
  enhancedClientSessionUUID: "",
  enhancedClientDevMode: false,
  enhancedShoppingListActive: false,
  isAppOutOfDate: false,
});

export default EnhancedClientConfigContext;

type EnhancedClientConfigContextProviderProps = {
  children?: React.ReactNode;
};
function checkIsAppOutOfDate(version: string): boolean {
  return semver.lt(version, "0.4.28");
}

export function EnhancedClientConfigContextProvider({
  children,
}: EnhancedClientConfigContextProviderProps): JSX.Element {
  const [enhancedClientConfig, setEnhancedClientConfig] =
    useState<EnhancedClientConfigContext>({
      enhancedClientActive: false,
      enhancedClientVersion: "",
      enhancedClientSessionUUID: "",
      enhancedClientDevMode: false,
      enhancedShoppingListActive: false,
      isAppOutOfDate: false,
    });

  useEffect(() => {
    const messageListenerCallback = async (event: MessageEvent) => {
      if (event.source !== window) {
        return;
      }
      if (event.data.channel === "daylightrxenhanced") {
        if (event.data.msgType === "connected") {
          console.log("Enhanced Client Connected");
          const enhancedClientActive = true;
          const enhancedShoppingListActive = true;
          const enhancedClientVersion = event.data.payload?.version || "";
          const enhancedClientDevMode = event.data.payload?.devMode || false;
          const enhancedClientSessionUUID =
            event.data.payload?.session_uuid || "";
          setEnhancedClientConfig((prev) => {
            if (
              prev.enhancedClientActive === enhancedClientActive &&
              prev.enhancedClientVersion === enhancedClientVersion &&
              prev.enhancedClientSessionUUID === enhancedClientSessionUUID &&
              prev.enhancedClientDevMode === enhancedClientDevMode &&
              prev.enhancedShoppingListActive === enhancedShoppingListActive
            ) {
              return prev;
            }
            console.log("Updating Enhanced Client Config");
            return {
              enhancedClientActive,
              enhancedClientVersion,
              enhancedClientSessionUUID,
              enhancedClientDevMode,
              enhancedShoppingListActive,
              isAppOutOfDate: checkIsAppOutOfDate(enhancedClientVersion),
            };
          });
        }
      }
    };
    window.addEventListener("message", messageListenerCallback);

    return () => {
      window.removeEventListener("message", messageListenerCallback);
    };
  }, []);

  useEffect(() => {
    const t = setTimeout(() => {
      if (
        !enhancedClientConfig.enhancedClientActive &&
        navigator.userAgent.indexOf("daylightrx") !== -1 &&
        window.location.href.indexOf("shoppingListPrint") === -1 &&
        window.location.href.indexOf("supplierNavigation") === -1 &&
        window.location.href.indexOf("supplierSafetyScreen") === -1 &&
        window.location.href.indexOf("manufacturer-store") === -1
      ) {
        Sentry.captureMessage(
          "Enhanced Client not connected within 5 seconds in daylightrx browser",
          "error"
        );
      } else if (enhancedClientConfig.enhancedClientActive) {
        console.log("Enhanced Client connected inside of daylightrx browser");
      } else {
        console.log(
          "Enhanced Client not connected, but not in daylightrx browser"
        );
      }
    }, 5000);
    return () => {
      console.log(
        "Clearing timeout for enhancedClientActive",
        enhancedClientConfig.enhancedClientActive
      );
      clearTimeout(t);
    };
  }, [enhancedClientConfig]);

  useEffect(() => {
    console.log("Sending connect message to enhanced client");
    window.postMessage({
      channel: "daylightrxenhanced",
      sender: "web",
      msgType: "connect",
    });
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timer | null = null;
    if (
      !enhancedClientConfig.enhancedClientActive &&
      navigator.userAgent.indexOf("daylightrx") !== -1 &&
      window.location.href.indexOf("shoppingListPrint") === -1 &&
      window.location.href.indexOf("supplierNavigation") === -1 &&
      window.location.href.indexOf("supplierSafetyScreen") === -1 &&
      window.location.href.indexOf("manufacturer-store") === -1
    ) {
      interval = setInterval(() => {
        console.log("Enhanced Client not connected, retrying");
        window.postMessage({
          channel: "daylightrxenhanced",
          sender: "web",
          msgType: "connect",
        });
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [enhancedClientConfig]);

  useEffect(() => {
    Sentry.setTag("electron.isActive", IS_ELECTRON);

    if (!IS_ELECTRON) return;
    Sentry.setTag(
      "electron.enhancedClientActive",
      enhancedClientConfig.enhancedClientActive
    );
    Sentry.setTag(
      "electron.version",
      enhancedClientConfig.enhancedClientVersion
    );
    Sentry.setTag(
      "electron.isAppOutOfDate",
      enhancedClientConfig.isAppOutOfDate
    );
    Sentry.setTag(
      "electron.sessionUUID",
      enhancedClientConfig.enhancedClientSessionUUID
    );

    if (posthog.has_opted_out_capturing()) {
      posthog.setPersonProperties({
        electronIsActive: IS_ELECTRON,
        electronEnhancedClientActive: enhancedClientConfig.enhancedClientActive,
        electronVersion: enhancedClientConfig.enhancedClientVersion,
        electronIsAppOutOfDate: enhancedClientConfig.isAppOutOfDate,
        electronSessionUUID: enhancedClientConfig.enhancedClientSessionUUID,
      });
    }
  }, [enhancedClientConfig]);

  console.log("Rendering EnhancedClientConfigContext");
  return (
    <EnhancedClientConfigContext.Provider value={enhancedClientConfig}>
      {children}
    </EnhancedClientConfigContext.Provider>
  );
}
