import { useEffect, useState } from "react";
import { authService } from "../../../../../services/libs/authenticationService";
import { SelectOption } from "../../../../../components/rxLibrary/selects/select";
import { displayErrorModal } from "../../../../../utilities/errors/displayErrorModal";
import { getAdminSupplierMappingAndEdiOptions } from "../../../../../services/supplierMappingAndEdi";

export function useAdminSupplierMappingAndEditOptions() {
  const [options, setOptions] = useState<{
    supplierOptions?: SelectOption[];
    pharmacyOptions?: SelectOption[];
  }>({});

  useEffect(() => {
    init();

    async function init() {
      try {
        const token = await authService.getAccessTokenSilently();
        const response = await getAdminSupplierMappingAndEdiOptions(token);
        const newOptions = response.data?.supplierMappingAndEdiOptions;
        if (!newOptions) {
          throw new Error(
            "Error fetching no admin supplier mapping and EDI options"
          );
        }

        const { supplier, pharmacy } = newOptions;
        const newSupplierOptions = supplier.map((v) => ({
          value: v.id,
          label: v.name,
        }));
        const newPharmacyOptions = pharmacy.map((v) => ({
          value: v.id,
          label: v.name,
          organizationId: v.pharmacyOrganizationId,
        }));

        setOptions({
          supplierOptions: newSupplierOptions,
          pharmacyOptions: newPharmacyOptions,
        });
      } catch (error) {
        console.error(
          "Error formatting admin supplier mapping and EDI options",
          error
        );
        displayErrorModal({ title: "Error getting page data" });
      }
    }
  }, []);

  return options;
}
