import { AgGridReact } from "ag-grid-react";
import type { GridOptions, GridReadyEvent } from "ag-grid-community";
import { ROW_CLASS_RULES } from "../utils/reportsConstants";
import { ReportWrapper } from "../utils/ReportWrapper";
import { useReport } from "../utils/useReport/useReport";
import { setupPinnedTopRowData } from "../utils/setupPinnedTopRowData";
import {
  ATTEMPTED_FILLS_EXPORT_CSV_CONFIG,
  MISSED_OPPORTUNITIES_EXPORT_CSV_CONFIG,
} from "../utils/csv/csv.constants";
import { NonAdherentRefillOutreachReportDetailsCellRendered } from "./NonAdherentRefillOutreachReportDetailsCellRendered/NonAdherentRefillOutreachReportDetailsCellRendered";
import { COLUMN_DEFS } from "./NonAdherentRefillOutreachReport.constants";
import {
  ATTEMPTED_FILLS_COLUMN_DEFS,
  MISSED_FILLS_COLUMN_DEFS,
} from "./NonAdherentRefillOutreachReportDetailsCellRendered/NonAdherentRefillOutreachReportDetailsCellRendered.constants";

const getRowStyle: GridOptions["getRowStyle"] = (params) => {
  if (params?.data?.pinnedRow) return { "font-weight": "bold" };
  return undefined;
};

function getSumAtKey(data: any[], key: string) {
  const sum = data.map((v) => v[key]).reduce((acc, v) => acc + v, 0);
  return sum;
}

function getPinnedRowData(data?: any[]) {
  if (!data?.length) return;

  const nonAdherentRefillOpportunitiesSum = getSumAtKey(
    data,
    "Non-Adherent Refill Opportunities"
  );
  const attemptedOutreachesSum = getSumAtKey(
    data,
    "Attempted Outreaches (of Non-Adherent Refill Opportunity)"
  );
  const unattemptedOutreachesSum = getSumAtKey(
    data,
    "Unattempted Outreaches (of Non-Adherent Refill Opportunity)"
  );

  const dispensedRefillsSum = getSumAtKey(data, "Dispensed Refills");

  const percentageAttemptedSum =
    nonAdherentRefillOpportunitiesSum === 0
      ? 0
      : attemptedOutreachesSum / nonAdherentRefillOpportunitiesSum;

  const percentageDispensedOfAttempted =
    attemptedOutreachesSum === 0
      ? 0
      : dispensedRefillsSum / attemptedOutreachesSum;

  const abandonedTransferredRefillsSum = getSumAtKey(
    data,
    "Abandoned/Transferred Refills"
  );
  const inProgressRefillsSum = getSumAtKey(data, "In-Progress Refills");

  return [
    {
      pivot: false,
      pinnedRow: true,
      "Non-Adherent Refill Opportunities": nonAdherentRefillOpportunitiesSum,
      "Attempted Outreaches (of Non-Adherent Refill Opportunity)":
        attemptedOutreachesSum,
      "Unattempted Outreaches (of Non-Adherent Refill Opportunity)":
        unattemptedOutreachesSum,
      "% Attempted (of Non-Adherent Refill Opportunity)":
        percentageAttemptedSum,
      "Dispensed Refills": dispensedRefillsSum,
      "% Dispensed of Attempted": percentageDispensedOfAttempted,
      "Abandoned/Transferred Refills": abandonedTransferredRefillsSum,
      "In-Progress Refills": inProgressRefillsSum,
    },
  ];
}

const MULTIPLE_DOWNLOAD_CSV_WITH_CHILDREN_BUTTONS_CONFIG = [
  {
    ...ATTEMPTED_FILLS_EXPORT_CSV_CONFIG,
    colDefs: ATTEMPTED_FILLS_COLUMN_DEFS.flatMap((col: any) => col?.children),
  },
  {
    ...MISSED_OPPORTUNITIES_EXPORT_CSV_CONFIG,
    colDefs: MISSED_FILLS_COLUMN_DEFS.flatMap((col: any) => col?.children),
  },
];

function onGridReady(event: GridReadyEvent) {
  setupPinnedTopRowData(event, getPinnedRowData);
}

export function NonAdherentRefillOutreachReport({ data }: { data: never[] }) {
  const report = useReport({
    onGridReady,
    columnDefs: COLUMN_DEFS,
    multipleDownloadCsvWithChildrenButtonsConfig:
      MULTIPLE_DOWNLOAD_CSV_WITH_CHILDREN_BUTTONS_CONFIG,
  });

  return (
    <ReportWrapper>
      <AgGridReact
        {...report}
        masterDetail
        detailRowAutoHeight
        pagination
        rowData={data}
        rowSelection="multiple"
        rowClassRules={ROW_CLASS_RULES}
        detailRowHeight={400}
        detailCellRenderer={NonAdherentRefillOutreachReportDetailsCellRendered}
        rowGroupPanelShow="always"
        getRowStyle={getRowStyle}
      />
    </ReportWrapper>
  );
}
