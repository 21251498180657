import { backendPost } from "../../services/request_legacy/config/backend-api";

export async function logJSON(
  token: string,
  pharmacyId: number,
  payload: string,
): Promise<number> {
  const response = await backendPost(`/log?pharmacy_id=${pharmacyId}`, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response.status;
}
