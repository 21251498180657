import {
  useRef,
  useState,
  useEffect,
  ReactNode,
  useContext,
  createContext,
  useCallback,
} from "react";
import dayjs from "dayjs";
import isNil from "lodash/isNil";
import isEqual from "lodash/isEqual";
import type { SelectOption } from "../../../../components/rxLibrary/selects/select";
import type { ItemInCart, ItemInModal } from "../../../../utilities/types";
import { formatItemPurchaseDetails } from "../../../../utilities/prescriptions/formatItem";
import { getPrescriptionId } from "../../../../utilities/prescriptions/getPrescriptionId";
import { formatDrugNameWithDetails } from "../../../../utilities/drugInfo/formatDrugNameWithDetails";
import {
  useShoppingState,
  useShoppingUpdater,
} from "../../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import { useShoppingCartServerState } from "../../../../contexts/shoppingContexts/ShoppingCartServerContext/ShoppingCartServerContext";
import { SortDirectionEnum } from "../../../../components/rxLibrary/selects/SortBySelect";
import { useStorageUpdater } from "../../../../contexts/shoppingContexts/StorageContext";
import { useBuyingPharmacy } from "../../../../contexts/BuyingPharmacyContext";
import { useSyncedShoppingCart } from "../../../../contexts/shoppingContexts/useSyncedShoppingCart/useSyncedShoppingCart";
import { sequenceActions } from "../ShoppingRx.utils";
import { useSimilarItems } from "./useShoppingRx.useSimilarItems";
import { useSelectedItemId } from "./useShoppingRx.useSelectedItemId";

type SortOption = SelectOption;

type SortConfig = {
  sorters: [SortOption, SortOption];
  lastUniversalSorter: SortOption;
};

export const PANEL_INVENTORY_ID = "inventory-panel";
export const PANEL_PRESCRIPTION_ID = "prescriptions-panel";

export const SHOPPING_RX_SORT_OPTIONS: SelectOption[] = [
  {
    value: "addedAt",
    label: "Date/Time Added",
    sorter: (item: ItemInCart) => sorterByDate(item, "addedAt"),
    sortDirection: SortDirectionEnum.DESC,
  },
  {
    value: "rx",
    label: "Rx",
    omitForInventory: true,
    sorter: (item: ItemInCart) => getPrescriptionId(item),
    sortDirection: SortDirectionEnum.ASC,
  },
  {
    value: "patientName",
    label: "Patient Name",
    omitForInventory: true,
    sorter: (item: ItemInCart) => item?.patient?.shortName,
    sortDirection: SortDirectionEnum.ASC,
  },
  {
    value: "itemName",
    label: "Item Name",
    sorter: (item: ItemInCart) => formatDrugNameWithDetails(item.drug),
    sortDirection: SortDirectionEnum.ASC,
  },
  {
    value: "fillDate",
    label: "Fill Date : Newest First",
    omitForInventory: true,
    sorter: (item: ItemInCart) => sorterByDate(item, "fillDate"),
    sortDirection: SortDirectionEnum.DESC,
  },
  {
    value: "purchaseBy",
    label: "Purchase By: Soonest First",
    sorter: (item: ItemInCart) => sorterByDate(item, "purchaseBy"),
    sortDirection: SortDirectionEnum.ASC,
  },
];

function scrollPanelToTop(id: string) {
  document.getElementById(id)?.scrollTo({ top: 0, behavior: "smooth" });
}

function sorterByDate(
  item: ItemInCart,
  field: "addedAt" | "purchaseBy" | "processedAt" | "fillDate"
) {
  const date = item[field];
  if (date) return dayjs(date);
  // No purchaseBy it is equivalent to today, we are moving this to the start of the day to make sure all the items have the same time
  if (field === "purchaseBy") return dayjs().startOf("day");
}

function sortItems(
  items: ItemInCart[],
  sortConfig: SortConfig,
  inventory?: boolean
): ItemInCart[] {
  let sorters = inventory
    ? sortConfig.sorters.filter((s) => !s.omitForInventory)
    : sortConfig.sorters;
  if (!sorters.length) sorters = [sortConfig.lastUniversalSorter];

  const formattedSorters = sorters.map(({ sorter, sortDirection }) => {
    const direction = sortDirection === SortDirectionEnum.ASC ? 1 : -1;
    return (a: ItemInCart, b: ItemInCart) => {
      const aValue = sorter(a);
      const bValue = sorter(b);

      if (isNil(aValue) && isNil(bValue)) return 0;
      if (isNil(aValue) && !isNil(bValue)) return 1 * direction;
      if (!isNil(aValue) && isNil(bValue)) return -1 * direction;

      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue) * direction;
      }

      if (aValue < bValue) return -1 * direction;
      if (aValue > bValue) return 1 * direction;
      return 0;
    };
  });

  const sortedItems = items.toSorted((a, b) => {
    for (const sorter of formattedSorters) {
      const result = sorter(a, b);
      if (result !== 0) return result;
    }
    return 0;
  });

  return sortedItems;
}

const ShoppingRxContext = createContext<
  ReturnType<typeof useShoppingRxContext> | undefined
>(undefined);

function useShoppingRxContext() {
  const {
    inventorySyncDate,
    isCartStateLoading,
    prescriptionsSyncDate,
    isPrescriptionsLoading,
    addPrescriptions,
    refreshCartState,
    forcedSyncPrescriptions,
  } = useSyncedShoppingCart();

  const cancelSequenceRef = useRef<() => void>();
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    sorters: [SHOPPING_RX_SORT_OPTIONS[0], SHOPPING_RX_SORT_OPTIONS[3]],
    lastUniversalSorter: SHOPPING_RX_SORT_OPTIONS[0],
  });
  const [inventoryItems, setInventoryItems] = useState<ItemInCart[]>([]);
  const [prescriptionsItems, setPrescriptionsItems] = useState<ItemInCart[]>(
    []
  );
  const sort = sortConfig.sorters[0];

  const { inventory } = useShoppingCartServerState();
  const { prescriptionItemsInCart } = useShoppingState();
  const { addInventoryItem } = useShoppingUpdater();
  const { setWaitButtonMode } = useStorageUpdater();
  const { addItemPurchaseDetailsList } = useShoppingUpdater();
  const { currentBuyingPharmacyId: pharmacyId } = useBuyingPharmacy();
  const { similarItems, removeSimilarItem, addPrescriptionsToSimilarItems } =
    useSimilarItems({
      inventoryItems,
      prescriptionsItems,
    });
  const { selectedItemId, selectItemId } = useSelectedItemId();

  const [highlightType, setHighlightType] = useState<
    "inventory" | "prescription"
  >();
  const [itemsAddedBanner, setItemsAddedBanner] =
    useState<["inventory" | "prescription", number]>();

  const highlightInventory = "inventory" === highlightType;
  const highlightPrescription = "prescription" === highlightType;

  const resetSequence = useCallback(() => {
    cancelSequenceRef?.current?.();
    cancelSequenceRef.current = undefined;
    setHighlightType(undefined);
    setItemsAddedBanner(undefined);
  }, []);

  const handleSortChange = useCallback((_: any, newSort: any) => {
    setSortConfig((prevSortConfig) => {
      const prevSort = prevSortConfig.sorters[0];
      const sorters: SortConfig["sorters"] = [newSort, prevSort];
      const lastUniversalSorter =
        sorters.find((s) => !s.omitForInventory) ??
        prevSortConfig.lastUniversalSorter;
      return { sorters, lastUniversalSorter };
    });
  }, []);

  const handleAddInventoryItem = useCallback(
    (item: ItemInCart) => {
      cancelSequenceRef.current = sequenceActions([
        [resetSequence],
        [highlightInventory],
        [addToInventory, 500],
        [scrollInventoryPanelToTop],
        [updateItemsAddedBanner, 2000],
        [resetSequence, 5000],
      ]);

      function highlightInventory() {
        setHighlightType("inventory");
      }

      function addToInventory() {
        addInventoryItem(item);
        setWaitButtonMode(false);
      }

      function scrollInventoryPanelToTop() {
        scrollPanelToTop(PANEL_INVENTORY_ID);
      }

      function updateItemsAddedBanner() {
        setItemsAddedBanner(["inventory", 1]);
      }
    },
    [resetSequence, addInventoryItem, setWaitButtonMode]
  );

  const addPrescriptionsToCart = useCallback(
    (newPrescriptions: ItemInModal[]) => {
      cancelSequenceRef.current = sequenceActions([
        [resetSequence],
        [highlightPrescriptions],
        [addToCart, 500],
        [scrollPrescriptionPanelToTop],
        [updateItemsAddedBanner, 1000],
        [updateSimilarItems, 1000],
        [resetSequence, 5000],
      ]);

      function highlightPrescriptions() {
        setHighlightType("prescription");
      }

      function addToCart() {
        const itemPurchaseDetailsList = newPrescriptions.map(
          formatItemPurchaseDetails
        );
        addItemPurchaseDetailsList(itemPurchaseDetailsList);
        addPrescriptions(newPrescriptions);
      }

      function scrollPrescriptionPanelToTop() {
        scrollPanelToTop(PANEL_PRESCRIPTION_ID);
      }

      function updateItemsAddedBanner() {
        const counter = newPrescriptions.length;
        setItemsAddedBanner(["prescription", counter]);
      }

      function updateSimilarItems() {
        addPrescriptionsToSimilarItems(newPrescriptions);
      }
    },
    [
      resetSequence,
      addPrescriptions,
      addItemPurchaseDetailsList,
      addPrescriptionsToSimilarItems,
    ]
  );

  useEffect(() => {
    const filteredItems = inventory.filter((item) => {
      return item.status === "list";
    });

    const newInventoryItems = sortItems(filteredItems, sortConfig, true);
    if (isEqual(inventoryItems, newInventoryItems)) return;

    setInventoryItems(newInventoryItems);
  }, [sortConfig, inventory]);

  useEffect(() => {
    const filteredItems = prescriptionItemsInCart.filter((item) => {
      return item.status === "list";
    });

    const newPrescriptionsItems = sortItems(filteredItems, sortConfig);
    if (isEqual(prescriptionsItems, newPrescriptionsItems)) return;

    setPrescriptionsItems(newPrescriptionsItems);
  }, [sortConfig, prescriptionItemsInCart]);

  useEffect(() => {
    resetSequence();
    return cancelSequenceRef?.current;
  }, [pharmacyId]);

  return {
    sort,
    similarItems,
    inventoryItems,
    selectedItemId,
    itemsAddedBanner,
    inventorySyncDate,
    isCartStateLoading,
    prescriptionsItems,
    highlightInventory,
    highlightPrescription,
    prescriptionsSyncDate,
    isPrescriptionsLoading,
    selectItemId,
    handleSortChange,
    refreshCartState,
    removeSimilarItem,
    addPrescriptionsToCart,
    handleAddInventoryItem,
    forcedSyncPrescriptions,
  };
}

export function ShoppingRxContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const context = useShoppingRxContext();
  return (
    <ShoppingRxContext.Provider value={context}>
      {children}
    </ShoppingRxContext.Provider>
  );
}

export function useShoppingRx() {
  const context = useContext(ShoppingRxContext);
  if (!context) {
    throw new Error("useShoppingRx must be used within a ShoppingRxContext");
  }
  return context;
}
