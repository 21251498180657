import React, { useMemo, ReactNode } from "react";
import { css, styled } from "twin.macro";
import cn from "classnames";
import pluralize from "pluralize";
import dayjs, { Dayjs } from "dayjs";
import type {
  DrugInfo,
  DrugWithStats,
  DispensesStatsData,
} from "../../../utilities/types";
import { getDispensesStatsGroup } from "../../../utilities/drugInfo/drugInfo";
import { dayjsWithMinMax } from "../../../utilities/dates/dayjsWithMinMax";
import { dayjsWithDuration } from "../../../utilities/dates/dayjsWithDuration";
import { BLACK_1 } from "../../rxLibrary/colors";

export const CancelBtn = styled.button`
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.07px;
  color: ${BLACK_1};
  text-decoration: underline;
`;

export const ModalContent = styled.div(
  ({ screen }: { screen: number }) => css`
    width: 1200px;
    margin: 16px auto;
    height: calc(100vh - 32px);
    overflow-y: auto;
    background-color: ${screen === 1 ? "#fff" : "#f5f5f5"};
  `
);

export function MediumParagraph({ children }: { children: ReactNode }) {
  return <p className="font-500 ml-4">{children}</p>;
}

function getDispensesStats(prescription: DrugWithStats) {
  const states30 = getDispensesStatsGroup(prescription, "last30Days");
  const states90 = getDispensesStatsGroup(prescription, "last90Days");
  const states180 = getDispensesStatsGroup(prescription, "last180Days");

  let daysAgo = "";
  let states: DispensesStatsData = {
    numberOfPrescriptions: 0,
    numberOfUnits: 0,
  };
  if (states30.numberOfPrescriptions) {
    daysAgo = "30";
    states = states30;
  } else if (states90.numberOfPrescriptions) {
    daysAgo = "90";
    states = states90;
  } else if (states180.numberOfPrescriptions) {
    daysAgo = "180";
    states = states180;
  }

  return { daysAgo, ...states };
}

function formatDrugUnits(numberOfUnits: number, drug: DrugInfo) {
  const { uom, form } = drug;
  let unitMeasure = "";
  if (uom === "Each") {
    unitMeasure = pluralize(form, numberOfUnits);
  } else if (uom === "Milliliter") {
    unitMeasure = pluralize("ml", numberOfUnits);
  } else if (uom === "Gram") {
    unitMeasure = "g";
  }
  const unitsNum = numberOfUnits
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const drugUnits = `${unitsNum} ${unitMeasure}`;

  return drugUnits;
}

function formatNumberOfPrescriptions(numberOfPrescriptions: number) {
  if (!numberOfPrescriptions) return;

  const rxNum = numberOfPrescriptions
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const text = `${rxNum} Rx${numberOfPrescriptions === 1 ? "" : "s"}`;
  return text;
}

function formatDispensesStats(prescription: DrugWithStats) {
  const { daysAgo, numberOfUnits, numberOfPrescriptions } =
    getDispensesStats(prescription);
  const numRxs = formatNumberOfPrescriptions(numberOfPrescriptions);
  if (!numRxs) return {};

  const drugUnits = formatDrugUnits(numberOfUnits, prescription.drug);
  return { numRxs, daysAgo, drugUnits };
}

export function DispensesStatsRow({
  prescription,
}: {
  prescription: DrugWithStats;
}) {
  const { numRxs, daysAgo, drugUnits } = useMemo(() => {
    return formatDispensesStats(prescription);
  }, [prescription]);

  if (!numRxs) return <p>Not dispensed in last 6 months</p>;
  return (
    <>
      {" "}
      <p className="text-sm font-500">
        {numRxs} ({drugUnits})
      </p>
      <p> Dispensed in the past {daysAgo} days</p>
    </>
  );
}

function LastDispensesDate({ items }: { items: DrugWithStats[] }) {
  const text = useMemo(() => {
    const mostDistantDate = items.reduce<Dayjs | undefined>((date, item) => {
      const newDateStr = item.mostRecentlyDispensedDate;
      if (!newDateStr) return date;

      const newDate = dayjs(newDateStr);
      if (!date) return newDate;

      const maxDate = dayjsWithMinMax.max(newDate, date);
      return maxDate;
    }, undefined);

    if (!mostDistantDate) return;
    const days = dayjsWithDuration
      .duration(dayjs().diff(mostDistantDate))
      .days();

    let daysAgo = `${days} days ago`;
    if (days === 0) daysAgo = "today";
    else if (days === 1) daysAgo = "yesterday";

    return ` - Last dispensed ${daysAgo}`;
  }, [items]);

  if (!text) return null;
  return <span>{text}</span>;
}

export function DispensesStatsTitle({
  items,
  type,
  tbl = false,
}: {
  items: DrugWithStats[];
  type: string;
  tbl?: boolean;
}) {
  const { numRxs, daysAgo, drugUnits } = useMemo(() => {
    let rxNum = 0;
    let unitsNum = 0;
    items.forEach((item) => {
      const states = getDispensesStatsGroup(item, type);
      rxNum += states.numberOfPrescriptions;
      unitsNum += states.numberOfUnits;
    });
    const numRxs = formatNumberOfPrescriptions(rxNum);
    if (!numRxs) return {};

    const drugUnits = formatDrugUnits(unitsNum, items[0].drug);
    const daysAgo = type.match(/\d+/);

    return { numRxs, daysAgo, drugUnits };
  }, [items, type]);

  if (!numRxs) return <p />;
  return (
    <>
      <p className="text-sm">
        {" "}
        <span className={cn(tbl && "font-500")}>
          {numRxs} ({drugUnits})
        </span>{" "}
        {!tbl && `dispensed in the past ${daysAgo} days`}
        {!tbl && <LastDispensesDate items={items} />}
      </p>
      {tbl && <p>Dispensed in the past {daysAgo} days</p>}
    </>
  );
}
