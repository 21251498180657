import type {
  DrugInfo,
  SuggestedNdcReason,
} from "../../../../../utilities/types";
import { formatNdc } from "../../../../../utilities/ndc/formatNdc";

export function formatTeeCodeFilterLabel({
  showTeeCode,
  topCardDrug,
  topCardSuggestedReason,
}: {
  showTeeCode?: boolean;
  topCardDrug?: DrugInfo;
  topCardSuggestedReason?: SuggestedNdcReason;
}) {
  if (!showTeeCode || !topCardDrug) return;

  const { ndc, name: drugName, manufacturer } = topCardDrug;
  const formattedNdc = formatNdc(ndc);

  let result: string | undefined;
  switch (topCardSuggestedReason) {
    case "order_frequency":
      result = `Only show AB-rated equivalents of the last purchased NDC (${drugName} - ${manufacturer})`;
      break;
    case "prescription_frequency":
      result = `Only show AB-rated equivalents of the last dispensed NDC (${drugName} - ${manufacturer})`;
      break;
    case "lowest_price":
      result = `Only show AB-rated equivalents of the lowest priced NDC (${drugName} - ${manufacturer})`;
      break;
    default:
      result = `Only show AB-rated equivalent NDCs of ${formattedNdc} (${drugName} - ${manufacturer})`;
      break;
  }

  return result;
}
