import type { ICellRendererParams } from "ag-grid-community";
import { useReport } from "../../utils/useReport/useReport";
import { DetailReports, useDetailReports } from "../../utils/DetailReports";
import {
  ATTEMPTED_FILLS_COLUMN_DEFS,
  MISSED_FILLS_COLUMN_DEFS,
} from "./NonAdherentRefillOutreachReportDetailsCellRendered.constants";

export function NonAdherentRefillOutreachReportDetailsCellRendered({
  api,
  data,
  node,
}: ICellRendererParams) {
  const { onGridReady } = useDetailReports({ api, node });

  const fillDetailReport = useReport({
    columnDefs: ATTEMPTED_FILLS_COLUMN_DEFS,
    onGridReady,
  });

  const previousFillDetailReport = useReport({
    columnDefs: MISSED_FILLS_COLUMN_DEFS,
    onGridReady,
  });

  return (
    <DetailReports
      data={data}
      reports={[
        {
          title: "Fill Detail - Non-Adherent, Covered Attempted Fills",
          key: "attempted_fills_json",
          report: fillDetailReport,
        },
        {
          title: "Fill Detail - Non-Adherent, Covered Missed Opportunities",
          key: "missed_fills_json",
          report: previousFillDetailReport,
        },
      ]}
    />
  );
}
