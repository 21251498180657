import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import semver from "semver";
import EnhancedClientConfigContext from "../../enhanced/EnhancedClientConfigContext";
import { useBuyingPharmacy } from "../../contexts/BuyingPharmacyContext";
import { notificationsService } from "../../services/libs/notificationsService/notificationsService";
import { getPageAlerts } from "../../services/pageAlerts";
import { useRequest } from "../../services/request/useRequest";
import { PAGE_ALERTS_BLACKLISTED_PATHS } from "../../utilities/config";
import { PageAlerts } from "./PageAlerts";

import { useIsOnline } from "../../utilities/useIsOnline/useIsOnline";

const PAGE_ALERTS_CHANNEL = "cache-page-alerts";
const PAGE_ALERTS_UPDATED_EVENT = "page-alerts-updated";
const PAGE_ALERTS_REFRESH_INTERVAL_MS = 30 * 60 * 1000; // 30 minutes in milliseconds

export function PageAlertsNotifications() {
  const isOnline = useIsOnline();

  const { pathname } = useLocation();

  const { currentBuyingPharmacyId: pharmacyId } = useBuyingPharmacy();
  const { enhancedClientActive, enhancedClientVersion } = useContext(
    EnhancedClientConfigContext
  );

  const [msgs, setMsgs] = useState<string[]>();

  const { data: pageAlerts, refetch } = useRequest({
    dataKey: "pageAlerts",
    request: getPageAlerts,
  });

  useEffect(() => {
    if (!isOnline) return;

    const intervalId = setInterval(refetch, PAGE_ALERTS_REFRESH_INTERVAL_MS);

    return () => clearInterval(intervalId);
  }, [refetch, isOnline]);

  useEffect(() => {
    if (!isOnline) return;

    const alertsChannel = notificationsService.subscribe(PAGE_ALERTS_CHANNEL);
    alertsChannel.bind(PAGE_ALERTS_UPDATED_EVENT, refetch);

    return () => notificationsService.unsubscribe(PAGE_ALERTS_CHANNEL);
  }, [refetch, isOnline]);

  useEffect(() => {
    if (!pageAlerts?.length) {
      setMsgs(undefined);
      return;
    }

    const firstPath = pathname.split("/").find((s: string) => s.length);
    if (!firstPath) {
      setMsgs(undefined);
      return;
    }

    const path = `/${firstPath}`;
    const newMsgs = pageAlerts
      .filter((alert) => {
        const {
          pages,
          pharmacyIds,
          electronOnly,
          blacklistedPages,
          electronMaxVersion,
        } = alert;

        if (!!electronOnly && !enhancedClientActive) return false;

        if (enhancedClientVersion && electronMaxVersion) {
          if (semver.gt(enhancedClientVersion, electronMaxVersion)) {
            return false;
          }
        }

        if (PAGE_ALERTS_BLACKLISTED_PATHS.includes(path)) return false;

        if (
          pages?.length &&
          !pages.includes(path) &&
          !pages.includes(pathname)
        ) {
          return false;
        }

        if (blacklistedPages?.length && blacklistedPages.includes(path)) {
          return false;
        }

        if (pharmacyIds?.length) {
          if (path === "/manufacturer") return false;
          if (!pharmacyId) return false;
          if (!pharmacyIds.includes(pharmacyId)) return false;
        }

        return true;
      })
      .map((alert) => alert.message);

    setMsgs(newMsgs);
  }, [
    pathname,
    pageAlerts,
    pharmacyId,
    enhancedClientActive,
    enhancedClientVersion,
  ]);

  return <PageAlerts msgs={msgs} />;
}
