import { useCreateForm } from "../../../../../components/antd/form/useCreateForm";
import { WideContainer } from "../../../../../components/containers/WideContainer";
import { createAdminPharmacyOrganization } from "../../../../../services/pharmacyOrganizations";
import { AdminPharmacyOrganizationForm } from "./AdminPharmacyOrganizationForm";

export function CreateAdminPharmacyOrganization() {
  const onSubmit = useCreateForm({
    redirectTo: "/admin/pharmacy-organization",
    create: createAdminPharmacyOrganization,
  });

  return (
    <WideContainer tw="pb-[200px]">
      <AdminPharmacyOrganizationForm onSubmit={onSubmit} />
    </WideContainer>
  );
}
