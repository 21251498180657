import { AgGridReact } from "ag-grid-react";
import { ReportWrapper } from "../utils/ReportWrapper";
import { useReport } from "../utils/useReport/useReport";
import { COLUMN_DEFS } from "./FillSummary.constants";

export function FillSummary({ data }: { data: never[] }) {
  const report = useReport({ columnDefs: COLUMN_DEFS });

  return (
    <ReportWrapper>
      <AgGridReact
        {...report}
        pagination
        rowData={data}
        rowSelection="multiple"
      />
    </ReportWrapper>
  );
}
