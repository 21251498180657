import { useCallback, useEffect, useMemo, useState } from "react";
import { Tooltip } from "antd";
import { styled } from "twin.macro";
import { tryWriteClipboard } from "../../../utilities/clipboard";
import { BLUE_1 } from "../colors";
import { IconCopy } from "../icons";
import { DEFAULT_TEXT_CSS } from "../typography";

const Button = styled.button<{ weight?: "bold" | "normal" }>`
  ${DEFAULT_TEXT_CSS}
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  column-gap: 2px;
  font-weight: ${({ weight }) => (weight === "bold" ? "bold" : "normal")};

  &:hover {
    color: ${BLUE_1};
  }
`;

export function CopyButton({
  text,
  weight,
  textToCopy,
  tooltipText = "Copy",
  className,
}: {
  text?: string;
  weight?: "bold" | "normal";
  textToCopy?: string;
  tooltipText?: string;
  className?: string;
}) {
  const [displayCopiedText, setDisplayCopiedText] = useState(false);

  const _textToCopy = useMemo(() => {
    if (textToCopy) return textToCopy;
    if (typeof text === "string") return text;
    return "";
  }, [text, textToCopy]);

  const handleOnClick = useCallback(() => {
    tryWriteClipboard(_textToCopy);
    setDisplayCopiedText(true);
  }, [_textToCopy]);

  useEffect(() => {
    if (!displayCopiedText) return;
    const timeoutId = setTimeout(() => {
      setDisplayCopiedText(false);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [displayCopiedText]);

  if (displayCopiedText) return "Copied...";

  return (
    <Tooltip title={tooltipText}>
      <Button weight={weight} className={className} onClick={handleOnClick}>
        <span>{text}</span>
        <IconCopy tw="w-[10px]" />
      </Button>
    </Tooltip>
  );
}
