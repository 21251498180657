import { createContext, useContext, useState, ReactNode, useMemo } from "react";
import { PageAlerts } from "../components/pageAlerts/PageAlerts";

const ClientPageAlertContext = createContext<
  ReturnType<typeof useClientPageAlertProvider> | undefined
>(undefined);

function useClientPageAlertProvider() {
  const [clientPageAlert, setClientPageAlert] = useState<string>();
  return { clientPageAlert, setClientPageAlert };
}

export function ClientPageAlertProvider({ children }: { children: ReactNode }) {
  const value = useClientPageAlertProvider();
  const { clientPageAlert } = value;

  const msgs = useMemo(() => {
    if (clientPageAlert) return [clientPageAlert];
  }, [clientPageAlert]);

  return (
    <ClientPageAlertContext.Provider value={value}>
      <PageAlerts msgs={msgs}></PageAlerts>
      {children}
    </ClientPageAlertContext.Provider>
  );
}

export function useClientPageAlert() {
  const context = useContext(ClientPageAlertContext);
  if (!context) {
    throw new Error(
      "useClientPageAlert must be used within a ClientPageAlertProvider"
    );
  }
  return context;
}
