import React, { useMemo } from "react";
import tw from "twin.macro";
import { NavLink } from "react-router-dom";
import type { Supplier } from "../../../utilities/types";
import { Text } from "../../../components/rxLibrary/typography";
import { ExtraWideContainer } from "../../../components/containers/ExtraWideContainer";
import { PrintWrapper } from "../../../components/rxLibrary/print";
import { SupplierImage } from "../../../components/suppliers/SupplierImage";
import { useManufacturerStoreHeaderRefillLink } from "./useManufacturerStoreHeaderRefillLink";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Link = tw(NavLink)`flex space-x-1 items-center`;

export function ManufacturerStoreHeader({ supplier }: { supplier: Supplier }) {
  const supplierId = supplier.id;

  const [manufacturerStoreUrl, orderHistoryUrl, accountSettingsUrl] =
    useMemo(() => {
      const manufacturerStoreUrl = `/manufacturer-store/${supplierId}`;
      return [
        manufacturerStoreUrl,
        `${manufacturerStoreUrl}/order-history`,
        `${manufacturerStoreUrl}/account-settings`,
      ];
    }, [supplierId]);

  const manufacturerStoreHeaderRefillLink =
    useManufacturerStoreHeaderRefillLink({
      supplierId,
      manufacturerStoreUrl,
    });

  return (
    <PrintWrapper tw="px-[80px]" bgColor="white-1">
      <ExtraWideContainer tw="relative z-20 flex justify-between items-center h-[118px]">
        <SupplierImage height={60} supplier={supplier} />

        <div tw="flex items-center space-x-2">
          <Link
            exact
            to={manufacturerStoreUrl}
            activeClassName="underline font-bold"
          >
            <Text>Catalog</Text>
          </Link>
          <Text color="grey-2">|</Text>

          {manufacturerStoreHeaderRefillLink && (
            <>
              <Link
                to={manufacturerStoreHeaderRefillLink}
                activeClassName="underline font-bold"
              >
                <Text>Refill Opportunities</Text>
              </Link>
              <Text color="grey-2">|</Text>
            </>
          )}

          <Link
            exact
            to={accountSettingsUrl}
            activeClassName="underline font-bold"
          >
            <Text>Account Settings</Text>
          </Link>
          <Text color="grey-2">|</Text>

          <Link
            exact
            to={orderHistoryUrl}
            activeClassName="underline font-bold"
          >
            <img
              src="/assets/pages/manufacturer-store/icon-order-history.svg"
              alt="Order History"
            />
            <Text>Order History</Text>
          </Link>
        </div>
      </ExtraWideContainer>
    </PrintWrapper>
  );
}
