import isNumber from "lodash/isNumber";
import toNumber from "lodash/toNumber";
import semver from "semver";
import pluralize from "pluralize";
import {
  DrugInfo,
  TaggedItem,
  SuggestedNdc,
  TaggedItemList,
  BaseTaggedItem,
  ListCategoryEnum,
  NDCTaggedItemList,
} from "./types";

export enum ParsedSearchTypeEnum {
  NDC,
  TEXT,
}

export function parseSearch(text: string): {
  value: string;
  type: ParsedSearchTypeEnum;
} {
  let search = text.trim();
  let hasNumbers = false;
  let hasHyphens = false;
  let hasOtherTypes = false;

  for (const char of search) {
    if (char === "-") {
      hasHyphens = true;
    } else if (isNumber(toNumber(char))) {
      hasNumbers = true;
    } else {
      hasOtherTypes = true;
      break;
    }
  }
  if (!hasOtherTypes && hasNumbers && hasHyphens) {
    search = search.replaceAll("-", "");
  }

  // Check if has 10 or 11 digits
  const isValidNDC = /^\d{10,11}$/.test(search);
  const result = {
    value: search,
    type: isValidNDC ? ParsedSearchTypeEnum.NDC : ParsedSearchTypeEnum.TEXT,
  };

  return result;
}

function nextIdx(
  ndcsTaken: Record<string, boolean>,
  list: TaggedItemList,
  idx: number
): number {
  let newIdx = idx;
  while (
    newIdx < list.items.length &&
    ndcsTaken[list.items[newIdx].drugInfo.ndc]
  ) {
    ++newIdx;
  }
  return newIdx;
}

function takeNextNdc(
  diffQuantity: TaggedItemList,
  idxQuantity: number,
  diffManufacturer: TaggedItemList,
  idxManufacturer: number,
  diffBoth: TaggedItemList,
  idxBoth: number,
  diffOthers: TaggedItemList,
  idxOthers: number
) {
  const candidates: { idx: number; list: TaggedItemList; priority: number }[] =
    [];

  if (diffQuantity.items.length > idxQuantity) {
    candidates.push({ list: diffQuantity, idx: idxQuantity, priority: 1 });
  }

  if (diffManufacturer.items.length > idxManufacturer) {
    candidates.push({
      idx: idxManufacturer,
      list: diffManufacturer,
      priority: 2,
    });
  }

  if (diffBoth.items.length > idxBoth) {
    candidates.push({ idx: idxBoth, list: diffBoth, priority: 3 });
  }

  if (diffOthers.items.length > idxOthers) {
    candidates.push({ idx: idxOthers, list: diffOthers, priority: 4 });
  }

  candidates.sort((c1, c2): number => {
    if (c1.idx === 0 && c2.idx === 0) {
      return c1.priority - c2.priority;
    }
    if (c1.idx === 0) {
      return -1;
    }
    if (c2.idx === 0) {
      return 1;
    }

    const itemA = c1.list.items[c1.idx];
    const itemB = c2.list.items[c2.idx];
    const costPerDoseA = itemA.catalogInfo.price / itemA.drugInfo.unitSize;
    const costPerDoseB = itemB.catalogInfo.price / itemB.drugInfo.unitSize;
    return costPerDoseA - costPerDoseB;
  });

  const chosen = candidates[0];
  if (!chosen) return {};

  const ndc = chosen.list.items[chosen.idx].drugInfo.ndc;
  const items = chosen.list.items.filter((item) => item.drugInfo.ndc === ndc);
  if (!items.length) return { ndc };

  const next: NDCTaggedItemList = {
    ndc,
    items,
    category: chosen.list.category,
  };
  return { ndc, next };
}

function accumulateNonMatches(
  items: NDCTaggedItemList[],
  diffQuantity: TaggedItemList,
  diffManufacturer: TaggedItemList,
  diffBoth: TaggedItemList,
  diffOthers: TaggedItemList,
  ndcsTaken: Record<string, boolean> = {},
  idxQuantity = 0,
  idxManufacturer = 0,
  idxBoth = 0,
  idxOthers = 0
): NDCTaggedItemList[] {
  if (
    idxQuantity === diffQuantity.items.length &&
    idxManufacturer === diffManufacturer.items.length &&
    idxBoth === diffBoth.items.length &&
    idxOthers === diffOthers.items.length
  ) {
    return items;
  }

  const { ndc, next } = takeNextNdc(
    diffQuantity,
    idxQuantity,
    diffManufacturer,
    idxManufacturer,
    diffBoth,
    idxBoth,
    diffOthers,
    idxOthers
  );
  const newTakenNdc = ndc ? { ...ndcsTaken, [ndc]: true } : ndcsTaken;
  const newItems = next ? [...items, next] : items;

  const newIdxQuantity = nextIdx(newTakenNdc, diffQuantity, idxQuantity);
  const newIdxManufacturer = nextIdx(
    newTakenNdc,
    diffManufacturer,
    idxManufacturer
  );
  const newIdxBoth = nextIdx(newTakenNdc, diffBoth, idxBoth);
  const newIdxOthers = nextIdx(newTakenNdc, diffOthers, idxOthers);

  return accumulateNonMatches(
    newItems,
    diffQuantity,
    diffManufacturer,
    diffBoth,
    diffOthers,
    newTakenNdc,
    newIdxQuantity,
    newIdxManufacturer,
    newIdxBoth,
    newIdxOthers
  );
}

export function groupItemsByNdc(
  taggedItemsGroupedByTag: TaggedItemList[]
): NDCTaggedItemList[] {
  const results: NDCTaggedItemList[] = [];
  const leadItemGroup = taggedItemsGroupedByTag.find(
    (item) =>
      item.category === ListCategoryEnum.NDC_MATCH ||
      item.category === ListCategoryEnum.SUGGESTED_NDC
  );

  if (leadItemGroup?.items?.length) {
    results.push({
      ...leadItemGroup,
      ndc: leadItemGroup.items[0].drugInfo.ndc,
    });
  }

  const itemsByCategory = taggedItemsGroupedByTag.reduce<
    Record<string, TaggedItemList>
  >((acc, item) => {
    if (
      item.category &&
      [
        ListCategoryEnum.DIFFERENT_QUANTITIES,
        ListCategoryEnum.DIFFERENT_MANUFACTURERS,
        ListCategoryEnum.DIFFERENT_MANUFACTURER_AND_QUANTITY,
      ].includes(item.category)
    ) {
      acc[item.category] = item;
    } else {
      acc[ListCategoryEnum.OTHERS] = {
        category: ListCategoryEnum.OTHERS,
        items: [...(acc[ListCategoryEnum.OTHERS]?.items ?? []), ...item.items],
      };
    }
    return acc;
  }, {});

  const diffQuantity = itemsByCategory[
    ListCategoryEnum.DIFFERENT_QUANTITIES
  ] ?? { items: [], category: ListCategoryEnum.DIFFERENT_QUANTITIES };

  const diffManufacturer = itemsByCategory[
    ListCategoryEnum.DIFFERENT_MANUFACTURERS
  ] ?? { items: [], category: ListCategoryEnum.DIFFERENT_MANUFACTURERS };

  const diffBoth = itemsByCategory[
    ListCategoryEnum.DIFFERENT_MANUFACTURER_AND_QUANTITY
  ] ?? {
    items: [],
    category: ListCategoryEnum.DIFFERENT_MANUFACTURER_AND_QUANTITY,
  };
  const diffOthers = itemsByCategory[ListCategoryEnum.OTHERS] ?? {
    items: [],
    category: ListCategoryEnum.OTHERS,
  };

  return accumulateNonMatches(
    results,
    diffQuantity,
    diffManufacturer,
    diffBoth,
    diffOthers
  );
}

function getTag({
  item,
  mainNdc,
  leadDrug,
  suggestedNDC,
}: {
  item: BaseTaggedItem;
  mainNdc: string;
  leadDrug: DrugInfo;
  suggestedNDC: SuggestedNdc;
}) {
  const { ndc, unitSize, manufacturer, unitQuantity } = item.drugInfo;
  const isSameManufacturer = leadDrug.manufacturer === manufacturer;
  const isSameUnitQuantity = leadDrug.unitQuantity === unitQuantity;
  const isSameUnitSize = leadDrug.unitSize === unitSize;

  if (ndc === suggestedNDC.ndc && suggestedNDC.reason !== "ndc_match") {
    return ListCategoryEnum.SUGGESTED_NDC;
  } else if (ndc === mainNdc) {
    return ListCategoryEnum.NDC_MATCH;
  } else if (!isSameManufacturer && isSameUnitQuantity && isSameUnitSize) {
    return ListCategoryEnum.DIFFERENT_MANUFACTURERS;
  } else if (isSameManufacturer && (!isSameUnitQuantity || !isSameUnitSize)) {
    return ListCategoryEnum.DIFFERENT_QUANTITIES;
  } else if (!isSameManufacturer && (!isSameUnitQuantity || !isSameUnitSize)) {
    return ListCategoryEnum.DIFFERENT_MANUFACTURER_AND_QUANTITY;
  }
  return ListCategoryEnum.OTHERS;
}

export function tagAndGroupQuoteItems(
  mainNdc: string,
  items: BaseTaggedItem[],
  suggestedNDC: SuggestedNdc
) {
  if (!items.length) return [];

  const leadDrug = items[0].drugInfo;
  const isLeadDrugUnitDosePackaging =
    leadDrug.unitDoseDescription === "Unit-Dose Packaging";

  const taggedItems = items.map((item) => {
    const tag = getTag({ mainNdc, item, leadDrug, suggestedNDC });

    const alternatePackaging =
      leadDrug.unitDoseDescription !== item.drugInfo.unitDoseDescription &&
      (isLeadDrugUnitDosePackaging ||
        item.drugInfo.unitDoseDescription === "Unit-Dose Packaging");

    const newItem: TaggedItem = { ...item, tag, alternatePackaging };
    if (
      tag === ListCategoryEnum.SUGGESTED_NDC &&
      suggestedNDC.reason !== "ndc_match"
    ) {
      newItem.suggestedReason = suggestedNDC.reason;
    }

    return newItem;
  });

  const taggedItemsGroupedByTag = taggedItems.reduce<
    Record<string, TaggedItemList>
  >((acc, item) => {
    const tag = item.tag;
    if (acc[tag]) acc[tag].items.push(item);
    else acc[tag] = { category: tag, items: [item] };
    return acc;
  }, {});

  const taggedItemsGroupedByTagList = Object.values(taggedItemsGroupedByTag);
  return taggedItemsGroupedByTagList;
}

export function disableV1EnhancementsForSupplier(supplier: string) {
  return supplier === "ANDA";
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function disableV2EnhancementsForSupplier(_: string) {
  return false;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function shallowLinksOnlyForSupplier(
  supplier: string,
  appVersion: string
) {
  if (!appVersion || semver.lt(appVersion, "0.3.82")) {
    return supplier === "ABC" || supplier === "ABC2";
  }
  return false;
}

export function getDispensedText(items: TaggedItem[]): string | null {
  const item = items[0];
  if (!item) return null;

  const { numberOfUnitsLast30Days: unitsNum, numberOfPrescriptionsLast30Days } =
    item.historicalDispensationInfo;
  if (numberOfPrescriptionsLast30Days === 0) {
    return null;
  }

  const { uom, form } = item.drugInfo;

  let units = "";
  if (uom === "Each") {
    units = pluralize(form, unitsNum);
  } else if (uom === "Milliliter") {
    units = pluralize("ml", unitsNum);
  } else if (uom === "Gram") {
    units = "g";
  }

  const dispenseText = `${String(numberOfPrescriptionsLast30Days).replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  )} ${numberOfPrescriptionsLast30Days > 1 ? "Rxs" : "Rx"} (${String(
    unitsNum
  ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${units})`;
  return dispenseText;
}
