import {
  useMemo,
  useState,
  useEffect,
  ReactNode,
  useContext,
  useCallback,
  createContext,
} from "react";
import dayjs, { Dayjs } from "dayjs";
import type { DateRangePickerValue } from "../rxLibrary/dateRangePicker";
import type { Pharmacy, ReportRefillOpportunity } from "../../utilities/types";
import { displayErrorModal } from "../../utilities/errors/displayErrorModal";
import { getQueryParams } from "../../utilities/queryParams/getQueryParams";
import { ensureArray } from "../../utilities/arrays/ensureArray";
import { getReportRefillOpportunity } from "../../services/report";
import { useRequest } from "../../services/request/useRequest";
import { useBuyingPharmacy } from "../../contexts/BuyingPharmacyContext";
import { removePaginationFields } from "../rxLibrary/pagination/pagination.utils";
import { SortDirectionEnum } from "../rxLibrary/selects/SortBySelect";
import { usePagination } from "../rxLibrary/pagination/usePagination";

function getInitialDates(): DateRangePickerValue {
  return [dayjs().subtract(180, "day"), dayjs()];
}

function getQueryParamsManufacturers() {
  const manufacturersQP = getQueryParams().manufacturer ?? [];
  const manufacturerIds: number[] = ensureArray(manufacturersQP)
    .map((v) => parseInt(v, 10))
    .filter((v) => !isNaN(v));
  return manufacturerIds;
}

function useManufacturerData() {
  const { currentBuyingPharmacy: pharmacy } = useBuyingPharmacy();
  const { refillMappingBasedBuyingUsersSeeRefillReport: allRefillMappings } =
    pharmacy ?? {};

  const [manufacturerFilters, _setManufacturerFilters] = useState<number[]>();
  const [manufacturerData, setManufacturerData] = useState<{
    refillMappings?: typeof allRefillMappings;
    manufacturerIds?: number[];
    manufacturerOptions?: { label: string; value: number }[];
  }>({});

  useEffect(() => {
    if (!allRefillMappings?.length) {
      setManufacturerData({});
      _setManufacturerFilters(undefined);
      return;
    }

    const manufacturerOptions = allRefillMappings.map((v) => ({
      label: v.manufacturerName,
      value: v.manufacturerId,
    }));
    const refillMappings = allRefillMappings.filter((v) => !v.isActiveSupplier);
    const manufacturersQP = getQueryParamsManufacturers();
    const manufacturerIds = allRefillMappings.map((v) => v.manufacturerId);
    const filters = manufacturerIds.filter((v) => manufacturersQP.includes(v));
    _setManufacturerFilters(filters);
    setManufacturerData({
      refillMappings,
      manufacturerIds,
      manufacturerOptions,
    });
  }, [allRefillMappings]);

  return { ...manufacturerData, manufacturerFilters, _setManufacturerFilters };
}

function useRefillOpportunitiesData(pharmacy: Pharmacy) {
  const { id: pharmacyId } = pharmacy;
  const {
    refillMappings,
    manufacturerIds,
    manufacturerFilters,
    manufacturerOptions,
    _setManufacturerFilters,
  } = useManufacturerData();

  const [reportRefillOpportunity, setReportRefillOpportunity] =
    useState<ReportRefillOpportunity>();
  const [dates, _setDates] = useState(getInitialDates);
  const [sortColumn, _setSortColumn] = useState("estimated_profit");
  const [sortDirection, _setSortDirection] = useState(SortDirectionEnum.DESC);
  const [showOnlyCoveredFills, _setShowOnlyCoveredFills] = useState(true);
  const [showOnlyPaidOutreaches, _setShowOnlyPaidOutreaches] = useState(true);
  const [lastSynchDate, setLastSyncDate] = useState<Dayjs>();

  const { pagination, updatePagination, resetPaginationPage } = usePagination({
    initialPagination: { currentPage: 1, pageSize: 50 },
  });
  const { currentPage, pageSize } = pagination ?? {};

  const refillOpportunitiesConfig = useMemo(() => {
    if (!manufacturerFilters || !manufacturerIds) return;

    const [startDate, endDate] = dates;
    return {
      endDate,
      pageSize,
      startDate,
      sortColumn,
      pharmacyId,
      pageNumber: currentPage,
      sortDirection,
      manufacturerIds: manufacturerFilters.length
        ? manufacturerFilters
        : manufacturerIds,
      showOnlyCoveredFills,
      showOnlyPaidOutreaches,
    };
  }, [
    dates,
    pageSize,
    sortColumn,
    pharmacyId,
    currentPage,
    sortDirection,
    manufacturerIds,
    manufacturerFilters,
    showOnlyCoveredFills,
    showOnlyPaidOutreaches,
  ]);

  const setDates = useCallback(
    (newDates: DateRangePickerValue) => {
      resetPaginationPage();
      _setDates(newDates);
    },
    [resetPaginationPage]
  );

  const setSortColumn = useCallback(
    (newSortColumn: string) => {
      resetPaginationPage();
      _setSortColumn(newSortColumn);
    },
    [resetPaginationPage]
  );

  const setSortDirection = useCallback(
    (newSortDirection: SortDirectionEnum) => {
      resetPaginationPage();
      _setSortDirection(newSortDirection);
    },
    [resetPaginationPage]
  );

  const setShowOnlyCoveredFills = useCallback(
    (newShowOnlyCoveredFills: boolean) => {
      resetPaginationPage();
      _setShowOnlyCoveredFills(newShowOnlyCoveredFills);
    },
    [resetPaginationPage]
  );

  const setShowOnlyPaidOutreaches = useCallback(
    (newShowOnlyPaidOutreaches: boolean) => {
      resetPaginationPage();
      _setShowOnlyPaidOutreaches(newShowOnlyPaidOutreaches);
    },
    [resetPaginationPage]
  );

  const setManufacturerFilters = useCallback(
    (newManufacturerFilters: number[]) => {
      resetPaginationPage();
      _setManufacturerFilters(newManufacturerFilters);
    },
    [resetPaginationPage]
  );

  const { data, error, isLoading, isRefetching, refetch } = useRequest({
    config: refillOpportunitiesConfig,
    start: !!refillOpportunitiesConfig,
    dataKey: "refillOpportunities",
    request: getReportRefillOpportunity,
  });

  useEffect(() => {
    if (error) {
      displayErrorModal({
        title: "Error fetching your report refill opportunities",
      });
    }

    if (!data) {
      setReportRefillOpportunity(undefined);
      return;
    }

    updatePagination(data);
    const reportRefillOpportunity = removePaginationFields(
      data
    ) as ReportRefillOpportunity;
    setReportRefillOpportunity(reportRefillOpportunity);
    setLastSyncDate(dayjs());
  }, [data, error]);

  return {
    dates,
    pharmacy,
    isLoading,
    sortColumn,
    pagination,
    pharmacyId,
    isRefetching,
    lastSynchDate,
    sortDirection,
    refillMappings,
    manufacturerIds,
    manufacturerFilters,
    manufacturerOptions,
    showOnlyCoveredFills,
    showOnlyPaidOutreaches,
    reportRefillOpportunity,
    refillOpportunitiesConfig,
    refetch,
    setDates,
    setSortColumn,
    setSortDirection,
    updatePagination,
    setManufacturerFilters,
    setShowOnlyCoveredFills,
    setShowOnlyPaidOutreaches,
  };
}

type RefillOpportunities = ReturnType<typeof useRefillOpportunitiesData>;

const RefillOpportunitiesContext = createContext<
  RefillOpportunities | undefined
>(undefined);

export function RefillOpportunitiesProvider({
  children,
  pharmacy,
}: {
  children: ReactNode;
  pharmacy: Pharmacy;
}) {
  const refillOpportunities = useRefillOpportunitiesData(pharmacy);
  return (
    <RefillOpportunitiesContext.Provider value={refillOpportunities}>
      {children}
    </RefillOpportunitiesContext.Provider>
  );
}

export function useRefillOpportunities() {
  const refillOpportunities = useContext(RefillOpportunitiesContext);
  return refillOpportunities as RefillOpportunities;
}
