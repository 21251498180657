import React, { useMemo } from "react";
const DOWN_ICON = new URL('svgurl:../../../assets/down.svg', import.meta.url).toString();
import { DrugInfo, DrugWithStats } from "../../../utilities/types";
import { drugInfoHasValidUnits } from "../../../utilities/drugInfo/drugInfo";
import { formatPurchaseByToDayjs } from "../../../utilities/prescriptions/purchaseBy/formatPurchaseByToDayjs";
import { formatPrescriberName } from "../../../utilities/prescriptions/formatPrescriberName";
import { useDrugFamilyPrescriptionsInShoppingList } from "../../../utilities/prescriptions/drugFamilyPrescriptions/useDrugFamilyPrescriptionsInShoppingList";
import { getPrescriptionId } from "../../../utilities/prescriptions/getPrescriptionId";
import { SHORT_DATE_FORMAT } from "../../../utilities/dates/dates.constants";
import { formatDrugDispenseQuantity } from "../../../utilities/drugInfo/formatDrugDispenseQuantity";
import { DuplicatedDrugFamilyWarning } from "../DuplicatedDrugFamilyWarning";
import { FillDateCol } from "../table/columns/FillDateCol";
import { RxDetailsCol } from "../table/columns/RxDetailsCol";
import { pipePrefixed, QuantityDescription } from "../../QuantityDescription";
import DispensedItem from "../../DispensedItem";
import { Container } from "../../containers/Container";
import { Box } from "../../rxLibrary/box";
import { Text } from "../../rxLibrary/typography";
import { SimpleTable } from "../../rxLibrary/tables";
import { DrugDetailsCol } from "./DrugDetailsCol";
import {
  CancelBtn,
  MediumParagraph,
  DispensesStatsTitle,
} from "./InventoryModal.dispenses";

function useTableItems(drug: DrugInfo) {
  const drugFamilyPrescriptions = useDrugFamilyPrescriptionsInShoppingList({
    drug,
  });

  const tableItems = useMemo(() => {
    return drugFamilyPrescriptions.map((prescription) => {
      const { drug, prescriber, purchaseBy: purchaseByRaw } = prescription;
      const { manufacturer } = drug;
      const id = getPrescriptionId(prescription);
      const prescriberName = formatPrescriberName(prescriber);
      const dispenseQuantity = formatDrugDispenseQuantity(drug);
      const purchaseByDate = formatPurchaseByToDayjs(purchaseByRaw);
      const purchaseBy = purchaseByDate?.format(SHORT_DATE_FORMAT) ?? "TODAY";

      return {
        id,
        drug,
        purchaseBy,
        manufacturer,
        prescription,
        prescriberName,
        dispenseQuantity,
      };
    });
  }, [drugFamilyPrescriptions]);

  return tableItems;
}

export function InventoryModalHeader({
  prescriptionData,
  dispensesStatsType,
  closeModal,
  resetScreen,
}: {
  prescriptionData: DrugWithStats[];
  dispensesStatsType: string;
  closeModal: () => void;
  resetScreen: () => void;
}) {
  const prescription = prescriptionData[0];
  const { drug } = prescription;
  const hasValidUnits = useMemo(() => drugInfoHasValidUnits(drug), [drug]);
  const tableItems = useTableItems(drug);
  const tableItemsCount = tableItems.length;

  return (
    <Box bgColor="white-1">
      <Container tw="flex flex-col min-h-[100px] space-y-[17px] pt-[42px] pb-[22px]">
        <div tw="flex justify-between">
          <button className="flex items-center underline" onClick={resetScreen}>
            <img src={DOWN_ICON} className="transform-90" alt="go back arrow" />

            <div className="text-lg ml-12 flex items-center">
              <DispensedItem drug={drug} ndc={false} />

              {hasValidUnits && (
                <QuantityDescription
                  drug={drug}
                  element={MediumParagraph}
                  format={pipePrefixed}
                />
              )}

              <div className="ml-6">
                {dispensesStatsType === "" ? (
                  prescriptionData.length > 4 && (
                    <p className="mr-6 text-sm">
                      Not dispensed in the last 6 months
                    </p>
                  )
                ) : (
                  <DispensesStatsTitle
                    items={prescriptionData}
                    type={dispensesStatsType}
                  />
                )}
              </div>
            </div>
          </button>

          <CancelBtn onClick={closeModal}>Cancel</CancelBtn>
        </div>

        {tableItemsCount > 0 && (
          <div tw="space-y-[8px]">
            <DuplicatedDrugFamilyWarning itemsCount={tableItemsCount} />

            <SimpleTable>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Manufacturer</th>
                  <th>Quantity</th>
                  <th>Buy By</th>
                  <th>Rx # , Patient</th>
                  <th>Date Added</th>
                  <th>Prescriber</th>
                </tr>
              </thead>

              <tbody>
                {tableItems.map((item) => {
                  const {
                    id,
                    drug,
                    purchaseBy,
                    manufacturer,
                    prescription,
                    prescriberName,
                    dispenseQuantity,
                  } = item;
                  const { packQuantity } = prescription;
                  const quantity =
                    (packQuantity ?? 0) > 1
                      ? `${packQuantity} packs of ${dispenseQuantity}`
                      : dispenseQuantity;

                  return (
                    <tr key={id}>
                      <td>
                        <DrugDetailsCol drug={drug} />
                      </td>

                      <td>{manufacturer}</td>

                      <td>{quantity}</td>

                      <td>
                        <Text weight="bold">{purchaseBy}</Text>
                      </td>

                      <td>
                        <RxDetailsCol prescription={prescription} />
                      </td>

                      <td>
                        <FillDateCol prescription={prescription} />
                      </td>

                      <td>{prescriberName}</td>
                    </tr>
                  );
                })}
              </tbody>
            </SimpleTable>
          </div>
        )}
      </Container>
    </Box>
  );
}
