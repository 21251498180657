import { Container } from "../../../../components/containers/Container";
import { FullPageLoader } from "../../../../components/loaders/FullPageLoader";
import {
  CompareResultsData,
  NdcResults,
  useCompareResults
} from "./useCompareResults/useCompareResults";
import { CompareResultsCards } from "./CompareResultsCards/CompareResultsCards";

export function CompareResults({
  enhancementExcludedSuppliers,
}: {
  enhancementExcludedSuppliers?: string[];
}) {
  const compareResults = useCompareResults(
    enhancementExcludedSuppliers
  );
  const { isEmpty, ndcResults, formattedNdc} = compareResults;

  if (isEmpty) {
    return (
      <Container tw="text-center text-lg">
        Unfortunately none of your catalogs currently carry{" "}
        <span tw="font-medium">{formattedNdc}</span>
      </Container>
    );
  }

  if (!ndcResults) {
    return <FullPageLoader />;
  }

  return <CompareResultsCards compareResults={compareResults as CompareResultsData & {ndcResults: NdcResults}} />;
}
