import { useCreateForm_legacy } from "../../../../../components/antd/form/useCreateForm";
import { createAdminSupplierMapping } from "../../../../../services/supplierMappings";
import { AdminSupplierMappingForm } from "./AdminSupplierMappingForm/AdminSupplierMappingForm";
import { WideContainer } from "../../../../../components/containers/WideContainer";
import { CLEAN_FROM_SHIPPING_CONFIG } from "./AdminSupplierMappingForm/AdminSupplierMappingForm.utils";

const DEFAULT_VALUES = {
  brandItemPenalty: 0,
  ecommerceEnabled: false,
  hasDirectDeal: false,
  isActive: true,
  notifyAlternativeSuppliersForBrand: true,
  alwaysReturnSeedDrugOverride: false,
  rebatedItemDiscount: 0,
  scraperConfig: {},
  shippingConfig: CLEAN_FROM_SHIPPING_CONFIG,
  suppliesSchedule2: false,
  suppliesSchedule3: true,
  suppliesSchedule4: true,
  suppliesSchedule5: true,
};

export function CreateAdminSupplierMapping() {
  const onSubmit = useCreateForm_legacy({
    redirectTo: "/admin/supplier-mapping",
    create: createAdminSupplierMapping,
  });

  return (
    <WideContainer tw="pb-[200px]">
      <AdminSupplierMappingForm
        initialValues={DEFAULT_VALUES}
        onSubmit={onSubmit}
      />
    </WideContainer>
  );
}
