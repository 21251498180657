import { useContext, useEffect, useState } from "react";
import BuyingPharmacyContext from "../../../contexts/BuyingPharmacyContext";
import { getSearch } from "../../../services/searches";
import { cleanNdc } from "../../../utilities/ndc/cleanNdc";
import { RequestState, SearchResult } from "../../../utilities/types";
import { formatMedispanError } from "../../../utilities/prescriptions/medispanError";
import {
  IHandleSearchNdc,
  IHandleSearchResults,
} from "../DrugSearch.constants";
import { NotCommonNdcSearchError } from "./useDrugSearch.errors";
import { IDrugSearchRequestState } from "./useDrugSearch.constants";
import { useDrugSearchValues } from "./drugSearchValues";

export { useUpdateDrugSearchValues } from "./drugSearchValues";

export function useDrugSearch({
  handleSearchResults,
  handleSearchNdc,
}: {
  handleSearchResults: IHandleSearchResults;
  handleSearchNdc?: IHandleSearchNdc;
}) {
  const { currentBuyingPharmacyId } = useContext(BuyingPharmacyContext);
  const drugSearchValues = useDrugSearchValues();
  const [drugSearchResults, setDrugSearchResults] = useState<SearchResult[]>(
    []
  );
  const [drugSearchResultsState, setDrugSearchResultsState] =
    useState<IDrugSearchRequestState>([RequestState.UNINITIATED]);
  const { search } = drugSearchValues;

  useEffect(() => {
    searchDrug();

    async function searchDrug() {
      if (!search || !currentBuyingPharmacyId) {
        setDrugSearchResultsState([RequestState.UNINITIATED]);
        setDrugSearchResults([]);
        return;
      }

      setDrugSearchResultsState([RequestState.PENDING]);

      try {
        if (handleSearchNdc) {
          const ndc = cleanNdc(search);

          if (ndc) {
            const shouldStop = await handleSearchNdc(ndc);
            if (!shouldStop) {
              setDrugSearchResultsState([RequestState.COMPLETED]);
              setDrugSearchResults([]);
            }
            return;
          }
        }

        const response = await getSearch({
          text: search,
          pharmacyId: currentBuyingPharmacyId,
          includeNonMedispan: 1,
        });
        if (!response) return;

        const { searchError, searchResults } = response.data.data;
        const medispanError = formatMedispanError(search, searchError);
        if (medispanError) {
          const { msg, type } = medispanError;
          setDrugSearchResultsState([RequestState.ERROR, type, msg]);
          setDrugSearchResults([]);
          return;
        }

        const shouldStop = await handleSearchResults({ searchResults });
        if (!shouldStop) {
          setDrugSearchResultsState([RequestState.COMPLETED]);
          setDrugSearchResults(searchResults);
        }
      } catch (error) {
        if (error instanceof NotCommonNdcSearchError) {
          setDrugSearchResultsState([RequestState.ERROR, "NDC"]);
        } else {
          setDrugSearchResultsState([RequestState.ERROR, "SEARCH"]);
        }
        setDrugSearchResults([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, currentBuyingPharmacyId]);

  return {
    drugSearchValues,
    drugSearchResults,
    drugSearchResultsState,
  };
}
