import React from "react";
import type { ExportToolPanelProps } from "../useReport/useReportSideBar";
import { DownloadCsvButton } from "./DownloadCsvButton";
import { ButtonExportCsvWithChildren } from "./DownloadCsvWithChildrenButton";
import { MultipleDownloadCsvWithChildrenButtons } from "./MultipleDownloadCsvWithChildrenButtons";

export function DefaultExportToolPanel({
  gridRef,
  detailCellRendererParams,
  multipleDownloadCsvWithChildrenButtonsConfig,
  handleExport,
}: ExportToolPanelProps) {
  const displayOneCsvWithChildrenButton = !!detailCellRendererParams;
  const displayMultipleCsvWithChildrenButtons =
    !displayOneCsvWithChildrenButton &&
    !!multipleDownloadCsvWithChildrenButtonsConfig;

  return (
    <>
      <DownloadCsvButton handleExport={handleExport} />

      {displayOneCsvWithChildrenButton && (
        <ButtonExportCsvWithChildren
          gridRef={gridRef}
          detailCellRendererParams={detailCellRendererParams}
        />
      )}

      {displayMultipleCsvWithChildrenButtons && (
        <MultipleDownloadCsvWithChildrenButtons
          gridRef={gridRef}
          config={multipleDownloadCsvWithChildrenButtonsConfig}
        />
      )}
    </>
  );
}
