import type {
  AccountOptions,
  AdminAccount,
  CreateAdminAccount,
  UpdateAdminAccount,
} from "../utilities/types";
import { requestClient, RequestClientConfig } from "./request/requestClient";

export async function getAdminAccounts(
  config: RequestClientConfig<Record<string, never>>
) {
  return requestClient<{ accounts: AdminAccount[] }>({
    ...config,
    url: "/admin/account",
  });
}

export async function getAdminAccount({
  id,
  ...config
}: RequestClientConfig<{
  id: number | string;
}>) {
  return requestClient<{ account: AdminAccount }>({
    ...config,
    url: `/admin/account/${id}`,
  });
}

export async function getAdminAccountOptions(
  config: RequestClientConfig<Record<string, never>>
) {
  return requestClient<{ accountOptions: AccountOptions }>({
    ...config,
    url: "/admin/account/options",
  });
}

export async function createAdminAccount({
  email,
  isAdmin,
  fullName,
  pharmacies,
  isTrialUser,
  businessRoles,
  sendWelcomeEmail,
  supportContextMenu,
  defaultBuyingPharmacyId,
  ...config
}: RequestClientConfig<CreateAdminAccount>) {
  return requestClient<{ account: AdminAccount }>({
    ...config,
    method: "POST",
    url: "/admin/account",
    params: { sendWelcomeEmail },
    data: {
      email,
      isAdmin,
      fullName,
      pharmacies,
      isTrialUser,
      businessRoles,
      supportContextMenu,
      defaultBuyingPharmacyId,
    },
  });
}

export async function updateAdminAccount({
  id,
  email,
  isAdmin,
  fullName,
  pharmacies,
  isTrialUser,
  businessRoles,
  supportContextMenu,
  defaultBuyingPharmacyId,
  ...config
}: RequestClientConfig<UpdateAdminAccount>) {
  return requestClient<{ account: AdminAccount }>({
    ...config,
    method: "PUT",
    url: `/admin/account/${id}`,
    data: {
      email,
      isAdmin,
      fullName,
      pharmacies,
      isTrialUser,
      businessRoles,
      supportContextMenu,
      defaultBuyingPharmacyId,
    },
  });
}

export async function adminAccountSendResetPasswordEmail({
  id,
  ...config
}: RequestClientConfig<{
  id: number;
}>) {
  return requestClient({
    ...config,
    method: "POST",
    url: `/admin/account/${id}/email/reset-password`,
  });
}

export async function adminAccountSendWelcomeEmail({
  id,
  ...config
}: RequestClientConfig<{
  id: number;
}>) {
  return requestClient({
    ...config,
    method: "POST",
    url: `/admin/account/${id}/email/welcome`,
  });
}

export async function adminAccountHasAuthUser({
  id,
  ...config
}: RequestClientConfig<{
  id: number;
}>) {
  return requestClient<{ hasAuthUser: boolean }>({
    ...config,
    url: `/admin/account/${id}/has-auth-user`,
  });
}
