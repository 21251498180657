import React, { useMemo } from "react";
import { Row } from "antd";
import p from "pluralize";
import { Col } from "../../../components/rxLibrary/grid";
import { Text } from "../../../components/rxLibrary/typography";
import { InvoiceHistoryHistory } from "../../../utilities/types";
import { DATE_WITH_YEAR } from "../../../utilities/dates/dates.constants";
import { formatDateToFormat } from "../../../utilities/dates/formatDateToFormat";

export function InvoiceTableHeader({
  invoice,
}: {
  invoice: InvoiceHistoryHistory;
}) {
  const { totalItems, totalAmount, invoiceDate, supplierName, invoiceNumbers } =
    invoice;

  const itemsStr = useMemo(() => {
    return `${totalItems} ${p("Items", totalItems)}`;
  }, [totalItems]);

  const invoiceDateStr = useMemo(() => {
    return formatDateToFormat(invoiceDate, DATE_WITH_YEAR);
  }, [invoiceDate]);

  return (
    <Row tw="h-full py-[19px]">
      <Col tw="w-[110px]">
        {!!invoiceDateStr && <Text weight="bold">{invoiceDateStr}</Text>}
      </Col>

      <Col tw="w-[180px]">
        {invoiceNumbers.map((id) => (
          <Text key={id} weight="bold" block>
            {id}
          </Text>
        ))}
      </Col>

      <Col tw="w-[180px]">
        <Text weight="bold">{supplierName}</Text>
      </Col>

      <Col tw="w-[255px]">
        <Text weight="bold">{itemsStr}</Text>
      </Col>

      <Col tw="w-[110px] text-right">
        <Text weight="bold">${totalAmount}</Text>
      </Col>

      <Col noBorder />
    </Row>
  );
}
