// THIS MUST BE BEFORE import Pusher
import { pusherCompatibilityHack } from "./pusherCompatibilityHack";
import Pusher from "pusher-js";
import { PUSHER_APP_KEY, BACKEND_URL } from "../../../utilities/config";
import { backendPost } from "../../request_legacy/config/backend-api";
import { authService } from "../authenticationService";

pusherCompatibilityHack();

class NotificationsService {
  /**
   * @warning This pusher client is intended to be initialized only after the first subscription, do not use directly since it could be undefined. Use the #pusher getter instead.
   */
  #pusherClient?: Pusher;

  get #pusher(): Pusher {
    return this.#getOrInitPusher();
  }

  #getOrInitPusher = (): Pusher => {
    if (this.#pusherClient) return this.#pusherClient;

    this.#pusherClient = new Pusher(PUSHER_APP_KEY, {
      cluster: "us2",
      channelAuthorization: {
        endpoint: `${BACKEND_URL}/pusher/auth`,
        transport: "ajax",
        customHandler: async (params, callback) => {
          const token = await authService.getAccessTokenSilently();
          const response = await backendPost(
            "/pusher/auth?socket_id=" +
              params.socketId +
              "&channel_name=" +
              params.channelName,
            "",
            {
              method: "POST",
              headers: { Authorization: "Bearer " + token },
            }
          );
          if (response.ok) {
            const pusherToken = await response.json();
            callback(null, pusherToken);
          } else {
            const responseText = await response.text();
            callback(new Error("Authorization Error:" + responseText), null);
          }
        },
      },
    });

    return this.#pusherClient;
  };

  subscribe = (channelName: string) => {
    const channel = this.#pusher.channel(channelName);
    if (channel) return channel;
    return this.#pusher.subscribe(channelName);
  };

  unsubscribe = (channelName: string) => {
    console.log("UNSUBSCRIBING FROM PUSH NOTIFICATIONS", channelName);
    const channel = this.#pusher.channel(channelName);
    channel?.unbind();
    channel?.unsubscribe();
  };
}

export const notificationsService = new NotificationsService();
