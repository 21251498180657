import React, { useCallback } from "react";
import "twin.macro";
import { Input } from "antd";
import SaveOutlined from "@ant-design/icons/SaveOutlined";
import { Button } from "../../../components/rxLibrary/buttons";
import {
  AuthSaveCredentialsPayload,
  SupplierAccountsSummaryView,
} from "../EnhancedSupplierAccount.constants";
import { dispatch } from "../EnhancedSupplierAccount.utils";

const validations: Record<string, {
  unGuidance?: string,
  unValidation?: (arg0: string) => string | void,
}> = {
  'ASTOR': {
    unGuidance: 'Astor username is an email address.',
    unValidation: (val: string) => {
      if(!val.includes('@')) {
        return 'Astor expects an email address as the Username.';
      }
      return undefined;;
    },
  },
  'OAK_DRUGS': {
    unGuidance: 'Oak Drugs username is an email address.',
    unValidation: (val: string) => {
      if(!val.includes('@')) {
        return 'Oak Drugs expects an email address as the Username.';
      }
      return undefined;
    },
  }
}
export function EnhancedSupplierAccountSettingsCreateAccount({
  supplierAccount,
  setViewMode,
  returnToSummary,
}: {
  supplierAccount: SupplierAccountsSummaryView;
  setViewMode: () => void;
  returnToSummary: () => void;
}) {
  const wouldBeFirstCredential = supplierAccount.accounts.length === 0;

  const cancelAccountCreation = useCallback(() => {
    if (wouldBeFirstCredential) returnToSummary();
    else setViewMode();
  }, [wouldBeFirstCredential, setViewMode, returnToSummary]);

  const validation = validations[supplierAccount.supplier];
  const createAccount = useCallback(() => {
    const username = document.getElementById(
      "new-credential-username"
    ) as HTMLInputElement;
    const password = document.getElementById(
      "new-credential-password"
    ) as HTMLInputElement;
    if (!username || !password) return;

    if (
      supplierAccount.accounts.find((account) => {
        return account.userName === username.value;
      })
    ) {
      alert(`Username ${username.value} already exists for this supplier`);
      return;
    }

    if (validation && validation.unValidation) {
      const validationError = validation.unValidation(username.value);
      if (validationError) {
        alert("Unable to save inputted Username and Password. " + validationError);
        return;
      }
    }

    const payload: AuthSaveCredentialsPayload = {
      supplier: supplierAccount.supplier,
      username: username.value,
      password: password.value,
    };
    console.log("saving payload", payload);
    dispatch("requestSaveSupplierCredentials", payload);
    setViewMode();
  }, [supplierAccount, setViewMode]);

  return (
    <tr>
      <td tw="p-2 flex-col align-top">
        <Input
          type="text"
          placeholder="Username"
          id="new-credential-username"
        />
        {validation && (
          <span tw="text-red text-xs">
            {validation.unGuidance}
          </span>
        )}
      </td>

      <td tw="p-2 align-top">
        <Input
          type="text"
          placeholder="Password"
          id="new-credential-password"
        />
      </td>

      <td tw="p-2 align-top">
        <div tw="flex justify-start space-x-4">
          <Button
            icon={<SaveOutlined rev={undefined} />}
            disabled={!supplierAccount.supplier}
            onClick={createAccount}
          >
            Save
          </Button>

          <Button variant="secondary" onClick={cancelAccountCreation}>
            Cancel
          </Button>
        </div>
      </td>
    </tr>
  );
}
