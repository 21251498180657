import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  manufacturerReportPercentFormatter,
  manufacturerReportCurrencyFormatter,
} from "../../utils/manufacturerReportFormatters";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName: "Plan",
    children: [
      {
        field: 'last_primary_bin',
        headerName: "Primary Payer BIN",
        pinned: "left",
        width: 125,
      },
      {
        field: 'last_primary_processor',
        headerName: "Primary Payer Processor",
        pinned: "left",
        width: 175,
      },
    ]
  },
  {
    field: 'fill_ct',
    headerName: "TRT TRx Count",
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'in_progress_fill_ct',
    headerName: "In Progress TRT TRx Count",
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'dispensed_fill_ct',
    headerName: "Dispensed TRT TRx Count",
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'abandoned_transferred_fill_ct',
    headerName: "Abandoned / Transferred TRT TRx Count",
    filter: "agNumberColumnFilter",
    width: 140,
  },
  {
    field: 'fills_percent_dispensed',
    headerName: "TRT Market Dispense Rate",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'fills_percent_in_progress',
    headerName: "TRT Market % In Progress",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'fills_percent_abandoned_transferred',
    headerName: "TRT Market Abandon / Transfer Rate",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 150,
    hide: true,
  },
  {
    field: 'brand_fill_ct',
    headerName: "Brand TRT TRx Count",
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'percent_of_brand_market',
    headerName: "% of Brand TRT Market",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'covered_brand_fill_ct',
    headerName: "Covered Brand TRT TRx Count",
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'uncovered_brand_fill_ct',
    headerName: "Uncovered Brand TRT TRx Count",
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'percent_covered_brand',
    headerName: "Brand TRT Coverage Rate",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'dispensed_brand_fill_ct',
    headerName: "Dispensed Brand TRT TRx Count",
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'percent_dispensed_brand',
    headerName: "Brand TRT Dispense Rate",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'generic_fill_ct',
    headerName: "Generic TRT TRx Count",
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'percent_of_generic_market',
    headerName: "% of Generic TRT Market",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'covered_generic_fill_ct',
    headerName: "Covered Generic TRT TRx Count",
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'uncovered_generic_fill_ct',
    headerName: "Uncovered Generic TRT TRx Count",
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'percent_covered_generic',
    headerName: "Generic TRT Coverage Rate",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'dispensed_generic_fill_ct',
    headerName: "Dispensed Generic TRT TRx Count",
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'percent_dispensed_generic',
    headerName: "Generic TRT Dispense Rate",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'tlando_fill_ct',
    headerName: "Tlando TRx Count",
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'percent_of_tlando_market',
    headerName: "% of Tlando Market",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'covered_tlando_fill_ct',
    headerName: "Covered Tlando TRx Count",
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'uncovered_tlando_fill_ct',
    headerName: "Uncovered Tlando TRx Count",
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'percent_covered_tlando',
    headerName: "Tlando Coverage Rate",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'dispensed_tlando_fill_ct',
    headerName: "Dispensed Tlando TRx Count",
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'percent_dispensed_tlando',
    headerName: "Tlando Dispense Rate",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'fills_covered_w_75_rej',
    headerName: "Count of Covered TRT TRxs w/ 75 Rejection in Claim Stream",
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'fills_covered_wout_75_rej',
    headerName: "Count of Covered TRT TRxs w/out 75 Rejection in Claim Stream",
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'fills_uncovered_w_75_rej',
    headerName: "Count of Uncovered TRT TRxs w/ 75 Rejection in Claim Stream",
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'fills_uncovered_wout_75_rej',
    headerName: "Count of Uncovered TRT TRxs w/ 75 Rejection in Claim Stream",
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'percent_fills_covered_w_75_rej',
    headerName: "% of TRT TRxs Covered w/ 75 Rejection in Claim Stream",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'percent_fills_covered_wout_75_rej',
    headerName: "% of TRT TRxs Covered w/out 75 Rejection in Claim Stream",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'percent_fills_uncovered_w_75_rej',
    headerName: "% of TRT TRxs Uncovered w/ 75 Rejection in Claim Stream",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'percent_fills_uncovered_wout_75_rej',
    headerName: "% of TRT TRxs Uncovered w/out 75 Rejection in Claim Stream",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'fills_covered_w_70_mr_rej',
    headerName: "Count of Covered TRT TRxs w/ 70/MR Rejection in Claim Stream",
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'fills_covered_wout_70_mr_rej',
    headerName: "Count of Covered TRT TRxs w/out 70/MR Rejection in Claim Stream",
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'fills_uncovered_w_70_mr_rej',
    headerName: "Count of Uncovered TRT TRxs w/ 70/MR Rejection in Claim Stream",
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'fills_uncovered_wout_70_mr_rej',
    headerName: "Count of Uncovered TRT TRxs w/ 70/MR Rejection in Claim Stream",
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'percent_fills_covered_w_70_mr_rej',
    headerName: "% of TRT TRxs Covered w/ 70/MR Rejection in Claim Stream",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'percent_fills_covered_wout_70_mr_rej',
    headerName: "% of TRT TRxs Covered w/out 70/MR Rejection in Claim Stream",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'percent_fills_uncovered_w_70_mr_rej',
    headerName: "% of TRT TRxs Uncovered w/ 70/MR Rejection in Claim Stream",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'percent_fills_uncovered_wout_70_mr_rej',
    headerName: "% of TRT TRxs Uncovered w/out 70/MR Rejection in Claim Stream",
    valueFormatter: (params) =>
      manufacturerReportPercentFormatter(params.value, 0),
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'avg_third_party_amt_generic',
    headerName: "Generic TRT Avg. 3rd Party Contribution",
    valueFormatter: (params) =>
      manufacturerReportCurrencyFormatter(params.value),
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'avg_patient_pay_generic',
    headerName: "Generic TRT Avg. Patient Pay",
    valueFormatter: (params) =>
      manufacturerReportCurrencyFormatter(params.value),
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'avg_total_revenue_generic',
    headerName: "Generic TRT Avg. Pharmacy Gross Revenue",
    valueFormatter: (params) =>
      manufacturerReportCurrencyFormatter(params.value),
    filter: "agNumberColumnFilter",
    width: 125,
    hide: true,
  },
  {
    field: 'avg_third_party_amt_brand',
    headerName: "Brand TRT Avg. 3rd Party Contribution",
    valueFormatter: (params) =>
      manufacturerReportCurrencyFormatter(params.value),
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'avg_manufacturer_amt_brand',
    headerName: "Brand TRT Avg. Manuf. Contribution",
    valueFormatter: (params) =>
      manufacturerReportCurrencyFormatter(params.value),
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'avg_patient_pay_brand',
    headerName: "Brand TRT Avg. Patient Pay",
    valueFormatter: (params) =>
      manufacturerReportCurrencyFormatter(params.value),
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'avg_total_revenue_brand',
    headerName: "Brand TRT Avg. Pharmacy Gross Revenue",
    valueFormatter: (params) =>
      manufacturerReportCurrencyFormatter(params.value),
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'avg_third_party_amt_tlando',
    headerName: "Tlando Avg. 3rd Party Contribution",
    valueFormatter: (params) =>
      manufacturerReportCurrencyFormatter(params.value),
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'avg_manufacturer_amt_tlando',
    headerName: "Tlando Avg. Manuf. Contribution",
    valueFormatter: (params) =>
      manufacturerReportCurrencyFormatter(params.value),
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'avg_patient_pay_tlando',
    headerName: "Tlando Avg. Patient Pay",
    valueFormatter: (params) =>
      manufacturerReportCurrencyFormatter(params.value),
    filter: "agNumberColumnFilter",
    width: 125,
  },
  {
    field: 'avg_total_revenue_tlando',
    headerName: "Tlando Avg. Pharmacy Gross Revenue",
    valueFormatter: (params) =>
      manufacturerReportCurrencyFormatter(params.value),
    filter: "agNumberColumnFilter",
    width: 125,
  },
];
