import type { Quotes } from "../utilities/types";
import { requestClient, RequestClientConfig } from "./request/requestClient";

export async function getQuotes({
  ndc,
  drugName,
  unitSize,
  pharmacyId,
  genericProductIdentifier,
  ...config
}: RequestClientConfig<{
  ndc: string;
  pharmacyId: number;
  drugName?: string;
  unitSize?: string;
  genericProductIdentifier?: string;
}>) {
  return requestClient<Quotes>({
    ...config,
    url: "/quotes",
    params: {
      ndc: ndc.replace(/\D+/g, ""),
      pharmacyId,
      genericProductIdentifier,
      drugName,
      unitSize,
      version: 2,
    },
  });
}
