import {
  Reducer,
  useState,
  ReactNode,
  useEffect,
  useReducer,
  useContext,
  useCallback,
  createContext,
} from "react";
import { v4 as uuidv4 } from "uuid";
import EnhancedClientConfigContext from "../../../enhanced/EnhancedClientConfigContext";
import { useBuyingPharmacy } from "../../../contexts/BuyingPharmacyContext";
import { Quotes, RequestState } from "../../../utilities/types";
import { useSearchParams } from "../../../utilities/url";
import { shouldSkipNotFoundSentryNotification } from "../../../services/request/shouldSkipNotFoundSentryNotification";
import { getQuotes } from "../../../services/quotes";
import { getSearch } from "../../../services/searches";
import { QuoteState, QuoteStateErrorDetailEnum } from "../Compare.constants";
import { formatCompletedQuoteState } from "./formatCompletedQuoteState/formatCompletedQuoteState";

const CompareContext = createContext<ReturnType<typeof useCompareContext>>(
  undefined as any
);

function useCompareContext() {
  const { currentBuyingPharmacyId: pharmacyId } = useBuyingPharmacy();
  const [enhancementRequestState, setEnhancementRequestState] = useState(
    RequestState.PENDING
  );

  const { enhancedClientActive, enhancedClientVersion } = useContext(
    EnhancedClientConfigContext
  );

  const {
    ndc = "",
    from,
    unit_size: unitSize,
    drug_name: drugName,
    generic_product_identifier: genericProductIdentifier,
  } = useSearchParams(
    "ndc",
    "from",
    "unit_size",
    "drug_name",
    "generic_product_identifier"
  );

  const [quoteState, setQuoteState] = useReducer<
    Reducer<QuoteState, Partial<QuoteState>>
  >((state, newState) => ({ ...state, ...newState }), {
    suggestedNDC: null,
    itemListsByNDC: [],
    quoteRequestState: RequestState.PENDING,
    errorDetail: undefined,
    relatedDrugsByNDC: {},
    suggestedDrug: undefined,
    traceId: "",
  });

  useEffect(() => {
    init();

    async function init() {
      if (!ndc || pharmacyId === null) return;

      setQuoteState({
        quoteRequestState: RequestState.PENDING,
        traceId: uuidv4(),
      });

      if (enhancedClientActive) {
        setEnhancementRequestState(RequestState.UNINITIATED);
      }

      try {
        // check for UPCs
        const searchResponse = await getSearch({
          text: ndc,
          pharmacyId,
        });
        const { searchResults } = searchResponse?.data.data ?? {};
        const mainNdc =
          searchResults?.length === 1
            ? searchResults[0].representativeDrugInfo.ndc
            : ndc;

        const response = await getQuotes({
          ndc: mainNdc,
          drugName,
          unitSize,
          pharmacyId,
          genericProductIdentifier,
          shouldSkipSentryNotification: shouldSkipNotFoundSentryNotification,
        });

        const quotes = response?.data?.data;
        if (!quotes) {
          throw new Error(QuoteStateErrorDetailEnum.NOT_FOUND);
        }

        const newQuoteState = formatCompletedQuoteState({
          ndc: mainNdc,
          quotes: quotes,
          enhancedClientActive,
          enhancedClientVersion,
        });
        setQuoteState(newQuoteState);
      } catch (error: any) {
        const responseStatus = error?.response?.status;
        let errorDetail = QuoteStateErrorDetailEnum.GENERIC_ERROR;
        if (error.message === "No quotes found" || responseStatus === 404) {
          errorDetail = QuoteStateErrorDetailEnum.NOT_FOUND;
        }
        setQuoteState({ quoteRequestState: RequestState.ERROR, errorDetail });
      }
    }
  }, [
    ndc,
    drugName,
    unitSize,
    pharmacyId,
    enhancedClientActive,
    enhancedClientVersion,
    genericProductIdentifier,
  ]);

  return {
    ndc,
    from,
    drugName,
    quoteState,
    enhancementRequestState,
    setEnhancementRequestState,
  };
}

export function CompareProvider({ children }: { children: ReactNode }) {
  const value = useCompareContext();
  return (
    <CompareContext.Provider value={value}>{children}</CompareContext.Provider>
  );
}

export function useCompare() {
  const context = useContext(CompareContext);
  if (context === undefined) {
    throw new Error("useCompare must be used within a CompareProvider");
  }
  return context;
}
