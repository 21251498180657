import  {MouseEventHandler, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { NdcOffering } from "../../../../../../enhanced/result_types";
import EnhancedClientConfigContext from "../../../../../../enhanced/EnhancedClientConfigContext";
import BuyingPharmacyContext from "../../../../../../contexts/BuyingPharmacyContext";
import { copyContent } from "../../../../../../utilities/copyContent";

export function useHandleNdcLinkClick(ndcOffering: NdcOffering): MouseEventHandler<HTMLElement> {
  const { enhancedClientActive } = useContext(EnhancedClientConfigContext);
  const { getSupplierById } = useContext(BuyingPharmacyContext);
  const history = useHistory();
  const ndc = ndcOffering.item.drugInfo.ndc;
  const supplierId = ndcOffering.item.catalogInfo.supplierId;

  const handleClick: MouseEventHandler<HTMLElement>  = useCallback((evt) => {
    const supplier = getSupplierById(supplierId);

    if (supplier?.isEcommerceEnabled && !enhancedClientActive) {
      evt.preventDefault();
      history.push(`/manufacturer-store/${supplierId}?ndc=${ndc}`);
    } else {
      copyContent(ndc);
    }
  }, [ndc, supplierId]);

  return handleClick;
}
