import React, { useCallback, useEffect, useMemo, useState } from "react";
import "twin.macro";
import { Input } from "antd";
import { IconButton } from "../buttons";
import { IconDown } from "../icons";
import { Text } from "../typography";
import { Select } from "../selects/select";
import { PaginationConfig, UpdatePagination } from "./pagination.utils";

export function Pagination({
  pagination,
  sizeOptions = [20, 50, 100, 200],
  updatePagination,
}: {
  pagination: PaginationConfig;
  sizeOptions?: number[] | null;
  updatePagination: UpdatePagination;
}) {
  const { currentPage, pageSize, totalPageCount, totalRows } = pagination;
  const [inputValue, setInputValue] = useState<number | string>(currentPage);

  const visibleItems = useMemo(() => {
    if (!totalRows || !pageSize) return;
    const initialItem = pageSize * (currentPage - 1) + 1;
    const lastItem = Math.min(pageSize * currentPage, totalRows);
    return `${initialItem}-${lastItem} of ${totalRows} items`;
  }, [pageSize, totalRows, currentPage]);

  const pageSizeOptions = useMemo(() => {
    if (!sizeOptions) return;

    let options = sizeOptions;
    if (!!pageSize && !options.includes(pageSize)) {
      // if pageSize is not included in the options only the flat number will be render without the other label data
      options = [...options, pageSize];
    } else {
      options = [...sizeOptions];
    }
    options.sort((a, b) => a - b);

    return options.map((value) => ({ label: `${value} / page`, value }));
  }, [pageSize, sizeOptions]);

  const handleCurrentPageChange = useCallback(
    (value: number) => {
      let newCurrentPage = Math.max(1, value);
      if (totalPageCount) {
        newCurrentPage = Math.min(totalPageCount, newCurrentPage);
      }

      updatePagination({
        pageSize,
        currentPage: newCurrentPage,
      });

      setInputValue(newCurrentPage);
    },
    [pageSize, totalPageCount, updatePagination]
  );

  const handleInputChange = useCallback((event) => {
    setInputValue(event.target.value);
  }, []);

  const handleInputBlur = useCallback(
    (event) => {
      const value = Number(event.target.value);
      handleCurrentPageChange(value);
    },
    [handleCurrentPageChange]
  );

  const goNextPage = useCallback(() => {
    handleCurrentPageChange(currentPage + 1);
  }, [currentPage, handleCurrentPageChange]);

  const goPrevPage = useCallback(() => {
    handleCurrentPageChange(currentPage - 1);
  }, [currentPage, handleCurrentPageChange]);

  const handlePageSizeChange = useCallback(
    (newPageSize) => {
      updatePagination({ pageSize: newPageSize, currentPage: 1 });
    },
    [updatePagination]
  );

  useEffect(() => {
    if (Number(currentPage) !== Number(inputValue)) setInputValue(currentPage);
  }, [currentPage]);

  if (totalPageCount === 1) return null;
  return (
    <div tw="flex items-center justify-center space-x-2">
      {!!visibleItems && <Text>{visibleItems}</Text>}

      <IconButton
        tw="rotate-90"
        disabled={!totalPageCount || currentPage === 1}
        onClick={goPrevPage}
      >
        <IconDown />
      </IconButton>

      <Text>PAGE</Text>

      <Input
        tw="w-[40px] text-center"
        type="number"
        min={1}
        max={totalPageCount}
        value={inputValue}
        disabled={!totalPageCount}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
      />

      <Text>/</Text>

      <Text>{totalPageCount}</Text>

      <IconButton
        disabled={!totalPageCount || currentPage === totalPageCount}
        onClick={goNextPage}
      >
        <IconDown tw="-rotate-90" />
      </IconButton>

      {!!pageSizeOptions && (
        <Select
          value={pageSize}
          options={pageSizeOptions}
          onChange={handlePageSizeChange}
        />
      )}
    </div>
  );
}
