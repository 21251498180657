import React, { useContext, useEffect } from "react";
import "twin.macro";
import { Route, Switch, useHistory } from "react-router-dom";
import AppContext from "../../contexts/AppContext";
import { NavBar } from "../../components/NavBar/NavBar";
import { AdminEdiDocPage } from "./pages/AdminEdiDocPage";
import { RedirectHomePage } from "../../components/RedirectHomePage";
import { Box } from "../../components/rxLibrary/box";
import { PharmaciesRoutes } from "./pages/adminManagement/pharmacies/PharmaciesRoutes";
import { PharmacyOrganizationsRoutes } from "./pages/adminManagement/pharmacyOrganizations/PharmacyOrganizationsRoutes";
import { SupplierMappingRoutes } from "./pages/adminManagement/supplierMapping/SupplierMappingRoutes";
import { SupplierMappingAndEdiRoutes } from "./pages/adminManagement/supplierMappingAndEdi/SupplierMappingAndEdiRoutes";
import { PharmacyEmailSubscriptionsRoutes } from "./pages/adminManagement/pharmacyEmailSubscription/PharmacyEmailSubscriptionRoutes";
import { AccountsRoutes } from "./pages/adminManagement/accounts/AccountsRoutes";
import { RefillMappingRoutes } from "./pages/adminManagement/refillMapping/SupplierMappingRoutes";
import { PageAlertRoutes } from "./pages/adminManagement/pageAlerts/PageAlertRoutes";
import { RxLibrary } from "./pages/RxLibrary";
import { AdminPage } from "./pages/AdminPage";

export function Admin() {
  const { user } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if (user && !user.is_admin) {
      history.push("/");
    }
  }, [user]);

  return (
    <>
      <NavBar />
      <Box bgColor="grey-4" tw="pt-8 h-full min-h-[calc(100dvh - 96px)]">
        {!!user && (
          <Switch>
            <Route path="/admin" component={AdminPage} exact />
            <Route path="/admin/page-alert" component={PageAlertRoutes} />
            <Route path="/admin/edi-doc" component={AdminEdiDocPage} />
            <Route path="/admin/rx-library" component={RxLibrary} />
            <Route
              path="/admin/pharmacy/email/subscription"
              component={PharmacyEmailSubscriptionsRoutes}
            />
            <Route path="/admin/account" component={AccountsRoutes} />
            <Route
              path="/admin/pharmacy-organization"
              component={PharmacyOrganizationsRoutes}
            />
            <Route
              path="/admin/refill-mapping"
              component={RefillMappingRoutes}
            />
            <Route
              path="/admin/supplier-mapping"
              component={SupplierMappingRoutes}
            />
            <Route
              path="/admin/supplier-mapping-and-edi"
              component={SupplierMappingAndEdiRoutes}
            />
            <Route path="/admin/pharmacy" component={PharmaciesRoutes} />
            <Route component={RedirectHomePage} />
          </Switch>
        )}
      </Box>
    </>
  );
}
