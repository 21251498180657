import { useContext, useMemo } from "react";
import { styled } from "twin.macro";
import { useParams } from "react-router-dom";
import pluralize from "pluralize";
import AppContext from "../../../contexts/AppContext";
import { Supplier } from "../../../utilities/types";
import { SUPPLIER_ID_FFF } from "../../../utilities/config";
import { joinReadableSentences } from "../../../utilities/arrays/joinReadableSentences";
import { IconCheck } from "../../../components/rxLibrary/icons";
import { Title, Text } from "../../../components/rxLibrary/typography";
import { ExtraWideContainer } from "../../../components/containers/ExtraWideContainer";
import {
  GREY_2,
  ORANGE_1,
  WHITE_1,
} from "../../../components/rxLibrary/colors";
import { ManufacturerStoreWrapper } from "../ManufacturerStoreWrapper";
import {
  getFFFOvernightShippingEndHourStr,
  shouldDisplayFFFOvernightShippingMsg,
} from "./supplierFFF";

const Divider = styled.div`
  height: 1px;
  background-color: ${GREY_2};
`;

const IconCheckWrapper = styled.div`
  background-color: ${ORANGE_1};
  color: ${WHITE_1};
  padding: 8px;
  border-radius: 20px;
`;

export function Completed({ supplier }: { supplier: Supplier }) {
  const { purchaseOrderNumbers } =
    useParams<{ purchaseOrderNumbers: string }>();
  const { user } = useContext(AppContext);
  const { email } = user ?? {};
  const isFFFSupplier = supplier.id === SUPPLIER_ID_FFF;

  const text = useMemo(() => {
    const orderNumbersList = purchaseOrderNumbers.split(",");
    const ordersCount = orderNumbersList.length;
    const orderNumbers = joinReadableSentences(purchaseOrderNumbers.split(","));
    return `Purchase order ${pluralize("number", ordersCount)} ${orderNumbers}`;
  }, [purchaseOrderNumbers]);

  const fffOvernightShippingMsgEndHour = useMemo(() => {
    const displayMsg = isFFFSupplier && shouldDisplayFFFOvernightShippingMsg();
    if (displayMsg) return getFFFOvernightShippingEndHourStr();
  }, [isFFFSupplier]);

  return (
    <ManufacturerStoreWrapper>
      <ExtraWideContainer tw="flex items-center justify-center bg-white h-[400px]">
        <div>
          <div tw="flex space-x-[10px] justify-center">
            <IconCheckWrapper>
              <IconCheck />
            </IconCheckWrapper>
            <Title block>Order placed!</Title>
          </div>

          <Divider tw="my-[12px]" />

          <Text block center>
            {text}
          </Text>

          <Text block center>
            Confirmation will be sent to {email ?? "your email."}
          </Text>

          {!!fffOvernightShippingMsgEndHour && (
            <Text block center>
              For overnight shipping tonight (at your expense) call FFF at (800)
              843-7477 before {fffOvernightShippingMsgEndHour}
            </Text>
          )}

          <Divider tw="mt-[12px]" />
        </div>
      </ExtraWideContainer>
    </ManufacturerStoreWrapper>
  );
}
