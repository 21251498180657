import React, { useCallback, useMemo } from "react";
import "twin.macro";
import cn from "classnames";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { DrugWithStats, ItemInCart } from "../../../../utilities/types";
import { normalizePackQuantity } from "../../../../utilities/prescriptions/packQuantity/normalizePackQuantity";
import {
  DROPDOWN_QUANTITY_LIST_ITEMS,
  IDropDownItem,
  DropDownListQty,
} from "../../../DropDownListQty";
import {
  pipePrefixed,
  QuantityDescription,
} from "../../../QuantityDescription";
import DispensedItem from "../../../DispensedItem";
import { Container } from "../../../containers/Container";
import { Box } from "../../../rxLibrary/box";
import {
  drugInfoHasValidUnits,
  getPackSizeList,
  getQuantity,
} from "../../../../utilities/drugInfo/drugInfo";
import { InventoryModalPopoverSwitch } from "../InventoryModalPopoverSwitch";
import { QuantityKeyTypes } from "../InventoryModal.constants";
import { MediumParagraph } from "../InventoryModal.dispenses";
import { GeneralDropDown } from "./GeneralDropDown";

const RADIO_STYLE = {
  color: "#000",
  "&.Mui-checked": {
    color: "#000",
  },
};

function getManufacturerPackSizeList(
  mainList: IDropDownItem[],
  manuList: IDropDownItem[]
): IDropDownItem[] {
  const list = mainList.map<IDropDownItem>((item) => {
    const items = manuList.filter((obj) => obj.label === item.label);
    const disabled = items.length === 0;
    return { ...item, disabled };
  });
  return list;
}

function getManufacturerList(
  data: DrugWithStats[],
  packSize?: string
): IDropDownItem[] {
  const uniqueNames: string[] = [];
  const items = packSize
    ? data.filter((item) => getQuantity(item.drug) === packSize)
    : data;

  const unique = items
    .map((item) => ({
      label: item.drug.manufacturer,
      value: item.drug.manufacturer,
    }))
    .filter((item) => {
      const isDuplicate = uniqueNames.includes(item.label);
      if (isDuplicate) return false;
      uniqueNames.push(item.label);
      return true;
    })
    .sort((a, b) => {
      const firstLabel = a.label.toLowerCase();
      const secondLabel = b.label.toLowerCase();
      if (firstLabel < secondLabel) return -1;
      if (firstLabel > secondLabel) return 1;
      return 0;
    });
  unique.unshift({ label: "Any", value: "any" });

  return unique;
}

function getPrescriptionItem(
  mainData: DrugWithStats[] | null,
  manufacturer: string,
  packSize: string
): DrugWithStats | null {
  if (!mainData) return null;

  const items = mainData
    .filter((prescription) => {
      if (manufacturer !== "any") {
        return (
          prescription.drug.manufacturer === manufacturer &&
          getQuantity(prescription.drug) === packSize
        );
      } else {
        return getQuantity(prescription.drug) === packSize;
      }
    })
    .sort((a, b) => {
      const val1 = a.drug.ndc;
      const val2 = b.drug.ndc;
      if (val1 < val2) return -1;
      if (val1 > val2) return 1;
      return 0;
    });

  return items[0];
}

export function InventoryModalPrescriptionAlternatives({
  diffOpt,
  mainData,
  optOneFlow,
  optTwoFlow,
  packSizeSelect,
  manufacturerSel,
  prescriptionData,
  dispensesStatsType,
  addNewDrug,
  setDiffOpt,
  setOptOneFlow,
  setOptTwoFlow,
  setPackSizeSelect,
  setManufacturerSel,
  updateQuantityKeys,
  getQuantityKeyValue,
}: {
  diffOpt: number;
  optTwoFlow: number;
  mainData: DrugWithStats[];
  optOneFlow: number;
  packSizeSelect: string;
  manufacturerSel: string;
  prescriptionData: DrugWithStats[];
  dispensesStatsType: string;
  setDiffOpt: (v: number) => void;
  setOptOneFlow: (v: number) => void;
  setOptTwoFlow: (v: number) => void;
  setPackSizeSelect: (v: string) => void;
  setManufacturerSel: (v: string) => void;
  addNewDrug: (prescription: ItemInCart) => void;
  getQuantityKeyValue: (id: string) => number;
  updateQuantityKeys: (v: QuantityKeyTypes) => void;
}) {
  const manufacturerOptPackQuantity = useMemo(() => {
    return normalizePackQuantity(getQuantityKeyValue("manufacturerOpt"));
  }, [getQuantityKeyValue]);

  const packSizeOptPackQuantity = useMemo(() => {
    return normalizePackQuantity(getQuantityKeyValue("packSizeOpt"));
  }, [getQuantityKeyValue]);
  
  const handleUpdateBuyOption = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, v: string) => {
      const newDiffOpt = parseInt(v);
      setDiffOpt(newDiffOpt);
      setOptOneFlow(0);
      setOptTwoFlow(0);
      setPackSizeSelect("");
      setManufacturerSel("");
    },
    []
  );

  if (mainData.length <= 4) return null;
  return (
    <Container className="mt-24 ml-96 mr-96 mb-48">
      {dispensesStatsType === "" && (
        <div className="mb-48">
          <p className="font-500 mr-6 text-lg mb-20">Recently Dispensed NDCs</p>
          <p className="mr-6">
            Nothing dispensed recently. Please select a specific NDC below.
          </p>
        </div>
      )}
      <div className="flex text-lg">
        {dispensesStatsType === "" && (
          <p className="font-500 mr-6">NDC Criteria</p>
        )}
        {dispensesStatsType !== "" && (
          <>
            <p className="font-500 mr-6">Looking for a different</p>
            {<DispensedItem drug={prescriptionData[0].drug} ndc={false} />}
            {drugInfoHasValidUnits(prescriptionData[0].drug) && (
              <QuantityDescription
                drug={prescriptionData[0].drug}
                element={MediumParagraph}
                format={pipePrefixed}
              />
            )}
            ?
          </>
        )}
      </div>

      <RadioGroup
        aria-labelledby="buying options"
        name="radio-buttons-group"
        onChange={handleUpdateBuyOption}
      >
        <div className="flex flex-col">
          <Box tw="flex border" className="p-14 mt-12" borderColor="grey-2">
            <FormControlLabel
              value="1"
              control={<Radio sx={RADIO_STYLE} />}
              label="Manufacturer"
              className={cn((diffOpt === 2 || diffOpt === 0) && "disabled")}
            />

            <GeneralDropDown
              disabled={diffOpt !== 1}
              className="modal-dropDown"
              placeholder={diffOpt !== 1 ? "Choose Manufacturer" : undefined}
              callBack={(v: string) => {
                optOneFlow === 0 && setOptOneFlow(1);
                setManufacturerSel(v);
              }}
              dropDownItems={getManufacturerList(mainData)}
            />

            {optOneFlow >= 1 && (
              <>
                <div style={{ width: "284px" }}>
                  <GeneralDropDown
                    className="modal-dropDown"
                    label="Pack Size"
                    callBack={(v: string) => {
                      setPackSizeSelect(v);
                      setOptOneFlow(2);
                    }}
                    dropDownItems={getManufacturerPackSizeList(
                      getPackSizeList(mainData),
                      getPackSizeList(
                        mainData,
                        manufacturerSel === "any" ? undefined : manufacturerSel
                      )
                    )}
                  />
                </div>
                <DropDownListQty
                  value={manufacturerOptPackQuantity.toString()}
                  label="Qty"
                  callBack={(v: string) => {
                    const obj = { id: "manufacturerOpt", value: parseInt(v) };
                    updateQuantityKeys(obj);
                  }}
                  dropDownItems={DROPDOWN_QUANTITY_LIST_ITEMS}
                />
              </>
            )}
            {optOneFlow >= 2 && packSizeSelect !== "" && (
              <div className="ml-24">
                <InventoryModalPopoverSwitch
                  prescription={
                    getPrescriptionItem(
                      mainData,
                      manufacturerSel,
                      packSizeSelect
                    ) || prescriptionData[0]
                  }
                  packQuantity={manufacturerOptPackQuantity}
                  callBack={addNewDrug}
                  manufacturer={manufacturerSel}
                  data={mainData}
                  prescriptions={prescriptionData}
                />
              </div>
            )}
          </Box>

          <Box tw="flex border" className="p-14 mt-24" borderColor="grey-2">
            <FormControlLabel
              value="2"
              control={<Radio sx={RADIO_STYLE} />}
              label="Pack Size"
              className={cn((diffOpt === 1 || diffOpt === 0) && "disabled")}
            />

            <GeneralDropDown
              disabled={diffOpt !== 2}
              className="modal-dropDown"
              placeholder={diffOpt !== 2 ? "Choose Pack Size" : undefined}
              callBack={(v: string) => {
                setPackSizeSelect(v);
                optTwoFlow === 0 && setOptTwoFlow(1);
              }}
              dropDownItems={getPackSizeList(mainData)}
            />

            <DropDownListQty
              disabled={diffOpt !== 2}
              value={packSizeOptPackQuantity.toString()}
              label="Qty"
              callBack={(v: string) => {
                const obj = { id: "packSizeOpt", value: parseInt(v) };
                updateQuantityKeys(obj);
              }}
              dropDownItems={DROPDOWN_QUANTITY_LIST_ITEMS}
            />
            {optTwoFlow >= 1 && (
              <GeneralDropDown
                className="modal-dropDown"
                label="Manufacturer"
                callBack={(v: string) => {
                  setManufacturerSel(v);
                  setOptTwoFlow(2);
                }}
                dropDownItems={getManufacturerPackSizeList(
                  getManufacturerList(mainData),
                  getManufacturerList(mainData, packSizeSelect)
                )}
              />
            )}
            {optTwoFlow >= 2 && manufacturerSel !== "" && (
              <div className="ml-24">
                <InventoryModalPopoverSwitch
                  prescription={
                    getPrescriptionItem(
                      mainData,
                      manufacturerSel,
                      packSizeSelect
                    ) || prescriptionData[0]
                  }
                  packQuantity={packSizeOptPackQuantity}
                  callBack={addNewDrug}
                  manufacturer={manufacturerSel}
                  data={mainData}
                  prescriptions={prescriptionData}
                />
              </div>
            )}
          </Box>
        </div>
      </RadioGroup>
    </Container>
  );
}
