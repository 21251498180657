import type { ShippingConfig, Supplier } from "../../../../../../utilities/types";

function convertFrom24To12Format(time24: string) {
  const t = time24.match(/([0-9]{1,2}):([0-9]{2})/);
  const [sHours, minutes] = (t && t.slice(1)) || [];
  const period = +sHours < 12 ? "AM" : "PM";
  const hours = +sHours % 12 || 12;

  if (minutes === "00") {
    return `${hours}${period}`;
  } else {
    return `${hours}:${minutes}${period}`;
  }
}

function timeRange(item: string[]): string {
  let time = convertFrom24To12Format(item[0]);
  if (item[1] && item[0] !== item[1]) {
    time = time + "; " + convertFrom24To12Format(item[1]);
  }

  return time;
}

function dayRange(items: string[][]): string {
  const dayOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  let time = timeRange(items[0]);
  const mainTime = JSON.stringify(items[0]);
  items.forEach((item, index) => {
    if (JSON.stringify(item) !== mainTime) {
      const tmp = timeRange(item);
      if (tmp.indexOf(";") > 0) {
        time += `|(${dayOfWeek[index]} ${tmp})`;
      } else {
        time += `|(${tmp} on ${dayOfWeek[index]})`;
      }
    }
  });

  return time;
}

function getCutOffTime(items: string[][]): string {
  // check if we have one time
  let time = JSON.stringify(items[0]);
  let equal = true;

  items.forEach((item) => {
    if (JSON.stringify(item) !== time) {
      equal = false;
    }
  });

  if (equal) {
    time = timeRange(items[0]);
  } else {
    time = dayRange(items);
  }

  return time;
}

export function getShippingConfig(
  name: string,
  suppliers: Supplier[]
): ShippingConfig {
  const defaultConfig: ShippingConfig = {
    fee: 0,
    minOrder: 0,
    minGenericOrder: 0,
    cutoffTimes: "",
    notes: "",
    version: 0,
  };

  const supplier = suppliers.find((supplier) => supplier.displayName === name);
  let shippingData = (supplier && supplier.shippingConfig) || defaultConfig;

  // Normalization
  shippingData = { ...defaultConfig, ...shippingData };

  if (Array.isArray(shippingData.notes) && shippingData.notes[0]) {
    shippingData.notes = shippingData.notes[0].toString();
  }
  if (Array.isArray(shippingData.cutoffTimes)) {
    shippingData.cutoffTimes = getCutOffTime(shippingData.cutoffTimes);
  }

  return shippingData;
}
