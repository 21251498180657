import type {
  InvoiceHistory,
  InvoiceHistorySortColumn,
} from "../utilities/types";
import { SortDirectionEnum } from "../components/rxLibrary/selects/SortBySelect";
import { requestClient, RequestClientConfig } from "./request/requestClient";

export async function getInvoiceHistory({
  sort,
  endDate,
  startDate,
  pageNumber,
  pharmacyId,
  supplierId,
  sortDirection,
  ...config
}: RequestClientConfig<{
  endDate: string;
  startDate: string;
  pharmacyId: number;
  sort?: InvoiceHistorySortColumn;
  pageNumber?: number;
  supplierId?: number;
  sortDirection?: SortDirectionEnum;
}>) {
  return requestClient<{ invoiceHistory: InvoiceHistory }>({
    ...config,
    url: "/invoice-history",
    params: {
      sort_column: sort,
      sort_direction: sortDirection,
      pharmacy_id: pharmacyId,
      supplier_id: supplierId,
      start_date: startDate,
      end_date: endDate,
      page_number: pageNumber,
    },
  });
}
