import {
  AdminPharmacyEmailSubscription,
  UpsertAdminPharmacyEmailSubscription,
  AdminPharmacyEmailSubscriptionOptions,
} from "../utilities/types";
import { requestClient, RequestClientConfig } from "./request/requestClient";
import {
  BackendResponse,
  backendFetch,
  backendPost,
} from "./request_legacy/config/backend-api";

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getAdminPharmacyEmailSubscription(
  token: string,
  id: string
) {
  const response = await backendFetch<{
    emailSubscription: AdminPharmacyEmailSubscription;
  }>(`/admin/pharmacy/email/subscription/${id}`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (response.error) {
    console.error("Error fetching admin pharmacy email subscription", response);
  }

  return response;
}

export async function getAdminPharmacyEmailSubscriptionOptions(
  config?: RequestClientConfig<Record<string, never>>
) {
  return requestClient<{
    emailSubscriptionOptions: AdminPharmacyEmailSubscriptionOptions;
  }>({
    ...config,
    url: "/admin/pharmacy/email/subscription/options",
  });
}

export async function getAdminPharmaciesEmailSubscriptions(
  config?: RequestClientConfig<Record<string, never>>
) {
  return requestClient<{
    emailSubscriptions: AdminPharmacyEmailSubscription[];
  }>({
    ...config,
    url: "/admin/pharmacy/email/subscription",
  });
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function createAdminPharmacyEmailSubscription(
  token: string,
  data: UpsertAdminPharmacyEmailSubscription
) {
  const payload = JSON.stringify({ data });
  const responseRaw = await backendPost(
    `/admin/pharmacy/email/subscription`,
    payload,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  const response = (await responseRaw.json()) as BackendResponse<{
    emailSubscription: AdminPharmacyEmailSubscription;
  }>;

  if (response.error) {
    console.error(
      "Error creating admin pharmacy email subscription",
      responseRaw,
      response,
      payload
    );
  }

  return response;
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function updateAdminPharmacyEmailSubscription(
  token: string,
  id: string,
  data: UpsertAdminPharmacyEmailSubscription
) {
  const payload = JSON.stringify({ data });
  const responseRaw = await backendPost(
    `/admin/pharmacy/email/subscription/${id}`,
    payload,
    {
      method: "PUT",
      headers: { Authorization: "Bearer " + token },
    }
  );
  const response = (await responseRaw.json()) as BackendResponse<{
    emailSubscription: AdminPharmacyEmailSubscription;
  }>;

  if (response.error) {
    console.error(
      "Error updating admin pharmacy email subscription",
      responseRaw,
      response,
      payload
    );
  }

  return response;
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function deleteAdminPharmacyEmailSubscription(
  token: string,
  id: string
) {
  const responseRaw = await backendFetch(
    `/admin/pharmacy/email/subscription/${id}`,
    {
      method: "DELETE",
      headers: { Authorization: "Bearer " + token },
    }
  );
  const response = (await (responseRaw as any).json()) as string;

  if (response !== "OK") {
    console.error(
      "Error deleting admin pharmacy email subscription",
      responseRaw,
      response
    );
  }

  return response;
}
