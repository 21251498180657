import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  manufacturerReportPercentFormatter,
  expandHereValueGetter,
} from "../utils/manufacturerReportFormatters";
import { getDetailCellRendererParams } from "../utils/getDetailCellRendererParams";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName: "Prescriber",
    children: [
      {field: 'prescriber_name', headerName: "Name", pinned: "left"},
      {field: 'prescriber_npi', headerName: "NPI", pinned: "left", width: 125},
      {
        field: 'prescriber_address',
        headerName: "Street Address",
        hide: true,
        pinned: "left"
      },
      {
        field: 'prescriber_city',
        headerName: "City",
        hide: true,
        pinned: "left",
        width: 150
      },
      {
        field: 'prescriber_state',
        headerName: "State",
        pinned: "left",
        width: 100
      },
      {
        field: 'prescriber_zip',
        headerName: "Zip",
        hide: true,
        pinned: "left",
        width: 125
      },
      {
        headerName: "Prescriber Detail",
        valueGetter: expandHereValueGetter,
        cellRenderer: "agGroupCellRenderer",
        pinned: "left", width: 150,
      },
      // { field: '', headerName: "Phone", hide: true },
      // { field: '', headerName: "Fax", hide: true },
    ],
  },
  {
    headerName: "TRT Market",
    children: [
      {
        field: 'trt_rxs',
        headerName: "# TRT Rxs",
        filter: "agNumberColumnFilter",
        width: 125
      },
      {
        field: 'trt_rxs_w_dispense',
        headerName: "# TRT Rxs w/ a Dispense",
        hide: true,
        filter: "agNumberColumnFilter",
        width: 125
      },
      {
        field: 'percent_trt_rxs_w_dispense',
        headerName: "% TRT Rxs w/ a Dispense",
        hide: true,
        filter: "agNumberColumnFilter",
        width: 125,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
      {
        field: 'percent_trt_of_total_rxs',
        headerName: "% TRT of all Rxs",
        filter: "agNumberColumnFilter",
        width: 125,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
    ],
  },
  {
    headerName: "Brand TRT",
    children: [
      {
        field: 'trt_brand_rxs',
        headerName: "# Brand TRT Rxs",
        filter: "agNumberColumnFilter",
        width: 125
      },
      {
        field: 'trt_brand_rxs_w_dispense',
        headerName: "# Brand TRT Rxs w/ a Dispense",
        hide: true,
        filter: "agNumberColumnFilter",
        width: 125
      },
      {
        field: 'percent_brand_trt_rxs_w_dispense',
        headerName: "% Brand TRT Rxs w/ a Dispense",
        hide: true,
        filter: "agNumberColumnFilter",
        width: 125,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
      {
        field: 'percent_brand_of_trt_rxs',
        headerName: "% Brand of TRT",
        hide: true,
        filter: "agNumberColumnFilter",
        width: 125,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
    ],
  },
  {
    headerName: "Generic TRT",
    children: [
      {
        field: 'trt_generic_rxs',
        headerName: "# Generic TRT Rxs",
        filter: "agNumberColumnFilter",
        width: 125
      },
      {
        field: 'trt_generic_rxs_w_dispense',
        headerName: "# Generic TRT Rxs w/ a Dispense",
        hide: true,
        filter: "agNumberColumnFilter",
        width: 125
      },
      {
        field: 'percent_generic_trt_rxs_w_dispense',
        headerName: "% Generic TRT Rxs w/ a Dispense",
        hide: true,
        filter: "agNumberColumnFilter",
        width: 125,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
      {
        field: 'percent_generic_of_trt_rxs',
        headerName: "% Generic of TRT",
        hide: true,
        filter: "agNumberColumnFilter",
        width: 125,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
    ],
  },
  {
    headerName: "Tlando",
    children: [
      {
        field: 'tlando_rxs',
        headerName: "# Tlando Rxs",
        filter: "agNumberColumnFilter",
        width: 125
      },
      {
        field: 'tlando_rxs_w_dispense',
        headerName: "# Tlando Rxs w/ a Dispense",
        filter: "agNumberColumnFilter",
        width: 125
      },
      {
        field: 'percent_tlando_rxs_w_dispense',
        headerName: "% Tlando Rxs w/ a Dispense",
        hide: true,
        filter: "agNumberColumnFilter",
        width: 125,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
      {
        field: 'percent_tlando_of_trt_rxs',
        headerName: "% TRT that is Tlando",
        filter: "agNumberColumnFilter",
        width: 125,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
      {
        field: 'percent_tlando_of_trt_brand_rxs',
        headerName: "% Brand TRT that is Tlando",
        filter: "agNumberColumnFilter",
        width: 125,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
      {
        field: 'percent_tlando_of_total_rxs',
        headerName: "% All Rxs that is Tlando",
        filter: "agNumberColumnFilter",
        width: 125,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
    ],
  },
];

export const DETAIL_CELL_RENDERER_PARAMS = getDetailCellRendererParams({
  detailGridOptions: {
    columnDefs: [
      { field: "Prescribed NDC" },
      { field: "Prescribed Drug Name" },
      { field: 'Strength', headerName: "Prescribed Strength" },
      { field: 'Is Brand', headerName: "Prescribed Drug Is Brand" },
      { field: 'Prescribed Rxs', headerName: "# Rxs", sort: 'desc', filter: "agNumberColumnFilter" },
      { field: 'Prescribed Rxs with a Dispense', headerName: "# Rxs w/ a Dispense", filter: "agNumberColumnFilter" },
      { field: 'Percent TRT Rxs For This NDC', headerName: "% TRT Rxs for this NDC", filter: "agNumberColumnFilter",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0) }
    ],
  },
});
