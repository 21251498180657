import { FC, RefObject, useContext, useMemo } from "react";
import type { AgGridReact } from "ag-grid-react";
import type { SideBarDef, IDetailCellRendererParams } from "ag-grid-community";
import type { MultipleDownloadCsvWithChildrenButtonsConfig } from "../csv/MultipleDownloadCsvWithChildrenButtons";
import AppContext from "../../../../../../contexts/AppContext";
import { DefaultExportToolPanel } from "../csv/DefaultExportToolPanel";
import { DEFAULT_AG_GRID_SIDEBAR } from "../reportsConstants";

export type ExportToolPanelProps<T = any> = {
  gridRef: RefObject<AgGridReact>;
  exportToolPanelProps: T;
  detailCellRendererParams?: any;
  multipleDownloadCsvWithChildrenButtonsConfig?: MultipleDownloadCsvWithChildrenButtonsConfig[];
  handleExport: () => void;
};

export type UseReportSideBarParams<T> = {
  gridRef: RefObject<AgGridReact>;
  sideBarDef?: SideBarDef;
  ExportToolPanel?: FC<ExportToolPanelProps<T>>;
  exportToolPanelProps?: any;
  detailCellRendererParams?: Partial<IDetailCellRendererParams>;
  multipleDownloadCsvWithChildrenButtonsConfig?: MultipleDownloadCsvWithChildrenButtonsConfig[];
};

export function useReportSideBar<T>({
  gridRef,
  sideBarDef = DEFAULT_AG_GRID_SIDEBAR,
  ExportToolPanel = DefaultExportToolPanel,
  exportToolPanelProps = {},
  detailCellRendererParams,
  multipleDownloadCsvWithChildrenButtonsConfig,
}: UseReportSideBarParams<T>) {
  const { user } = useContext(AppContext);
  const isTrialUser = user?.is_trial;

  const newSideBarDef: SideBarDef = useMemo(() => {
    if (isTrialUser) return sideBarDef;
    return {
      ...sideBarDef,
      toolPanels: [
        ...(sideBarDef.toolPanels ?? []),
        {
          id: "export",
          labelDefault: "Export",
          labelKey: "export",
          iconKey: "save",
          width: 320,
          toolPanel: function renderExportToolPanel() {
            function handleExport() {
              if (!gridRef.current) return;
              gridRef.current.api.exportDataAsCsv();
            }

            return (
              <div tw="p-4 flex flex-col space-y-4">
                <ExportToolPanel
                  gridRef={gridRef}
                  exportToolPanelProps={exportToolPanelProps}
                  detailCellRendererParams={detailCellRendererParams}
                  multipleDownloadCsvWithChildrenButtonsConfig={
                    multipleDownloadCsvWithChildrenButtonsConfig
                  }
                  handleExport={handleExport}
                />
              </div>
            );
          },
        },
      ],
    };
  }, [
    gridRef,
    sideBarDef,
    isTrialUser,
    ExportToolPanel,
    exportToolPanelProps,
    detailCellRendererParams,
    multipleDownloadCsvWithChildrenButtonsConfig,
  ]);

  return newSideBarDef;
}
