import type {
  SearchResult,
  SearchErrorNotFoundInMedispan,
} from "../utilities/types";
import { requestClient, RequestClientConfig } from "./request/requestClient";

export async function getSearch({
  text,
  pharmacyId,
  includeNonMedispan,
  ...config
}: RequestClientConfig<{
  text: string;
  pharmacyId: number;
  includeNonMedispan?: 1;
}>) {
  return requestClient<{
    searchResults: SearchResult[];
    searchError?: SearchErrorNotFoundInMedispan;
  }>({
    ...config,
    url: "/search",
    params: { text, version: 2, pharmacyId, includeNonMedispan },
  });
}
