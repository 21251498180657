import React, { useMemo } from "react";
import {
  getAdminSupplierMapping,
  updateAdminSupplierMapping,
} from "../../../../../services/supplierMappings";
import { useEditForm_legacy } from "../../../../../components/antd/form/useEditForm";
import { WideContainer } from "../../../../../components/containers/WideContainer";
import { AdminSupplierMappingForm } from "./AdminSupplierMappingForm/AdminSupplierMappingForm";
import { formatFormShippingConfig } from "./AdminSupplierMappingForm/AdminSupplierMappingForm.utils";

export function EditAdminSupplierMapping() {
  const { values, onSubmit } = useEditForm_legacy({
    name: "supplierMapping",
    update: updateAdminSupplierMapping,
    fetchValues: getAdminSupplierMapping,
  });

  const { id, initialValues, changeLogNotes, catalogSupplierId } =
    useMemo(() => {
      if (!values) return {};
      const {
        id,
        changeLogNotes,
        catalogSupplierId,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        supplier,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        lastUpdatedById,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        lastUpdatedByEmail,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        createdAt,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        updatedAt,
        ...data
      } = values;

      const initialValues = {
        ...data,
        shippingConfig: formatFormShippingConfig(data.shippingConfig),
      };

      return { id, initialValues, changeLogNotes, catalogSupplierId };
    }, [values]);

  return (
    <WideContainer tw="pb-[200px]">
      <AdminSupplierMappingForm
        isUpdateForm
        id={id}
        initialValues={initialValues}
        changeLogNotes={changeLogNotes}
        catalogSupplierId={catalogSupplierId}
        onSubmit={onSubmit}
      />
    </WideContainer>
  );
}
