import { useCallback, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  ColGroupDef,
  GridReadyEvent,
  IDetailCellRendererParams,
} from "ag-grid-community";
import type { MultipleDownloadCsvWithChildrenButtonsConfig } from "../csv/MultipleDownloadCsvWithChildrenButtons";
import { getMainMenuItems } from "../getMainMenuItems";
import { DEFAULT_COL_DEF } from "../reportsConstants";
import { UseReportSideBarParams, useReportSideBar } from "./useReportSideBar";
import { formatColumnDefs } from "./formatColumnDefs";
import { formatDetailCellRendererParams } from "./formatDetailCellRendererParams";

type UseReportParams<T> = Omit<UseReportSideBarParams<T>, "gridRef"> & {
  columnDefs?: (ColDef | ColGroupDef)[];
  detailCellRendererParams?: Partial<IDetailCellRendererParams>;
  multipleDownloadCsvWithChildrenButtonsConfig?: MultipleDownloadCsvWithChildrenButtonsConfig[];
  onGridReady?: (event: GridReadyEvent) => void;
};

export type UseReportReturn = ReturnType<typeof useReport>;

export function useReport<T>({
  columnDefs,
  sideBarDef,
  ExportToolPanel,
  exportToolPanelProps,
  detailCellRendererParams,
  multipleDownloadCsvWithChildrenButtonsConfig,
  onGridReady,
}: UseReportParams<T>) {
  const gridRef = useRef<AgGridReact>(null);

  const sideBar = useReportSideBar<T>({
    gridRef,
    sideBarDef,
    ExportToolPanel,
    exportToolPanelProps,
    detailCellRendererParams,
    multipleDownloadCsvWithChildrenButtonsConfig,
  });

  const newColumnDefs = useMemo(() => {
    return formatColumnDefs(columnDefs);
  }, [columnDefs]);

  const newDetailCellRendererParams = useMemo(() => {
    return formatDetailCellRendererParams(detailCellRendererParams);
  }, [detailCellRendererParams]);

  const newOnGridReady = useCallback(
    (event: GridReadyEvent) => {
      onGridReady && onGridReady(event);
    },
    [onGridReady]
  );

  return {
    ref: gridRef,
    sideBar,
    columnDefs: newColumnDefs,
    animateRows: true,
    suppressExcelExport: true,
    enableRangeSelection: true,
    enableCellTextSelection: true,
    popupParent: document.body,
    defaultColDef: DEFAULT_COL_DEF,
    detailCellRendererParams: newDetailCellRendererParams,
    onGridReady: newOnGridReady,
    getMainMenuItems,
  };
}
