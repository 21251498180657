import { ColDef, ColGroupDef } from "ag-grid-community";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName: "Pharmacy",
    children: [
      {
        field: "pharmacy_name",
        headerName: "Pharmacy Name",
        pinned: "left",
      },
      {
        field: "npi",
        headerName: "Pharmacy NPI",
        pinned: "left",
      },
    ],
  },
  {
    headerName: "Invoice Date",
    children: [
      {
        field: "month_start",
        headerName: "Date",
        sort: "desc",
      },
      {
        field: "month_name",
        headerName: "Month",
      },
      {
        field: "year",
        headerName: "Year",
      },
    ],
  },
  {
    headerName: "Data",
    children: [
      {
        field: "attempted",
        headerName: "Adjudicated Rxs",
        sort: "desc",
      },
      {
        field: "fee",
        headerName: "Fee",
        valueFormatter: ({ value }) => {
          return `$${value.toLocaleString()}`;
        },
      },
      {
        field: "total_paid",
        headerName: "Total Payment",
        valueFormatter: ({ value }) => {
          return `$${value.toLocaleString()}`;
        },
      },
      {
        field: "note",
        headerName: "Status Note",
      },
    ],
  },
];
