import React from "react";
import pluralize from "pluralize";
import type { SearchErrorNotFoundInMedispan } from "../types";

export const NOT_FOUND_IN_MEDISPAN_ERROR = "NOT_FOUND_IN_MEDISPAN";

export function formatMedispanError(
  search: string,
  searchError?: SearchErrorNotFoundInMedispan
) {
  if (searchError?.type !== NOT_FOUND_IN_MEDISPAN_ERROR) return;

  const { type, ndcs } = searchError;
  const ndcsStr = pluralize("NDC", ndcs.length) + " " + ndcs.join(", ");
  const msg = (
    <>
      <strong>{ndcsStr}</strong> associated with <strong>Rx {search}</strong> is
      not available in Medispan. Consider updating the dispensed NDC in the PMS,
      and try again in a few minutes. Otherwise, search by drug name.
    </>
  );
  return { msg, type };
}
