import { useMemo } from "react";
import type { ItemInCart } from "../../../../../utilities/types";
import { formatDispenseQuantity } from "../../../../../utilities/prescriptions/formatDispenseQuantity";
import { useItemInventory } from "../../../../../utilities/prescriptions/useItemInventory";
import { Text } from "../../../../../components/rxLibrary/typography";
import { Box } from "../../../../../components/rxLibrary/box";
import { ItemTypeEnum } from "../../../../../utilities/prescriptions/itemType/itemTypeEnum";

export function ShoppingListColumnDispensedQuantity({
  prescription,
  hideDispensedQuantity,
}: {
  prescription: ItemInCart;
  hideDispensedQuantity?: boolean;
}) {
  const inventory = useItemInventory(prescription);

  const dispensedQuantity = useMemo(() => {
    if (!hideDispensedQuantity) return formatDispenseQuantity(prescription);
  }, [prescription, hideDispensedQuantity]);

  if (prescription.itemType === ItemTypeEnum.NoPMS || !inventory) {
    if (!dispensedQuantity) return;

    return (
      <Text weight="bold" block center>
        {dispensedQuantity}
      </Text>
    );
  }

  const { eoh, boh } = inventory;
  return (
    <div tw="flex flex-col items-center">
      {!!dispensedQuantity && (
        <Box tw="border-b px-[12px]" borderColor="grey-2">
          <Text weight="bold">{dispensedQuantity}</Text>
        </Box>
      )}

      <div>
        <Text weight="bold">
          <Text color="grey-2">BOH: </Text>
          {boh}
        </Text>

        {eoh !== undefined && (
          <Text weight="bold">
            <Text color="grey-2"> EOH </Text>
            {eoh}
          </Text>
        )}
      </div>
    </div>
  );
}
