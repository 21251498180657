import React, {Reducer, useEffect, useReducer} from "react";
import {formatNdc} from "../../utilities/ndc/formatNdc";
import "../../scss/base.scss";
import "../../scss/enhSuppSafeScn.scss";
const refreshIcon = new URL('svgurl:../../components/rxLibrary/icons/svg/icon-refresh.svg', import.meta.url).toString();

export type EnhancedSupplierSafetyScreenDisplayConfig = {
  supplier: string;
  supplierDisplay: string;
  useCase: "default" | "havingTrouble";
} | {
  supplier: string;
  supplierDisplay: string;
  useCase: "loadingSearch";
  searchContext: {ndc: string};
}

type ConfigAdj = {
  type: "setConfig",
  config: EnhancedSupplierSafetyScreenDisplayConfig
}

export default function EnhancedSupplierSafetyScreen(): JSX.Element {
  const [displayConfig, updateDisplayConfig] = useReducer<Reducer<EnhancedSupplierSafetyScreenDisplayConfig, ConfigAdj>>((state, update): EnhancedSupplierSafetyScreenDisplayConfig => {
    switch(update.type) {
      case "setConfig":
        return update.config;
    }
    return state;
  }, {
    supplier: "default",
    supplierDisplay: "Default Supplier",
    useCase: "default",
  })

  useEffect(() => {
    document.body.style.backgroundColor = 'rgba(0, 0, 0, 0.6)';
    const root = document.getElementById("root");
    if (root) {
      // root.style.backgroundColor = 'rgba(255,255,255,255)';
      root.style.height = 'auto';
    }
    return () => {
      document.body.style.backgroundColor = 'rgba(255,255,255)';
      const root = document.getElementById("root");
      if (root) {
        root.style.backgroundColor = 'inherit';
        root.style.height = '100%';
      }
    }
  }, [displayConfig])

  useEffect(() => {
    const messageListenerCallback = async (event: MessageEvent) => {
      if (event.source !== window) {
        return;
      }
      if (event.data.channel === "daylightrxenhanced") {
        if (event.data.msgType === "safetyScreenDisplayConfig_default") {
          const payload = event.data.payload as { supplier: string, supplierDisplay: string };
          updateDisplayConfig({
            type: "setConfig", config: {
              supplier: payload.supplier,
              supplierDisplay: payload.supplierDisplay,
              useCase: "default",
            }
          });
        }
        if (event.data.msgType === "safetyScreenDisplayConfig_loadingSearch") {
          const payload = event.data.payload as { supplier: string, supplierDisplay: string, searchContext: {ndc: string} };
          updateDisplayConfig({
            type: "setConfig", config: {
              supplier: payload.supplier,
              supplierDisplay: payload.supplierDisplay,
              useCase: "loadingSearch",
              searchContext: payload.searchContext,
            }
          });
        }
        if (event.data.msgType === "safetyScreenDisplayConfig_havingTrouble") {
          const payload = event.data.payload as { supplier: string, supplierDisplay: string };
          updateDisplayConfig({
            type: "setConfig", config: {
              supplier: payload.supplier,
              supplierDisplay: payload.supplierDisplay,
              useCase: "havingTrouble",
            }
          });
        }
      }
    }
    window.addEventListener("message", messageListenerCallback);

    return () => {
      window.removeEventListener("message", messageListenerCallback);
    }
  }, []);

  return <div className="supplierSafetyScreen">
    {displayConfig.useCase === "loadingSearch" &&
      <div className="loading-search" style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "white",
        fontSize: "32px",
        backgroundColor: "rgb(46,46,46)",
        padding: "16px",
        width: "640px",
      }}>
        Loading search results for {formatNdc(displayConfig.searchContext.ndc)}<div className="dot-flashing" style={{display:"inline-block", marginLeft: "20px"}}></div>
      </div>
    }
    {displayConfig.useCase === "havingTrouble" &&
      <div className="having-trouble" style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "black",
        backgroundColor: "white",
        padding: "16px",
        width: "800px",
        borderRadius: "4px",
        paddingTop: "96px",
        paddingBottom: "32px",
        paddingLeft: "64px",
        paddingRight: "64px",
        fontSize: "18px",
        boxShadow: "0px 0px 16px 0px rgba(0,0,0,0.2)",
      }}>
        <h2 style={{marginBottom: "16px"}}><strong>Having trouble with
          the {displayConfig.supplierDisplay} website?</strong></h2>
        <ol>
          <li style={{listStyle: "decimal", listStylePosition: "inside"}}>First,
            try to
            <img
              src={refreshIcon}
              className="hover-pointer refreshIcon"
              alt="refresh"
            /> <span className="action refresh" onClick={() => {
              window.postMessage({
                channel: "daylightrxenhanced",
                sender: "web",
                msgType: "safetyScreenRefresh",
                payload: {
                  supplier: displayConfig.supplier,
                }
              });
            }}>Refresh {displayConfig.supplierDisplay}&apos;s site</span>
          </li>
          <li style={{listStyle: "decimal", listStylePosition: "inside"}}>If
            refreshing doesn&apos;t solve the issue, you can
            <span className="action reset" style={{marginLeft: "4px"}}
                  onClick={() => {
                    window.postMessage({
                      channel: "daylightrxenhanced",
                      sender: "web",
                      msgType: "safetyScreenReset",
                      payload: {
                        supplier: displayConfig.supplier,
                      }
                    });
                  }}>Reset the connection to {displayConfig.supplierDisplay}&apos;s site here</span>.
            <ul style={{marginTop: "8px", marginLeft: "32px"}}>
              <li style={{listStyle: "disc", listStylePosition: "inside"}}>Note:
                Your shopping list will not be affected
              </li>
              <li style={{
                listStyle: "disc",
                listStylePosition: "inside"
              }}>However, you will need to log in again
                to {displayConfig.supplierDisplay}&apos;s site
              </li>
            </ul>
          </li>
        </ol>
        <hr style={{marginTop: "32px", marginBottom: "32px"}}/>
        <button className={"btn btn-white-shade"} style={{marginLeft: "auto", marginRight: "auto"}} id="close" onClick={() => {
          window.postMessage({
            channel: "daylightrxenhanced",
            sender: "web",
            msgType: "safetyScreenClose",
            payload: {
              stayOnSupplier: true,
            }
          });
        }}>Close
        </button>
      </div>
    }
    {displayConfig.useCase === "loadingSearch" || displayConfig.useCase === "default" &&
      <div className="interaction-bar" style={{
        position: "fixed",
        bottom: "0px",
        left: "0px",
        width: "100%",
        height: "140px",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
        <h1 id="title"><span id="supplierDisplay"></span> Safe Mode</h1>
        <h2 id="message"></h2>
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "32px",
        }}>
          <button className={"btn btn-white-shade"} id="close" onClick={() => {
            window.postMessage({
              channel: "daylightrxenhanced",
              sender: "web",
              msgType: "safetyScreenClose",
            });
          }}>Return to DaylightRx
          </button>
          <button className={"btn btn-white-shade"} id="interact" onClick={() => {
            window.postMessage({
              channel: "daylightrxenhanced",
              sender: "web",
              msgType: "safetyScreenInteract",
            });
          }}>Interact with {displayConfig.supplierDisplay}</button>
        </div>
      </div>
    }
  </div>
}
