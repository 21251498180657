import type { Quotes } from "../../../../utilities/types";
import type { QuoteState } from "../../Compare.constants";
import {
  groupItemsByNdc,
  tagAndGroupQuoteItems,
} from "../../../../utilities/search";

export function formatCompletedQuoteStateWebFormatter({
  ndc,
  quotes,
}: {
  ndc: string;
  quotes: Quotes;
}): Partial<QuoteState> {
  const { items, relatedDrugs, suggestedNDC } = quotes;

  const suggestedDrug = relatedDrugs.find((d) => d.ndc === suggestedNDC.ndc);

  const taggedItemsGroupedByTag = tagAndGroupQuoteItems(
    ndc,
    items,
    suggestedNDC
  );
  const itemListsByNDC = groupItemsByNdc(taggedItemsGroupedByTag);

  return { suggestedDrug, itemListsByNDC };
}
