import { Text } from "../../../../../../../components/rxLibrary/typography";
import { Box } from "../../../../../../../components/rxLibrary/box";
import { IconCheck } from "../../../../../../../components/rxLibrary/icons";

export function CompareCardDetailsTeeCode({ teeCode }: { teeCode?: string }) {
  if (!teeCode) return null;
  return (
    <Box
      tw="flex items-center border p-1 space-x-1 mt-1 rounded-[3px]"
      borderColor="green-4"
    >
      <IconCheck />
      <Text weight="bold" color="black-1" size={11}>
        TEE CODE {teeCode}
      </Text>
    </Box>
  );
}
