import { ComponentProps, useCallback } from "react";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { getBorderColorCss } from "../../../components/rxLibrary/colors";
import { usePerformance } from "../usePerformance";

type onChangeFn = NonNullable<ComponentProps<typeof RangePicker>["onChange"]>;

const { RangePicker } = DatePicker;
const BORDER_COLOR_CSS = getBorderColorCss("grey-2");

export function PerformanceHeaderDateRangePicker() {
  const { reportId, fromDate, toDate, setToDate, setFromDate } =
    usePerformance();

  const onChange: onChangeFn = useCallback(
    (dates) => {
      if (dates === null) return;

      const [fromDate, toDate] = dates;
      if (fromDate) setFromDate(fromDate);
      if (toDate) setToDate(toDate);
    },
    [setToDate, setFromDate]
  );

  // Not show if the report is Pharmacy Payments (for Refills or for Data)
  if (reportId === 41 || reportId === 57)
    return <div style={{ width: "276px" }}></div>;

  return (
    <div tw="flex items-center space-x-2">
      <span tw="font-light">Dates:</span>

      <RangePicker
        tw="rounded min-h-[38px]"
        css={BORDER_COLOR_CSS}
        value={[fromDate, toDate]}
        presets={[
          {
            label: "Last 30 Days",
            value: [dayjs().subtract(30, "day"), dayjs().subtract(1, "day")],
          },
          {
            label: "Last 7 Days",
            value: [dayjs().subtract(7, "day"), dayjs().subtract(1, "day")],
          },
        ]}
        onChange={onChange}
        allowClear={false}
      />
    </div>
  );
}
