import React, { useEffect, useContext, useCallback, useState } from "react";
import tw from "twin.macro";
import type { NdcOffering } from "../../../../../../../enhanced/result_types";
import type { ShippingConfig } from "../../../../../../../utilities/types";
import EnhancedClientContext, {
  EnhancedStockValidationAvailability,
} from "../../../../../../../enhanced/EnhancedClientContext";
import EnhancedClientCommandContext from "../../../../../../../enhanced/EnhancedClientCommandContext";
import EnhancedClientConfigContext from "../../../../../../../enhanced/EnhancedClientConfigContext";
import { useBuyingPharmacy } from "../../../../../../../contexts/BuyingPharmacyContext";
import { formatDrugNameWithDetails } from "../../../../../../../utilities/drugInfo/formatDrugNameWithDetails";
import { shallowLinksOnlyForSupplier } from "../../../../../../../utilities/search";
import { currencyWithCentsAtAllTimes } from "../../../../../../../utilities/numbers/currency";
import { tryWriteClipboard } from "../../../../../../../utilities/clipboard";
import AccordionPanel from "../../../../../../../components/AccordionPanel";
import HorizontalCarousel from "../../../../../../../components/HorizontalCarousel/HorizontalCarousel";
import { ExpirationDate } from "../../../../../../../components/ExpirationDate";
import { useHandleNdcLinkClick } from "../useHandleNdcLinkClick";
import { useDetermineEcommerceByOffering } from "../useDetermineEcommerce";
import { useObtainEnhancedClientDeepLink } from "../useObtainEnhancedClientDeepLink";
import { getShippingConfig } from "../getShippingConfig";
import { CutOffTime } from "../CutOffTime";
import { LowerLabel } from "../LowerLabel";
import { MinOrder } from "../MinOrder";

function PriceOrAvailabilityText({ result }: { result: NdcOffering }) {
  if (result.loginRequired) {
    return <span>Login to see price</span>;
  }

  const priceDisplay =
    currencyWithCentsAtAllTimes(result.price) +
    `${result.supplierData?.isRebatedItem ? " w/ Rebate" : ""}`;

  if (
    result.supplierData &&
    result.supplierData.availability !==
      EnhancedStockValidationAvailability.AVAILABLE
  ) {
    switch (result.supplierData.availability) {
      case EnhancedStockValidationAvailability.UNAVAILABLE:
        return <>Not in Stock</>;
      case EnhancedStockValidationAvailability.BACKORDERED:
        return (
          <>
            <span>Back Order</span>
          </>
        );
      case EnhancedStockValidationAvailability.PENDING:
        return (
          <>
            <span>
              {priceDisplay}{" "}
              {result.supplierData.placeholderCreationTime &&
              Date.now() - result.supplierData.placeholderCreationTime < 5000
                ? "Verifying"
                : "Unverified"}
            </span>
          </>
        );
    }
  }

  if (result.price === 0) return <span>See Website</span>;
  return <span>{priceDisplay}</span>;
}

function SupplierShippingTbl({
  featured,
  alternatives,
}: {
  featured: NdcOffering;
  alternatives: NdcOffering[];
}) {
  const { currentBuyingPharmacy } = useBuyingPharmacy();
  const [shippingData, setShippingData] = useState<string[][] | null>(null);
  const [cellWidth, setCellWidth] = useState<number[] | null>(null);

  const calculateWidth = (featured: NdcOffering) => {
    const width: number[] = [];
    const carousel = document.querySelector(
      `#carousel${featured.item.drugInfo.ndc}`
    );
    if (carousel) {
      carousel
        .querySelectorAll(".catalog-link, .catalog-link-shallow")
        .forEach((s) => {
          const elem = s as HTMLElement;
          // 16 is the padding
          width.push(elem?.offsetWidth + 16);
        });
    }
    return width;
  };

  const adjustTblInfo = () => {
    const height: number[] = [];
    const carousel = document.querySelector(
      `#carousel${featured.item.drugInfo.ndc}`
    );
    const info = document.querySelector(
      `#shippingTbl${featured.item.drugInfo.ndc}`
    );
    if (carousel) {
      carousel.querySelectorAll(".tbl-row").forEach((s) => {
        const elem = s as HTMLElement;
        // 18 is the padding
        height.push(elem?.offsetHeight);
      });
    }
    if (info) {
      info.querySelectorAll(".tbl-info").forEach((s, index) => {
        const elem = s as HTMLElement;
        // 18 is the padding
        elem.style.height = `${height[index]}px`;
        elem.style.minHeight = `${height[index]}px`;
      });
    }
  };

  type CustomShippingConfig = ShippingConfig &
    Record<"supplierName", string | undefined>;
  const data: CustomShippingConfig[] = [];
  let supplierName;
  useEffect(() => {
    if (currentBuyingPharmacy) {
      supplierName = featured.item.catalogInfo.supplierDisplayName;
      data.push({
        ...getShippingConfig(supplierName, currentBuyingPharmacy?.suppliers),
        supplierName,
      });

      alternatives.forEach((item) => {
        supplierName = item.item.catalogInfo.supplierDisplayName;
        data.push({
          ...getShippingConfig(supplierName, currentBuyingPharmacy?.suppliers),
          supplierName,
        });
      });

      const fee: string[] = [];
      const cutoffTimes: string[] = [];
      const notes: string[] = [];

      data.forEach((obj) => {
        let feeTxt = "";
        for (const [key, value] of Object.entries(obj)) {
          switch (key) {
            case "fee":
            case "minOrder":
            case "minGenericOrder":
              feeTxt +=
                obj.supplierName === "IPC"
                  ? "$200 Generics or $5000 Brand|"
                  : `${value}|`;
              break;
            case "cutoffTimes":
              cutoffTimes.push((value || "").toString());
              break;
            case "notes":
              notes.push((value || "").toString());
              break;
          }
        }
        fee.push(feeTxt);
      });

      setCellWidth(calculateWidth(featured));

      const arr: string[][] = [];
      arr.push(fee);
      arr.push(cutoffTimes);
      arr.push(notes);

      setShippingData(arr);
      setTimeout(adjustTblInfo, 50);
    }
  }, []);

  return (
    <div tw=" mt-[8px]">
      {shippingData &&
        cellWidth &&
        shippingData.map((items: string[], index) => {
          return (
            <div key={index} tw="flex flex-col" className="tbl-row">
              <div
                tw="flex"
                className="items"
                css={[
                  index === 0 && tw`min-h-[81px]`,
                  index === 1 && tw`min-h-[42px]`,
                  index === 2 && tw`min-h-[62px]`,
                ]}
              >
                {items.map((item: string, keyIndex) => {
                  return (
                    <div
                      key={keyIndex}
                      tw="min-w-[96px] bg-[#E6EDF8] p-[2px 0] border-b border-white"
                      style={{
                        width: `${cellWidth[keyIndex]}px`,
                        maxWidth: `${cellWidth[keyIndex]}px`,
                      }}
                    >
                      <div
                        css={[
                          keyIndex === items.length - 1
                            ? tw`border-[#E6EDF8]`
                            : tw`border-white`,
                          tw`border-r-2 py-[9px] px-[8px] min-h-full text-[13px] `,
                        ]}
                      >
                        {index === 0 && MinOrder(item)}
                        {index === 1 && CutOffTime(item)}
                        {index === 2 && <p>{item}</p>}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
}

function SupplierElement({
  result,
  bold,
}: {
  result: NdcOffering;
  bold?: boolean;
}) {
  const { enhancedClientActive, enhancedClientVersion } = useContext(
    EnhancedClientConfigContext
  );
  const { showSupplier } = useContext(EnhancedClientCommandContext);
  const { unskipSupplier } = useContext(EnhancedClientContext);
  const handleLinkClick = useHandleNdcLinkClick(result);
  const isEcommerce = useDetermineEcommerceByOffering(result);
  const deepLink = useObtainEnhancedClientDeepLink(result);
  const isShallow = shallowLinksOnlyForSupplier(
    result.item.catalogInfo.supplier,
    enhancedClientVersion
  );

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      tryWriteClipboard(result.item.drugInfo.ndc);
      if (isShallow && enhancedClientActive) {
        e.preventDefault();
        showSupplier(result.item.catalogInfo.supplier);
      } else {
        handleLinkClick(e);
      }
    },
    [enhancedClientActive, showSupplier, isShallow, handleLinkClick]
  );

  if (result.loginRequired) {
    return (
      <div
        key={`${result.item.catalogInfo.supplier}:${result.item.drugInfo.ndc}`}
        tw="flex items-start flex-col pr-[8px] pl-[8px] min-w-[96px]"
      >
        <a
          href={isEcommerce ? deepLink : result.item.catalogInfo.link}
          css={[tw`text-[16px]  hover:underline`, bold && tw`font-bold`]}
          target={`tab_${result.item.catalogInfo.supplier}`}
          onClick={(e) => {
            e.preventDefault();
            unskipSupplier(result.item.catalogInfo.supplier);
            // showSupplierForUseCase(result.item.catalogInfo.supplier, "login")
          }}
        >
          <span tw="whitespace-nowrap">
            {result.item.catalogInfo.supplierDisplayName}
          </span>

          <LowerLabel css={[bold && tw`font-bold`]}>
            <PriceOrAvailabilityText result={result} />
          </LowerLabel>
          <div className={`text-sm ${bold ? "font-500" : "font-300"}`}>
            <ExpirationDate
              textOnly
              catalogInfo={result.item.catalogInfo}
              supplierData={result.supplierData}
            />
          </div>
        </a>
      </div>
    );
  }

  return (
    <div
      key={`${result.item.catalogInfo.supplier}:${result.item.drugInfo.ndc}`}
      tw="flex items-start flex-col pr-[8px] pl-[8px] min-w-[96px]"
    >
      <a
        href={isEcommerce ? deepLink : result.item.catalogInfo.link}
        css={[tw`text-[16px]  hover:underline`, bold && tw`font-bold`]}
        target={`tab_${result.item.catalogInfo.supplier}`}
        className={
          isShallow ? `catalog-link-shallow group` : `catalog-link group`
        }
        data-supplier={result.item.catalogInfo.supplier}
        data-supplier-id={result.item.catalogInfo.supplierId}
        data-drug-descriptive-name={formatDrugNameWithDetails(
          result.item.drugInfo
        )}
        data-drug-manufacturer-name={result.item.drugInfo.manufacturer}
        data-is-ecommerce={isEcommerce}
        data-ndc={result.item.drugInfo.ndc}
        data-deeplink={deepLink}
        onClick={onClick}
      >
        <span tw="whitespace-nowrap">
          {result.item.catalogInfo.supplierDisplayName}
        </span>

        <LowerLabel css={[bold && tw`font-bold`]}>
          <PriceOrAvailabilityText result={result} />
        </LowerLabel>

        <div className={`text-sm ${bold ? "font-500" : "font-300"}`}>
          <ExpirationDate
            textOnly
            catalogInfo={result.item.catalogInfo}
            supplierData={result.supplierData}
          />
        </div>
      </a>
    </div>
  );
}

export function CompareCardDetailsSuppliers({
  featured,
  alternatives,
  shippingPanel,
}: {
  featured: NdcOffering;
  alternatives: NdcOffering[];
  shippingPanel: boolean;
}) {
  useEffect(() => {
    const s = document.querySelector(
      `#suppliersPanel${featured.item.drugInfo.ndc}`
    ) as HTMLElement;
    const sp = document.querySelector(
      `#tag${featured.item.drugInfo.ndc}`
    ) as HTMLElement;
    const si = document.querySelector(
      `#tagInfo${featured.item.drugInfo.ndc}`
    ) as HTMLElement;
    if (s && sp && si) {
      if (shippingPanel) {
        const h = si?.offsetHeight;
        sp.style.paddingBottom = `${s?.offsetHeight - (h - 11)}px`;
      } else {
        sp.style.paddingBottom = "0";
      }
    }
  }, [shippingPanel]);

  return (
    <div tw="w-[100%] max-w-[445px] border-t border-[#cfcfcf] pt-[8px] min-h-[53px]">
      <HorizontalCarousel id={`carousel${featured.item.drugInfo.ndc}`}>
        <div tw="flex">
          <SupplierElement result={featured} bold />
          {alternatives.map((item) => {
            return (
              <SupplierElement
                key={`${item.item.catalogInfo.supplier}:${item.item.drugInfo.ndc}`}
                result={item}
              />
            );
          })}
        </div>

        <AccordionPanel open={shippingPanel}>
          {shippingPanel ? (
            <SupplierShippingTbl
              featured={featured}
              alternatives={alternatives}
            />
          ) : null}
        </AccordionPanel>
      </HorizontalCarousel>
    </div>
  );
}
