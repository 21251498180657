import { useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import keyBy from "lodash/keyBy";
import type {
  InvoiceHistoryHistoryConfigOption,
  InvoiceHistorySortColumn,
  Supplier,
} from "../../utilities/types";
import { formatDateToBeFormat } from "../../utilities/dates/formatDateToBeFormat";
import { SortDirectionEnum } from "../../components/rxLibrary/selects/SortBySelect";
import { useBuyingPharmacy } from "../../contexts/BuyingPharmacyContext";
import { getInvoiceHistory } from "../../services/invoiceHistory";
import { useRequest } from "../../services/request/useRequest";
import { usePagination } from "../../components/rxLibrary/pagination/usePagination";

const DAYS_OPTIONS = [7, 30, 90].map((i) => {
  return { label: `Past ${i} Days`, value: i };
});

const SORT_OPTIONS: { label: string; value: InvoiceHistorySortColumn }[] = [
  { label: "Invoice Date", value: "invoice_date" },
  { label: "Supplier Name", value: "supplier_name" },
  { label: "Total Items", value: "total_items" },
  { label: "Total Amount", value: "total_amount" },
];

function getSupplierLabel({
  supplier,
  supplierConfigById,
}: {
  supplier: Supplier;
  supplierConfigById: Record<number, InvoiceHistoryHistoryConfigOption>;
}) {
  const { id: supplierId, displayName } = supplier;
  const supplierConfig = supplierConfigById[supplierId];
  if (!supplierConfig) return displayName;

  const { configuredInvoice, configuredInvoiceGlobal } = supplierConfig;
  if (!configuredInvoiceGlobal) return `${displayName}*`;
  if (!configuredInvoice && configuredInvoiceGlobal) return `${displayName}**`;
  return displayName;
}

function getWarningsConfig({
  supplier,
  supplierId,
  configOptions,
}: {
  supplier?: Supplier;
  supplierId: number;
  configOptions?: InvoiceHistoryHistoryConfigOption[];
}) {
  if (!configOptions) return {};

  if (supplierId === 0) {
    const footnoteWarning2 = configOptions.some((config) => {
      return !config.configuredInvoice && config.configuredInvoiceGlobal;
    });
    const footnoteWarning3 = configOptions.some((config) => {
      return !config.configuredInvoiceGlobal;
    });
    return { footnoteWarning1: true, footnoteWarning2, footnoteWarning3 };
  }

  if (!supplier) return {};

  const supplierConfig = configOptions.find((config) => {
    return config.supplierId === supplierId;
  });
  if (!supplierConfig) return {};

  const { offlinePurchasing } = supplier;
  const { configuredInvoice, configuredInvoiceGlobal } = supplierConfig;

  if (!configuredInvoiceGlobal && !offlinePurchasing) {
    return { emptyPageWarning1Web: true };
  }
  if (!configuredInvoiceGlobal && offlinePurchasing) {
    return { emptyPageWarning1WIP: true };
  }
  if (!configuredInvoice && configuredInvoiceGlobal) {
    return { emptyPageWarning2: true };
  }

  return { footnoteWarning1: true };
}

export function useInvoiceHistory() {
  const { currentBuyingPharmacy, getSupplierById } = useBuyingPharmacy();
  const [supplierId, _setSupplierId] = useState(0);
  const [sort, setSort] = useState(SORT_OPTIONS[0].value);
  const [days, _setDays] = useState(DAYS_OPTIONS[0].value);
  const [sortDirection, setSortDirection] = useState(SortDirectionEnum.DESC);

  const { suppliers, id: pharmacyId } = currentBuyingPharmacy ?? {};

  const { pagination, updatePagination } = usePagination({
    initialPagination: { currentPage: 1 },
  });
  const { currentPage } = pagination ?? {};

  const config = useMemo(() => {
    if (!pharmacyId) return;

    const startDate = formatDateToBeFormat(dayjs().subtract(days, "days"));
    const endDate = formatDateToBeFormat(dayjs());
    return {
      sort,
      pharmacyId,
      supplierId: supplierId || undefined,
      startDate,
      endDate,
      pageNumber: currentPage,
      sortDirection,
    };
  }, [sort, days, pharmacyId, supplierId, currentPage, sortDirection]);

  const {
    data: invoiceHistory,
    error,
    isLoading,
  } = useRequest({
    start: !!config,
    config,
    dataKey: "invoiceHistory",
    request: getInvoiceHistory,
    updatePagination,
  });

  const { histories, configOptions } = invoiceHistory ?? {};

  const supplier = useMemo(() => {
    return getSupplierById(supplierId);
  }, [supplierId, getSupplierById]);

  const suppliersOptions = useMemo(() => {
    const supplierConfigById = keyBy(configOptions, "supplierId");
    return [
      { label: "All", value: 0 },
      ...(suppliers?.map((supplier) => {
        const label = getSupplierLabel({ supplier, supplierConfigById });
        return { label, value: supplier.id };
      }) ?? []),
    ];
  }, [suppliers, configOptions]);

  const {
    footnoteWarning1 = false,
    footnoteWarning2 = false,
    footnoteWarning3 = false,
    emptyPageWarning1Web = false,
    emptyPageWarning1WIP = false,
    emptyPageWarning2 = false,
  } = useMemo(() => {
    return getWarningsConfig({ supplier, supplierId, configOptions });
  }, [supplier, supplierId, configOptions]);

  const setSupplierId = useCallback(
    (value: number) => {
      updatePagination({ currentPage: 1 });
      _setSupplierId(value);
    },
    [updatePagination]
  );

  const setDays = useCallback(
    (value: number) => {
      updatePagination({ currentPage: 1 });
      _setDays(value);
    },
    [updatePagination]
  );

  return {
    sort,
    days,
    error,
    supplier,
    isLoading,
    histories,
    supplierId,
    pagination,
    sortOptions: SORT_OPTIONS,
    daysOptions: DAYS_OPTIONS,
    sortDirection,
    footnoteWarning1,
    footnoteWarning2,
    footnoteWarning3,
    suppliersOptions,
    emptyPageWarning1Web,
    emptyPageWarning1WIP,
    emptyPageWarning2,
    setDays,
    setSort,
    setSupplierId,
    setSortDirection,
    updatePagination,
  };
}
