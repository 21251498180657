import { NavBar } from "../../components/NavBar/NavBar";
import { Box } from "../../components/rxLibrary/box";
import { PerformanceHeader } from "./PerformanceHeader/PerformanceHeader";
import { PerformanceContextProvider } from "./usePerformance";
import { PerformanceReport } from "./PerformanceReport";
import { WideContainer } from "../../components/containers/WideContainer";
import { PerformanceFooter } from "./PerformanceFooter";

export function Performance() {
  return (
    <Box tw="flex flex-col overflow-y-hidden h-[100dvh]" bgColor="grey-4">
      <NavBar />

      <PerformanceContextProvider>
        <PerformanceHeader />

        <WideContainer tw="flex-1 overflow-y-hidden">
          <PerformanceReport />
        </WideContainer>

        <PerformanceFooter />
      </PerformanceContextProvider>
    </Box>
  );
}
