import React from "react";
import EditOutlined from "@ant-design/icons/EditOutlined";
import MailOutlined from "@ant-design/icons/MailOutlined";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import keyBy from "lodash/keyBy";
import {
  getAdminPharmacies,
  getAdminPharmacyOptions,
} from "../../../../../services/pharmacies";
import { useRequest } from "../../../../../services/request/useRequest";
import {
  AdminPharmacyFull,
  AdminPharmacyOptions,
} from "../../../../../utilities/types";
import {
  Button,
  IconButton,
} from "../../../../../components/rxLibrary/buttons";
import { ViewChangeLogNotes } from "../components/ViewChangeLogNotes";
import { renderBoolean } from "../../../../../components/antd/table/renderBoolean";
import { renderTimeSinceADate } from "../../../../../components/antd/table/renderTimeSinceADate";
import { TitleWithTooltip } from "../../../../../components/antd/table/TitleWithTooltip";
import { getTextSorter } from "../../../../../components/antd/table/getTextSorter";
import { getNumberSorter } from "../../../../../components/antd/table/getNumberSorter";
import { getDateSorter } from "../../../../../components/antd/table/getDateSorter";
import { getBooleanFilter } from "../../../../../components/antd/table/getBooleanFilter";
import { renderAdminSupplier } from "../../../../../components/antd/table/renderAdminSupplier";
import { renderAdminPharmacyOrganization } from "../../../../../components/antd/table/renderAdminPharmacyOrganization";
import { useTableColumnsWithOptions } from "../../../../../components/antd/table/useTableColumnsWithOptions";
import { TableColumns } from "../../../../../components/antd/table/table.constants";
import { AdminManagementTable } from "../components/AdminManagementTable";
import { AdminManagementHeader } from "../components/AdminManagementHeader";
import { IconPlus } from "../../../../../components/rxLibrary/icons";
import { getColumnSearchProps } from "../../../../../components/antd/table/getColumnSearchProps";
import { renderDate } from "../../../../../components/antd/table/renderDate";

const BREADCRUMBS = [{ title: "Pharmacies" }];

const COLUMNS: TableColumns<AdminPharmacyFull> = [
  {
    dataIndex: "id",
    title: "ID",
    width: 60,
    fixed: "left",
    sorter: getNumberSorter("id"),
  },
  {
    dataIndex: "name",
    title: "Name",
    width: 150,
    fixed: "left",
    ...getColumnSearchProps("name"),
    sorter: getTextSorter("name"),
  },
  {
    dataIndex: "legalPharmacyName",
    title: "Legal Pharmacy Name",
    width: 150,
  },
  {
    dataIndex: "pharmacyOrganization",
    title: "Pharmacy Organization",
    width: 230,
    ...getColumnSearchProps("pharmacyOrganization.name"),
    sorter: getTextSorter("pharmacyOrganization.name"),
    render: renderAdminPharmacyOrganization,
  },
  { dataIndex: "pms", title: "PMS", width: 100, sorter: getTextSorter("pms") },
  {
    dataIndex: "deaCode",
    title: "DEA Code",
    width: 100,
  },
  { dataIndex: "npi", title: "NPI", width: 100 },
  {
    dataIndex: "nabpNumber",
    title: "NABP Number",
    width: 100,
  },
  { dataIndex: "license", title: "Board of Pharmacy License", width: 150 },
  {
    dataIndex: "timezone",
    title: "Timezone",
    width: 150,
    sorter: getTextSorter("timezone"),
  },
  {
    dataIndex: "address",
    title: "Address",
    width: 200,
    ...getColumnSearchProps("address"),
  },
  {
    dataIndex: "city",
    title: "City",
    width: 100,
    ...getColumnSearchProps("city"),
  },
  { dataIndex: "state", title: "State", width: 70 },
  { dataIndex: "postalCode", title: "Postal Code", width: 100 },
  {
    dataIndex: "isSynced",
    title: "Show PMS info on P1",
    align: "center",
    width: 180,
    filters: [
      { text: "Show", value: true },
      { text: "No Show", value: false },
    ],
    onFilter: getBooleanFilter("isSynced"),
    sorter: getNumberSorter("isSynced"),
    render: renderBoolean,
  },
  {
    dataIndex: "supplierScope",
    title: "Supplier Scope",
    width: 130,
    sorter: getTextSorter("supplierScope"),
  },
  {
    dataIndex: "usePmsSchema",
    title: (
      <TitleWithTooltip
        title="Use PMS Schema"
        tooltip="Use multi-PMS merged database schema on back-end. Until spring 2024, Pioneer should be false and Liberty/PrimeRx should be true."
      />
    ),
    width: 150,
    render: renderBoolean,
  },
  {
    dataIndex: "syncLibertyInventory",
    title: "Sync Liberty Inventory",
    width: 150,
    render: renderBoolean,
  },
  {
    dataIndex: "primarySupplier",
    title: "Primary Supplier (Optional)",
    width: 140,
    render: renderAdminSupplier,
  },
  {
    dataIndex: "secondarySupplier",
    title: "Primary Secondary Supplier (Optional)",
    width: 140,
    render: renderAdminSupplier,
  },
  {
    dataIndex: "changeLogNotes",
    title: "Change Log Notes",
    width: 160,
    render: (changeLogNotes: string[]) => (
      <ViewChangeLogNotes changeLogNotes={changeLogNotes} small />
    ),
  },
  {
    dataIndex: "createdAt",
    title: "Created At",
    width: 110,
    sorter: getDateSorter("createdAt"),
    render: renderTimeSinceADate,
  },
  {
    dataIndex: "updatedAt",
    title: "Update At",
    width: 110,
    sorter: getDateSorter("updateAt"),
    render: renderTimeSinceADate,
  },
  {
    dataIndex: "pharmacyStatus",
    title: "Pharmacy Status",
    width: 150,
    sorter: getTextSorter("pharmacyStatus"),
  },
  {
    dataIndex: "isIncludedInManufacturerReports",
    title: "Included in Manufacturer Reports",
    align: "center",
    width: 200,
    filters: [
      { text: "Included", value: true },
      { text: "Not Included", value: false },
    ],
    onFilter: getBooleanFilter("isIncludedInManufacturerReports"),
    render: renderBoolean,
  },
  {
    dataIndex: "ein",
    title: "EIN",
    width: 150,
  },
  {
    dataIndex: "justworksName",
    title: "Justworks Name",
    width: 150,
  },
  {
    dataIndex: "justworksAccountAndEinSharedAcrossPharmacies",
    title: "Justworks Account/EIN Shared",
    width: 250,
    filters: [
      { text: "Shared", value: true },
      { text: "Not Shared", value: false },
    ],
    onFilter: getBooleanFilter("justworksAccountAndEinSharedAcrossPharmacies"),
    render: renderBoolean,
  },
  {
    dataIndex: "bopLicenseExpiryDate",
    title: "BOP License Expiry Date",
    width: 180,
    sorter: getDateSorter("bopLicenseExpiryDate"),
    render: renderDate,
  },
  {
    dataIndex: "deaLicenseExpiryDate",
    title: "DEA License Expiry Date",
    width: 180,
    sorter: getDateSorter("deaLicenseExpiryDate"),
    render: renderDate,
  },
  {
    key: "actions",
    dataIndex: "id",
    title: "Actions",
    width: 100,
    fixed: "right",
    render: (id) => (
      <div tw="flex items-center">
        <Link to={`/admin/pharmacy/${id}`}>
          <IconButton>
            <EditOutlined rev={undefined} />
          </IconButton>
        </Link>

        <Tooltip title="Open supplier mappings page filtered by pharmacy">
          <Link tw="underline" to={`/admin/supplier-mapping?pharmacy=${id}`}>
            SM
          </Link>
        </Tooltip>

        <Tooltip title="Open invoice email filtered by pharmacy">
          <Link
            tw="underline"
            to={`/admin/pharmacy/email/subscription?pharmacy=${id}`}
          >
            <IconButton>
              <MailOutlined rev={undefined} />
            </IconButton>
          </Link>
        </Tooltip>
      </div>
    ),
  },
];

function columnFormatter(dataIndex: string, options: AdminPharmacyOptions) {
  if (dataIndex === "pharmacyStatus") {
    const pharmacyStatusByValue = keyBy(options.pharmacyStatus, "value");
    return {
      filters: options.pharmacyStatus.map((v) => ({
        text: v.label,
        value: v.value,
      })),
      onFilter: (value: string, record: any) => record.pharmacyStatus === value,
      render: (value: string) => {
        return pharmacyStatusByValue[value]?.label || value;
      },
    };
  }

  let filters;
  if (dataIndex === "state") {
    filters = options[dataIndex].map((v) => {
      return { text: `${v.name} - ${v.code}`, value: v.code };
    });
  }

  if (
    dataIndex === "pms" ||
    dataIndex === "timezone" ||
    dataIndex === "supplierScope"
  ) {
    filters = options[dataIndex].map((v) => ({ text: v, value: v }));
  }

  if (!filters) return;
  return {
    filters,
    filterSearch: true,
    onFilter: (value: string, record: any) => record[dataIndex] === value,
  };
}

export function AdminPharmacies() {
  const { data, error, isLoading } = useRequest({
    dataKey: "pharmacies",
    request: getAdminPharmacies,
  });

  const columns = useTableColumnsWithOptions({
    dataKey: "pharmacyOptions",
    columns: COLUMNS,
    request: getAdminPharmacyOptions,
    columnFormatter,
  });

  return (
    <>
      <AdminManagementHeader breadcrumbs={BREADCRUMBS}>
        <Link to="/admin/pharmacy/new">
          <Button icon={<IconPlus />}>Add Pharmacy</Button>
        </Link>
      </AdminManagementHeader>

      <AdminManagementTable
        columns={columns}
        dataSource={data}
        loading={isLoading}
        hasError={!!error}
      />
    </>
  );
}
