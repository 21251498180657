import { ReactNode } from "react";
import isEmpty from "lodash/isEmpty";
import DotLoader from "../../loaders/DotLoader/DotLoader";
import { RequestState, SearchResult } from "../../../utilities/types";
import { formatNdc } from "../../../utilities/ndc/formatNdc";
import { NOT_FOUND_IN_MEDISPAN_ERROR } from "../../../utilities/prescriptions/medispanError";
import { IDrugSearchRequestState } from "../useDrugSearch/useDrugSearch.constants";
import {
  IDrugSearchTableData,
  IHandleDrugClick,
} from "../DrugSearch.constants";
import { DrugSearchResultsTable } from "./DrugSearchResultsTable";

export function DrugSearchResults({
  searchResults,
  drugSearchResultsState,
  drugSearchTableData,
  searchValue,
  brandValue,
  handleDrugClick,
}: {
  searchResults: SearchResult[];
  drugSearchResultsState: IDrugSearchRequestState;
  drugSearchTableData: IDrugSearchTableData;
  searchValue?: string;
  brandValue: string;
  handleDrugClick: IHandleDrugClick;
}) {
  const [drugSearchState, drugSearchErrorType, drugSearchErrorMessage] =
    drugSearchResultsState;

  if (drugSearchState === RequestState.UNINITIATED || !searchValue) {
    return null;
  }

  if (drugSearchState === RequestState.PENDING) {
    return (
      <ResultsMessage>
        <DotLoader />
        Searching for {searchValue}...
      </ResultsMessage>
    );
  }

  if (drugSearchState === RequestState.ERROR) {
    if (drugSearchErrorType === NOT_FOUND_IN_MEDISPAN_ERROR) {
      return <ResultsMessage>{drugSearchErrorMessage}</ResultsMessage>;
    }

    if (drugSearchErrorType === "NDC") {
      return (
        <ResultsMessage>
          <span tw="font-medium">{formatNdc(searchValue)}</span> is not a common
          NDC. Please search for the drug name instead
        </ResultsMessage>
      );
    }

    return <ResultsMessage>Error Searching for {searchValue}</ResultsMessage>;
  }

  if (searchResults.length === 0) {
    return (
      <ResultsMessage>
        We don{"'"}t recognize the drug{" "}
        <span className="font-500">{searchValue}</span>. Please check the
        spelling and try again.
      </ResultsMessage>
    );
  }

  if (searchResults.length === 1 && !searchResults[0].isAvailableInCatalog) {
    return (
      <ResultsMessage>
        We couldn{"'"}t find any drugs carried by your suppliers for{" "}
        <span className="font-500">{searchValue}</span>.
      </ResultsMessage>
    );
  }

  if (isEmpty(drugSearchTableData)) {
    return (
      <ResultsMessage>
        No {brandValue} found for {searchValue}
      </ResultsMessage>
    );
  }

  return (
    <DrugSearchResultsTable
      drugSearchTableData={drugSearchTableData}
      handleDrugClick={handleDrugClick}
    />
  );
}

function ResultsMessage({ children }: { children: ReactNode }) {
  return <div className="pt-12 text-center text-lg">{children}</div>;
}
