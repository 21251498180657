import { PurchaseOrder } from "../utilities/types";
import { requestClient, RequestClientConfig } from "./request/requestClient";

export async function postOrder({
  order,
  pharmacyId,
  supplierId,
  ...config
}: RequestClientConfig<{
  order: {
    items: {
      numPackages: number;
      supplierItemNumber?: string;
    }[];
    skipDuplicateCheck: boolean;
  };
  pharmacyId: number;
  supplierId: number;
}>) {
  return requestClient<{ purchaseOrder: PurchaseOrder }>({
    ...config,
    url: "/order",
    method: "POST",
    data: { order },
    params: { pharmacyId, supplierId, version: 2 },
  });
}
