import { WideContainer } from "../containers/WideContainer";
import { IconBell } from "../rxLibrary/icons";
import { Text } from "../rxLibrary/typography";
import { Box } from "../rxLibrary/box";

export function PageAlerts({ msgs }: { msgs?: string[] }) {
  if (!msgs?.length) return null;

  return msgs.map((msg, i) => (
    <Box key={i + msg} tw="relative mb-[3px] z-50" bgColor="red-1">
      <WideContainer tw="flex items-center justify-center py-[15px] space-x-[8px]">
        <div>
          <IconBell color="white-1" />
        </div>

        <Text color="white-1" weight="bold" size="large">
          {msg}
        </Text>
      </WideContainer>
    </Box>
  ));
}
