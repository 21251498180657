import React, { useMemo } from "react";
import pluralize from "pluralize";
import type { Dayjs } from "dayjs";
import { Button } from "../../../../components/rxLibrary/buttons";
import { IconRefresh } from "../../../../components/rxLibrary/icons";
import { Text, Title } from "../../../../components/rxLibrary/typography";
import { useBuyingPharmacy } from "../../../../contexts/BuyingPharmacyContext";
import { HumanizedTimeAgo } from "../../../../components/dates/HumanizedTimeAgo";

export function ShoppingListTablePmsHeader({
  loading,
  prescriptionsSyncDate,
  prescriptionItemsInCartCount,
  visiblePrescriptionItemsCount,
  syncPrescriptions,
}: {
  loading: boolean;
  prescriptionsSyncDate?: Dayjs;
  prescriptionItemsInCartCount: number;
  visiblePrescriptionItemsCount: number;
  syncPrescriptions: () => void;
}) {
  const { currentBuyingPharmacy: pharmacy } = useBuyingPharmacy();
  const { pms } = pharmacy ?? {};

  const message = useMemo(() => {
    const filteredPrescriptionItemsCount =
      prescriptionItemsInCartCount - visiblePrescriptionItemsCount;
    const itemsPlural = pluralize("Item", visiblePrescriptionItemsCount);

    let newMessage = `${
      pms ?? "PMS"
    } ${itemsPlural}: ${visiblePrescriptionItemsCount}`;
    if (
      !loading &&
      prescriptionItemsInCartCount > 0 &&
      filteredPrescriptionItemsCount > 0
    ) {
      newMessage += ` (+${filteredPrescriptionItemsCount} filtered)`;
    }

    return newMessage;
  }, [
    pms,
    loading,
    prescriptionItemsInCartCount,
    visiblePrescriptionItemsCount,
  ]);

  return (
    <>
      <Title variant="h2">{message}</Title>

      <Button
        variant="secondary"
        icon={<IconRefresh />}
        onClick={syncPrescriptions}
      >
        Refresh
      </Button>

      {!loading && !!prescriptionsSyncDate && (
        <Text size="medium">
          Refreshed <HumanizedTimeAgo date={prescriptionsSyncDate} />
        </Text>
      )}
    </>
  );
}
