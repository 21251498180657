import type {
  GridApi,
  GridReadyEvent,
  FilterChangedEvent,
  RowDataUpdatedEvent,
} from "ag-grid-community";

type GetPinnedRowDataFn = (data?: any[]) => Record<string, any>[] | undefined;

function updatePinnedTopRowData(
  api: GridApi,
  getPinnedRowData: GetPinnedRowDataFn
) {
  const data = api.getModel()?.rowsToDisplay?.map((node: any) => node.data);
  const pinnedRowData = getPinnedRowData(data);
  api.setPinnedTopRowData(pinnedRowData);
}

export function setupPinnedTopRowData(
  event: GridReadyEvent,
  getPinnedRowData: GetPinnedRowDataFn
) {
  const api = event.api;

  updatePinnedTopRowData(api, getPinnedRowData);

  api.addEventListener("filterChanged", (e: FilterChangedEvent) => {
    updatePinnedTopRowData(e.api, getPinnedRowData);
  });

  api.addEventListener("rowDataUpdated", (e: RowDataUpdatedEvent) => {
    updatePinnedTopRowData(e.api, getPinnedRowData);
  });
}
