import React, { useCallback, useState } from "react";
import { IconClock } from "../../../../../components/rxLibrary/icons";
import { useShoppingUpdater } from "../../../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import { TableHeader } from "../table/tableHeader/TableHeader";
import { ExpandableTable } from "../../../../../components/rxLibrary/tables/ExpandableTable/ExpandableTable";
import { BuyLaterTableModal } from "./BuyLaterTableModal/BuyLaterTableModal";
import { ShoppingItem } from "../../useShoppingList";
import { BuyLaterItem, useBuyLaterTableItems } from "./useBuyLaterTableItems";
import { BuyLaterTableRow } from "./BuyLaterTableRow";

export function BuyLaterTable({
  shoppingItems,
}: {
  shoppingItems: ShoppingItem[];
}) {
  const buyLaterTableItems = useBuyLaterTableItems();
  const [modalItem, setModalItem] = useState<BuyLaterItem>();
  const { updateBuyLaterItemPurchaseBy } = useShoppingUpdater();
  const itemsCount = buyLaterTableItems.length;

  const openModal = useCallback((item: BuyLaterItem) => {
    setModalItem(item);
  }, []);

  const closeModal = useCallback(() => {
    setModalItem(undefined);
  }, []);

  const onPurchaseByChange = useCallback(
    ({ date, item }: { item: BuyLaterItem; date?: Date }) => {
      if (date) {
        updateBuyLaterItemPurchaseBy(item.rxNumbers, date);
      } else {
        openModal(item);
      }
    },
    [openModal, updateBuyLaterItemPurchaseBy]
  );

  if (itemsCount === 0) return null;
  return (
    <>
      <ExpandableTable
        items={buyLaterTableItems}
        itemProps={{ onPurchaseByChange }}
        Row={BuyLaterTableRow}
        header={
          <TableHeader
            Icon={IconClock}
            title="Buy by a Later Date List"
            text="These items were marked for a later date"
            itemsCount={itemsCount}
          />
        }
      />

      {!!modalItem && (
        <BuyLaterTableModal
          item={modalItem}
          shoppingItems={shoppingItems}
          closeModal={closeModal}
        />
      )}
    </>
  );
}
