import React, { useMemo } from "react";
import dayjs from "dayjs";
import type {
  NdcOffering,
  NdcOfferingSet,
} from "../../../../../../../enhanced/result_types";
import type {
  TaggedItem,
  HistoricalOrderingInfo,
} from "../../../../../../../utilities/types";
import { dayjsWithDuration } from "../../../../../../../utilities/dates/dayjsWithDuration";
import { currencyWithCentsUnlessGreaterThanOrEqualToOneThousand } from "../../../../../../../utilities/numbers/currency";
import AccordionPanel from "../../../../../../../components/AccordionPanel";
import DispensedItem from "../../../../../../../components/DispensedItem";
import { Image } from "../../../../../../../components/Image";
import { useModal } from "../../../../../../../components/rxLibrary/modals";
import { DrugModal } from "../../../../../../../components/DrugModal";
import { CompareCardDetailsNdc } from "./CompareCardDetailsNdc";
import { CompareCardDetailsTeeCode } from "./CompareCardDetailsTeeCode";
import { CompareCardDetailsSuppliers } from "./CompareCardDetailsSuppliers";

function formatHistoricalOrdering({
  lastOrdered,
  lastSupplier,
  lastInvoicePrice,
}: HistoricalOrderingInfo) {
  let dateStr = "";
  const date = dayjs(lastOrdered);
  if (date.isSame(new Date(), "day") || date.isAfter(new Date(), "day")) {
    dateStr = "today";
  } else if (date.isSame(dayjs().subtract(1, "day"), "day")) {
    dateStr = "yesterday";
  } else {
    dateStr = dayjsWithDuration.duration(date.diff(new Date())).humanize(true);
  }

  let priceStr;
  if (Number.isFinite(lastInvoicePrice)) {
    const price =
      currencyWithCentsUnlessGreaterThanOrEqualToOneThousand(lastInvoicePrice);
    priceStr = ` for ${price}`;
  }

  return `Last invoiced at ${lastSupplier} ${dateStr}${priceStr}`;
}

export function CompareCardDetails({
  item,
  openPanel,
  ndcOffering,
  ndcOfferings,
  shippingPanel,
  showTeeCode,
}: {
  item: TaggedItem;
  openPanel: boolean;
  shippingPanel: boolean;
  ndcOffering: NdcOffering;
  ndcOfferings: NdcOfferingSet;
  showTeeCode: boolean;
}) {
  const { isModalOpen, openModal, closeModal } = useModal();

  const { drugInfo, historicalOrderingInfo } = item;
  const { ndc, teeCode, imageURI, manufacturer } = drugInfo;

  const historicalOrdering = useMemo(() => {
    if (!historicalOrderingInfo) return;
    return formatHistoricalOrdering(historicalOrderingInfo);
  }, [historicalOrderingInfo]);

  return (
    <>
      <div tw="flex flex-row h-[100%]">
        <div tw="flex flex-col justify-between h-[100%]">
          <Image
            src={imageURI}
            tw="h-[52px] w-[70px] object-contain cursor-pointer"
            onClick={openModal}
          />

          <AccordionPanel open={shippingPanel}>
            <div tw="flex flex-col" id={`shippingTbl${ndc}`}>
              <div
                tw="text-[13px] text-right max-w-[56px] min-w-[56px] pt-[10px]"
                className="tbl-info"
              >
                <p>Overnight</p>
                <p>Min.</p>
                <p>Order</p>
              </div>
              <div
                tw="text-[13px] text-right max-w-[56px] min-w-[56px] pt-[10px]"
                className="tbl-info"
              >
                <p>Cutoff</p>
              </div>
              <div
                tw="text-[13px] text-right max-w-[56px] min-w-[56px] pt-[10px]"
                className="tbl-info"
              >
                <p>Notes</p>
              </div>
            </div>
          </AccordionPanel>
        </div>

        <div tw="ml-4 flex flex-col justify-between w-[100%]">
          <div>
            <DispensedItem
              tw="font-medium text-lg"
              ndc={false}
              drug={drugInfo}
              showQuantityDescription
              onTitleClick={openModal}
            />

            <div tw="font-medium text-lg" title={manufacturer}>
              Manufacturer: {manufacturer}
            </div>

            <div tw="flex items-center space-x-3">
              <CompareCardDetailsNdc ndc={ndc} />
              {showTeeCode && <CompareCardDetailsTeeCode teeCode={teeCode} />}
            </div>
          </div>

          {!!historicalOrdering && (
            <p tw="font-medium text-base mt-[12px] leading-[24px]">
              {historicalOrdering}
            </p>
          )}

          <div id={`suppliersPanel${ndcOffering.item.drugInfo.ndc}`} tw="mt-4">
            <CompareCardDetailsSuppliers
              featured={ndcOffering}
              shippingPanel={openPanel}
              alternatives={ndcOfferings.alternatives}
            />
          </div>
        </div>
      </div>

      {isModalOpen && <DrugModal drugInfo={drugInfo} onClose={closeModal} />}
    </>
  );
}
