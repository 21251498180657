import semver from "semver";

export function displayIfWide(text: string, upToScreenWidth?: number, screenWidth?: number) {
  if (upToScreenWidth && screenWidth && screenWidth > upToScreenWidth) {
    return text;
  }
  return "";
}
export function trunc(text: string, number: number, upToScreenWidth?: number, screenWidth?: number) {
  if (upToScreenWidth && screenWidth && screenWidth > upToScreenWidth) {
    return text;
  }
  return text.length > number ? text.substring(0, number) + "..." : text;
}


const MINIMUM_CLIENT_VERSIONS_FOR_SUPPLIERS: Record<string, string> = {
  "IPD": "0.4.24",
  "SOUTH_POINTE": "0.3.64",
  "MCKESSON": "0.4.34",
  "MCKESSON_SPECIALTY": "0.4.34",
  "HEALTHSOURCE": "0.3.76",
  "KINRAY": "0.4.32",
}

export function supplierIsDisabledInEnhancedClient(supplierName: string, enhancedClientVersion: string | null): boolean {
  if (!enhancedClientVersion) {
    return false;
  }

  const minimumVersion = MINIMUM_CLIENT_VERSIONS_FOR_SUPPLIERS[supplierName];
  return !!(minimumVersion && semver.lt(enhancedClientVersion, minimumVersion));
}
