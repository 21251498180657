import { useMemo } from "react";
import {
  getAdminPharmacy,
  updateAdminPharmacy,
} from "../../../../../services/pharmacies";
import { formatBeDateToDate } from "../../../../../utilities/dates/formatBeDateToDate";
import { useEditForm } from "../../../../../components/antd/form/useEditForm";
import { WideContainer } from "../../../../../components/containers/WideContainer";
import { AdminPharmacyForm } from "./AdminPharmacyForm/AdminPharmacyForm";

export function EditAdminPharmacy() {
  const { values, onSubmit } = useEditForm({
    name: "pharmacy",
    update: updateAdminPharmacy,
    fetchValues: getAdminPharmacy,
  });

  const { initialValues, changeLogNotes } = useMemo(() => {
    if (!values) return {};
    const {
      changeLogNotes,
      deaLicenseExpiryDate,
      bopLicenseExpiryDate,
      ...initialValues
    } = values;

    return {
      initialValues: {
        ...initialValues,
        deaLicenseExpiryDate: formatBeDateToDate(deaLicenseExpiryDate),
        bopLicenseExpiryDate: formatBeDateToDate(bopLicenseExpiryDate),
      },
      changeLogNotes,
    };
  }, [values]);

  return (
    <WideContainer tw="pb-[200px]">
      <AdminPharmacyForm
        isUpdateForm
        initialValues={initialValues}
        changeLogNotes={changeLogNotes}
        onSubmit={onSubmit}
      />
    </WideContainer>
  );
}
