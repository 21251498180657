import type { PharmacyStatus } from "../../../../../utilities/types";
import { createAdminPharmacy } from "../../../../../services/pharmacies";
import { useCreateForm } from "../../../../../components/antd/form/useCreateForm";
import { WideContainer } from "../../../../../components/containers/WideContainer";
import { AdminPharmacyForm } from "./AdminPharmacyForm/AdminPharmacyForm";

const DEFAULT_INITIAL = {
  isSynced: true,
  usePmsSchema: true,
  syncLibertyInventory: false,
  pharmacyStatus: "ACTIVE" as PharmacyStatus,
  isIncludedInManufacturerReports: true,
  justworksAccountAndEinSharedAcrossPharmacies: false,
  supplierScope: "All",
  timezone: "America/New_York",
};

export function CreateAdminPharmacy() {
  const onSubmit = useCreateForm({
    redirectTo: "/admin/pharmacy",
    create: createAdminPharmacy,
  });

  return (
    <WideContainer tw="pb-[200px]">
      <AdminPharmacyForm initialValues={DEFAULT_INITIAL} onSubmit={onSubmit} />
    </WideContainer>
  );
}
