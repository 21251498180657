import sumBy from "lodash/sumBy";
import { AgGridReact } from "ag-grid-react";
import type { GridOptions, GridReadyEvent } from "ag-grid-community";
import { ROW_CLASS_RULES } from "../utils/reportsConstants";
import { ReportWrapper } from "../utils/ReportWrapper";
import { useReport } from "../utils/useReport/useReport";
import { setupPinnedTopRowData } from "../utils/setupPinnedTopRowData";
import {
  ATTEMPTED_FILLS_EXPORT_CSV_CONFIG,
  MISSED_OPPORTUNITIES_EXPORT_CSV_CONFIG,
} from "../utils/csv/csv.constants";
import { COLUMN_DEFS } from "./NonAdherentRefillOutreachIncyte.constants";
import { NonAdherentRefillOutreachMayneDetailsCellRendered } from "./NonAdherentRefillOutreachIncyteDetailsCellRendered/NonAdherentRefillOutreachIncyteDetailsCellRendered";
import {
  ATTEMPTED_FILLS_COLUMN_DEFS,
  MISSED_FILLS_COLUMN_DEFS,
} from "./NonAdherentRefillOutreachIncyteDetailsCellRendered/NonAdherentRefillOutreachIncyteDetailsCellRendered.constants";

const getRowStyle: GridOptions["getRowStyle"] = (params) => {
  if (params?.data?.pinnedRow) return { "font-weight": "bold" };
  return undefined;
};

function getPinnedRowData(data?: any[]) {
  if (!data?.length) return;

  const nonAdherentRefillOpportunitiesSum = sumBy(
    data,
    "DBT Total Opportunities (90-180 Days Past Due)"
  );
  const expiredRefillOpportunitiesSum = sumBy(
    data,
    "DBT Expired Opportunities (90-180 Days Past Due)"
  );
  const attemptedOutreachesSum = sumBy(
    data,
    "DBT Attempted Outreaches (90-180 Days Past Due)"
  );
  const unattemptedOutreachesSum =
    nonAdherentRefillOpportunitiesSum - attemptedOutreachesSum;

  const dispensedRefillsSum = sumBy(
    data,
    "Dispensed Refills (90-180 Days Past Due)"
  );

  const nonAdherentRefillOpportunities365Sum = sumBy(
    data,
    "DBT Total Opportunities"
  );
  const expiredRefillOpportunities365Sum = sumBy(
    data,
    "DBT Expired Opportunities"
  );
  const attemptedOutreaches365Sum = sumBy(
    data,
    "Attempted Outreaches (of Non-Adherent Refill Opportunity)"
  );
  const unattemptedOutreaches365Sum = sumBy(
    data,
    "DBT Unattempted Opportunities"
  );

  const dispensedRefills365Sum = sumBy(data, "Dispensed Refills");
  const abandonedTransferredRefills365Sum = sumBy(
    data,
    "Abandoned/Transferred Refills"
  );
  const inProgressRefills365Sum = sumBy(data, "In-Progress Refills");

  const percentInProgressRefills365Sum =
    nonAdherentRefillOpportunities365Sum === 0
      ? 0
      : inProgressRefills365Sum / nonAdherentRefillOpportunities365Sum;
  const percentDispensedRefills365Sum =
    nonAdherentRefillOpportunities365Sum === 0
      ? 0
      : dispensedRefills365Sum / nonAdherentRefillOpportunities365Sum;
  const percentAttemptedOutreaches365Sum =
    nonAdherentRefillOpportunities365Sum === 0
      ? 0
      : attemptedOutreaches365Sum / nonAdherentRefillOpportunities365Sum;

  const percentageAttemptedSum =
    nonAdherentRefillOpportunitiesSum === 0
      ? 0
      : attemptedOutreachesSum / nonAdherentRefillOpportunitiesSum;

  const percentageDispensedOfAttempted =
    attemptedOutreachesSum === 0
      ? 0
      : dispensedRefillsSum / attemptedOutreachesSum;
  const percentageDispensedOfOpportunity =
    nonAdherentRefillOpportunitiesSum === 0
      ? 0
      : dispensedRefillsSum / nonAdherentRefillOpportunitiesSum;

  const abandonedTransferredRefillsSum = sumBy(
    data,
    "Abandoned/Transferred Refills (90-180 Days Past Due)"
  );
  const inProgressRefillsSum = sumBy(
    data,
    "In-Progress Refills (90-180 Days Past Due)"
  );
  const percentageInProgressOfOpportunity =
    nonAdherentRefillOpportunitiesSum === 0
      ? 0
      : inProgressRefillsSum / nonAdherentRefillOpportunitiesSum;

  return [
    {
      pivot: false,
      pinnedRow: true,
      "DBT Total Opportunities (90-180 Days Past Due)":
        nonAdherentRefillOpportunitiesSum,
      "DBT Attempted Outreaches (90-180 Days Past Due)": attemptedOutreachesSum,
      "DBT Unattempted Opportunities (90-180 Days Past Due)":
        unattemptedOutreachesSum,
      "% Attempted (of DBT Total Opportunities 90-180 Days Past Due)":
        percentageAttemptedSum,
      "Dispensed Refills (90-180 Days Past Due)": dispensedRefillsSum,
      "% Dispensed (of DBT Attempted Outreaches 90-180 Days Past Due)":
        percentageDispensedOfAttempted,
      "% Dispensed (of DBT Total Opportunities 90-180 Days Past Due)":
        percentageDispensedOfOpportunity,
      "% In Progress (of DBT Total Opportunities 90-180 Days Past Due)":
        percentageInProgressOfOpportunity,
      "Abandoned/Transferred Refills (90-180 Days Past Due)":
        abandonedTransferredRefillsSum,
      "In-Progress Refills (90-180 Days Past Due)": inProgressRefillsSum,
      "DBT Total Opportunities": nonAdherentRefillOpportunities365Sum,
      "Attempted Outreaches (of Non-Adherent Refill Opportunity)":
        attemptedOutreaches365Sum,
      "DBT Unattempted Opportunities": unattemptedOutreaches365Sum,
      "Dispensed Refills": dispensedRefills365Sum,
      "Abandoned/Transferred Refills": abandonedTransferredRefills365Sum,
      "In-Progress Refills": inProgressRefills365Sum,
      "% In Progress (of DBT Total Opportunities)":
        percentInProgressRefills365Sum,
      "% Dispensed (of DBT Total Opportunities)": percentDispensedRefills365Sum,
      "% Attempted (of DBT Total Opportunities)":
        percentAttemptedOutreaches365Sum,
      "DBT Expired Opportunities": expiredRefillOpportunities365Sum,
      "DBT Expired Opportunities (90-180 Days Past Due)":
        expiredRefillOpportunitiesSum,
    },
  ];
}

const MULTIPLE_DOWNLOAD_CSV_WITH_CHILDREN_BUTTONS_CONFIG = [
  {
    ...ATTEMPTED_FILLS_EXPORT_CSV_CONFIG,
    colDefs: ATTEMPTED_FILLS_COLUMN_DEFS.flatMap((col: any) => col?.children),
  },
  {
    ...MISSED_OPPORTUNITIES_EXPORT_CSV_CONFIG,
    colDefs: MISSED_FILLS_COLUMN_DEFS.flatMap((col: any) => col?.children),
  },
];

function onGridReady(event: GridReadyEvent) {
  setupPinnedTopRowData(event, getPinnedRowData);
}

export function NonAdherentRefillOutreachIncyte90To180({
  data,
}: {
  data: never[];
}) {
  const report = useReport({
    onGridReady,
    columnDefs: COLUMN_DEFS,
    multipleDownloadCsvWithChildrenButtonsConfig:
      MULTIPLE_DOWNLOAD_CSV_WITH_CHILDREN_BUTTONS_CONFIG,
  });

  return (
    <ReportWrapper>
      <AgGridReact
        {...report}
        masterDetail
        detailRowAutoHeight
        pagination
        rowData={data}
        rowSelection="multiple"
        rowClassRules={ROW_CLASS_RULES}
        detailRowHeight={400}
        detailCellRenderer={NonAdherentRefillOutreachMayneDetailsCellRendered}
        rowGroupPanelShow="always"
        getRowStyle={getRowStyle}
      />
    </ReportWrapper>
  );
}
