import {
  DrugInfo,
  RequestState,
  SuggestedNdc,
  NDCTaggedItemList,
} from "../../utilities/types";

export enum QuoteStateErrorDetailEnum {
  NOT_FOUND = "No quotes found",
  GENERIC_ERROR = "An error occurred while fetching quotes",
}

export type QuoteState = {
  traceId: string;
  suggestedNDC: SuggestedNdc | null;
  itemListsByNDC: NDCTaggedItemList[];
  quoteRequestState: RequestState;
  relatedDrugsByNDC: Record<string, DrugInfo>;
  errorDetail?: QuoteStateErrorDetailEnum;
  suggestedDrug?: DrugInfo;
};
