import React from "react";
import EditOutlined from "@ant-design/icons/EditOutlined";
import { Link } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import keyBy from "lodash/keyBy";
import type {
  AdminSupplierMapping,
  AdminSupplierMappingOptions,
} from "../../../../../utilities/types";
import { useRequest } from "../../../../../services/request/useRequest";
import {
  getAdminSupplierMappingOptions,
  getAdminSupplierMappings,
} from "../../../../../services/supplierMappings";
import {
  Button,
  IconButton,
} from "../../../../../components/rxLibrary/buttons";
import { getNumberSorter } from "../../../../../components/antd/table/getNumberSorter";
import { useTableColumnsWithOptions } from "../../../../../components/antd/table/useTableColumnsWithOptions";
import { renderAdminSupplier } from "../../../../../components/antd/table/renderAdminSupplier";
import { renderBoolean } from "../../../../../components/antd/table/renderBoolean";
import {
  getBooleanFilter,
  YES_NO_BOOLEAN_FILTERS,
  YES_NO_NONE_BOOLEAN_FILTERS,
} from "../../../../../components/antd/table/getBooleanFilter";
import { getTextSorter } from "../../../../../components/antd/table/getTextSorter";
import { TitleWithTooltip } from "../../../../../components/antd/table/TitleWithTooltip";
import { renderJson } from "../../../../../components/antd/table/renderJson";
import { TableColumns } from "../../../../../components/antd/table/table.constants";
import { renderAdminPharmacy } from "../../../../../components/antd/table/renderAdminPharmacy";
import { AdminManagementTable } from "../components/AdminManagementTable";
import { AdminManagementHeader } from "../components/AdminManagementHeader";
import { IconPlus } from "../../../../../components/rxLibrary/icons";
import { Text } from "../../../../../components/rxLibrary/typography";
import { useColumnsWithQueryParamsFilters } from "../../../../../components/antd/table/useColumnsWithQueryParamsFilters";
import { ViewChangeLogNotes } from "../components/ViewChangeLogNotes";

const BREADCRUMBS = [{ title: "Supplier Mapping" }];

const COLUMNS: TableColumns<AdminSupplierMapping> = [
  {
    dataIndex: "id",
    title: "ID",
    width: 60,
    fixed: "left",
    sorter: getNumberSorter("id"),
  },
  {
    dataIndex: "pharmacyId",
    title: "Pharmacy ID",
    width: 110,
    sorter: getNumberSorter("pharmacyId"),
  },
  {
    dataIndex: "pharmacy",
    title: "Pharmacy",
    width: 150,
    sorter: getTextSorter("pharmacy.name"),
    render: renderAdminPharmacy,
  },
  {
    dataIndex: "supplierId",
    title: "Supplier ID",
    width: 110,
    sorter: getNumberSorter("supplierId"),
  },
  {
    dataIndex: "supplier",
    title: "Supplier",
    width: 150,
    sorter: getTextSorter("supplier.name"),
    render: renderAdminSupplier,
  },
  {
    dataIndex: "isActive",
    title: "Is Active",
    width: 110,
    filters: [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ],
    onFilter: getBooleanFilter("isActive"),
    sorter: getNumberSorter("isActive"),
    render: renderBoolean,
  },
  {
    dataIndex: "customerAccountId",
    title: (
      <TitleWithTooltip
        title="Customer Account ID"
        tooltip="The supplier's account number for the pharmacy"
      />
    ),
    width: 140,
  },
  {
    dataIndex: "billToPharmacy",
    title: "Bill To Pharmacy",
    width: 140,
  },
  {
    dataIndex: "defaultSupplierShippingConfig",
    title: "Default Supplier Shipping Config",
    width: 250,
  },
  {
    dataIndex: "shippingConfig",
    title: (
      <TitleWithTooltip
        title="Shipping Config Override"
        tooltip="Min ship and cutoff time override from default. UI will be enhanced soon"
      />
    ),
    width: 250,
    render: renderJson,
  },
  {
    dataIndex: "suppliesSchedule2",
    title: (
      <TitleWithTooltip
        title="Allow C2s"
        tooltip="Should we filter out controls for this pharmacy-supplier C2"
      />
    ),
    width: 130,
    filters: YES_NO_BOOLEAN_FILTERS,
    onFilter: getBooleanFilter("suppliesSchedule2"),
    render: renderBoolean,
  },
  {
    dataIndex: "suppliesSchedule3",
    title: (
      <TitleWithTooltip
        title="Allow C3s"
        tooltip="Should we filter out controls for this pharmacy-supplier C3"
      />
    ),
    width: 130,
    filters: YES_NO_BOOLEAN_FILTERS,
    onFilter: getBooleanFilter("suppliesSchedule3"),
    render: renderBoolean,
  },
  {
    dataIndex: "suppliesSchedule4",
    title: (
      <TitleWithTooltip
        title="Allow C4s"
        tooltip="Should we filter out controls for this pharmacy-supplier C4"
      />
    ),
    width: 130,
    filters: YES_NO_BOOLEAN_FILTERS,
    onFilter: getBooleanFilter("suppliesSchedule4"),
    render: renderBoolean,
  },
  {
    dataIndex: "suppliesSchedule5",
    title: (
      <TitleWithTooltip
        title="Allow C5s"
        tooltip="Should we filter out controls for this pharmacy-supplier C5"
      />
    ),
    width: 130,
    filters: YES_NO_BOOLEAN_FILTERS,
    onFilter: getBooleanFilter("suppliesSchedule5"),
    render: renderBoolean,
  },
  {
    dataIndex: "rebatedItemDiscount",
    title: (
      <TitleWithTooltip
        title="Generic Item Discount in v2"
        tooltip="% Discount that the optimization should consider. Range of 0-1"
      />
    ),
    width: 160,
    sorter: getNumberSorter("rebatedItemDiscount"),
  },

  {
    dataIndex: "brandItemPenalty",
    title: (
      <TitleWithTooltip
        title="Brand Item Penalty in v2"
        tooltip="% Penalty for a brand"
      />
    ),
    width: 150,
    sorter: getNumberSorter("brandItemPenalty"),
  },
  {
    dataIndex: "notifyAlternativeSuppliersForBrand",
    title: (
      <TitleWithTooltip
        title="Notify Alternative Suppliers For Brand"
        tooltip="Typically for a primary where there's a GCR. This shows when there are alternatives for brand items on P3. Helpful for primary suppliers where brand purchases can be moved to another supplier"
      />
    ),
    width: 210,
    filters: YES_NO_BOOLEAN_FILTERS,
    onFilter: getBooleanFilter("notifyAlternativeSuppliersForBrand"),
    sorter: getNumberSorter("notifyAlternativeSuppliersForBrand"),
    render: renderBoolean,
  },
  {
    dataIndex: "scraperConfig",
    title: (
      <TitleWithTooltip
        title="Scraper Config"
        tooltip="Should we filter out controls for this pharmacy-supplier C2"
      />
    ),
    width: 250,
    render: renderJson,
  },
  {
    dataIndex: "alwaysReturnSeedDrugOverride",
    title: (
      <TitleWithTooltip
        title="Always Return Seed Drug Override"
        tooltip="Typically true for primary suppliers. This means to look up every NDC in Electron regardless of catalog"
      />
    ),
    width: 180,
    filters: YES_NO_NONE_BOOLEAN_FILTERS,
    onFilter: getBooleanFilter("alwaysReturnSeedDrugOverride"),
    render: renderBoolean,
  },
  {
    dataIndex: "ecommerceEnabled",
    title: (
      <TitleWithTooltip
        title="Ecommerce Enabled"
        tooltip="Ecommerce vs offline supplier option"
      />
    ),
    width: 140,
    filters: [
      { text: "Enabled", value: true },
      { text: "Disabled", value: false },
    ],
    sorter: getNumberSorter("ecommerceEnabled"),
    onFilter: getBooleanFilter("ecommerceEnabled"),
    render: renderBoolean,
  },
  {
    dataIndex: "hasDirectDeal",
    title: (
      <TitleWithTooltip
        title="Has Direct Deal"
        tooltip="Part of bundle-based deal for manufacturer direct"
      />
    ),
    width: 150,
    filters: YES_NO_BOOLEAN_FILTERS,
    onFilter: getBooleanFilter("hasDirectDeal"),
    sorter: getNumberSorter("hasDirectDeal"),
    render: renderBoolean,
  },
  {
    dataIndex: "catalogSupplierIdOverride",
    title: (
      <TitleWithTooltip
        title="Catalog Supplier ID Override"
        tooltip="Set this if the EDI catalog is actually a different supplier's catalog. Rarely used"
      />
    ),
    width: 170,
  },
  {
    dataIndex: "catalogSupplierOverride",
    title: (
      <TitleWithTooltip
        title="Catalog Supplier Override"
        tooltip="Set this if the EDI catalog is actually a different supplier's catalog. Rarely used"
      />
    ),
    width: 160,
    render: renderAdminSupplier,
  },
  {
    dataIndex: "catalogSupplierId",
    title: (
      <TitleWithTooltip
        title="Catalog Supplier ID"
        tooltip="Supplier used for EDI catalog"
      />
    ),
    width: 130,
  },
  {
    dataIndex: "catalogSupplier",
    title: (
      <TitleWithTooltip
        title="Catalog Supplier"
        tooltip="Supplier used for EDI catalog"
      />
    ),
    width: 150,
    render: renderAdminSupplier,
  },
  {
    dataIndex: "pharmacyIsaCode",
    title: "Pharmacy ISA Code",
    width: 150,
  },
  {
    dataIndex: "supplierIsaCode",
    title: "Supplier ISA Code",
    width: 140,
  },
  {
    dataIndex: "changeLogNotes",
    title: "Change Log Notes",
    width: 160,
    render: (changeLogNotes: string[]) => (
      <ViewChangeLogNotes changeLogNotes={changeLogNotes} small />
    ),
  },
  {
    key: "actions",
    dataIndex: "id",
    title: "Actions",
    width: 80,
    fixed: "right",
    render: (id) => (
      <div>
        <Link to={`/admin/supplier-mapping/${id}`}>
          <IconButton>
            <EditOutlined
              rev={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            />
          </IconButton>
        </Link>
      </div>
    ),
  },
];

function columnFormatter(dataIndex: string, options: AdminSupplierMappingOptions) {
  const { defaultSupplierShippingConfig, pharmacy: pharmacies } = options;
  const pharmaciesById = keyBy(pharmacies, "id");

  if (dataIndex === "defaultSupplierShippingConfig") {
    return {
      render: (_: undefined, record: AdminSupplierMapping) => {
        const defaultShippingConfig =
          defaultSupplierShippingConfig[record.supplierId?.toString()];
        const hasShippingConfigOverride = !isEmpty(record.shippingConfig);
        const children = renderJson(defaultShippingConfig);

        if (hasShippingConfigOverride && defaultShippingConfig) {
          return (
            <>
              <Text tw="mb-2" color="grey-2" block>
                {children}
              </Text>

              <Text color="yellow-1" block>
                Warning: Shipping Config Overwritten
              </Text>
            </>
          );
        }

        return children;
      },
    };
  }

  if (dataIndex === "supplier" || dataIndex === "pharmacy") {
    const filters = options[dataIndex].map((v) => ({
      text: `${v.name} - ${v.id}`,
      value: v.id,
    }));
    const onFilter = (value: any, record: any) => {
      return record[dataIndex]?.id.toString() === value.toString();
    };
    return { filters, filterSearch: true, onFilter };
  }

  if (dataIndex === "billToPharmacy") {
    return {
      render: (_: undefined, record: AdminSupplierMapping) => {
        const billToPharmacyId = record.billToPharmacyId;
        const pharmacy = billToPharmacyId
          ? pharmaciesById[billToPharmacyId]
          : null;
        return renderAdminPharmacy(pharmacy);
      },
    };
  }
}

export function AdminSupplierMappings() {
  const { data, error, isLoading } = useRequest({
    dataKey: "supplierMappings",
    request: getAdminSupplierMappings,
  });

  const columns = useTableColumnsWithOptions({
    dataKey: "supplierMappingOptions",
    columns: COLUMNS,
    request: getAdminSupplierMappingOptions,
    columnFormatter,
  });

  const { columnsWithFilters, onTableChange } =
    useColumnsWithQueryParamsFilters(columns);

  return (
    <>
      <AdminManagementHeader breadcrumbs={BREADCRUMBS}>
        <Link to="/admin/supplier-mapping/new">
          <Button icon={<IconPlus />}>Add Supplier Mapping</Button>
        </Link>
      </AdminManagementHeader>

      <AdminManagementTable
        columns={columnsWithFilters}
        dataSource={data}
        loading={isLoading}
        hasError={!!error}
        onChange={onTableChange}
      />
    </>
  );
}
