import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import uniq from "lodash/uniq";
import type { QuoteState } from "../Compare.constants";
import {
  DrugInfo,
  Pharmacy,
  RequestState,
  SuggestedNdc,
} from "../../../utilities/types";
import {
  disableV1EnhancementsForSupplier,
  getDispensedText,
} from "../../../utilities/search";
import { useBuyingPharmacy } from "../../../contexts/BuyingPharmacyContext";
import { EnhancedSupplierConnectionStatus } from "../../../enhanced/types";
import EnhancedClientContext, {
  EnhancedStockValidationAvailability,
  RequestEnhanceItemParam,
} from "../../../enhanced/EnhancedClientContext";
import { drugInfoToDrugNameUOMSizeStr } from "../../../utilities/drugInfo/drugInfoToDrugNameUOMSizeStr";
import EnhancedClientCommandContext from "../../../enhanced/EnhancedClientCommandContext";
import EnhancedSupplierLoginInterstitial from "../../../enhanced/EnhancedSupplierLoginInterstitial";
import { CompareSearchBar } from "./CompareSearchBar";
import { useCompare } from "../useCompare/useCompare";
import { CompareResults } from "./CompareResults/CompareResults";
import { CompareQuoteStateError } from "./CompareQuoteStateError";

function toDrugNameUOMSizeStr(
  suggestedNDC: SuggestedNdc | null,
  relatedDrugsByNDC: Record<string, DrugInfo>
): string {
  if (!suggestedNDC) {
    return "";
  }
  const searchDrug = relatedDrugsByNDC[suggestedNDC.ndc];
  if (!searchDrug) return "";

  return drugInfoToDrugNameUOMSizeStr(searchDrug);
}

function toEnhancementRequestList(
  quoteState: QuoteState,
  enhancedSupplierConnectionStatuses: Record<
    string,
    EnhancedSupplierConnectionStatus
  >,
  currentBuyingPharmacy: Pharmacy | null
): RequestEnhanceItemParam[] {
  if (!currentBuyingPharmacy) {
    return [];
  }

  const listsByNdc = quoteState.itemListsByNDC;
  const suppliersRequested = new Set<string>();
  const requests: RequestEnhanceItemParam[] = listsByNdc
    .map((v) => {
      return v.items.map((i) => {
        suppliersRequested.add(i.catalogInfo.supplier);
        return {
          ndc: i.drugInfo.ndc,
          supplier: i.catalogInfo.supplier,
          price: i.catalogInfo.price,
          drugNameUOMSizeStr: toDrugNameUOMSizeStr(
            quoteState.suggestedNDC,
            quoteState.relatedDrugsByNDC
          ),
          supplierItemNumbers: i.catalogInfo.supplierItemNumber
            ? [i.catalogInfo.supplierItemNumber]
            : [],
        };
      });
    })
    .flat()
    .filter((item) => {
      return (
        !!enhancedSupplierConnectionStatuses[item.supplier] &&
        !enhancedSupplierConnectionStatuses[item.supplier]
          .enhancementsDisabled &&
        !disableV1EnhancementsForSupplier(item.supplier)
      );
    });

  return requests;
}

function anySuppliersUnskipped(oldSkips: string[], newSkips: string[]) {
  return !oldSkips.every((x) => {
    return newSkips.includes(x);
  });
}

export function EnhancedCompare() {
  const {
    ndc,
    from,
    drugName,
    quoteState,
    enhancementRequestState,
    setEnhancementRequestState,
  } = useCompare();

  const { currentBuyingPharmacy } = useBuyingPharmacy();
  const { showMain } = useContext(EnhancedClientCommandContext);
  const {
    requestEnhanceItems,
    requestAppendEnhanceItems,
    enhancedSupplierConnectionStatuses,
    currentEnhancementState,
    skippedSuppliers,
    skipSupplier,
  } = useContext(EnhancedClientContext);
  const [forceTransition, setForceTransition] = useState(false);
  const [
    supplierConnectionStatusUpdateTime,
    setSupplierConnectionStatusUpdateTime,
  ] = useState(Date.now());

  const onLoginsFinished = useCallback(() => {
    showMain();
  }, [showMain]);

  const onInterstitialComplete = useCallback(() => {
    console.log("interstitial onComplete");
  }, []);

  const onInterstitialTimeout = useCallback(() => {
    console.log("enhancements timeout triggered; forcing transition, ndc", ndc);
    setForceTransition(true);
  }, [ndc, setForceTransition]);

  useEffect(() => {
    setSupplierConnectionStatusUpdateTime(Date.now);
    setTimeout(() => {
      setSupplierConnectionStatusUpdateTime(Date.now);
    }, 5000);
  }, [enhancedSupplierConnectionStatuses]);

  const skippedSupplierList = useMemo(() => {
    return Object.keys(skippedSuppliers);
  }, [skippedSuppliers]);

  const limitLoginsTo = useMemo(() => {
    console.log("limitLoginsTo", {
      enhancedSupplierConnectionStatuses,
      skippedSuppliers,
    });
    return uniq(
      Object.keys(enhancedSupplierConnectionStatuses)
        .filter(
          (s) => !enhancedSupplierConnectionStatuses[s].enhancementsDisabled
        )
        .filter(
          (s) => !disableV1EnhancementsForSupplier(s) && !skippedSuppliers[s]
        )
    );
  }, [enhancedSupplierConnectionStatuses, skippedSuppliers]);

  const loggedInToAllSuppliers = useMemo(() => {
    console.log("Checking loggedInToAllSuppliers", {
      limitLoginsTo,
      enhancedSupplierConnectionStatuses,
    });
    return limitLoginsTo.every(
      (s) =>
        enhancedSupplierConnectionStatuses[s].loggedIn &&
        enhancedSupplierConnectionStatuses[s]._updatedAt <
          supplierConnectionStatusUpdateTime - 4000
    );
  }, [
    limitLoginsTo,
    enhancedSupplierConnectionStatuses,
    supplierConnectionStatusUpdateTime,
  ]);

  useEffect(() => {
    if (quoteState.quoteRequestState === RequestState.PENDING) {
      return;
    }
    if (!loggedInToAllSuppliers) {
      return;
    }

    if (currentEnhancementState.traceId !== quoteState.traceId) {
      console.log("Preparing enhancement requests", {
        cesTraceId: currentEnhancementState.traceId,
        qsTraceId: quoteState.traceId,
      });
      const requests: RequestEnhanceItemParam[] = toEnhancementRequestList(
        quoteState,
        enhancedSupplierConnectionStatuses,
        currentBuyingPharmacy
      ).filter((req) => {
        return enhancedSupplierConnectionStatuses[req.supplier].loggedIn;
      });

      if (requests.length > 0) {
        requestEnhanceItems(quoteState.traceId, requests);
        setEnhancementRequestState(RequestState.PENDING);
      } else {
        setEnhancementRequestState(RequestState.COMPLETED);
      }
    } else {
      const requests: RequestEnhanceItemParam[] = toEnhancementRequestList(
        quoteState,
        enhancedSupplierConnectionStatuses,
        currentBuyingPharmacy
      )
        .filter((req) => {
          return enhancedSupplierConnectionStatuses[req.supplier].loggedIn;
        })
        .filter((req) => {
          return (
            currentEnhancementState.suppliersSeen[req.supplier] === undefined
          );
        });

      if (requests.length > 0) {
        console.log("Preparing enhancement request appends", {
          cesTraceId: currentEnhancementState.traceId,
          qsTraceId: quoteState.traceId,
          requests,
          suppliersSeen: currentEnhancementState.suppliersSeen,
        });
        requestAppendEnhanceItems(quoteState.traceId, requests);
      }
    }
  }, [
    currentBuyingPharmacy,
    quoteState,
    currentEnhancementState,
    enhancedSupplierConnectionStatuses,
    loggedInToAllSuppliers,
  ]);

  useEffect(() => {
    if (!loggedInToAllSuppliers) return;
    if (quoteState.quoteRequestState === RequestState.PENDING) return;
    if (enhancementRequestState === RequestState.UNINITIATED) return;
    if (currentEnhancementState.requestBatch.length === 0) return;

    console.log("Checking enhancement state");
    console.log("connectionStatuses", enhancedSupplierConnectionStatuses);
    console.log("items", currentEnhancementState.enhancedSupplierItems);
    console.log("requestsCompleted", currentEnhancementState.requestsCompleted);
    let requestsProcessed = 0;
    currentEnhancementState.requestBatch.forEach((req) => {
      console.log("Checking", req);
      if (
        currentEnhancementState.requestsCompleted &&
        currentEnhancementState.requestsCompleted[req.supplier]
      ) {
        requestsProcessed += 1;
        console.log(
          `ok completed; ${requestsProcessed}/${currentEnhancementState.requestBatch.length}`
        );
      } else {
        const supplierResults =
          currentEnhancementState.enhancedSupplierItems[req.supplier];
        if (!supplierResults) {
          console.log("No supplierResults");
          return;
        }
        const ndcResult = supplierResults[req.ndc];
        if (!ndcResult) {
          console.log("No ndcResult");
          return;
        }
        if (
          ndcResult.availability === EnhancedStockValidationAvailability.PENDING
        ) {
          console.log("Pending availability");
          return;
        }
        requestsProcessed += 1;
        console.log(
          `ok found; ${requestsProcessed}/${currentEnhancementState.requestBatch.length}`,
          ndcResult
        );
      }
    });
    if (requestsProcessed === currentEnhancementState.requestBatch.length) {
      console.log("All requests processed!", quoteState.itemListsByNDC);
      console.log("SETTING COMPLETED via PROCESSED");
      setEnhancementRequestState(RequestState.COMPLETED);
    } else {
      console.log(
        "Processed",
        requestsProcessed,
        "of",
        currentEnhancementState.requestBatch.length,
        "requests"
      );
    }
  }, [
    loggedInToAllSuppliers,
    quoteState,
    enhancementRequestState,
    currentEnhancementState,
    enhancedSupplierConnectionStatuses,
  ]);

  useEffect(() => {
    if (enhancementRequestState === RequestState.PENDING && forceTransition) {
      setEnhancementRequestState(RequestState.COMPLETED);
      setForceTransition(false);
    }
  }, [enhancementRequestState, forceTransition]);

  const skippedSupplierListTracker = useRef<string[]>([]);
  const prevNdc = useRef<string | null>(null);
  const [shouldShowResults, setShouldShowResults] = useState(false);

  useEffect(() => {
    console.log("DEBUG Checking shouldShowResults", {
      skippedSupplierListTracker: skippedSupplierListTracker.current,
      skippedSupplierList,
      loggedInToAllSuppliers,
    });
    if (
      anySuppliersUnskipped(
        skippedSupplierListTracker.current,
        skippedSupplierList
      ) &&
      !loggedInToAllSuppliers
    ) {
      console.log("DEBUG Setting shouldShowResults to false");
      setShouldShowResults(false);
    }
    if (prevNdc.current !== ndc) {
      console.log("DEBUG Setting shouldShowResults to false (ndc change)");
      setShouldShowResults(false);
    }
    skippedSupplierListTracker.current = skippedSupplierList;
    prevNdc.current = ndc;
  }, [skippedSupplierList, loggedInToAllSuppliers, ndc]);

  useEffect(() => {
    if (
      loggedInToAllSuppliers &&
      enhancementRequestState === RequestState.COMPLETED
    ) {
      console.log("DEBUG Setting shouldShowResults to true");
      setShouldShowResults(true);
    }
  }, [loggedInToAllSuppliers, enhancementRequestState]);

  return (
    <>
      <CompareSearchBar
        dispensed={quoteState.quoteRequestState === RequestState.COMPLETED}
        dispensedValue={
          quoteState.quoteRequestState === RequestState.COMPLETED &&
          quoteState.itemListsByNDC[0]
            ? getDispensedText(quoteState.itemListsByNDC[0].items)
            : null
        }
        textSearch={from === "selectDrug"}
        ndc={from === "selectDrug" ? drugName || "" : ndc}
        disabled={quoteState.quoteRequestState === RequestState.PENDING}
      />

      <div tw="pt-12">
        {quoteState.quoteRequestState === RequestState.ERROR ||
        enhancementRequestState === RequestState.ERROR ? (
          <CompareQuoteStateError ndc={ndc} errorDetail={quoteState.errorDetail} />
        ) : (
          <>
            {!shouldShowResults && (
              <EnhancedSupplierLoginInterstitial
                key={ndc}
                searchId={ndc}
                expectedMaxEnhancementTimeSeconds={20}
                quoteRequestState={quoteState.quoteRequestState}
                onComplete={onInterstitialComplete}
                onLoginsFinished={onLoginsFinished}
                onCancelSupplierLogin={skipSupplier}
                limitedTo={limitLoginsTo}
                onTimeout={onInterstitialTimeout}
              />
            )}

            {shouldShowResults && (
              <CompareResults
                enhancementExcludedSuppliers={skippedSupplierList}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}
