import type {
  AdminRefillMapping,
  AdminRefillMappingOptions,
} from "../utilities/types";
import { formatDateToBeFormat } from "../utilities/dates/formatDateToBeFormat";
import { requestClient, RequestClientConfig } from "./request/requestClient";

export async function getAdminRefillMappings(config: RequestClientConfig) {
  return requestClient<{ refillMappings: AdminRefillMapping[] }>({
    ...config,
    url: "/admin/refill-mapping",
  });
}

export async function getAdminRefillMappingOptions(
  config: RequestClientConfig
) {
  return requestClient<{
    refillMappingOptions: AdminRefillMappingOptions;
  }>({
    ...config,
    url: "/admin/refill-mapping/options",
  });
}

export async function createAdminRefillMapping({
  pharmacyId,
  manufacturerId,
  reportStartDate: reportStartDateRaw,
  ...config
}: RequestClientConfig<{
  pharmacyId: number;
  manufacturerId: number;
  reportStartDate?: string | null;
}>) {
  const reportStartDate: string | null = formatDateToBeFormat(reportStartDateRaw) ?? null;

  return requestClient<{ data: void }>({
    ...config,
    url: "/admin/refill-mapping",
    method: "POST",
    data: { pharmacyId, manufacturerId, reportStartDate },
  });
}

export async function deleteAdminRefillMapping({
  pharmacyId,
  manufacturerId,
  ...config
}: RequestClientConfig<{
  pharmacyId: number;
  manufacturerId: number;
}>) {
  return requestClient<{ data: void }>({
    ...config,
    url: "/admin/refill-mapping",
    method: "DELETE",
    data: { pharmacyId, manufacturerId },
  });
}
