import { useCallback, useEffect, useState } from "react";
import { useClientPageAlert } from "../../../../../../contexts/ClientPageAlertContext";
import { Button } from "../../../../../../components/rxLibrary/buttons";
import {
  IconEye,
  IconEyeShut,
} from "../../../../../../components/rxLibrary/icons";

export function PageAlertsPreviewButton({ text }: { text: string }) {
  const [displayPageAlertPreview, setDisplayPageAlertPreview] = useState(false);
  const { setClientPageAlert } = useClientPageAlert();

  const togglePageAlertPreview = useCallback(() => {
    setDisplayPageAlertPreview((prev) => !prev);
  }, []);

  useEffect(() => {
    setClientPageAlert(displayPageAlertPreview ? text : undefined);
  }, [text, displayPageAlertPreview, setClientPageAlert]);

  useEffect(() => {
    return () => setClientPageAlert(undefined);
  }, [setClientPageAlert]);

  return (
    <Button
      onClick={togglePageAlertPreview}
      icon={displayPageAlertPreview ? <IconEyeShut /> : <IconEye />}
    >
      Preview Page Alert
    </Button>
  );
}
