import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import posthog from "posthog-js";

export function formatDrugExpirationDate(info?: { expirationDate?: string }) {
  const { expirationDate } = info ?? {};
  if (!expirationDate) return;

  const currentTime = dayjs();
  const expirationDateObj = dayjs(expirationDate);
  if (!expirationDateObj.isValid()) {
    const error = new Error(`Invalid expiration date: ${expirationDate}`);
    Sentry.captureException(error);
    if (posthog.has_opted_in_capturing()) {
      posthog.captureException(error);
    }
    return;
  }
  const timeBetween = expirationDateObj.diff(currentTime, "days");

  if (timeBetween > 365) return;
  const result =
    expirationDateObj.format("M") + "/" + expirationDateObj.format("YY");

  return result;
}
