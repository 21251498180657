import React, { createContext, useCallback, useContext } from "react";
import EditOutlined from "@ant-design/icons/EditOutlined";
import { Link } from "react-router-dom";
import keyBy from "lodash/keyBy";
import { message, Tooltip } from "antd";
import type {
  AdminPageAlert,
  AdminPageAlertOptions,
} from "../../../../../utilities/types";
import {
  getAdminPageAlerts,
  deleteAdminPageAlert,
  getAdminPageAlertsOptions,
} from "../../../../../services/pageAlerts";
import { useRequest } from "../../../../../services/request/useRequest";
import {
  Button,
  IconButton,
} from "../../../../../components/rxLibrary/buttons";
import { getNumberSorter } from "../../../../../components/antd/table/getNumberSorter";
import { getDateSorter } from "../../../../../components/antd/table/getDateSorter";
import { renderTimeSinceADate } from "../../../../../components/antd/table/renderTimeSinceADate";
import { renderAdminPharmacies } from "../../../../../components/antd/table/renderAdminPharmacies";
import { TableColumns } from "../../../../../components/antd/table/table.constants";
import { AdminManagementTable } from "../components/AdminManagementTable";
import { AdminManagementHeader } from "../components/AdminManagementHeader";
import { IconPlus, IconTrash } from "../../../../../components/rxLibrary/icons";
import {
  getBooleanFilter,
  YES_NO_BOOLEAN_FILTERS,
} from "../../../../../components/antd/table/getBooleanFilter";
import { renderBoolean } from "../../../../../components/antd/table/renderBoolean";
import { useTableColumnsWithOptions } from "../../../../../components/antd/table/useTableColumnsWithOptions";
import { renderList } from "../../../../../components/antd/table/renderList";

const AdminPageAlertsContext = createContext({ refetch: () => {} });

const BREADCRUMBS = [{ title: "Page Alerts" }];

const COLUMNS: TableColumns<AdminPageAlert> = [
  {
    dataIndex: "id",
    title: "ID",
    width: 30,
    fixed: "left",
    sorter: getNumberSorter("id"),
  },
  {
    dataIndex: "message",
    title: "Message",
    width: 100,
  },
  {
    dataIndex: "enabled",
    title: "Enabled",
    width: 70,
    filters: YES_NO_BOOLEAN_FILTERS,
    onFilter: getBooleanFilter("enabled"),
    sorter: getNumberSorter("enabled"),
    render: renderBoolean,
  },
  {
    dataIndex: "pages",
    title: "Pages",
    width: 120,
    render: renderList,
  },
  {
    dataIndex: "blacklistedPages",
    title: "Blacklisted Pages",
    width: 120,
    render: renderList,
  },
  {
    dataIndex: "electronOnly",
    title: "Electron Only",
    width: 70,
    filters: YES_NO_BOOLEAN_FILTERS,
    onFilter: getBooleanFilter("electronOnly"),
    sorter: getNumberSorter("electronOnly"),
    render: renderBoolean,
  },
  {
    dataIndex: "electronMaxVersion",
    title: "Electron Max Version",
    width: 50,
  },
  {
    dataIndex: "pharmacyIds",
    title: "Pharmacies",
    width: 100,
  },
  {
    dataIndex: "createdAt",
    title: "Created At",
    width: 50,
    sorter: getDateSorter("createdAt"),
    render: renderTimeSinceADate,
  },
  {
    dataIndex: "updatedAt",
    title: "Update At",
    width: 50,
    sorter: getDateSorter("updateAt"),
    render: renderTimeSinceADate,
  },
  {
    key: "actions",
    dataIndex: "id",
    title: "Actions",
    width: 50,
    fixed: "right",
    render: (id) => (
      <div tw="flex">
        <Link to={`/admin/page-alert/${id}`}>
          <IconButton>
            <EditOutlined rev={undefined} />
          </IconButton>
        </Link>

        <DeleteAdminPageAlertBtn id={id} />
      </div>
    ),
  },
];

function DeleteAdminPageAlertBtn({ id }: { id: number }) {
  const { refetch } = useContext(AdminPageAlertsContext);

  const handleDelete = useCallback(async () => {
    try {
      await deleteAdminPageAlert({ id });
      refetch();
    } catch (error) {
      message.error("Failed to delete page alert");
    }
  }, [id, refetch]);

  return (
    <Tooltip title="Delete">
      <IconButton onClick={handleDelete}>
        <IconTrash color="red-1" />
      </IconButton>
    </Tooltip>
  );
}

function optionsFormatter(options: AdminPageAlertOptions) {
  const { supplier, pharmacy } = options;
  const pharmaciesById = keyBy(pharmacy, "id");

  const suppliers = supplier.map((supplier) => {
    return {
      path: `/manufacturer-store/${supplier.id}`,
      label: `Manufacturer Store (${supplier.id}): ${supplier.name}`,
    };
  });
  const suppliersByLink = keyBy(suppliers, "path");

  return { suppliersByLink, pharmaciesById };
}

function columnFormatter(
  dataIndex: string,
  options: ReturnType<typeof optionsFormatter>
) {
  const { suppliersByLink, pharmaciesById } = options;

  if (dataIndex === "pharmacyIds") {
    return {
      render: (_: undefined, record: AdminPageAlert) => {
        const pharmacyIds = record.pharmacyIds;
        if (!pharmacyIds?.length) return;
        const pharmacies = pharmacyIds.map((id) => pharmaciesById[id]);
        return renderAdminPharmacies(pharmacies);
      },
    };
  }

  if (dataIndex === "pages") {
    return {
      render: (_: undefined, record: AdminPageAlert) => {
        const pages = record.pages;
        if (!pages?.length) return;

        return (
          <div tw="space-x-2">
            {pages.map((page) => {
              const supplierLink = suppliersByLink[page];
              if (supplierLink) {
                return (
                  <Tooltip key={page} title={supplierLink.path}>
                    {supplierLink.label}
                  </Tooltip>
                );
              }

              return page;
            })}
          </div>
        );
      },
    };
  }
}

export function AdminPageAlerts() {
  const { data, error, isLoading, refetch } = useRequest({
    dataKey: "pageAlerts",
    request: getAdminPageAlerts,
  });

  const columns = useTableColumnsWithOptions({
    dataKey: "pageAlertOptions",
    columns: COLUMNS,
    request: getAdminPageAlertsOptions,
    columnFormatter,
    optionsFormatter,
  });

  return (
    <>
      <AdminManagementHeader breadcrumbs={BREADCRUMBS}>
        <Link to="/admin/page-alert/new">
          <Button icon={<IconPlus />}>Add Page Alert</Button>
        </Link>
      </AdminManagementHeader>

      <AdminPageAlertsContext.Provider value={{ refetch }}>
        <AdminManagementTable
          columns={columns}
          dataSource={data}
          loading={isLoading}
          hasError={!!error}
        />
      </AdminPageAlertsContext.Provider>
    </>
  );
}
