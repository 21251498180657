import { useMemo } from "react";
import { AdminPharmacyOrganizationForm } from "./AdminPharmacyOrganizationForm";
import {
  getAdminPharmacyOrganization,
  updateAdminPharmacyOrganization,
} from "../../../../../services/pharmacyOrganizations";
import { useEditForm } from "../../../../../components/antd/form/useEditForm";
import { WideContainer } from "../../../../../components/containers/WideContainer";

export function EditAdminPharmacyOrganization() {
  const { values, onSubmit } = useEditForm({
    name: "pharmacyOrganization",
    update: updateAdminPharmacyOrganization,
    fetchValues: getAdminPharmacyOrganization,
  });

  const { initialValues, pharmacies } = useMemo(() => {
    if (!values) return {};
    const { pharmacies, ...initialValues } = values;
    return { initialValues, pharmacies };
  }, [values]);

  return (
    <WideContainer tw="pb-[200px]">
      <AdminPharmacyOrganizationForm
        isUpdateForm
        initialValues={initialValues}
        pharmacies={pharmacies}
        onSubmit={onSubmit}
      />
    </WideContainer>
  );
}
