import { useCallback } from "react";
import { AxiosResponse } from "axios";
import type { RequestClientConfig } from "../../../services/request/requestClient";
import { authService } from "../../../services/libs/authenticationService";
import { BackendResponse } from "../../../services/request_legacy/config/backend-api";
import { OnSubmit } from "./useHandleSubmit";

/**
 * @deprecated please use useCreateForm instead
 */
export function useCreateForm_legacy<
  I extends Record<string, any>,
  O extends Record<string, any>
>({
  redirectTo,
  create,
}: {
  redirectTo: string;
  create: (token: string, data: I) => Promise<BackendResponse<O>>;
}) {
  const onSubmit: OnSubmit<I> = useCallback(
    async (values) => {
      const token = await authService.getAccessTokenSilently();
      const response = await create(token, values);
      return { response, redirectTo };
    },
    [create]
  );

  return onSubmit;
}

export function useCreateForm<
  I extends Record<string, any>,
  O extends Record<string, any>
>({
  redirectTo,
  create,
}: {
  redirectTo: string;
  create: (
    data: RequestClientConfig<I>
  ) => Promise<void | AxiosResponse<{ data: O }>>;
}) {
  const onSubmit: OnSubmit<I> = useCallback(
    async (values) => {
      let formattedResponse: BackendResponse<O> = { error: true };

      try {
        const response = await create(values);
        const data = response?.data?.data;
        if (data) formattedResponse = { data };
      } catch (error: any) {
        const errorResponse = error?.response?.data;
        if (formattedResponse) formattedResponse = errorResponse;
      }

      return { response: formattedResponse, redirectTo };
    },
    [create]
  );

  return onSubmit;
}
