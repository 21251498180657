import { useMemo } from "react";
import tw, { styled } from "twin.macro";
import { Skeleton } from "antd";
import type { Pharmacy } from "../../../../utilities/types";
import { SUPPLIER_ID_FFF } from "../../../../utilities/config";
import { useRequest } from "../../../../services/request/useRequest";
import { getOrderHistory } from "../../../../services/orderHistory";
import { Title, Text } from "../../../../components/rxLibrary/typography";
import { ExtraWideContainer } from "../../../../components/containers/ExtraWideContainer";
import { FullPageLoader } from "../../../../components/loaders/FullPageLoader";
import { Col, Row } from "../../../../components/rxLibrary/grid";
import { GREY_3 } from "../../../../components/rxLibrary/colors";
import { UnexpectedError } from "../../../../components/errors/UnexpectedError";
import { SimpleLink } from "../../../../components/rxLibrary/Link";
import { ManufacturerStoreWrapper } from "../../ManufacturerStoreWrapper";
import { OrderHistoryRow } from "./OrderHistoryRow/OrderHistoryRow";
import { SHIP_DATE_WARNING } from "./OrderHistory.constants";

const GreyRow = styled(Row)`
  background-color: ${GREY_3};
`;

export function OrderHistory({
  pharmacy,
  supplierId,
}: {
  pharmacy: Pharmacy;
  supplierId: number;
}) {
  const { id: pharmacyId, name: pharmacyName } = pharmacy;
  const isFFFSupplier = supplierId === SUPPLIER_ID_FFF;

  const config = useMemo(() => {
    return { pharmacyId, supplierId };
  }, [pharmacyId, supplierId]);
  const {
    data: orderHistory,
    error,
    isLoading,
  } = useRequest({
    config,
    dataKey: "orders",
    request: getOrderHistory,
  });

  const hasShippingCost = useMemo(() => {
    return !!orderHistory?.some((o) => o.shippingCost);
  }, [orderHistory]);

  const hasPurchaseDateMissingUsingShipDate = useMemo(() => {
    return !!orderHistory?.some((o) => o.purchaseDateMissingUsingShipDate);
  }, [orderHistory]);

  if (error) return <UnexpectedError />;

  return (
    <ManufacturerStoreWrapper>
      <ExtraWideContainer tw="bg-white px-24 pt-[40px] pb-[98px]">
        <div css={isFFFSupplier ? tw`mb-[5px]` : tw`mb-[40px]`}>
          <Title>Order History - {pharmacyName}</Title>

          {isFFFSupplier && (
            <Text tw="mt-[14px]" block>
              FFF does not send shipping details to DaylightRx.{" "}
              <SimpleLink
                href="https://biosupply.fffenterprises.com/my-account/orders"
                openInNewTab
              >
                Please log into the FFF website
              </SimpleLink>{" "}
              for shipping details.
            </Text>
          )}
        </div>

        <div tw="min-h-[500px]">
          <div>
            <GreyRow header>
              <Col css={hasShippingCost ? tw`w-[140px]` : tw`w-[180px]`}>
                <Text weight="bold">
                  {hasShippingCost
                    ? "Purchase Order #"
                    : "Purchase Order Number"}
                </Text>
              </Col>

              <Col tw="w-[95px]">
                <Text weight="bold">Order Date</Text>
              </Col>

              <Col tw="flex-1">
                <Text weight="bold">Ordered By</Text>
              </Col>

              <Col tw="w-[115px]">
                <Text weight="bold">Status</Text>
              </Col>

              {hasShippingCost && (
                <Col tw="w-[100px]">
                  <Text weight="bold">Shipping Fee</Text>
                </Col>
              )}

              <Col tw="w-[110px]">
                <Text weight="bold">Total</Text>
              </Col>

              <Col tw="w-[108px]">
                <Text weight="bold">Invoice</Text>
              </Col>

              <Col tw="w-[153px]" />
            </GreyRow>

            {orderHistory?.map((order, i) => (
              <OrderHistoryRow
                key={i}
                order={order}
                pharmacyId={pharmacyId}
                supplierId={supplierId}
                hasShippingCost={hasShippingCost}
              />
            ))}

            {hasPurchaseDateMissingUsingShipDate && (
              <Text tw="mt-4" block>
                {SHIP_DATE_WARNING}
              </Text>
            )}

            {isLoading && (
              <Skeleton
                tw="mt-4"
                active
                title={false}
                paragraph={{ rows: 15 }}
              />
            )}
          </div>

          {isLoading && <FullPageLoader text="Loading Order History" />}
        </div>
      </ExtraWideContainer>
    </ManufacturerStoreWrapper>
  );
}
