import { RuleObject } from "antd/lib/form";
import { StoreValue } from "antd/lib/form/interface";

export async function urlPathValidator(_: RuleObject, value: StoreValue) {
  if (!value) return;

  const invalidPaths = value.filter((path: string) => {
    try {
      const url = new URL(path, "http://dummy-base");
      return url.pathname !== path || !url.pathname.startsWith("/");
    } catch {
      return true;
    }
  });

  if (invalidPaths.length > 0) {
    throw new Error(`Invalid URL paths: ${invalidPaths.join(", ")}`);
  }
}
