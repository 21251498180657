import { RequestState, Quotes } from "../../../../utilities/types";
import { formatCompletedQuoteStateEnhancedFormatter } from "./formatCompletedQuoteStateEnhancedFormatter";
import { formatCompletedQuoteStateWebFormatter } from "./formatCompletedQuoteStateWebFormatter";

export function formatCompletedQuoteState({
  ndc,
  quotes,
  enhancedClientActive,
  enhancedClientVersion,
}: {
  ndc: string;
  quotes: Quotes;
  teeCodeFilterValue?: boolean;
  enhancedClientActive?: boolean;
  enhancedClientVersion?: string;
}) {
  const useEnhancedFormatter = !!(
    enhancedClientActive && enhancedClientVersion
  );
  const data = useEnhancedFormatter
    ? formatCompletedQuoteStateEnhancedFormatter({
        ndc,
        quotes,
        enhancedClientVersion,
      })
    : formatCompletedQuoteStateWebFormatter({ ndc, quotes });

  const quoteState = {
    ...data,
    itemListsByNDC: data.itemListsByNDC ?? [],
    quoteRequestState: RequestState.COMPLETED,
  };
  return quoteState;
}
