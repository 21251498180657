import { Text } from "../../../../components/rxLibrary/typography";

export function ShoppingRxPanelLoader({
  inventory,
  isLoading,
}: {
  inventory?: boolean;
  isLoading?: boolean;
}) {
  const syncingSrc = inventory
    ? "/assets/shopping/cloud-yellow.gif"
    : "/assets/shopping/cloud-blue.gif";

  const itemsLabel = inventory ? "Inventory Items" : "Prescriptions";
  const itemsBtnLabel = inventory ? "' + Add NDC / Item'" : "' + Add Rx'";

  if (isLoading) {
    return (
      <div tw="flex justify-center items-center mt-[68px] space-x-[15px]">
        <img src={syncingSrc} alt="Syncing" width="70" />

        <Text size="medium" color="grey-1" center>
          Syncing with server
        </Text>
      </div>
    );
  }

  return (
    <div tw="flex justify-center py-[39px]">
      <Text size="medium" color="grey-1" center>
        There are no {itemsLabel} is your list.
        <br />
        Use the {itemsBtnLabel} button above to build your list
      </Text>
    </div>
  );
}
