import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  expandHereValueGetter,
  manufacturerReportPercentFormatter,
} from "../utils/manufacturerReportFormatters";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName: "Fill Details",
    valueGetter: (params) => {
      if (params?.data?.pinnedRow) return "Total";
      return expandHereValueGetter();
    },
    cellRenderer: "agGroupCellRenderer",
    pinned: "left",
  },
  {
    headerName: "Pharmacy",
    children: [
      { field: "Pharmacy Name", pinned: "left" },
      { field: "Pharmacy NPI", pinned: "left", hide: true },
    ],
  },
  {
    headerName: "Pharmacy Summary",
    children: [
      { field: "Program Start Date" },
      {
        field: "DBT Total Opportunities (1-180 Days Past Due)",
        headerName: "Non-Adherent Refill Opportunities (1-180 Days Past Due)",
        sort: "desc",
      },
      {
        field: "DBT Expired Opportunities (1-180 Days Past Due)",
        headerName: "Expired Refill Opportunities (1-180 Days Past Due)",
        hide: true,
      },
      {
        field: "DBT Attempted Outreaches (1-180 Days Past Due)",
        headerName:
          "Attempted Outreaches (of Non-Adherent Refill Opportunities 1-180 Days Past Due)",
      },
      {
        field: "DBT Unattempted Opportunities (1-180 Days Past Due)",
        headerName:
          "Unattempted Outreaches (of Non-Adherent Refill Opportunities 1-180 Days Past Due)",
        hide: true,
      },
      {
        field: "% Attempted (of DBT Total Opportunities 1-180 Days Past Due)",
        headerName:
          "% Attempted of Non-Adherent Refill Opportunities (1-180 Days Past Due)",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
      {
        field: "Abandoned/Transferred Refills (1-180 Days Past Due)",
        headerName: "Abandoned/Transferred Refills (1-180 Days Past Due)",
        hide: true,
      },
      {
        field: "In-Progress Refills (1-180 Days Past Due)",
        headerName: "In-Progress Refills (1-180 Days Past Due)",
        hide: true,
      },
      {
        field: "Dispensed Refills (1-180 Days Past Due)",
        headerName: "Dispensed Refills (1-180 Days Past Due)",
        aggFunc: "sum",
      },
      {
        field: "% Dispensed (of DBT Attempted Outreaches 1-180 Days Past Due)",
        headerName: "% Dispensed of Attempted Outreaches (1-180 Days Past Due)",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
      {
        field: "% Dispensed (of DBT Total Opportunities 1-180 Days Past Due)",
        headerName:
          "% Dispensed of Non-Adherent Refill Opportunities (1-180 Days Past Due)",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
      {
        field: "% In Progress (of DBT Total Opportunities 1-180 Days Past Due)",
        headerName:
          "% In-Progress of Non-Adherent Refill Opportunities (1-180 Days Past Due)",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
      {
        field: "DBT Total Opportunities",
        headerName: "Non-Adherent Refill Opportunities (1-365 Days Past Due)",
        hide: true,
      },
      {
        field: "DBT Expired Opportunities",
        headerName: "Expired Refill Opportunities (1-365 Days Past Due)",
        hide: true,
      },
      {
        field: "Attempted Outreaches (of Non-Adherent Refill Opportunity)",
        hide: true,
        headerName:
          "Attempted Outreaches (of Non-Adherent Refill Opportunities 1-365 Days Past Due)",
      },
      {
        field: "DBT Unattempted Opportunities",
        headerName:
          "Unattempted Outreaches (of Non-Adherent Refill Opportunities 1-365 Days Past Due)",
        hide: true,
      },
      {
        field: "Abandoned/Transferred Refills",
        headerName: "Abandoned/Transferred Refills (1-365 Days Past Due)",
        hide: true,
      },
      {
        field: "In-Progress Refills",
        headerName: "In-Progress Refills (1-365 Days Past Due)",
        hide: true,
      },
      {
        field: "Dispensed Refills",
        headerName: "Dispensed Refills (1-365 Days Past Due)",
        hide: true,
        aggFunc: "sum",
      },
      {
        field: "% Attempted (of DBT Total Opportunities)",
        headerName:
          "% Attempted of Non-Adherent Refill Opportunities (1-365 Days Past Due)",
        hide: true,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
      {
        field: "% Dispensed (of DBT Total Opportunities)",
        headerName:
          "% Dispensed of Non-Adherent Refill Opportunities (1-365 Days Past Due)",
        hide: true,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
      {
        field: "% In Progress (of DBT Total Opportunities)",
        headerName:
          "% In-Progress of Non-Adherent Refill Opportunities (1-365 Days Past Due)",
        hide: true,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
    ],
  },
];
