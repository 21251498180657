import React, { useMemo } from "react";
import dayjs from "dayjs";
import { AgGridReact } from "ag-grid-react";
import type { LicensingPaymentsTable } from "../LicensingPayments.types";
import { DATE_WITH_YEAR } from "../../../../../utilities/dates/dates.constants";
import { useReport } from "../../../../Manufacturer/ManufacturerReports/reports/utils/useReport/useReport";
import { Text } from "../../../../../components/rxLibrary/typography";
import { TableWrapper } from "../../TableWrapper";
import { COLUMN_DEFS } from "./LicensingPaymentsTable.constants";

export function LicensingPaymentsTable({
  data,
}: {
  data?: LicensingPaymentsTable[];
}) {
  const report = useReport({ columnDefs: COLUMN_DEFS });

  const subtitle = useMemo(() => {
    const date = dayjs().subtract(1, "day").format(DATE_WITH_YEAR);
    return `Estimates based on outreaches through ${date}`;
  }, []);

  return (
    <>
      <div tw="p-4 pb-6">
        <Text size="x-large" block>
          Pharmacy Data Licensing Payments
        </Text>
        <Text block>{subtitle}</Text>
      </div>

      <TableWrapper>
        <AgGridReact
          {...report}
          pagination
          rowData={data ?? []}
          rowSelection="multiple"
          multiSortKey="ctrl"
          alwaysMultiSort={true}
        />
      </TableWrapper>
    </>
  );
}
