import React, {
  createContext,
  Reducer,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from "react";
import {v4 as uuidv4} from 'uuid';
import cloneDeep from "lodash/cloneDeep";
import { authService } from "../services/libs/authenticationService";
import AppContext from "../contexts/AppContext";
import PharmaciesContext from "../contexts/PharmaciesContext";
import BuyingPharmacyContext from "../contexts/BuyingPharmacyContext";
import {logJSON} from "./services/logJSON";
import {
  ContractIdentifierStr,
  Pharmacy,
  Supplier,
  User
} from "../utilities/types";
import {ActiveConfiguration} from "./EnhancedAppConfig";
import {AppError, EnhancedSupplierConnectionStatus} from "./types";
import {css, Global} from "@emotion/react";
import {
  useEnhancedViewUnavailableMessage
} from "./app_widgets/EnhancedViewUnavailableMessage";
import EnhancedClientConfigContext from "./EnhancedClientConfigContext";
import {supplierIsDisabledInEnhancedClient} from "./util";
import {getUser} from "../services/request_legacy/user";

export enum EnhancedStockValidationAvailability {
    BACKORDERED,
    PENDING,
    AVAILABLE,
    UNAVAILABLE
}

export type SupplierItemDataType = {
  supplierStatus: EnhancedSupplierConnectionStatus,
  placeholderCreationTime?: number,

  ndc: string,
  supplier: string,
  availability: EnhancedStockValidationAvailability,
  price?: number,
  priceNote?: string, // deprecated; use isRebatedItem
  message: string | null,
  deeplink?: string,

  quantityAvailable?: number | string,
  supplierItemNumber?: string,
  isRebatedItem?: boolean,
  expirationDate?: string,
  searchResultWasError?: boolean,

  supplierContractName?: string | null,
  supplierContractIdentifier?: ContractIdentifierStr | null,
  appliedCustomSupplierContractDiscount?: number,
  priceBeforeCustomSupplierContractDiscount?: number,
};

export type EnhancedItemsBySupplierType = Partial<
  Record<string, Record<string, SupplierItemDataType>>
>;

export type EnhancedAdditionalItemsByNDCType = Partial<
  Record<string, Record<string, SupplierItemDataType>>
>;

export type RequestEnhanceItemParam = {
    ndc: string,
    supplier: string,
    price: number,
    drugNameUOMSizeStr: string,
    supplierItemNumbers: string[],
}

export type RequestEnhanceBatchPayload = {
    traceId: string,
    enhancementRequests: RequestEnhanceItemParam[],
    runMultiplePerSupplier?:boolean,
}

export type ShoppingListSearchPayload = RequestEnhanceBatchPayload & {
    runMultiplePerSupplier: true,
}

export type CompletedWorkItem = {
    txnId: string,
    key: string,
    reason: string,
    supplier: string,
}
export type ShoppingListItemCompletion = {
    workItem: CompletedWorkItem,
    results: SupplierItemDataType[],
    traceId: string,
    supplier: string,
    requestIndex: number,
    requestCount: number,
    problematic: boolean,
}
export type ShoppingListSupplierStatus = "active" | "refreshing" | "problematic" | "deactivated";
export type ShoppingListContext = {
    state: "requested" | "in_progress" | "completed" | "aborting" | "aborted",
    request: ShoppingListSearchPayload,
    supplierRequestParams: Record<string, RequestEnhanceItemParam[]>,
    supplierRequestCursors: Record<string, number>,
    supplierRequestAbortPoints: Record<string, number>,
    supplierAlreadyRefreshedOnce: Record<string, boolean>,
    supplierStatus: Record<string, ShoppingListSupplierStatus>,
    completions: ShoppingListItemCompletion[],
    reenqueuedCompletions: ShoppingListItemCompletion[],
}

interface RequestEnhanceItemsFunc {
    (traceId: string, batch: RequestEnhanceItemParam[]): string | null
}

interface RequestEnhanceShoppingListFunc {
    (traceId: string, batch: RequestEnhanceItemParam[], treatAsFirstSearch: boolean): string | null
}

interface PokeSupplierFunc {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (name: string, data?: any): void
}

interface VoidFunc {
    (): void
}

export type ProblemReport = {
    user?: User,
    currentPharmacy?: Pharmacy,
    problemDescription: string,
    reportId: string,
    supplier?: string,
    requestedEnhancements?: RequestEnhanceItemParam[],
}

interface ReportProblemFunc {
    (problemReport: ProblemReport): void
}

type EnhancedClientContext = {
    requestEnhanceItems: RequestEnhanceItemsFunc,
    requestAppendEnhanceItems: RequestEnhanceItemsFunc,
    currentEnhancementState: EnhancementState,
    enhancedOverlayShown: boolean,
    requestClearEnhancedOverlay: VoidFunc,
    refreshSuppliers: VoidFunc,
    enhancedSettingsModalShown: boolean,
    setEnhancedSettingsModalShown: React.Dispatch<React.SetStateAction<boolean>>,
    enhancedReportProblemModalShown: boolean,
    setEnhancedReportProblemModalShown: React.Dispatch<React.SetStateAction<boolean>>,
    reportProblem: ReportProblemFunc,
    enhancedSupplierConnectionStatuses: Record<string, EnhancedSupplierConnectionStatus>,
    showLogin: PokeSupplierFunc,
    clearEnhancementCache: VoidFunc,
    requestRestart: VoidFunc,
    enhancementTimeout: number,
    setEnhancementTimeout: (n: number) => void,
    requestEnhanceShoppingList: RequestEnhanceShoppingListFunc,
    currentShoppingListContext: ShoppingListContext | null,
    isShoppingListLocked: boolean,
    abortShoppingListSearch: VoidFunc,
    deactivateShoppingListSupplier: PokeSupplierFunc,
    reactivateShoppingListSupplier: PokeSupplierFunc,
    eCommerceAvailable: boolean,
    skippedSuppliers: SkippedEnhancedSuppliers,
    skipSupplier: (supplier: string) => void,
    unskipSupplier: (supplier: string) => void,
};

const EnhancedClientContext = createContext<EnhancedClientContext>({
    requestEnhanceItems: () => { return null; },
    requestAppendEnhanceItems: () => { return null; },
    currentEnhancementState: {
        isShoppingList: false,
        requestBatch: [],
        requestedNDCs: {},
        requestedSupplierItemNumbers: {},
        requestsCompleted: {},
        traceId: null,
        suppliersSeen: {},
        supplierProblems: {},
        enhancedSupplierItems: {},
        enhancedAdditionalItemsByNDC: {},
        createdAt: 0,
        isFirstSearch: true,
    },
    enhancedOverlayShown: false,
    requestClearEnhancedOverlay: () => {},
    refreshSuppliers: () => {},
    enhancedSettingsModalShown: false,
    setEnhancedSettingsModalShown: () => {},
    enhancedReportProblemModalShown: false,
    reportProblem: () => {},
    setEnhancedReportProblemModalShown: () => {},
    enhancedSupplierConnectionStatuses: {},
    showLogin: () => {},
    clearEnhancementCache: () => {},
    requestRestart: () => {},
    enhancementTimeout: 3000,
    setEnhancementTimeout: () => {},
    requestEnhanceShoppingList: () => { return null; },
    currentShoppingListContext: null,
    isShoppingListLocked: false,
    abortShoppingListSearch: () => {},
    deactivateShoppingListSupplier: () => {},
    reactivateShoppingListSupplier: () => {},
    eCommerceAvailable: false,
    skippedSuppliers: {},
    skipSupplier: () => {},
    unskipSupplier: () => {},
});

export default EnhancedClientContext;

type EnhancedClientContextProviderProps = {
    children?: React.ReactNode;
};

export type EnhancementState = {
  traceId: string | null;
  isShoppingList: boolean;
  requestBatch: RequestEnhanceItemParam[];
  requestedNDCs: Record<string, boolean>;
  requestedSupplierItemNumbers: Record<string, Record<string, string>>;
  requestsCompleted: Record<string, boolean>;
  enhancedSupplierItems: EnhancedItemsBySupplierType;
  enhancedAdditionalItemsByNDC: EnhancedAdditionalItemsByNDCType;
  suppliersSeen: Record<string, boolean>;
  supplierProblems: Record<string, EnhancedSupplierProblemObserver>;
  createdAt: number;
  isFirstSearch: boolean;
};

type EnhancementStateCreate = {
    type: "create",
    isShoppingList: boolean,
    traceId: string,
    requestBatch: RequestEnhanceItemParam[],
}
type EnhancementStateAppend = {
    type: "append",
    traceId: string,
    requestBatch: RequestEnhanceItemParam[],
}
type EnhancementStateUpdate = {
  type: "update";
  supplierEnhancedItems?: SupplierItemDataType[];
  supplierCompletedRequest?: string;
  supplierProblems?: Record<string, EnhancedSupplierProblemObserver>;
};

function assertNever(x: never): never {
    throw new Error("Unexpected object: " + x);
}

function compareByAvailability(first: SupplierItemDataType, second: SupplierItemDataType) : SupplierItemDataType | null {
    if (first.availability === second.availability) return null;
    if (first.availability === EnhancedStockValidationAvailability.PENDING) {
        return second;
    }
    if (second.availability === EnhancedStockValidationAvailability.PENDING) {
        return first;
    }
    if (first.availability === EnhancedStockValidationAvailability.UNAVAILABLE) {
        return second;
    }
    if (second.availability === EnhancedStockValidationAvailability.UNAVAILABLE) {
        return first;
    }
    if (first.availability === EnhancedStockValidationAvailability.BACKORDERED) {
        return second;
    }
    if (second.availability === EnhancedStockValidationAvailability.BACKORDERED) {
        return first;
    }
    return null;
}

function updateIsBetter(update: SupplierItemDataType, previous: SupplierItemDataType): boolean {
    const itemWithBetterAvailability = compareByAvailability(update, previous);
    if (itemWithBetterAvailability === update) {
        return true;
    }
    if (itemWithBetterAvailability === previous) {
        return false;
    }

    if (update.price === 0 || !update.price) {
        return false;
    }
    if (previous.price === 0 || !previous.price) {
        return true;
    }
    return update.price < previous.price;
}

export type SkippedEnhancedSuppliers = Record<string, { exp: number }>;

function loadSkippedSuppliersFromLocalStorage(): SkippedEnhancedSuppliers {
  const skipped = localStorage.getItem("enhancedCompareSkippedSuppliers");
  if (!skipped) {
    return {};
  }
  const skippedSuppliers = JSON.parse(skipped);
  const now = Date.now();
  Object.keys(skippedSuppliers).forEach(supplier => {
    if (skippedSuppliers[supplier].exp < now) {
      delete skippedSuppliers[supplier];
    }
  });
  return skippedSuppliers;
}

function saveSkippedSuppliersToLocalStorage(skipped: SkippedEnhancedSuppliers) {
  const now = Date.now();
  Object.keys(skipped).forEach(supplier => {
    if (skipped[supplier].exp < now) {
      delete skipped[supplier];
    }
  });
  localStorage.setItem("enhancedCompareSkippedSuppliers", JSON.stringify(skipped));
}

function midnight() {
  return new Date(new Date().setHours(24, 0, 0, 0)).getTime();
}


export type EnhancedSupplierProblemObserver = {
    reported: boolean,
    isProblem: boolean,
    instanceTimes: number[],
}

let ENHANCED_ITEMS_QUEUE: SupplierItemDataType[] = [];

function filterSuppliersByClientVersion(pharmacies: Pharmacy[], enhancedClientVersion: string): Pharmacy[] {
  const filteredPharmacies: Pharmacy[] = [];
  pharmacies.forEach(pharmacy => {
    const filteredSuppliers: Record<string, Supplier> = {};
    pharmacy.suppliers.forEach(supplier => {
      if (!supplierIsDisabledInEnhancedClient(supplier.name, enhancedClientVersion)) {
        filteredSuppliers[supplier.name] = supplier;
      }
    });
    filteredPharmacies.push({...pharmacy, suppliers: Object.values(filteredSuppliers)});
  });
  return filteredPharmacies;
}

function loadEnhancedSupplierConnectionStatuses(): Record<string, EnhancedSupplierConnectionStatus> {
    const stored = window.sessionStorage.getItem("enhancedSupplierConnectionStatuses");
    if (!stored) {
        return {};
    }
    return JSON.parse(stored);
}

const ENHANCEMENT_REVIEW_TIME_MS = 16000;

export function EnhancedClientContextProvider({
                                       children,
                                   }: EnhancedClientContextProviderProps): JSX.Element {
    const { showViewUnavailableMessage, contextHolder } = useEnhancedViewUnavailableMessage();
    const { user } = useContext(AppContext);
    const { pharmacies, refreshPharmacies } = useContext(PharmaciesContext);
    const { currentBuyingPharmacyId, currentBuyingPharmacy, currentBuyingPharmacyConfig } = useContext(BuyingPharmacyContext);
    const [enhancedSupplierConnectionStatuses, setEnhancedSupplierConnectionStatuses] = useState<Record<string, EnhancedSupplierConnectionStatus>>(loadEnhancedSupplierConnectionStatuses);
    const { enhancedClientVersion } = useContext(EnhancedClientConfigContext);
    const [enhancedSettingsModalShown, setEnhancedSettingsModalShown] = useState<boolean>(false);
    const [enhancedReportProblemModalShown, setEnhancedReportProblemModalShown] = useState<boolean>(false);
    const [enhancedOverlayShown, setEnhancedOverlayShown] = useState<boolean>(false);
    const [twoFARequestedBy, setTwoFARequestedBy] = useState<string|undefined>();
    const [elementOverlayCenterX, setElementOverlayCenterX] = useState<number>(0);
    const [elementOverlayY, setElementOverlayY] = useState<number>(0);
    const [accessToken, setAccessToken] = useState<string>("");
    const [enhancementTimeout, setEnhancementTimeout] = useState<number>(3000);
    const [enhancementReviewTimestamp, setEnhancementReviewTimestamp] = useState<number>(0);
    const [currentEnhancementState, updateCurrentEnhancementState] = useReducer<Reducer<EnhancementState, EnhancementStateCreate | EnhancementStateAppend | EnhancementStateUpdate>>(
        (state, operation) => {
            switch (operation.type) {
                case "create": {
                    const newRequestsCompleted: Record<string, boolean> = {};
                    const newSuppliersSeen: Record<string, boolean> = {};
                    const newSupplierItems: EnhancedItemsBySupplierType = {};
                    const newItemsByNdc: EnhancedAdditionalItemsByNDCType = {};
                    const newRequestedNdcs: Record<string, boolean> = {};
                    const newRequestedSupplierItemNumbers: Record<string, Record<string, string>> = {};

                    operation.requestBatch.forEach(req => {
                        newSuppliersSeen[req.supplier] = false;
                        newRequestsCompleted[req.supplier] = false;
                        const placeholder: Record<string, SupplierItemDataType> = newSupplierItems[req.supplier] || {};
                        placeholder[req.ndc] = {
                            ndc: req.ndc,
                            supplier: req.supplier,
                            supplierStatus: enhancedSupplierConnectionStatuses[req.supplier],
                            price: req.price,
                            availability: EnhancedStockValidationAvailability.PENDING,
                            message: null,
                            placeholderCreationTime: Date.now(),
                        };
                        newRequestedNdcs[req.ndc] = true;
                        newSupplierItems[req.supplier] = placeholder;
                        if (req.supplierItemNumbers) {
                          let itemNumbersRequestedForSupplier = newRequestedSupplierItemNumbers[req.supplier];
                          if (!itemNumbersRequestedForSupplier) {
                            itemNumbersRequestedForSupplier = {};
                            newRequestedSupplierItemNumbers[req.supplier] = itemNumbersRequestedForSupplier;
                          }
                          req.supplierItemNumbers.forEach(supplierItemNumber => {
                            itemNumbersRequestedForSupplier[supplierItemNumber] = req.ndc;
                          });
                        }
                    });
                    return {
                        isShoppingList: operation.isShoppingList,
                        traceId: operation.traceId,
                        requestBatch: operation.requestBatch,
                        requestedNDCs: newRequestedNdcs,
                        requestedSupplierItemNumbers: newRequestedSupplierItemNumbers,
                        requestsCompleted: newRequestsCompleted,
                        suppliersSeen: newSuppliersSeen,
                        supplierProblems: state.supplierProblems,
                        enhancedSupplierItems: newSupplierItems,
                        enhancedAdditionalItemsByNDC: newItemsByNdc,
                        createdAt: new Date().getTime(),
                        isFirstSearch: state.traceId === null,
                    }
                }
                case "append" : {
                  const newState: EnhancementState = JSON.parse(JSON.stringify(state));
                  let modified = false;
                  operation.requestBatch.forEach((req) => {
                    if (newState.suppliersSeen[req.supplier] === undefined) {
                      modified = true;
                      newState.requestBatch.push(req);
                      newState.suppliersSeen[req.supplier] = false;
                      newState.requestsCompleted[req.supplier] = false;
                      const placeholder: Record<string, SupplierItemDataType> = newState.enhancedSupplierItems[req.supplier] || {};
                      placeholder[req.ndc] = {
                        ndc: req.ndc,
                        supplier: req.supplier,
                        supplierStatus: enhancedSupplierConnectionStatuses[req.supplier],
                        price: req.price,
                        availability: EnhancedStockValidationAvailability.PENDING,
                        message: null,
                        placeholderCreationTime: Date.now(),
                      };
                      newState.requestedNDCs[req.ndc] = true;
                      newState.enhancedSupplierItems[req.supplier] = placeholder;
                      if (req.supplierItemNumbers) {
                        let itemNumbersRequestedForSupplier = newState.requestedSupplierItemNumbers[req.supplier];
                        if (!itemNumbersRequestedForSupplier) {
                          itemNumbersRequestedForSupplier = {};
                          newState.requestedSupplierItemNumbers[req.supplier] = itemNumbersRequestedForSupplier;
                        }
                        req.supplierItemNumbers.forEach(supplierItemNumber => {
                          itemNumbersRequestedForSupplier[supplierItemNumber] = req.ndc;
                        });
                      }
                    }
                  })
                  if (!modified) {
                    console.log("append no change");
                    return state;
                  }
                  return newState;
                }
                case "update": {
                    const newState: EnhancementState = {
                        isShoppingList: state.isShoppingList,
                        requestBatch: state.requestBatch,
                        requestsCompleted: state.requestsCompleted,
                        requestedNDCs: state.requestedNDCs,
                        requestedSupplierItemNumbers: state.requestedSupplierItemNumbers,
                        suppliersSeen: state.suppliersSeen,
                        supplierProblems: state.supplierProblems,
                        traceId: state.traceId,
                        enhancedSupplierItems: state.enhancedSupplierItems,
                        enhancedAdditionalItemsByNDC: state.enhancedAdditionalItemsByNDC,
                        createdAt: state.createdAt,
                        isFirstSearch: state.isFirstSearch,
                    };
                    if (operation.supplierEnhancedItems && operation.supplierEnhancedItems.length > 0) {
                        const newEnhancedSupplierItems: EnhancedItemsBySupplierType = cloneDeep(state.enhancedSupplierItems);
                        const newEnhancedAdditionalItemsByNDC: EnhancedAdditionalItemsByNDCType = cloneDeep(state.enhancedAdditionalItemsByNDC);
                        operation.supplierEnhancedItems.forEach(updatedItem => {
                            const existingSupplierData: Record<string, SupplierItemDataType> | undefined = state.enhancedSupplierItems[updatedItem.supplier];
                            if (!state.requestedNDCs[updatedItem.ndc]) {
                              if (updatedItem.supplierItemNumber && state.requestedSupplierItemNumbers[updatedItem.supplier] && state.requestedSupplierItemNumbers[updatedItem.supplier][updatedItem.supplierItemNumber]) {
                                updatedItem.ndc = state.requestedSupplierItemNumbers[updatedItem.supplier][updatedItem.supplierItemNumber];
                              }
                            }
                            if (!existingSupplierData || !existingSupplierData[updatedItem.ndc] || updateIsBetter(updatedItem, existingSupplierData[updatedItem.ndc])) {
                                const newSupplierData = newEnhancedSupplierItems[updatedItem.supplier] || {};
                                newSupplierData[updatedItem.ndc] = updatedItem;
                                newEnhancedSupplierItems[updatedItem.supplier] = newSupplierData;

                                newState.enhancedSupplierItems = newEnhancedSupplierItems;
                                if(!state.requestedNDCs[updatedItem.ndc]) {
                                    const newNdcData = newEnhancedAdditionalItemsByNDC[updatedItem.ndc] || {};
                                    newNdcData[updatedItem.supplier] = updatedItem;
                                    newEnhancedAdditionalItemsByNDC[updatedItem.ndc] = newNdcData;
                                    newState.enhancedAdditionalItemsByNDC = newEnhancedAdditionalItemsByNDC;
                                }

                                newState.suppliersSeen[updatedItem.supplier] = true;
                                newState.supplierProblems[updatedItem.supplier] = {
                                    instanceTimes: [],
                                    isProblem: false,
                                    reported: newState.supplierProblems[updatedItem.supplier]?.reported || false,
                                }
                            }
                        })
                    }
                    if (operation.supplierCompletedRequest) {
                        const newRecord = cloneDeep(newState.requestsCompleted);
                        newRecord[operation.supplierCompletedRequest] = true;
                        newState.requestsCompleted = newRecord;
                    }
                    if (operation.supplierProblems) {
                        newState.supplierProblems = operation.supplierProblems;
                    }
                    return newState;
                }
                default:
                    assertNever(operation);
            }
        },
        {
            isShoppingList: false,
            requestBatch: [],
            requestedNDCs: {},
            requestedSupplierItemNumbers: {},
            requestsCompleted: {},
            suppliersSeen: {},
            supplierProblems: {},
            traceId: null,
            enhancedSupplierItems: {},
            enhancedAdditionalItemsByNDC: {},
            createdAt: 0,
            isFirstSearch: true,
        }
    );
    const [isShoppingListLocked, setIsShoppingListLocked] = useState<boolean>(false);
    const [currentShoppingListContext, setCurrentShoppingListContext] = useState<ShoppingListContext|null>(null);

    const [ skippedSuppliers , setSkippedSuppliers ] = useState<SkippedEnhancedSuppliers>(loadSkippedSuppliersFromLocalStorage);
    const skipSupplier = useCallback((supplier: string) => {
      setSkippedSuppliers((prev) => {
        const newSkipped = { ...prev };
        newSkipped[supplier] = { exp: midnight() };
        return newSkipped;
      });
    }, [skippedSuppliers, setSkippedSuppliers]);

    const unskipSupplier = useCallback((supplier: string) => {
      setSkippedSuppliers((prev) => {
        const newSkipped = { ...prev };
        delete newSkipped[supplier];
        return newSkipped;
      })
    }, [skippedSuppliers, setSkippedSuppliers]);

    useEffect(() => {
      saveSkippedSuppliersToLocalStorage(skippedSuppliers);
    }, [skippedSuppliers]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log("clearing skipped suppliers at midnight");
      setSkippedSuppliers({}); // clear skipped suppliers at midnight
    }, midnight() - Date.now() + 1000);
    return () => {
      clearTimeout(timeout);
    }
  }, []);


  const refreshSuppliers = useCallback(async () => {
      const pharmacies = await refreshPharmacies();
      if (pharmacies) {
        for (const pharmacy of pharmacies) {
          if (pharmacy.id === currentBuyingPharmacyId) {
            window.postMessage({
              channel: "daylightrxenhanced",
              sender: "web",
              msgType: "suppliersRefreshed",
              payload: pharmacy.suppliers,
            })
          }
        }
      }

      /* Side Effect of also refreshing user permissions */
      const token = await authService.getAccessTokenSilently();
      const {data, error} = await getUser(token);
      if (error) {
        console.error("Error refreshing user", error);
        return;
      }
      if (!data?.user) {
        console.error("No user data found");
        return;
      }
      window.postMessage({
        channel: "daylightrxenhanced",
        sender: "web",
        msgType: "userRefreshed",
        payload: data.user,
      });
    }, [refreshPharmacies, currentBuyingPharmacyId])

    useEffect(() => {
        (async () => {
            setAccessToken(await authService.getAccessTokenSilently());
        })();
    });

    useEffect(() => {
        const t = setInterval(() => {
            if (ENHANCED_ITEMS_QUEUE.length > 0) {
                updateCurrentEnhancementState({type:"update", supplierEnhancedItems: ENHANCED_ITEMS_QUEUE});
                ENHANCED_ITEMS_QUEUE = [];
            }
        }, 500);
        return () => {
            clearInterval(t);
        }
    })

    useEffect(() => {
        if (accessToken && user) {
            const messageListenerCallback = async (event: MessageEvent) => {
                if (event.source !== window) {
                    return;
                }
                if (event.data.channel === "daylightrxenhanced") {
                    if (event.data.msgType === 'enhanceItemReply') {
                        console.log("EnhancedClientContext:enhanceItemReply", event.data);
                        const supplierItem: SupplierItemDataType = event.data.payload.supplierItem;
                        ENHANCED_ITEMS_QUEUE.push(supplierItem);
                    }
                    if (event.data.msgType === 'loggedInReply') {
                        console.log("EnhancedClientContext:loggedInReply", event.data);
                        // TODO
                    }
                    if (event.data.msgType === "showEnhancedOverlay") {
                        setEnhancedOverlayShown(true);
                    }
                    if (event.data.msgType === "clearEnhancedOverlay") {
                        setEnhancedOverlayShown(false);
                    }
                    if (event.data.msgType === "showSettingsModal") {
                        if (user?.is_admin) {
                            setEnhancedSettingsModalShown(true);
                        }
                    }
                    if (event.data.msgType === "clearSettingsModal") {
                        setEnhancedSettingsModalShown(false);
                    }
                    if (event.data.msgType === "enhancedSupplierStatuses") {
                        // console.log("enhancedClientContext:received enhancedSupplierConnectionStatuses", event.data);
                        setEnhancedSupplierConnectionStatuses((prevEnhancedSupplierStatuses) => {
                            let changed = false;
                            const changes: EnhancedSupplierConnectionStatus[] = [];
                            event.data.payload.forEach((newStatus: EnhancedSupplierConnectionStatus) => {
                              const prevStatus = prevEnhancedSupplierStatuses[newStatus.name];
                              if (!prevStatus) {
                                changes.push(newStatus);
                                changed = true;
                                return;
                              }
                              if (newStatus.userEmail) { // TODO can eliminate this check after all clients have upgraded to 0.4.35
                                if (prevStatus.userEmail !== newStatus.userEmail || prevStatus.userPharmacyId !== newStatus.userPharmacyId) {
                                  changes.push(newStatus);
                                  changed = true;
                                  return;
                                }
                                if (prevStatus.placeholder && !newStatus.placeholder) {
                                  changes.push(newStatus);
                                  changed = true;
                                  return;
                                }
                                if (!prevStatus.placeholder && newStatus.placeholder) {
                                  // skip new placeholder updates if we have a real status
                                  return;
                                }
                              }
                              if (prevStatus.loggedIn !== newStatus.loggedIn || prevStatus.enhancementsDisabled !== newStatus.enhancementsDisabled) {
                                changes.push(newStatus);
                                changed = true;
                                return;
                              }
                            });
                            if (changes.length) {
                              console.log("setEnhancedSupplierConnectionStatuses changes", changes);
                            }
                            if(!changed) {
                                return prevEnhancedSupplierStatuses;
                            }

                            const newEnhancedSupplierStatuses: Record<string, EnhancedSupplierConnectionStatus> = cloneDeep(prevEnhancedSupplierStatuses);
                            changes.forEach((newStatus: EnhancedSupplierConnectionStatus) => {
                              newStatus._updatedAt = new Date().getTime();
                              newEnhancedSupplierStatuses[newStatus.name] = newStatus;
                            });
                            window.sessionStorage.setItem("enhancedSupplierConnectionStatuses", JSON.stringify(newEnhancedSupplierStatuses));
                            return newEnhancedSupplierStatuses;
                        })
                    }
                    if (event.data.msgType === "clearEnhancedSupplierStatus") {
                      console.log("enhancedClientContext:received clearEnhancedSupplierStatus", event.data);
                      const name = event.data.payload;
                      setEnhancedSupplierConnectionStatuses((prev) => {
                        const newEnhancedSupplierStatuses: Record<string, EnhancedSupplierConnectionStatus> = cloneDeep(prev);
                        delete newEnhancedSupplierStatuses[name];
                        window.sessionStorage.setItem("enhancedSupplierConnectionStatuses", JSON.stringify(newEnhancedSupplierStatuses));
                        return newEnhancedSupplierStatuses;
                      });
                    }
                    if (event.data.msgType === "clearEnhancedSupplierStatuses") {
                      console.log("enhancedClientContext:received clearEnhancedSupplierStatuses", event.data);
                        setEnhancedSupplierConnectionStatuses(() => {
                            window.sessionStorage.removeItem("enhancedSupplierConnectionStatuses");
                            return {};
                        });
                    }
                    if (event.data.msgType === "2FARequested") {
                        console.log("enhancedClientContext:received 2FARequested", event.data);
                        setTwoFARequestedBy(event.data.payload.supplier);
                    }
                    if (event.data.msgType === "LogJSON") {
                        console.log("enhancedClientContext:received LogJSON", event.data);
                        let body = event.data.payload;
                        if (typeof(body) === 'object') {
                            body = JSON.stringify(body);
                        }
                        if (currentBuyingPharmacyId) {
                            await logJSON(accessToken, currentBuyingPharmacyId, body);
                        }
                    }
                    if (event.data.msgType === "completedSearchTransaction") {
                        console.log("enhancedClientContext:completedSearchTransaction", event.data);
                        if (event.data.payload.txnId === currentEnhancementState.traceId) {
                            console.log("supplier completed search");
                            updateCurrentEnhancementState({type: "update", supplierCompletedRequest: event.data.payload.supplier})
                        } else if (event.data.payload.txnId && event.data.payload.txnId.startsWith(currentEnhancementState.traceId)) {
                          // shopping list search result; no-op
                        } else {
                          console.log("currentEnhancementRequestTraceId mismatch:", currentEnhancementState.traceId, event.data.payload.txnId)
                        }
                    }

                    if (event.data.msgType === "shoppingListSearchStarted") {
                        console.log("enhancedClientContext:shoppingListSearchStarted", event.data.payload as ShoppingListContext);
                        setCurrentShoppingListContext(event.data.payload);
                    }
                    if (event.data.msgType === "shoppingListSearchProgress") {
                        console.log("enhancedClientContext:shoppingListSearchProgress", event.data.payload as {completion?: ShoppingListItemCompletion, context: ShoppingListContext});
                        setCurrentShoppingListContext(event.data.payload.context);
                    }
                    if (event.data.msgType === "shoppingListSearchAborted") {
                        console.log("enhancedClientContext:shoppingListSearchAborted", event.data.payload as ShoppingListContext);
                        setCurrentShoppingListContext(event.data.payload);
                    }
                    if (event.data.msgType === "shoppingListSearchCompleted") {
                        console.log("enhancedClientContext:shoppingListSearchCompleted", event.data.payload as ShoppingListContext);
                        setCurrentShoppingListContext(event.data.payload);
                    }
                    if (event.data.msgType === "shoppingListSearchLocked") {
                        console.log("enhancedClientContext:shoppingListSearchLocked");
                        setIsShoppingListLocked(true);
                    }
                    if (event.data.msgType === "shoppingListSearchUnlocked") {
                        console.log("enhancedClientContext:shoppingListSearchUnlocked");
                        setIsShoppingListLocked(false);
                    }
                    if (event.data.msgType === "handleUnavailableSupplier") {
                        console.log("enhancedClientContext:handleUnavailableSupplier", event.data);
                        if (event.data.payload?.supplier) {
                            showViewUnavailableMessage({
                                type: "view-unavailable",
                                name: event.data.payload.supplier.displayName || event.data.payload.supplier.name || event.data.payload.supplier,
                                isMain: false,
                                isEcommerce: false,
                            });
                        }
                    }
                    if (event.data.msgType === "refreshSuppliers") {
                      await refreshSuppliers();
                    }
                    if (event.data.msgType === "error-app-internal") {
                      const error = event.data.payload as AppError;
                      if (error.type === "view-unavailable") {
                        showViewUnavailableMessage(error);
                      }
                    }
                }
            };
            window.addEventListener("message", messageListenerCallback);

            return () => {
                window.removeEventListener("message", messageListenerCallback);
            }
        } else {
          return () => {};
        }
    }, [currentEnhancementState, accessToken, user, currentBuyingPharmacyId, refreshSuppliers, showViewUnavailableMessage])

    useEffect(() => {
      if (!enhancedClientVersion) {
        return;
      }

      let interval: NodeJS.Timeout | null = null;
      if (user && currentBuyingPharmacyConfig && currentBuyingPharmacyConfig.pharmacyId === currentBuyingPharmacyId) {
        let count = 0;
        const syncDaylightRxUser = () => {
          if (count++ > 5) {
            if (interval) {
              clearInterval(interval);
            }
            return;
          }
          window.postMessage({
              channel: "daylightrxenhanced",
              sender: "web",
              msgType: "daylightrxUser",
              payload: {
                  user,
                  pharmacies: filterSuppliersByClientVersion(pharmacies, enhancedClientVersion),
                  currentBuyingPharmacyId,
                  appConfig: ActiveConfiguration,
                  currentBuyingPharmacyConfig,
              }
          })
        }
        syncDaylightRxUser();
        interval = setInterval(syncDaylightRxUser, 10000);
      }
      return () => {
        if (interval) {
          clearInterval(interval);
        }
      }
    }, [user, pharmacies, currentBuyingPharmacyId, currentBuyingPharmacyConfig, enhancedClientVersion]);

    const reviewEnhancementState = () => {
        const currTime = new Date().getTime();
        if (currTime - currentEnhancementState.createdAt < 10000) {
            return;
        }
        const expectedSuppliers: Set<string> = currentEnhancementState.requestBatch.reduce((p: Set<string>, c: RequestEnhanceItemParam) => {
            p.add(c.supplier);
            return p;
        }, new Set<string>());

        const newSummary = JSON.parse(JSON.stringify(currentEnhancementState.supplierProblems));
        expectedSuppliers.forEach(s => {
            if (currentEnhancementState.suppliersSeen[s]) {
                clearSupplierProblemReports(s, newSummary);
            } else {
                observeSupplierProblemReport(s, newSummary, currentEnhancementState.requestBatch.filter(p => p.supplier === s));
            }
        })
        updateCurrentEnhancementState({type: "update", supplierProblems: newSummary});
    }

    useEffect(() => {
        if(enhancementReviewTimestamp > 0) {
            reviewEnhancementState();
        }
    }, [enhancementReviewTimestamp])

    const requestEnhanceItems: RequestEnhanceItemsFunc = (traceId: string, batch: RequestEnhanceItemParam[]) => {
        if (batch.length === 0) {
            return null;
        }
        updateCurrentEnhancementState({
            type: "create",
            isShoppingList: false,
            traceId: traceId,
            requestBatch: batch,
        });
        console.log("Tracing", traceId);
        setTimeout(() => {setEnhancementReviewTimestamp(new Date().getTime())}, ENHANCEMENT_REVIEW_TIME_MS);
        return traceId;
    }

    const requestAppendEnhanceItems: RequestEnhanceItemsFunc = useCallback((traceId: string, batch: RequestEnhanceItemParam[]) => {
      if (batch.length === 0) {
        return null;
      }
      updateCurrentEnhancementState({
        type: "append",
        traceId: traceId,
        requestBatch: batch
      });
      console.log("Appending", traceId);
      return traceId;
    }, []);

    type traceId = string;
    type SubmittedRequests = Record<traceId, {traceId: traceId, suppliers: string[], timestamp: number}>;
    const [submittedRequests, setSubmittedRequests] = useState<SubmittedRequests>({});
    useEffect(() => {
      const traceId = currentEnhancementState.traceId;
      const newSubmittedRequests = {...submittedRequests};
      if (currentEnhancementState.isShoppingList) {
        console.log("EnhancedClientContext:shoppingList observed; individual requests ignored", traceId);
        return;
      }
      if (traceId) {
        let submittedForTraceId = newSubmittedRequests[traceId];
        if (!submittedForTraceId) {
          submittedForTraceId = {
            traceId: traceId,
            suppliers: [],
            timestamp: Date.now()
          };
          newSubmittedRequests[traceId] = submittedForTraceId;
        }

        const enhancementRequests: RequestEnhanceItemParam[] = [];
        currentEnhancementState.requestBatch.forEach((req) => {
          if (!submittedForTraceId.suppliers.find((s) => s === req.supplier)) {
            submittedForTraceId.suppliers.push(req.supplier);
            enhancementRequests.push(req);
          }
        })

        if (enhancementRequests.length > 0) {
          console.log("EnhancedClientContext:requestingEnhancedItems", traceId);
          window.postMessage({
            channel: "daylightrxenhanced",
            sender: "web",
            msgType: "requestEnhanceBatch",
            payload: {
              traceId: traceId,
              enhancementRequests: enhancementRequests
            }
          });
          setSubmittedRequests(newSubmittedRequests);
        }
      }

    }, [currentEnhancementState, submittedRequests])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (Object.keys(submittedRequests).length > 5) {
        const newSubmittedRequests = {...submittedRequests};
        let changed = false;
        // sort keys by timestamp
        const sortedKeys = Object.keys(newSubmittedRequests).sort((a, b) => newSubmittedRequests[a].timestamp - newSubmittedRequests[b].timestamp);
        while (sortedKeys.length > 5) {
          const key = sortedKeys.shift();
          if (key) {
            console.log("EnhancedClientContext:deletingOldSubmittedRequest", key);
            delete newSubmittedRequests[key];
            changed = true;
          }
        }
        if (changed) {
          setSubmittedRequests(newSubmittedRequests);
        }
      }
    }, 60 * 1000);
    return () => {
      clearTimeout(timeout);
    }
  }, [submittedRequests]);

    const requestEnhanceShoppingList: RequestEnhanceShoppingListFunc = (traceId: string, batch: RequestEnhanceItemParam[], treatAsFirstSearch: boolean) => {
        if (batch.length === 0) {
            return null;
        }
        console.log("EnhancedClientContext:requestEnhanceShoppingList", traceId);
        window.postMessage({
            channel: "daylightrxenhanced",
            sender: "web",
            msgType: "requestEnhanceShoppingList",
            payload: {
                traceId: traceId,
                enhancementRequests: batch,
                runMultiplePerSupplier: true,
                treatAsFirstSearch: treatAsFirstSearch,
            }
        });
        updateCurrentEnhancementState({
            type: "create",
            isShoppingList: true,
            traceId: traceId,
            requestBatch: batch,
        });
        setCurrentShoppingListContext({
            request: {
                traceId: traceId,
                enhancementRequests: batch,
                runMultiplePerSupplier: true,
            },
            state: "requested",
            supplierRequestParams: {},
            supplierRequestAbortPoints: {},
            supplierRequestCursors: {},
            supplierAlreadyRefreshedOnce: {},
            supplierStatus: {},
            completions: [],
            reenqueuedCompletions: [],
        })
        return traceId;
    }

    const abortShoppingListSearch = () => {
        console.log("EnhancedClientContext:abortShoppingListSearch");
        window.postMessage({
            channel: "daylightrxenhanced",
            sender: "web",
            msgType: "abortShoppingListSearch",
            payload: {
            }
        });
    }

    const deactivateShoppingListSupplier = (supplier: string) => {
        console.log("EnhancedClientContext:deactivateShoppingListSupplier", supplier);
        window.postMessage({
            channel: "daylightrxenhanced",
            sender: "web",
            msgType: "deactivateShoppingListSupplier",
            payload: supplier,
        });
    }

    const reactivateShoppingListSupplier = (supplier: string) => {
        console.log("EnhancedClientContext:reactivateShoppingListSupplier", supplier);
        window.postMessage({
            channel: "daylightrxenhanced",
            sender: "web",
            msgType: "reactivateShoppingListSupplier",
            payload: supplier,
        });
    }

    const requestClearEnhancedOverlay: VoidFunc = () => {
        console.log("EnhancedClientContext:requestClearEnhancedOverlay");
        window.postMessage({
            channel: "daylightrxenhanced",
            sender: "web",
            msgType: "requestClearEnhancedOverlay",
        });
    }

    const showLogin: PokeSupplierFunc = useCallback((name, data) => {
        setElementOverlayCenterX(data.center_x);
        setElementOverlayY(data.y);
        window.postMessage({
            channel: "daylightrxenhanced",
            sender: "web",
            msgType: "showElement",
            payload: {
                name,
                elementType: 'login',
                center_x: data.center_x,
                y: data.y,
                max_h: 640,
            },
        });
    }, [setElementOverlayCenterX, setElementOverlayY]);

    const reportProblem: ReportProblemFunc = (problemReport) => {
        window.postMessage({
            channel: "daylightrxenhanced",
            sender: "web",
            msgType: "reportProblem",
            payload: problemReport,
        })
    }

    useEffect(() => {
        if(twoFARequestedBy) {
            console.log("EnhancedClientContext:posting showElement for 2FA", twoFARequestedBy, elementOverlayCenterX, elementOverlayY);
            window.postMessage({
                channel: "daylightrxenhanced",
                sender: "web",
                msgType: "showElement",
                payload: {
                    name: twoFARequestedBy,
                    elementType: '2FA',
                    center_x: elementOverlayCenterX,
                    y: elementOverlayY,
                    max_h: 640,
                },
            });
            setTwoFARequestedBy(undefined);
        }
    }, [elementOverlayCenterX, elementOverlayY, twoFARequestedBy]);

    const clearEnhancementCache: VoidFunc = () => {
        window.postMessage({
            channel: "daylightrxenhanced",
            sender: "web",
            msgType: "clearEnhancementCache",
        })
    }

    const requestRestart: VoidFunc = () => {
        window.postMessage({
            channel: "daylightrxenhanced",
            sender: "web",
            msgType: "requestRestart",
        })
    }

    const observeSupplierProblemReport = (supplier: string, summary: Record<string, EnhancedSupplierProblemObserver>, requestedEnhancements: RequestEnhanceItemParam[]) => {
        const currTime = new Date().getTime();
        if (!summary[supplier]) {
            summary[supplier] = {
                reported: false,
                isProblem: false,
                instanceTimes: []
            };
        }
        summary[supplier].instanceTimes = summary[supplier].instanceTimes.filter(t => t > currTime - (5 * 60 * 1000));
        summary[supplier].instanceTimes.push(currTime);
        if (summary[supplier].instanceTimes.length > 0) {
            summary[supplier].isProblem = true;

            if (!summary[supplier].reported) {
                summary[supplier].reported = true;
                reportProblem({
                    supplier: supplier,
                    user: user,
                    currentPharmacy: currentBuyingPharmacy || undefined,
                    reportId: uuidv4(),
                    problemDescription: 'Automatic Report: Too many unverified records',
                    requestedEnhancements
                });
            }
        }
    }

    const clearSupplierProblemReports = (supplier: string, summary: Record<string, EnhancedSupplierProblemObserver>) => {
        if (!summary[supplier]) {
            summary[supplier] = {
                reported: false,
                isProblem: false,
                instanceTimes: []
            };
        }
        summary[supplier].instanceTimes = [];
        summary[supplier].isProblem = false;
    }

    const eCommerceAvailable = useMemo(() => {
      return enhancedSupplierConnectionStatuses['daylightrx_ecommerce']?.loggedIn;
    }, [enhancedSupplierConnectionStatuses]);

    return (
        <EnhancedClientContext.Provider
            value={{
                requestEnhanceItems,
                requestAppendEnhanceItems,
                requestEnhanceShoppingList,
                currentEnhancementState,
                enhancedOverlayShown,
                requestClearEnhancedOverlay,
                refreshSuppliers,
                enhancedSettingsModalShown,
                setEnhancedSettingsModalShown,
                enhancedReportProblemModalShown,
                setEnhancedReportProblemModalShown,
                reportProblem,
                enhancedSupplierConnectionStatuses,
                showLogin,
                clearEnhancementCache,
                requestRestart,
                enhancementTimeout,
                setEnhancementTimeout,
                currentShoppingListContext,
                isShoppingListLocked,
                abortShoppingListSearch,
                deactivateShoppingListSupplier,
                reactivateShoppingListSupplier,
                eCommerceAvailable,
                skippedSuppliers,
                skipSupplier,
                unskipSupplier,
            }}
        >
            <Global styles={css`
                .enhanced-message-notice-content {
                    background-color: #324dc7 !important;
                    color: white;
                    border: 2px solid white !important;
                }
            `} />
            {contextHolder}
            {children}
        </EnhancedClientContext.Provider>
    );
}
