import { useMemo } from "react";
import type { DrugInfo } from "../utilities/types";

import { Modal } from "antd";
import { Image as ImageComponent } from "./Image";
import { Box } from "./rxLibrary/box";
import { Button } from "./rxLibrary/buttons";

import { formatNdc } from "../utilities/ndc/formatNdc";
import { formatDrugNameWithDetails } from "../utilities/drugInfo/formatDrugNameWithDetails";
import { formatDrugDispenseQuantity } from "../utilities/drugInfo/formatDrugDispenseQuantity";

export function DrugModal({
  drugInfo,
  onClose,
}: {
  drugInfo: DrugInfo;
  onClose: () => void;
}) {
  const drugNameWithDetails = useMemo(
    () => formatDrugNameWithDetails(drugInfo),
    [drugInfo]
  );

  const quantityDescription = useMemo(
    () => formatDrugDispenseQuantity(drugInfo),
    [drugInfo]
  );

  return (
    <Modal
      open
      onCancel={onClose}
      footer={null}
      closeIcon={null}
      width="800px"
      styles={{
        content: {
          padding: "40px",
        },
        mask: {
          backgroundColor: "rgba(255, 255, 255, 0.84)",
        },
      }}
    >
      <div tw="flex gap-x-[25px]">
        <div tw="w-1/3 shrink-0">
          <ImageComponent src={drugInfo.imageURI} tw="w-full object-contain" />
        </div>

        <div tw="flex-1">
          <div tw="flex flex-wrap mb-[10px] gap-x-[6px]">
            <Box
              bgColor="green-1"
              bgColorOpacity={20}
              tw="px-[5px] py-[2px] rounded-[3px] uppercase font-medium"
            >
              {drugInfo.isBrand ? "Brand" : "Generic"}
            </Box>

            <Box
              bgColor="green-1"
              bgColorOpacity={20}
              tw="px-[5px] py-[2px] rounded-[3px] uppercase font-medium"
            >
              {drugInfo.isRx ? "RX" : "OTC"}
            </Box>

            {drugInfo.controlledSubstanceCode && (
              <Box
                bgColor="green-1"
                bgColorOpacity={20}
                tw="px-[5px] py-[2px] rounded-[3px] uppercase font-medium"
              >
                {`Schedule ${
                  drugInfo.controlledSubstanceCode === "2"
                    ? "II"
                    : drugInfo.controlledSubstanceCode === "3"
                    ? "III"
                    : drugInfo.controlledSubstanceCode === "4"
                    ? "IV"
                    : drugInfo.controlledSubstanceCode === "5"
                    ? "V"
                    : drugInfo.controlledSubstanceCode
                }`}
              </Box>
            )}

            {drugInfo.isNarrowTherapeuticIndex && (
              <Box
                bgColor="green-1"
                bgColorOpacity={20}
                tw="px-[5px] py-[2px] rounded-[3px] uppercase font-medium"
              >
                Narrow Therapeutic
              </Box>
            )}

            {drugInfo.teeCode && (
              <Box
                bgColor="green-1"
                bgColorOpacity={20}
                tw="px-[5px] py-[2px] rounded-[3px] uppercase font-medium"
              >
                Tee Code: {drugInfo.teeCode}
              </Box>
            )}
          </div>

          <h4 tw="text-[21px] mb-[5px] font-bold">{drugNameWithDetails}</h4>

          <div tw="text-[16px] mb-[2px] font-bold">{quantityDescription}</div>

          <div tw="text-[16px] mb-[2px]">
            <strong>Manufacturer:</strong> {drugInfo.manufacturer}
          </div>

          <Box
            borderColor="grey-2"
            tw="text-[16px] pb-[15px] mb-[15px] border-b border-dashed"
          >
            <strong>NDC:</strong> {formatNdc(drugInfo.ndc)}
          </Box>

          <div tw="text-[16px] mb-[2px]">
            <strong>Form:</strong> {drugInfo.form}
          </div>

          <div tw="text-[16px] mb-[2px]">
            <strong>Route of Administration:</strong>{" "}
            {drugInfo.routeOfAdministration}
          </div>

          <div tw="text-[16px] mb-[2px]">
            <strong>Package:</strong> {drugInfo.packageDescription}
          </div>
        </div>
      </div>

      <Box
        borderColor="grey-2"
        tw="flex justify-center border-t mt-[20px] pt-[20px]"
      >
        <Button onClick={onClose} variant="secondary">
          Done
        </Button>
      </Box>
    </Modal>
  );
}
