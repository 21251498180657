import { Modal, ModalFuncProps } from "antd";
import type { ReactNode } from "react";
import { Text } from "../../components/rxLibrary/typography";
import { SimpleLink } from "../../components/rxLibrary/Link";
import { CHAT_BUTTON_Z_INDEX } from "../config";

export function displayErrorModal({
  title = "There was a problem",
  email = "support@daylightrx.com",
  okText,
  content,
  onOk,
}: {
  title?: string;
  email?: string;
  okText?: string;
  content?: ReactNode;
  onOk?: ModalFuncProps["onOk"];
} = {}) {
  Modal.error({
    onOk,
    title,
    width: 450,
    okText,
    icon: null,
    zIndex: CHAT_BUTTON_Z_INDEX - 1,
    style: { top: 130 },
    content: content || (
      <Text>
        Please try again or contact us via the orange chat widget below or at{" "}
        <SimpleLink href={`mailto:${email}`}>{email}</SimpleLink>
      </Text>
    ),
  });
}
