import { useContext, useEffect } from "react";
import AppContext from "../../../../contexts/AppContext";
import { TEST_PHARMACY_ID } from "../../../../utilities/config";
import { useBuyingPharmacy } from "../../../../contexts/BuyingPharmacyContext";
import { useClientPageAlert } from "../../../../contexts/ClientPageAlertContext";

export function useOrderNotSentToSupplierAdminWarning() {
  const { setClientPageAlert } = useClientPageAlert();
  const { user } = useContext(AppContext);
  const { currentBuyingPharmacy } = useBuyingPharmacy();
  const { id: pharmacyId, name } = currentBuyingPharmacy ?? {};
  const isAdmin = user?.is_admin;

  useEffect(() => {
    const alert =
      isAdmin && pharmacyId === TEST_PHARMACY_ID
        ? `Orders for administrators at ${name} will not be sent to supplier.`
        : undefined;
    setClientPageAlert(alert);

    return () => setClientPageAlert(undefined);
  }, [name, isAdmin, pharmacyId, setClientPageAlert]);
}
