import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  currencyFormatter,
  numberFormatter,
} from "../../utils/performanceReportFormatters";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    field: "pharmacyName",
    headerName: "Pharmacy",
    pinned: "left",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "programName",
    headerName: "Manufacturer",
    flex: 1,
    minWidth: 225,
  },
  {
    field: "month",
    headerName: "Time Period",
    flex: 1,
    minWidth: 150,
    valueFormatter: (p) => {
      const date = new Date(p.value + "-01");
      return date.toLocaleString("default", {
        month: "long",
        year: "numeric",
        timeZone: "UTC",
      });
    },
    sort: "desc",
    sortIndex: 0,
  },
  {
    field: "units",
    headerName: "Adjudicated Rxs",
    valueFormatter: ({ value }) => numberFormatter(value),
    flex: 1,
    minWidth: 125,
  },
  {
    field: "serviceFee",
    headerName: "Fee",
    valueFormatter: ({ value }) => currencyFormatter(value),
    flex: 1,
    minWidth: 125,
  },
  {
    field: "totalEarned",
    headerName: "Total Earned",
    valueFormatter: ({ value }) => currencyFormatter(value),
    flex: 1,
    minWidth: 125,
  },
  {
    field: "note",
    headerName: "Note",
    flex: 1,
    minWidth: 125,
  },
];
