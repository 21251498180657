import { useCallback, useMemo, useState } from "react";
import { Form, InputNumber, message } from "antd";
import type { RefillItemDrugItem } from "./RefillOpportunitiesAcquisitionCost.utils";
import { currencyWithCentsAtAllTimes } from "../../../utilities/numbers/currency";
import {
  createPharmacyRefillItem,
  updatePharmacyRefillItem,
} from "../../../services/pharmacies";
import { DrugDetailsCol } from "../../shopping/table/columns/DrugDetailsCol";
import { Button } from "../../rxLibrary/buttons";
import { IconCheck } from "../../rxLibrary/icons";
import { Box } from "../../rxLibrary/box";

function formatInitialCost(initialCostStr: string | undefined) {
  if (initialCostStr === undefined) return;
  const initialCost = Number(initialCostStr);
  if (!isNaN(initialCost)) return initialCost;
}

function currencyToNumber(value?: any) {
  if (value === "" || value === null || value === undefined) return;

  const valueStr = String(value);
  let valueWithoutCommas = valueStr.replace("$", "");
  const numberPartsWithComma = valueStr.match(/(,\d{3})/gm);
  if (numberPartsWithComma) {
    // Remove commas from the number, example: 1,000,000.12 => 1000000.12
    valueWithoutCommas = numberPartsWithComma.reduce(
      (tmpValue, numberPartWithComma) => {
        const numberPartWithoutComma = numberPartWithComma.replace(",", "");
        return tmpValue.replace(numberPartWithComma, numberPartWithoutComma);
      },
      valueStr
    );
  }

  const valueNum = Number(valueWithoutCommas);
  if (!isNaN(valueNum)) return valueNum;
}

function numberToCurrency(
  value: any,
  info: { userTyping: boolean; input: string }
): string {
  if (value === "" || value === null || value === undefined) return "";
  if (info?.userTyping) return value.toString();

  const currency = currencyWithCentsAtAllTimes(value, true);
  return currency;
}

export function RefillOpportunitiesAcquisitionCostRefillItem({
  drugItem,
  supplierId,
  pharmacyId,
  enableRefetchOnClose,
}: {
  drugItem: RefillItemDrugItem;
  supplierId: number;
  pharmacyId: number;
  enableRefetchOnClose: () => void;
}) {
  type FormValues = { cost: number };

  const [form] = Form.useForm<FormValues>();
  const currentCost = Form.useWatch("cost", form);
  const { ndc, drug, cost: initialCostStr, hasRefillItem } = drugItem;
  const drugName = drug.name;
  const [isNew, setIsNew] = useState(!hasRefillItem);

  const initialCost = useMemo(() => {
    return formatInitialCost(initialCostStr);
  }, [initialCostStr]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        const action = isNew
          ? createPharmacyRefillItem
          : updatePharmacyRefillItem;
        await action({
          ndc,
          cost: values.cost,
          supplierId,
          pharmacyId,
        });
        setIsNew(false);
        message.success(`Cost saved for ${drugName} - NDC: ${ndc}`);
        enableRefetchOnClose();
      } catch (error) {
        message.error(`Error saving cost for ${drugName} - NDC: ${ndc}`);
      }
    },
    [
      ndc,
      isNew,
      drugName,
      supplierId,
      pharmacyId,
      enableRefetchOnClose,
    ]
  );

  return (
    <Box bgColor="white-1" borderColor="grey-2" tw="border p-2 rounded-md">
      <Form
        layout="inline"
        form={form}
        initialValues={{ cost: initialCost }}
        onFinish={handleSubmit}
      >
        <div tw="flex w-full items-center justify-between space-x-2">
          <DrugDetailsCol tw="w-[auto] border-none text-left" drug={drug} />

          <div tw="flex items-center justify-between space-x-2">
            <Form.Item
              noStyle
              name="cost"
              rules={[{ required: true }, { type: "number" }]}
            >
              <InputNumber
                tw="w-[110px]"
                min={0}
                step={0.01}
                addonBefore="$"
                parser={currencyToNumber}
                formatter={numberToCurrency}
              />
            </Form.Item>

            <Button
              icon={<IconCheck />}
              disabled={currentCost === undefined}
              type="submit"
            >
              Apply
            </Button>
          </div>
        </div>
      </Form>
    </Box>
  );
}
