import React, { useEffect, useState } from "react";
import "twin.macro";
import { Link } from "react-router-dom";
import { DatePicker, Form, Popconfirm, Select } from "antd";
import type { SelectOption } from "../../../../../components/rxLibrary/selects/select";
import { UpsertAdminRefillMapping } from "../../../../../utilities/types";
import {
  OnSubmit,
  useHandleSubmit,
} from "../../../../../components/antd/form/useHandleSubmit";
import { FormGrid } from "../../../../../components/antd/form/FormGrid";
import { AdminManagementHeader } from "../components/AdminManagementHeader";
import { Button } from "../../../../../components/rxLibrary/buttons";
import { useRequest } from "../../../../../services/request/useRequest";
import { getAdminRefillMappingOptions } from "../../../../../services/refillMappings";

function useAdminRefillMappingOptions() {
  const [options, setOptions] = useState<{
    pharmacyOptions?: SelectOption[];
    manufacturerOptions?: SelectOption[];
  }>({});

  const { data } = useRequest({
    dataKey: "refillMappingOptions",
    request: getAdminRefillMappingOptions,
  });

  useEffect(() => {
    if (!data) return;

    const newOptions = {
      pharmacyOptions: data.pharmacy.map((v) => ({
        label: v.name,
        value: v.id,
      })),
      manufacturerOptions: data.manufacturer.map((v) => ({
        label: v.name,
        value: v.id,
      })),
    };

    setOptions(newOptions);
  }, [data]);

  return options;
}

export function AdminRefillMappingForm({
  onSubmit,
}: {
  onSubmit: OnSubmit<UpsertAdminRefillMapping>;
}) {
  const { pharmacyOptions, manufacturerOptions } =
    useAdminRefillMappingOptions();
  const { form, isSubmitting, handleSubmit } = useHandleSubmit({
    onSubmit,
  });

  return (
    <>
      <AdminManagementHeader
        breadcrumbs={[
          { title: <Link to="/admin/refill-mapping">Refill Mapping</Link> },
          { title: "Create Refill Mapping" },
        ]}
      />

      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        disabled={isSubmitting}
        scrollToFirstError
      >
        <FormGrid>
          <Form.Item
            name="pharmacyId"
            label="Pharmacy"
            rules={[{ required: true }]}
          >
            <Select
              options={pharmacyOptions}
              optionFilterProp="label"
              showSearch
              allowClear
            />
          </Form.Item>

          <Form.Item
            name="manufacturerId"
            label="Manufacturer"
            rules={[{ required: true }]}
          >
            <Select
              options={manufacturerOptions}
              optionFilterProp="label"
              showSearch
              allowClear
            />
          </Form.Item>

          <Form.Item name="reportStartDate" label="Report Start Date">
            <DatePicker tw="w-full" format="DD MMM YYYY" allowClear />
          </Form.Item>
        </FormGrid>

        <Popconfirm title="Sure to continue?" onConfirm={form.submit}>
          <Button disabled={isSubmitting}>Create Refill Mapping</Button>
        </Popconfirm>
      </Form>
    </>
  );
}
