import React, { useCallback, useMemo, useState } from "react";
import tw from "twin.macro";
import { Checkbox } from "antd";
import type {
  CompareResultsData,
  NdcResults
} from "../useCompareResults/useCompareResults";
import { ListCategoryEnum } from "../../../../../utilities/types";
import { Container } from "../../../../../components/containers/Container";
import { Box } from "../../../../../components/rxLibrary/box";
import { Switch } from "../../../../../components/rxLibrary/switch";
import { useCompare } from "../../../useCompare/useCompare";
import { CompareCard } from "./CompareCard/CompareCard";
import { formatTeeCodeFilterLabel } from "./formatTeeCodeFilterLabel";

export function CompareResultsCards({
                                      compareResults,
                                    }: {
  compareResults: CompareResultsData & {ndcResults: NdcResults},
}) {
  const [showDiffQuantities, setShowDiffQuantities] = useState(true);
  const [showDiffManufacturers, setShowDiffManufacturers] = useState(true);
  const { ndc } = useCompare();
  const { topCards, bottomCards, origMatch, totalCardCount, mainNdcOffering } =
    compareResults.ndcResults;
  const topCardItem = topCards[0]?.featured?.item;
  const { drugInfo: topCardDrug, suggestedReason: topCardSuggestedReason } =
    topCardItem ?? {};

  const visibleBottomCards = useMemo(() => {
    return bottomCards.filter(({ category }) => {
      switch (category) {
        case ListCategoryEnum.DIFFERENT_QUANTITIES:
          return showDiffQuantities;
        case ListCategoryEnum.DIFFERENT_MANUFACTURERS:
          return showDiffManufacturers;
        case ListCategoryEnum.DIFFERENT_MANUFACTURER_AND_QUANTITY:
          return showDiffManufacturers && showDiffQuantities;
      }
      return true;
    });
  }, [bottomCards, showDiffQuantities, showDiffManufacturers]);

  const toggleShowDiffQuantities = useCallback(() => {
    setShowDiffQuantities((prev) => !prev);
  }, []);

  const toggleShowDiffManufacturers = useCallback(() => {
    setShowDiffManufacturers((prev) => !prev);
  }, []);

  const teeCodeFilterLabel = useMemo(() => {
    return formatTeeCodeFilterLabel({
      showTeeCode: compareResults.showTeeCode,
      topCardDrug,
      topCardSuggestedReason,
    });
  }, [compareResults, topCardDrug, topCardSuggestedReason]);

  return (
    <>
      {(topCards.length === 0 ||
        (origMatch.category !== ListCategoryEnum.NDC_MATCH &&
          origMatch.category !== ListCategoryEnum.SUGGESTED_NDC)) && (
        <Container tw="text-center pb-6 text-lg">
          {ndc} not found in any supplier catalogs. Alternatives below.
        </Container>
      )}

      <Container
        tw="space-y-6"
        css={bottomCards.length ? tw`mb-[93px]` : tw`mb-[192px]`}
      >
        {!!teeCodeFilterLabel && (
          <div tw="flex items-center space-x-1">
            <Switch
              value={compareResults.teeCodeFilterValue}
              onClick={compareResults.toggleTeeCodeFilterValue}
            />
            <p>{teeCodeFilterLabel}</p>
          </div>
        )}

        {topCards.map((ndcOfferings) => (
          <CompareCard
            key={ndcOfferings.ndc}
            ndcOfferings={ndcOfferings}
            totalCardCount={totalCardCount}
            mainNdcOffering={mainNdcOffering}
            showTeeCodes={compareResults.showTeeCode}
          />
        ))}
      </Container>

      {!!bottomCards.length && (
        <Box bgColor="grey-2">
          <Container tw="py-[82px] pb-[25px]">
            <div tw="flex justify-between pb-8 items-center">
              <div tw="font-medium text-2xl ml-4">Higher Priced Items</div>

              <div tw="font-medium text-sm">
                <Checkbox
                  checked={showDiffQuantities}
                  onClick={toggleShowDiffQuantities}
                />{" "}
                Show different quantities
              </div>

              <div tw="font-medium text-sm mr-4">
                <Checkbox
                  checked={showDiffManufacturers}
                  onClick={toggleShowDiffManufacturers}
                />{" "}
                Show other manufacturer options
              </div>
            </div>

            <div tw="space-y-6">
              {visibleBottomCards.map((ndcOfferings) => (
                <CompareCard
                  key={ndcOfferings.ndc}
                  ndcOfferings={ndcOfferings}
                  totalCardCount={totalCardCount}
                  mainNdcOffering={mainNdcOffering}
                  showTeeCodes={compareResults.showTeeCode}
                  isBelowTheFold
                />
              ))}
            </div>
          </Container>
        </Box>
      )}
    </>
  );
}
