import { useMemo } from "react";
import type { TaggedItem } from "../../../../../../utilities/types";

export function CompareCardItemProjection({ item }: { item: TaggedItem }) {
  const { numberOfUnitsLast30Days } = item.historicalDispensationInfo;
  const { unitSize } = item.drugInfo;

  const msg = useMemo(() => {
    if (numberOfUnitsLast30Days === 0) return "Not dispensed in 30+ days";

    const projection = unitSize / (numberOfUnitsLast30Days / 30);
    if (projection === 0) return "Not dispensed in 30+ days";
    if (projection >= 180) return "Over 180 Days To Dispense";

    const days = projection < 1 ? 1 : Math.round(projection).toLocaleString();
    return `${days} Days To Dispense (Est.)`;
  }, [unitSize, numberOfUnitsLast30Days]);

  return <p tw="text-[13px] font-bold">{msg}</p>;
}
