import { AgGridReact } from "ag-grid-react";
import { ROW_CLASS_RULES } from "../../utils/reportsConstants";
import { ReportWrapper } from "../../utils/ReportWrapper";
import { useReport } from "../../utils/useReport/useReport";
import { COLUMN_DEFS } from "./PlanGroup.constants";

export function CompetitorPlanGroup({ data }: { data: never[] }) {
  const report = useReport({
    columnDefs: COLUMN_DEFS,
    exportToolPanelProps: { data },
  });

  return (
    <ReportWrapper>
      <AgGridReact
        {...report}
        masterDetail
        detailRowAutoHeight
        pagination
        rowData={data}
        rowSelection="multiple"
        rowClassRules={ROW_CLASS_RULES}
        rowGroupPanelShow="always"
      />
    </ReportWrapper>
  );
}
