import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import "twin.macro";
import { Tooltip, message } from "antd";
import { Link } from "react-router-dom";
import keyBy from "lodash/keyBy";
import {
  Button,
  IconButton,
} from "../../../../../components/rxLibrary/buttons";
import { getNumberSorter } from "../../../../../components/antd/table/getNumberSorter";
import { TableColumns } from "../../../../../components/antd/table/table.constants";
import { AdminManagementTable } from "../components/AdminManagementTable";
import { AdminManagementHeader } from "../components/AdminManagementHeader";
import { IconPlus, IconTrash } from "../../../../../components/rxLibrary/icons";
import { useRequest } from "../../../../../services/request/useRequest";
import {
  deleteAdminRefillMapping,
  getAdminRefillMappingOptions,
  getAdminRefillMappings,
} from "../../../../../services/refillMappings";
import {
  AdminRefillMapping,
  AdminRefillMappingOptions,
} from "../../../../../utilities/types";
import { getTextSorter } from "../../../../../components/antd/table/getTextSorter";
import { renderAdminPharmacy } from "../../../../../components/antd/table/renderAdminPharmacy";
import { renderAdminManufacturer } from "../../../../../components/antd/table/renderAdminManufacturer";
import { renderTimeSinceADate } from "../../../../../components/antd/table/renderTimeSinceADate";
import { getDateSorter } from "../../../../../components/antd/table/getDateSorter";
import { getColumnSearchProps } from "../../../../../components/antd/table/getColumnSearchProps";

type RefillMapping = AdminRefillMapping & {
  pharmacy?: AdminRefillMappingOptions["pharmacy"][number];
  manufacturer?: AdminRefillMappingOptions["manufacturer"][number];
};

const AdminRefillMappingContext = createContext({ refetch: () => {} });

const BREADCRUMBS = [{ title: "Refill Mapping" }];

const COLUMNS: TableColumns<RefillMapping> = [
  {
    dataIndex: "id",
    title: "ID",
    width: 60,
    fixed: "left",
    sorter: getNumberSorter("id"),
  },
  {
    dataIndex: "pharmacyId",
    title: "Pharmacy ID",
    width: 110,
    sorter: getNumberSorter("pharmacyId"),
  },
  {
    dataIndex: "pharmacy",
    title: "Pharmacy",
    width: 150,
    ...getColumnSearchProps("pharmacy.name"),
    sorter: getTextSorter("pharmacy.name"),
    render: renderAdminPharmacy,
  },
  {
    dataIndex: "manufacturerId",
    title: "Manufacturer ID",
    width: 110,
    sorter: getNumberSorter("manufacturerId"),
  },
  {
    dataIndex: "manufacturer",
    title: "Manufacturer",
    width: 150,
    ...getColumnSearchProps("manufacturer.name"),
    sorter: getTextSorter("manufacturer.name"),
    render: renderAdminManufacturer,
  },
  {
    dataIndex: "reportStartDate",
    title: "Report Start Date",
    width: 150,
    sorter: getDateSorter("reportStartDate"),
    render: renderTimeSinceADate,
  },
  {
    key: "actions",
    dataIndex: "id",
    title: "Actions",
    width: 80,
    fixed: "right",
    render: (_, refillMapping) => {
      const { pharmacyId, manufacturerId } = refillMapping;
      return (
        <div>
          <DeleteAdminRefillMappingBtn
            pharmacyId={pharmacyId}
            manufacturerId={manufacturerId}
          />
        </div>
      );
    },
  },
];

function DeleteAdminRefillMappingBtn({
  pharmacyId,
  manufacturerId,
}: {
  pharmacyId: number;
  manufacturerId: number;
}) {
  const { refetch } = useContext(AdminRefillMappingContext);

  const handleDelete = useCallback(async () => {
    try {
      await deleteAdminRefillMapping({
        pharmacyId,
        manufacturerId,
      });
      refetch();
    } catch (error) {
      message.error("Failed to delete refill mapping");
    }
  }, [pharmacyId, manufacturerId, refetch]);

  return (
    <Tooltip title="Delete">
      <IconButton onClick={handleDelete}>
        <IconTrash color="red-1" />
      </IconButton>
    </Tooltip>
  );
}

function useAdminRefillMappings() {
  const {
    data: baseRefillMappings,
    error,
    isLoading,
    refetch,
  } = useRequest({
    dataKey: "refillMappings",
    request: getAdminRefillMappings,
  });

  const { data: refillMappingOptions } = useRequest({
    dataKey: "refillMappingOptions",
    request: getAdminRefillMappingOptions,
  });

  const [refillMappings, setRefillMappings] = useState<RefillMapping[]>();
  const data = refillMappings ?? baseRefillMappings;

  useEffect(() => {
    if (!baseRefillMappings || !refillMappingOptions) {
      setRefillMappings(undefined);
      return;
    }

    const pharmaciesById = keyBy(refillMappingOptions.pharmacy, "id");
    const manufacturersById = keyBy(refillMappingOptions.manufacturer, "id");
    const newRefillMappings = baseRefillMappings.map<RefillMapping>((item) => ({
      ...item,
      pharmacy: pharmaciesById[item.pharmacyId],
      manufacturer: manufacturersById[item.manufacturerId],
    }));

    setRefillMappings(newRefillMappings);
  }, [baseRefillMappings, refillMappingOptions]);

  return { data, error, isLoading, refetch };
}

export function AdminRefillMappings() {
  const { data, error, isLoading, refetch } = useAdminRefillMappings();

  return (
    <>
      <AdminManagementHeader breadcrumbs={BREADCRUMBS}>
        <Link to="/admin/refill-mapping/new">
          <Button icon={<IconPlus />}>Add Refill Mapping</Button>
        </Link>
      </AdminManagementHeader>

      <AdminRefillMappingContext.Provider value={{ refetch }}>
        <AdminManagementTable
          columns={COLUMNS}
          loading={isLoading}
          dataSource={data}
          hasError={!!error}
        />
      </AdminRefillMappingContext.Provider>
    </>
  );
}
