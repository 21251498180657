import { useCallback } from "react";
import type { SupplierOrderItemRecommendation_All } from "../../../../../../services/utils";
import { useShoppingUpdater } from "../../../../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import {
  PurchaseByButton,
  CalendarFooterWarning,
} from "../../../../../../components/shopping/PurchaseByButton/PurchaseByButton";

export function ShoppingListTableRowPurchaseByButton({
  item,
  supplierId,
  hasMinShipWarning,
}: {
  item: SupplierOrderItemRecommendation_All;
  supplierId: number;
  hasMinShipWarning: boolean;
}) {
  const { moveOptimizedItemToBuyLater } = useShoppingUpdater();

  const handleUpdatePurchaseBy = useCallback(
    (date?: Date) => {
      if (!date) return;
      moveOptimizedItemToBuyLater({ item, date, supplierId });
    },
    [item, supplierId, moveOptimizedItemToBuyLater]
  );

  return (
    <PurchaseByButton
      onChange={handleUpdatePurchaseBy}
      calendarFooter={
        <CalendarFooterWarning
          text="Today's min. ship will not be met without this item."
          hide={!hasMinShipWarning}
        />
      }
      changesRequireConfirmation
      disableToday
    />
  );
}
