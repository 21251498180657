import React from "react";
import { CSVLink } from "react-csv";
import ExportOutlined from "@ant-design/icons/ExportOutlined";
import type { ExportToolPanelProps } from "../useReport/useReportSideBar";
import { Button } from "../../../../../../components/rxLibrary/buttons";
import { DownloadCsvWrapper } from "./DownloadWrapper";

export function ExportToolPanelLarge({
  exportToolPanelProps,
}: ExportToolPanelProps<{
  exportCsvHeaders: string[];
  exportAllCsvHeaders: string[];
  exportCsvData: Array<never>;
  exportAllCsvData: Array<never>;
}>) {
  const {
    exportCsvHeaders,
    exportAllCsvHeaders,
    exportCsvData,
    exportAllCsvData,
  } = exportToolPanelProps;

  return (
    <>
      <DownloadCsvWrapper text="Export table">
        <CSVLink
          data={exportCsvData}
          headers={exportCsvHeaders}
          filename="export.csv"
        >
          <Button icon={<ExportOutlined />} variant="tertiary">
            Export
          </Button>
        </CSVLink>
      </DownloadCsvWrapper>

      <DownloadCsvWrapper text="Export all fields">
        <CSVLink
          data={exportAllCsvData}
          headers={exportAllCsvHeaders}
          filename="export-all-fields.csv"
        >
          <Button icon={<ExportOutlined />} variant="tertiary">
            Export
          </Button>
        </CSVLink>
      </DownloadCsvWrapper>
    </>
  );
}
