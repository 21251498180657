import { Text } from "../../components/rxLibrary/typography";
import { Button } from "../../components/rxLibrary/buttons";
import { SimpleLink } from "../../components/rxLibrary/Link";
import { displayErrorModal } from "./displayErrorModal";

export function displayReloadPageErrorModal() {
  displayErrorModal({
    onOk: () => window.location.reload(),
    okText: "Reload Page",
    title: "There was an issue loading the page",
    content: (
      <Text>
        <Button
          tw="ml-[-4px]"
          variant="text-3"
          onClick={() => window.location.reload()}
        >
          Reload the page
        </Button>
        or contact us via the orange chat widget below or at{" "}
        <SimpleLink href={`mailto:support@daylightrx.com`}>
          support@daylightrx.com
        </SimpleLink>
      </Text>
    ),
  });
}
