import type {
  AdminPharmacyOrganization,
  UpsertAdminPharmacyOrganization,
} from "../utilities/types";
import { requestClient, RequestClientConfig } from "./request/requestClient";

export async function getAdminPharmacyOrganization({
  id,
  ...config
}: RequestClientConfig<{
  id: number | string;
}>) {
  return requestClient<{ pharmacyOrganization: AdminPharmacyOrganization }>({
    ...config,
    url: `/admin/pharmacy-organization/${id}`,
  });
}

export async function getAdminPharmacyOrganizations(
  config?: RequestClientConfig<Record<string, never>>
) {
  return requestClient<{ pharmacyOrganizations: AdminPharmacyOrganization[] }>({
    ...config,
    url: "/admin/pharmacy-organization",
  });
}

export async function createAdminPharmacyOrganization({
  name,
  ...config
}: RequestClientConfig<UpsertAdminPharmacyOrganization>) {
  return requestClient<{ pharmacyOrganization: AdminPharmacyOrganization }>({
    ...config,
    url: "/admin/pharmacy-organization",
    method: "POST",
    data: { name },
  });
}

export async function updateAdminPharmacyOrganization({
  id,
  name,
  ...config
}: RequestClientConfig<UpsertAdminPharmacyOrganization>) {
  return requestClient<{ pharmacyOrganization: AdminPharmacyOrganization }>({
    ...config,
    url: `/admin/pharmacy-organization/${id}`,
    method: "PUT",
    data: { name },
  });
}
