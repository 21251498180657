import { useMemo, useCallback } from "react";
import Select from "react-select";
import { usePerformance } from "../usePerformance";

export function PerformanceHeaderReportSelector() {
  const { reports, reportId, setReportId } = usePerformance();

  /**
   * If report is not visible (available) in pharmacy, it will be shown with an asterisk (*).
   * There is no need to check if user has permission to see the report as API will return only visible ones for normal users and all for admins
   * So if it is !r.visible, it means it is admin user anyways
   */
  const options = useMemo(() => {
    return reports.map((r) => ({
      value: r.id,
      label: !r.visible ? `${r.title} *` : r.title,
    }));
  }, [reports]);

  const selectedReport = useMemo(() => {
    return options.find((report) => report.value == reportId);
  }, [options, reportId]);

  const handleSelection = useCallback(
    (selection: typeof options[number] | null) => {
      setReportId(selection?.value);
    },
    [setReportId]
  );

  return (
    <div tw="flex space-x-2 items-center">
      <span tw="font-light">Report:</span>

      <Select
        tw="w-[192px]"
        value={selectedReport ?? null}
        options={options}
        isMulti={false}
        onChange={handleSelection}
      />
    </div>
  );
}
