import { useCallback } from "react";
import type { AxiosResponse } from "axios";
import type { RequestClientConfig } from "../../../services/request/requestClient";
import { useRequest } from "../../../services/request/useRequest";
import { TableColumns } from "./table.constants";

function defaultOptionsFormatter<I, O>(options: I): O {
  return options as unknown as O;
}

export function useTableColumnsWithOptions<
  Options,
  FormattedOptions,
  Columns extends TableColumns<any>,
  Key extends string
>({
  dataKey,
  columns,
  request,
  columnFormatter,
  optionsFormatter = defaultOptionsFormatter,
}: {
  dataKey: Key;
  columns: Columns;
  request: (config: RequestClientConfig<any>) => Promise<void | AxiosResponse<{
    data?: Record<Key, Options>;
    error?: any;
  }>>;
  columnFormatter: (
    dataIndex: string,
    options: FormattedOptions
  ) => Record<string, any> | undefined;
  optionsFormatter?: (options: Options) => FormattedOptions;
}): Columns {
  const formatter = useCallback(
    (options: Options) => {
      const formattedOptions = optionsFormatter(options);
      const newColumns = columns.map((column) => {
        if (!("dataIndex" in column)) return column;

        const { dataIndex } = column;
        if (!dataIndex || typeof dataIndex !== "string") return column;

        const extraFields = columnFormatter(dataIndex, formattedOptions);
        return extraFields ? { ...column, ...extraFields } : column;
      });

      return newColumns;
    },
    [columns, columnFormatter, optionsFormatter]
  );

  const { formattedData: columnsWithOptions } = useRequest({
    dataKey,
    request,
    formatter,
  });

  const response = (columnsWithOptions as Columns | undefined) ?? columns;
  return response;
}
