import React, { useCallback, useEffect, useRef, useState } from "react";
import { styled } from "twin.macro";
import { Affix, Button } from "antd";
import { useLocation } from "react-router-dom";
import posthog from "posthog-js";
import * as Sentry from "@sentry/react";
import { getPageVersion } from "../../services/app";
import { useIsOnline } from "../../utilities/useIsOnline/useIsOnline";
import { WHITE_1 } from "../rxLibrary/colors";
import { Text } from "../rxLibrary/typography";

const PAGE_RELOAD_BLACKLISTED_PATHS = [
  "/shoppingListTwo",
  "/shoppingListThree",
  "/supplierNavigation",
  "/supplierBrowserBar",
  "/supplierSafetyScreen",
  "/compare",
  "/download",
];

const PAGE_VERSION_CHECK_INTERVAL_MS = 1000 * 60 * 5;

export function ReloadPageAlert() {
  const initialPageVersionRef = useRef<string>();

  const [hideReloadAlert, setHideReloadAlert] = useState(true);

  const isOnline = useIsOnline();

  const { pathname } = useLocation();

  const isBlacklistedPage = PAGE_RELOAD_BLACKLISTED_PATHS.includes(pathname);

  /**
   * Note: Please whenever calling this function, make sure to check if page is online
   */
  const checkIsDifferentPageVersion = useCallback(async () => {
    try {
      const pageVersion = await getPageVersion();

      return pageVersion !== initialPageVersionRef.current;
    } catch (error) {
      console.error("Failed to fetch page version", error);
      return false;
    }
  }, []);

  useEffect(() => {
    if (!initialPageVersionRef.current || isBlacklistedPage || !isOnline) {
      return;
    }

    tryToAutoRefreshPage();

    async function tryToAutoRefreshPage() {
      const isDifferentPageVersion = await checkIsDifferentPageVersion();

      if (isDifferentPageVersion) {
        reloadPage();
      }
    }
  }, [pathname, isBlacklistedPage, isOnline, checkIsDifferentPageVersion]);

  useEffect(() => {
    if (!isOnline) return;

    let intervalId: ReturnType<typeof setInterval>;
    let timeoutId: ReturnType<typeof setTimeout>;

    initRecurrentPageVersionValidation();

    async function initRecurrentPageVersionValidation() {
      // We do not want to over fetch page version if it is already set
      // For example, if user in one moment loses connection, then comes back online

      if (!initialPageVersionRef.current) {
        try {
          const pageVersion = await getPageVersion();
          initialPageVersionRef.current = pageVersion;
          Sentry.setTag("web.pageVersion", pageVersion);
          if (posthog.has_opted_out_capturing()) {
            posthog.setPersonProperties({ pageVersion });
          }

          intervalId = setInterval(
            validatePageVersion,
            PAGE_VERSION_CHECK_INTERVAL_MS
          );
        } catch (error) {
          console.error("Failed to get initial page version", error);

          timeoutId = setTimeout(
            initRecurrentPageVersionValidation,
            PAGE_VERSION_CHECK_INTERVAL_MS
          );
        }
      } else {
        intervalId = setInterval(
          validatePageVersion,
          PAGE_VERSION_CHECK_INTERVAL_MS
        );
      }
    }

    async function validatePageVersion() {
      const isDifferentPageVersion = await checkIsDifferentPageVersion();

      if (isDifferentPageVersion) {
        setHideReloadAlert(false);
      }
    }

    return () => {
      intervalId && clearInterval(intervalId);
      timeoutId && clearTimeout(timeoutId);
    };
  }, [isOnline, checkIsDifferentPageVersion]);

  if (isBlacklistedPage || hideReloadAlert) return null;

  return (
    <Affix tw="relative z-[1299]">
      <GrowWrapper>
        <TranslateWrapper>
          <div tw="flex text-white pt-[64px]">
            <img
              tw="w-[158px]"
              src="/assets/components/refresh-page-icon.svg"
              alt="Refresh Page"
            />
            <div tw="p-[29px] flex-1">
              <div tw="mb-8 text-left">
                <Text color="white-1" size={40} block>
                  We improved some things!
                </Text>

                <Text color="white-1" size="x-large" block>
                  Please refresh this page for latest version
                </Text>
              </div>

              <YellowButton onClick={reloadPage} size="large">
                Refresh Page
              </YellowButton>
            </div>
          </div>
        </TranslateWrapper>
      </GrowWrapper>
    </Affix>
  );
}

function reloadPage() {
  window.location.reload();
}

// Component height is 50% of the viewport height, but at least 400px
const COMPONENT_HEIGHT = Math.max(
  400,
  document.documentElement.clientHeight * 0.5
);
const ANIMATION_DURATION_MS = 2500;

const YellowButton = styled(Button)`
  background-color: #ffd302;
  border-color: #ffd302;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  font-stretch: condensed;

  &.ant-btn {
    padding-left: 40px;
    padding-right: 40px;
  }

  &.ant-btn-default:hover {
    color: #fff;
    border-color: #000;
  }
`;

const GrowWrapper = styled.div`
  @keyframes growAnimation {
    from {
      height: 0;
    }
    to {
      height: ${COMPONENT_HEIGHT}px;
    }
  }

  width: 100%;
  animation: growAnimation ${ANIMATION_DURATION_MS}ms linear;
  background-color: #0a1e79;
  text-align: center;
  height: ${COMPONENT_HEIGHT}px;
  border-bottom: 2px solid ${WHITE_1};
`;

const TranslateWrapper = styled.div`
  @keyframes translateAnimation {
    from {
      transform: translateY(-${COMPONENT_HEIGHT}px);
    }
    to {
      transform: translateY(0);
    }
  }

  animation: translateAnimation ${ANIMATION_DURATION_MS}ms linear;
  justify-content: center;
  align-items: center;
  height: inherit;
  display: flex;
  height: ${COMPONENT_HEIGHT}px;
`;
