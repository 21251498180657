import { FC } from "react";
import { PharmacySummaryReport } from "./ManufacturerReports/reports/PharmacySummary/PharmacySummaryReport";
import { PharmacyReport } from "./ManufacturerReports/reports/PharmacyReport/PharmacyReport";
import { FillSummary } from "./ManufacturerReports/reports/FillSummary/FillSummary";
import { CompetitorFillSummary } from "./ManufacturerReports/reports/CompetitorFillSummary/CompetitorFillSummary";
// import { CouponAsPrimaryWithPreviousDetail } from "./ManufacturerReports/reports/CouponAsPrimaryWithPreviousDetail/CouponAsPrimaryWithPreviousDetail";
import { Occ8CouponContribution } from "./ManufacturerReports/reports/Occ8CouponContribution/Occ8CouponContribution";
import { AllClaimWithFillSummaryDispensedInProgress } from "./ManufacturerReports/reports/AllClaimWithFillSummaryDispensedInProgress/AllClaimWithFillSummaryDispensedInProgress";
import { AllClaimWithFillSummaryFirstAdjudication } from "./ManufacturerReports/reports/AllClaimWithFillSummaryFirstAdjudication/AllClaimWithFillSummaryFirstAdjudication";
import { AllFillsByStatusQuantityPayerDispensedInProgress } from "./ManufacturerReports/reports/AllFillsByPayerDispensedInProgress/AllFillsByStatusQuantityPayerDispensedInProgress/AllFillsByStatusQuantityPayerDispensedInProgress";
import { AllFillsByPayerStatusQuantityDispensedInProgress } from "./ManufacturerReports/reports/AllFillsByPayerDispensedInProgress/AllFillsByPayerStatusQuantityDispensedInProgress/AllFillsByPayerStatusQuantityDispensedInProgress";
import { AllFillsByPayerQuantityStatusDispensedInProgress } from "./ManufacturerReports/reports/AllFillsByPayerDispensedInProgress/AllFillsByPayerQuantityStatusDispensedInProgress/AllFillsByPayerQuantityStatusDispensedInProgress";
import { AllFillsByQuantityStatusPayerDispensedInProgress } from "./ManufacturerReports/reports/AllFillsByPayerDispensedInProgress/AllFillsByQuantityStatusPayerDispensedInProgress/AllFillsByQuantityStatusPayerDispensedInProgress";
import { AllClaimWithFillSummaryAbandonedTransferred } from "./ManufacturerReports/reports/AllClaimWithFillSummaryAbandonedTransferred/AllClaimWithFillSummaryAbandonedTransferred";
import { AllFillsByStatusQuantityPayerAbandonedTransferred } from "./ManufacturerReports/reports/AllFillsByPayerAbandonedTransferred/AllFillsByStatusQuantityPayerAbandonedTransferred/AllFillsByStatusQuantityPayerAbandonedTransferred";
import { AllFillsByPayerStatusQuantityAbandonedTransferred } from "./ManufacturerReports/reports/AllFillsByPayerAbandonedTransferred/AllFillsByPayerStatusQuantityAbandonedTransferred/AllFillsByPayerStatusQuantityAbandonedTransferred";
import { AllFillsByPayerQuantityStatusAbandonedTransferred } from "./ManufacturerReports/reports/AllFillsByPayerAbandonedTransferred/AllFillsByPayerQuantityStatusAbandonedTransferred/AllFillsByPayerQuantityStatusAbandonedTransferred";
import { AllFillsByQuantityStatusPayerAbandonedTransferred } from "./ManufacturerReports/reports/AllFillsByPayerAbandonedTransferred/AllFillsByQuantityStatusPayerAbandonedTransferred/AllFillsByQuantityStatusPayerAbandonedTransferred";
import { NonAdherentRefillOutreachReport } from "./ManufacturerReports/reports/NonAdherentRefillOutreach/NonAdherentRefillOutreachReport";
import { NonAdherentRefillOutreachPilot } from "./ManufacturerReports/reports/NonAdherentRefillOutreachPilot/NonAdherentRefillOutreachPilot";
import { NonAdherentRefillOutreachIncyte } from "./ManufacturerReports/reports/NonAdherentRefillOutreachIncyte/NonAdherentRefillOutreachIncyte";
import { NonAdherentRefillOutreachIncyte90To180 } from "./ManufacturerReports/reports/NonAdherentRefillOutreachIncyte90To180/NonAdherentRefillOutreachIncyte";
import { NonAdherentRefillOutreachMayne } from "./ManufacturerReports/reports/NonAdherentRefillOutreachMayne/NonAdherentRefillOutreachMayne";
import { Summary } from "./ManufacturerReports/reports/Summary/Summary";
import { CompetitorPrescriber } from "./ManufacturerReports/reports/CompetitorPrescriber/CompetitorPrescriber";
import { CompetitorPharmacy } from "./ManufacturerReports/reports/CompetitorPharmacy/CompetitorPharmacy";
import { CompetitorPlan } from "./ManufacturerReports/reports/CompetitorPlan/Plan/Plan";
import { CompetitorPlanPharmacy } from "./ManufacturerReports/reports/CompetitorPlan/PlanPharmacy/PlanPharmacy";
import { CompetitorPlanGroup } from "./ManufacturerReports/reports/CompetitorPlan/PlanGroup/PlanGroup";
import { PharmacyDataLicensing } from "./ManufacturerReports/reports/PharmacyDataLicensing/PharmacyDataLicensing";

export enum ManufacturerReportType {
  AG_CHART = "AG_CHART",
  AG_GRID = "AG_GRID",
  MODE = "MODE",
  COMING_SOON = "COMING SOON",
}

export type ManufacturerReportComponent =
  | FC<{ data: never[] }>
  | FC<{ data: never }>
  | FC<{
      data: never[];
      exportCsvData: never[];
      exportAllCsvData: never[];
    }>
  | FC<{
      data: never;
      exportCsvData: never[];
      exportAllCsvData: never[];
    }>;

export type ManufacturerReportItem = {
  key: string;
  label: string;
  reportId: number;
  reportType: ManufacturerReportType;
  Report?: ManufacturerReportComponent;
  iframeProps?: Record<string, any>;
};

type ManufacturerReportGroup = {
  key: string;
  label: string;
  children: (ManufacturerReportGroup | ManufacturerReportItem)[];
};

export type ManufacturerReport =
  | ManufacturerReportItem
  | ManufacturerReportGroup;

type BaseManufacturerReportItem = Omit<ManufacturerReportItem, "key" | "label">;
type BaseManufacturerReportGroup = Omit<
  ManufacturerReportGroup,
  "key" | "children"
> & {
  children: (BaseManufacturerReportItem | BaseManufacturerReportGroup)[];
};
export type BaseManufacturerReport =
  | BaseManufacturerReportItem
  | BaseManufacturerReportGroup;

export const MANUFACTURER_REPORTS: BaseManufacturerReport[] = [
  {
    reportId: 12, // Summary
    reportType: ManufacturerReportType.AG_CHART,
    Report: Summary,
  },
  {
    label: "All Fills",
    children: [
      {
        reportId: 13, // All Claims, Grouped by Fill for Dispensed and In Progress Fills
        reportType: ManufacturerReportType.AG_GRID,
        Report: AllClaimWithFillSummaryDispensedInProgress,
      },
      {
        reportId: 25, // All Claims, Grouped by Fill for Abandoned and Transferred Fills
        reportType: ManufacturerReportType.AG_GRID,
        Report: AllClaimWithFillSummaryAbandonedTransferred,
      },
      {
        reportId: 56, // All Claims, Grouped by Fill, with First Adjudication Date
        reportType: ManufacturerReportType.AG_GRID,
        Report: AllClaimWithFillSummaryFirstAdjudication,
      },
      {
        reportId: 10, // Basic Data (Last Claim Only)
        reportType: ManufacturerReportType.AG_GRID,
        Report: FillSummary,
      },
      {
        reportId: 59, // Basic Data for Competitors
        reportType: ManufacturerReportType.AG_GRID,
        Report: CompetitorFillSummary,
      },
    ],
  },
  {
    label: "All Fills by Payer",
    children: [
      {
        label: "Dispensed and In Progress Fills",
        children: [
          {
            reportId: 22, // By Dispensed Quantity \u2192 Dispensed Status \u2192 Payer
            reportType: ManufacturerReportType.AG_GRID,
            Report: AllFillsByQuantityStatusPayerDispensedInProgress,
          },
          {
            reportId: 14, // By Dispensed Status \u2192 Dispensed Quantity \u2192 Payer
            reportType: ManufacturerReportType.AG_GRID,
            Report: AllFillsByStatusQuantityPayerDispensedInProgress,
          },
          {
            reportId: 20, // By Payer \u2192 Dispensed Status \u2192 Dispensed Quantity
            reportType: ManufacturerReportType.AG_GRID,
            Report: AllFillsByPayerStatusQuantityDispensedInProgress,
          },
          {
            reportId: 21, // By Payer \u2192 Dispensed Quantity \u2192 Dispensed Status
            reportType: ManufacturerReportType.AG_GRID,
            Report: AllFillsByPayerQuantityStatusDispensedInProgress,
          },
        ],
      },
      {
        label: "Transferred and Abandoned Fills",
        children: [
          {
            reportId: 29, // By Dispensed Quantity \u2192 Dispensed Status \u2192 Payer
            reportType: ManufacturerReportType.AG_GRID,
            Report: AllFillsByQuantityStatusPayerAbandonedTransferred,
          },
          {
            reportId: 26, // By Dispensed Status \u2192 Dispensed Quantity \u2192 Payer
            reportType: ManufacturerReportType.AG_GRID,
            Report: AllFillsByStatusQuantityPayerAbandonedTransferred,
          },
          {
            reportId: 27, // By Payer \u2192 Dispensed Status \u2192 Dispensed Quantity
            reportType: ManufacturerReportType.AG_GRID,
            Report: AllFillsByPayerStatusQuantityAbandonedTransferred,
          },
          {
            reportId: 28, // By Payer \u2192 Dispensed Quantity \u2192 Dispensed Status
            reportType: ManufacturerReportType.AG_GRID,
            Report: AllFillsByPayerQuantityStatusAbandonedTransferred,
          },
        ],
      },
    ],
  },
  {
    label: "Coupon Card",
    children: [
      {
        reportId: 15, // OCC 8 Fills with Coupon Contribution - Dispensed Fills
        reportType: ManufacturerReportType.AG_GRID,
        Report: Occ8CouponContribution,
      },
      // report not generated after 2024-01-09 due to long processing time
      // {
      //   reportId: 23, // Coupon Card Used as Primary Payer - Dispensed Fills
      //   reportType: ManufacturerReportType.AG_GRID,
      //   Report: CouponAsPrimaryWithPreviousDetail,
      // },
    ],
  },
  {
    label: "Pharmacy",
    children: [
      {
        reportId: 31, // Pharmacy Summary
        reportType: ManufacturerReportType.AG_GRID,
        Report: PharmacySummaryReport,
      },
      {
        reportId: 24, // Pharmacy Detail
        reportType: ManufacturerReportType.AG_GRID,
        Report: PharmacyReport,
      },
    ],
  },
  {
    reportId: 32, // Non-Adherent Covered Refill Outreach, by Pharmacy
    reportType: ManufacturerReportType.AG_GRID,
    Report: NonAdherentRefillOutreachReport,
  },
  {
    reportId: 42, // Non-Adherent Covered Refill Outreach, by Pharmacy (Pilot)
    reportType: ManufacturerReportType.AG_GRID,
    Report: NonAdherentRefillOutreachPilot,
  },
  {
    reportId: 50, // Non-Adherent Covered Refill Outreach, by Pharmacy (Incyte SOON TO BE DEPRECATED)
    reportType: ManufacturerReportType.AG_GRID,
    Report: NonAdherentRefillOutreachIncyte,
  },
  {
    reportId: 51, // Non-Adherent Covered Refill Outreach, by Pharmacy (Mayne)
    reportType: ManufacturerReportType.AG_GRID,
    Report: NonAdherentRefillOutreachMayne,
  },
  {
    reportId: 53, // Non-Adherent Covered Refill Outreach, by Pharmacy (Incyte 90-180 Days)
    reportType: ManufacturerReportType.AG_GRID,
    Report: NonAdherentRefillOutreachIncyte90To180,
  },
  {
    reportId: 43, // Verity Competitor Report - Prescriber
    reportType: ManufacturerReportType.AG_GRID,
    Report: CompetitorPrescriber,
  },
  {
    reportId: 44, // Verity Competitor Report - Pharmacy
    reportType: ManufacturerReportType.AG_GRID,
    Report: CompetitorPharmacy,
  },
  {
    reportId: 58, // Pharmacy Data Licensing
    reportType: ManufacturerReportType.AG_GRID,
    Report: PharmacyDataLicensing,
  },
  {
    label: "Market by Payer",
    children: [
      {
        reportId: 46, // Verity Competitor Plan
        reportType: ManufacturerReportType.AG_GRID,
        Report: CompetitorPlan,
      },
      {
        reportId: 48, // Verity Competitor Plan/Pharmacy
        reportType: ManufacturerReportType.AG_GRID,
        Report: CompetitorPlanPharmacy,
      },
      {
        reportId: 49, // Verity Competitor Plan/Group
        reportType: ManufacturerReportType.AG_GRID,
        Report: CompetitorPlanGroup,
      },
    ],
  },
  {
    reportId: 33, // (Coming Soon) Refill Program Summary
    reportType: ManufacturerReportType.COMING_SOON,
  },
  {
    reportId: 34, // (Coming Soon)
    reportType: ManufacturerReportType.COMING_SOON,
  },
  {
    reportId: 38, // (Coming Soon)
    reportType: ManufacturerReportType.COMING_SOON,
  },
  {
    reportId: 39, // (Coming Soon)
    reportType: ManufacturerReportType.COMING_SOON,
  },
];
