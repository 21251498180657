import { formatDateToBeFormat } from "../utilities/dates/formatDateToBeFormat";
import type {
  AdminPharmacyFull,
  AdminPharmacyOptions,
  DrugInfo,
  Pharmacy,
  PharmacyRefillItem,
  UpsertAdminPharmacy,
} from "../utilities/types";
import type { RequestClientConfig } from "./request/requestClient";
import { requestClient } from "./request/requestClient";

export async function getPharmacies(
  config?: RequestClientConfig<Record<string, never>>
) {
  return requestClient<{ pharmacies: Pharmacy[] }>({
    ...config,
    url: "/pharmacies",
  });
}

export async function getAdminPharmacy({
  id,
  ...config
}: RequestClientConfig<{
  id: number | string;
}>) {
  return requestClient<{ pharmacy: AdminPharmacyFull }>({
    ...config,
    url: `/admin/pharmacy/${id}`,
  });
}

export async function getAdminPharmacies(
  config?: RequestClientConfig<Record<string, never>>
) {
  return requestClient<{ pharmacies: AdminPharmacyFull[] }>({
    ...config,
    url: "/admin/pharmacy",
  });
}

export async function getAdminPharmacyOptions(
  config?: RequestClientConfig<Record<string, never>>
) {
  return requestClient<{ pharmacyOptions: AdminPharmacyOptions }>({
    ...config,
    url: "/admin/pharmacy/options",
  });
}

export async function createAdminPharmacy({
  name,
  legalPharmacyName,
  pharmacyOrganizationId,
  deaCode,
  nabpNumber,
  license,
  timezone,
  address,
  city,
  state,
  postalCode,
  supplierScope,
  primarySupplierId,
  secondarySupplierId,
  isSynced,
  usePmsSchema,
  syncLibertyInventory,
  changeLogNote,
  pharmacyStatus,
  isIncludedInManufacturerReports,
  ein,
  justworksName,
  justworksAccountAndEinSharedAcrossPharmacies,
  bopLicenseExpiryDate,
  deaLicenseExpiryDate,
  ...config
}: RequestClientConfig<UpsertAdminPharmacy>) {
  return requestClient<{ pharmacy: AdminPharmacyFull }>({
    ...config,
    url: "/admin/pharmacy",
    method: "POST",
    data: {
      name,
      legalPharmacyName,
      pharmacyOrganizationId,
      deaCode,
      nabpNumber,
      license,
      timezone,
      address,
      city,
      state,
      postalCode,
      supplierScope,
      primarySupplierId,
      secondarySupplierId,
      isSynced,
      usePmsSchema,
      syncLibertyInventory,
      changeLogNote,
      pharmacyStatus,
      isIncludedInManufacturerReports,
      ein,
      justworksName,
      justworksAccountAndEinSharedAcrossPharmacies,
      bopLicenseExpiryDate: formatDateToBeFormat(bopLicenseExpiryDate),
      deaLicenseExpiryDate: formatDateToBeFormat(deaLicenseExpiryDate),
    },
  });
}

export async function updateAdminPharmacy({
  id,
  name,
  legalPharmacyName,
  pharmacyOrganizationId,
  deaCode,
  nabpNumber,
  license,
  timezone,
  address,
  city,
  state,
  postalCode,
  supplierScope,
  primarySupplierId,
  secondarySupplierId,
  isSynced,
  usePmsSchema,
  syncLibertyInventory,
  changeLogNote,
  pharmacyStatus,
  isIncludedInManufacturerReports,
  ein,
  justworksName,
  justworksAccountAndEinSharedAcrossPharmacies,
  bopLicenseExpiryDate,
  deaLicenseExpiryDate,
  ...config
}: RequestClientConfig<UpsertAdminPharmacy & { id: number }>) {
  return requestClient<{ pharmacy: AdminPharmacyFull }>({
    ...config,
    url: `/admin/pharmacy/${id}`,
    method: "PUT",
    data: {
      name,
      legalPharmacyName,
      pharmacyOrganizationId,
      deaCode,
      nabpNumber,
      license,
      timezone,
      address,
      city,
      state,
      postalCode,
      supplierScope,
      primarySupplierId,
      secondarySupplierId,
      isSynced,
      usePmsSchema,
      syncLibertyInventory,
      changeLogNote,
      pharmacyStatus,
      isIncludedInManufacturerReports,
      ein,
      justworksName,
      justworksAccountAndEinSharedAcrossPharmacies,
      bopLicenseExpiryDate: formatDateToBeFormat(bopLicenseExpiryDate),
      deaLicenseExpiryDate: formatDateToBeFormat(deaLicenseExpiryDate),
    },
  });
}

export async function getPharmacyRefillItemsDrugs({
  supplierId,
  ...config
}: RequestClientConfig<{
  supplierId: number;
}>) {
  return requestClient<{ drugs: DrugInfo[] }>({
    ...config,
    url: `/pharmacy/refill-item/drug/${supplierId}`,
  });
}

export async function getPharmacyRefillItems({
  pharmacyId,
  ...config
}: RequestClientConfig<{ pharmacyId: number }>) {
  return requestClient<{
    refillItemsBySupplierId: Record<string, PharmacyRefillItem[]>;
  }>({
    ...config,
    url: `/pharmacy/refill-item/${pharmacyId}`,
  });
}

export async function createPharmacyRefillItem({
  ndc,
  cost,
  supplierId,
  pharmacyId,
  ...config
}: RequestClientConfig<{
  ndc: string;
  cost: number;
  pharmacyId: number;
  supplierId: number;
}>) {
  return requestClient({
    ...config,
    url: `/pharmacy/refill-item/${pharmacyId}`,
    method: "POST",
    data: { ndc, cost, supplierId },
  });
}

export async function updatePharmacyRefillItem({
  ndc,
  cost,
  supplierId,
  pharmacyId,
  ...config
}: RequestClientConfig<{
  ndc: string;
  cost: number;
  pharmacyId: number;
  supplierId: number;
}>) {
  return requestClient({
    ...config,
    url: `/pharmacy/refill-item/${pharmacyId}`,
    method: "PUT",
    data: { ndc, cost, supplierId },
  });
}
