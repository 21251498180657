import { useCreateForm } from "../../../../../components/antd/form/useCreateForm";
import { WideContainer } from "../../../../../components/containers/WideContainer";
import { createAdminPageAlert } from "../../../../../services/pageAlerts";
import { AdminPageAlertForm } from "./AdminPageAlertForm/AdminPageAlertForm";

const DEFAULT_INITIAL = {
  pages: [],
  blacklistedPages: [],
  pharmacyIds: [],
  enabled: false,
  electronOnly: false,
  electronMaxVersion: null,
};

export function CreateAdminPageAlert() {
  const onSubmit = useCreateForm({
    redirectTo: "/admin/page-alert",
    create: createAdminPageAlert,
  });

  return (
    <WideContainer tw="pb-[200px]">
      <AdminPageAlertForm initialValues={DEFAULT_INITIAL} onSubmit={onSubmit} />
    </WideContainer>
  );
}
