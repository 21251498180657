import { Tooltip } from "antd";
import { Link } from "react-router-dom";

export function renderAdminPharmacy(
  pharmacy: { id: number; name: string } | null
) {
  if (!pharmacy) return null;
  return (
    <Tooltip title="Go to pharmacy page">
      <Link tw="underline" to={`/admin/pharmacy/${pharmacy.id}`}>
        {pharmacy.name} ({pharmacy.id})
      </Link>
    </Tooltip>
  );
}
