import React from "react";
import type { LicensingPaymentsData } from "./LicensingPayments.types";
import { LicensingPaymentsTable } from "./LicensingPaymentsTable/LicensingPaymentsTable";

export function LicensingPaymentsReport({
  data,
}: {
  data?: LicensingPaymentsData;
}) {
  if (!data) return;

  const { data: pharmacyPaymentsData } = data;

  return (
    <>
      <div tw="flex-1 h-[calc(100% - 50px)] bg-white">
        <LicensingPaymentsTable data={pharmacyPaymentsData} />
      </div>
    </>
  );
}
