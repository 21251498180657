import { AgGridReact } from "ag-grid-react";
import { ROW_CLASS_RULES } from "../utils/reportsConstants";
import { ReportWrapper } from "../utils/ReportWrapper";
import { useReport } from "../utils/useReport/useReport";
import {
  COLUMN_DEFS,
  DETAIL_CELL_RENDERER_PARAMS,
} from "./CompetitorPrescriber.constants";

export function CompetitorPrescriber({ data }: { data: never[] }) {
  const report = useReport({
    columnDefs: COLUMN_DEFS,
    exportToolPanelProps: { data },
    detailCellRendererParams: DETAIL_CELL_RENDERER_PARAMS,
  });

  return (
    <ReportWrapper>
      <AgGridReact
        {...report}
        masterDetail
        detailRowAutoHeight
        pagination
        rowData={data}
        rowSelection="multiple"
        rowClassRules={ROW_CLASS_RULES}
        rowGroupPanelShow="always"
      />
    </ReportWrapper>
  );
}
