import { useContext } from "react";
import EnhancedClientConfigContext from "../../enhanced/EnhancedClientConfigContext";
import { CompareProvider } from "./useCompare/useCompare";
import { EnhancedCompare } from "./pages/EnhancedCompare";
import { WebCompare } from "./pages/WebCompare";

export function Compare() {
  const { enhancedClientActive } = useContext(EnhancedClientConfigContext);

  return (
    <CompareProvider>
      {enhancedClientActive ? <EnhancedCompare /> : <WebCompare />}
    </CompareProvider>
  );
}
