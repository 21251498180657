import React, { ReactNode, useState } from "react";
import tw, { css } from "twin.macro";
import dayjs, { Dayjs } from "dayjs";
import { Anchor, Divider, Slider } from "antd";
import omit from "lodash/omit";
import random from "lodash/random";
import {
  Button,
  BackButton,
  IconButton,
  SyncButton,
  CopyButton,
} from "../../../components/rxLibrary/buttons";
import { Text, Title } from "../../../components/rxLibrary/typography";
import { WideContainer } from "../../../components/containers/WideContainer";
import {
  COLORS,
  RxColor,
  RxColorOpacity,
} from "../../../components/rxLibrary/colors";
import * as iconsAll from "../../../components/rxLibrary/icons";
import { ClosableTag, Tag } from "../../../components/rxLibrary/tags";
import { Switch } from "../../../components/rxLibrary/switch";
import { Checkbox } from "../../../components/rxLibrary/checkbox";
import {
  Dropdown,
  DropdownMenuOptions,
} from "../../../components/rxLibrary/dropdown";
import {
  Popover,
  useIsPopoverOpen,
} from "../../../components/rxLibrary/popover";
import { Col, InnerRow, Row } from "../../../components/rxLibrary/grid";
import { Select } from "../../../components/rxLibrary/selects/select";
import {
  SimpleTable,
  ExpandableTable,
} from "../../../components/rxLibrary/tables";
import { Warning } from "../../../components/rxLibrary/warnings";
import { QtySelect } from "../../../components/rxLibrary/selects/QtySelect";
import {
  SortBySelect,
  SortDirectionEnum,
} from "../../../components/rxLibrary/selects/SortBySelect";
import { Box } from "../../../components/rxLibrary/box";
import { DateRangePicker } from "../../../components/rxLibrary/dateRangePicker";
import { Pagination } from "../../../components/rxLibrary/pagination/Pagination";
import {
  MultiSelectCheckboxes,
  MultiSelectCheckboxesSelectedTags,
} from "../../../components/rxLibrary/selects/MultiSelectCheckboxes";
import { Modal, useModal } from "../../../components/rxLibrary/modals";
import { SimpleLink } from "../../../components/rxLibrary/Link";
import { SyncButtonAndText } from "../../../components/rxLibrary/buttons/SyncButtonAndText";

const ICONS = omit(iconsAll, ["styleSvgIcon"]);
const { IconCheck, IconX } = ICONS;

export function RxLibrary() {
  return (
    <WideContainer tw="pb-[200px]">
      <div tw="flex">
        <div tw="flex-1">
          <Title block tw="mb-4 border-b-2">
            Rx Library
          </Title>

          <Resources />
          <Colors />
          <Boxes />
          <Icons />
          <Typography />
          <Buttons />
          <Tags />
          <Warnings />
          <Switches />
          <Modals />
          <Checkboxes />
          <Dropdowns />
          <Popovers />
          <Selects />
          <DateRangePickers />
          <Grids />
          <Tables />
          <Paginations />
        </div>

        <div tw="w-[150px]">
          <Anchor
            replace
            items={[
              {
                key: "resources",
                href: "#resources",
                title: "Resources",
              },
              {
                key: "colors",
                href: "#colors",
                title: "Colors",
              },
              {
                key: "boxes",
                href: "#boxes",
                title: "Box",
              },
              {
                key: "icons",
                href: "#icons",
                title: "Icons",
              },
              {
                key: "typography",
                href: "#typography",
                title: "Typography",
              },
              {
                key: "buttons",
                href: "#buttons",
                title: "Buttons",
              },
              {
                key: "tags",
                href: "#tags",
                title: "Tags",
              },
              {
                key: "warnings",
                href: "#warnings",
                title: "Warnings",
              },
              {
                key: "switches",
                href: "#switches",
                title: "Switches",
              },
              {
                key: "modals",
                href: "#modals",
                title: "Modals",
              },
              {
                key: "checkboxes",
                href: "#checkboxes",
                title: "Checkboxes",
              },
              {
                key: "dropdowns",
                href: "#dropdowns",
                title: "Dropdowns",
              },
              {
                key: "popovers",
                href: "#popovers",
                title: "Popovers",
              },
              {
                key: "selects",
                href: "#selects",
                title: "Selects",
              },
              {
                key: "date-range-pickers",
                href: "#date-range-pickers",
                title: "Date Range Pickers",
              },
              {
                key: "grids",
                href: "#grids",
                title: "Grids",
              },
              {
                key: "tables",
                href: "#tables",
                title: "Tables",
              },
              {
                key: "paginations",
                href: "#paginations",
                title: "Paginations",
              },
            ]}
          />
        </div>
      </div>
    </WideContainer>
  );
}

function Resources() {
  return (
    <div id="resources">
      <VariantTitle>Resources</VariantTitle>
      <Variants>
        <SimpleLink
          href="https://app.zeplin.io/project/6191c505b9c2f322fa3714af/screen/644f9b678f5e62262fba6eb6"
          openInNewTab
          block
        >
          Rx Library Design - Zeplin
        </SimpleLink>

        <SimpleLink
          href="https://ant.design/components/overview"
          openInNewTab
          block
        >
          Base Components - Ant Design
        </SimpleLink>
      </Variants>
    </div>
  );
}

function Colors() {
  return (
    <div id="colors">
      <VariantTitle>Colors</VariantTitle>

      <Variants tw="grid grid-cols-12 gap-4">
        {Object.entries(COLORS).map(([name, color]) => (
          <div key={name} tw="text-center">
            <div
              tw="w-20 h-20 rounded-lg"
              style={{ backgroundColor: color as string }}
            />
            <Text block>{color}</Text>
            <Text block weight="bold">
              {name}
            </Text>
          </div>
        ))}
      </Variants>
    </div>
  );
}

function Boxes() {
  const [color, setColor] = useState<RxColor>("green-4");
  const [borderColor, setBorderColor] = useState<RxColor>("green-5");
  const [bgColor, setBgColor] = useState<RxColor>("green-3");
  const [outlineColor, setOutlineColor] = useState<RxColor>("red-1");
  const [bgColorOpacity, setBgColorOpacity] = useState<RxColorOpacity>(100);

  function renderSelectColor(value: any, setValue: (v: any) => void) {
    return (
      <div tw="flex space-x-2">
        <Select
          tw="w-[200px]"
          placeholder="Color"
          value={value}
          onChange={(c) => setValue(c)}
          options={Object.keys(COLORS).map((name: any) => ({
            label: (
              <div tw="flex items-center space-x-2">
                <Box bgColor={name} tw="w-[30px] h-[20px]" />
                <Text weight="bold">{name}</Text>
              </div>
            ),
            value: name,
          }))}
        />

        <div tw="flex items-center space-x-1">
          <Switch
            value={!!value}
            onClick={(checked) => {
              const colorKeys = Object.keys(COLORS);
              const colorIndex = random(0, colorKeys.length - 1);
              const colorKey = colorKeys[colorIndex] as RxColor;
              setValue(checked ? colorKey : undefined);
            }}
          />
          <Text block>Enabled</Text>
        </div>
      </div>
    );
  }

  return (
    <div id="boxes">
      <VariantTitle>Boxes</VariantTitle>
      <Text tw="mb-4" block>
        Inspired in the MUI Box component, the rx library Box is a helper
        component designed to easily use the rx library colors:
      </Text>

      <Variants>
        <VariantSubtitle>Colors Props</VariantSubtitle>

        <ul tw="space-y-4 mb-4">
          <li tw="space-y-1">
            <Text weight="bold" block>
              color
            </Text>
            <Text block> Text color</Text>
            {renderSelectColor(color, setColor)}
          </li>

          <li tw="space-y-1">
            <Text weight="bold" block>
              borderColor
            </Text>
            <Text block>
              Border color, to see the border is necessary to set the{" "}
              <SimpleLink
                href="https://tailwindcss.com/docs/border-width"
                openInNewTab
              >
                border width
              </SimpleLink>
            </Text>
            {renderSelectColor(borderColor, setBorderColor)}
          </li>

          <li tw="space-y-1">
            <Text weight="bold" block>
              outlineColor
            </Text>
            <Text block>
              Outline color, to see the outline is necessary to set the{" "}
              <SimpleLink
                href="https://tailwindcss.com/docs/outline-width"
                openInNewTab
              >
                outline width
              </SimpleLink>
            </Text>
            {renderSelectColor(outlineColor, setOutlineColor)}
          </li>

          <li tw="space-y-1">
            <Text weight="bold" block>
              bgColor
            </Text>
            <Text block>Background color</Text>
            {renderSelectColor(bgColor, setBgColor)}
          </li>

          <li tw="space-y-1">
            <Text weight="bold" block>
              bgColorOpacity
            </Text>

            <Text block>
              Background color opacity percentage between 0% and 100%, default
              is undefined that is equivalent to 100%
            </Text>

            <div>
              <Slider
                value={bgColorOpacity}
                onChange={(v) => setBgColorOpacity(v as any)}
                min={0}
                max={100}
                step={1}
              />
              <div tw="flex">
                {Array.from(Array(101)).map((_, i) => (
                  <Box
                    key={i}
                    bgColor={bgColor}
                    borderColor="black-1"
                    bgColorOpacity={i as any}
                    tw="h-[20px] flex-1"
                    css={i === bgColorOpacity && tw`border`}
                  >
                    {i === bgColorOpacity && <Text>{i}</Text>}
                  </Box>
                ))}
              </div>
            </div>
          </li>
        </ul>

        <Divider />

        <Text tw="mb-2" weight="bold" block>
          Box with colors props example:
        </Text>

        <Box
          tw="text-center p-2"
          css={[!!borderColor && tw`border-2`, !!outlineColor && tw`outline`]}
          color={color}
          bgColor={bgColor}
          borderColor={borderColor}
          outlineColor={outlineColor}
          bgColorOpacity={bgColorOpacity}
        >
          {[
            !!color && `Color: ${color}`,
            !!borderColor && `Border Color: ${borderColor}`,
            !!outlineColor && `Outline Color: ${outlineColor}`,
            !!bgColor && `Background Color: ${bgColor}`,
            !!bgColorOpacity && `Background Color Opacity: ${bgColorOpacity}`,
          ]
            .filter(Boolean)
            .join(", ")}
        </Box>
      </Variants>
    </div>
  );
}

function Icons() {
  return (
    <div id="icons">
      <VariantTitle>Icons</VariantTitle>

      <Variants
        tw="grid grid-cols-12 gap-4"
        style={{ color: COLORS["blue-1"] }}
      >
        {Object.entries(ICONS).map(([name, Icon], i) => (
          <div key={i} tw="flex flex-col items-center space-y-2">
            <Icon />
            <Text>{name}</Text>
          </div>
        ))}
      </Variants>

      <Variants>
        <VariantSubtitle>Colors</VariantSubtitle>
        <Text tw="mb-4" block>
          You can use the prop <Text weight="bold">color</Text> to change the
          icon color to any color of the rx library, example:
        </Text>

        <Variants>
          <Variants tw="flex space-x-4">
            {Object.entries(COLORS).map(([name]) => (
              <IconCheck key={name} color={name} />
            ))}
          </Variants>
        </Variants>
      </Variants>
    </div>
  );
}

function Typography() {
  return (
    <div id="typography">
      <VariantTitle>Typography</VariantTitle>
      <Text block>
        <ul>
          <li>
            The rendered tag could be changed using .withComponent(...).{" "}
            <a
              tw="underline"
              href="https://emotion.sh/docs/styled#change-the-rendered-tag-using-withcomponent"
            >
              Link doc
            </a>
          </li>
        </ul>
      </Text>

      <Variants>
        <VariantSubtitle>Title</VariantSubtitle>

        <Variants>
          <VariantSubtitle>Variants</VariantSubtitle>

          <Variants tw="flex flex-col space-y-2">
            <Title>H1 (default)</Title>
            <Title variant="h2">H2</Title>
          </Variants>
        </Variants>

        <Variants>
          <VariantSubtitle>Modifiers</VariantSubtitle>

          <Variants>
            <VariantSubtitle>Inline (default)</VariantSubtitle>

            <Variants>
              <Title>h1 inline</Title>
              <Title variant="h2">h2 inline</Title>
            </Variants>
          </Variants>

          <Variants>
            <VariantSubtitle>Block</VariantSubtitle>

            <Variants>
              <Title block>h1 block</Title>
              <Title variant="h2" block>
                h2 block
              </Title>
            </Variants>
          </Variants>
        </Variants>
      </Variants>

      <Variants>
        <VariantSubtitle>Text</VariantSubtitle>

        <Variants>
          <VariantSubtitle>Modifiers</VariantSubtitle>
          <Variants>
            <VariantSubtitle>Inline (default)</VariantSubtitle>

            <Variants>
              <Text>inline text light</Text>
              <Text weight="bold">inline text bold</Text>
            </Variants>
          </Variants>

          <Variants>
            <VariantSubtitle>Block</VariantSubtitle>

            <Variants>
              <Text block>block light</Text>
              <Text block weight="bold">
                block bold
              </Text>
            </Variants>
          </Variants>

          <Variants>
            <VariantSubtitle>Center</VariantSubtitle>

            <Variants>
              <Text block center>
                block center
              </Text>
              <Text block center weight="bold">
                block center
              </Text>
            </Variants>
          </Variants>
        </Variants>

        <Variants>
          <VariantSubtitle>Weights</VariantSubtitle>

          <SimpleTable>
            <thead>
              <tr>
                <th>weight</th>
                <th>light</th>
                <th>bold</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>color</td>
                <td>
                  {Object.keys(COLORS).map((color) => (
                    <Text key={color} color={color as RxColor} block>
                      {color}
                    </Text>
                  ))}
                </td>
                <td>
                  {Object.keys(COLORS).map((color) => (
                    <Text
                      key={color}
                      color={color as RxColor}
                      weight="bold"
                      block
                    >
                      {color}
                    </Text>
                  ))}
                </td>
              </tr>
              <tr>
                <td>size</td>
                <td>
                  <Text block size={12}>
                    size: any integer number (example: 12)
                  </Text>
                  <Text block>small: 14px (default)</Text>
                  <Text block size="medium">
                    medium: 16px
                  </Text>
                  <Text block size="large">
                    large: 18px
                  </Text>
                  <Text block size="x-large">
                    x-large: 24px
                  </Text>
                </td>
                <td>
                  <Text block weight="bold" size={12}>
                    size: any integer number (example: 12)
                  </Text>
                  <Text block weight="bold">
                    small: 14px (default)
                  </Text>
                  <Text block weight="bold" size="medium">
                    medium: 16px
                  </Text>
                  <Text block weight="bold" size="large">
                    large: 18px
                  </Text>
                  <Text block weight="bold" size="x-large">
                    x-large: 24px
                  </Text>
                </td>
              </tr>
            </tbody>
          </SimpleTable>
        </Variants>
      </Variants>
    </div>
  );
}

function Buttons() {
  const [isSyncLoading, setIsSyncLoading] = useState(false);

  function handleSync() {
    setIsSyncLoading(true);
    setTimeout(() => setIsSyncLoading(false), 2000);
  }

  return (
    <div id="buttons">
      <VariantTitle>Buttons</VariantTitle>

      <Variants>
        <VariantSubtitle>Variants</VariantSubtitle>

        <Variants>
          <VariantSubtitle>Primary (default)</VariantSubtitle>

          <Variants tw="flex space-x-2">
            <Button>Primary (default)</Button>
            <Button disabled>Primary disabled</Button>

            <Button icon={<IconCheck />}>Primary icon</Button>
            <Button icon={<IconCheck />} disabled>
              Primary icon disabled
            </Button>
          </Variants>
        </Variants>

        <Variants>
          <VariantSubtitle>Secondary</VariantSubtitle>

          <Variants tw="flex space-x-2">
            <Button variant="secondary">Secondary</Button>
            <Button variant="secondary" disabled>
              Secondary disabled
            </Button>

            <Button variant="secondary" icon={<IconCheck />}>
              Secondary icon
            </Button>
            <Button variant="secondary" icon={<IconCheck />} disabled>
              Secondary icon disabled
            </Button>
          </Variants>
        </Variants>

        <Variants>
          <VariantSubtitle>Tertiary</VariantSubtitle>

          <Variants tw="flex space-x-2">
            <Button variant="tertiary">Tertiary</Button>
            <Button variant="tertiary" disabled>
              Tertiary disabled
            </Button>

            <Button variant="tertiary" icon={<IconCheck />}>
              Tertiary icon
            </Button>
            <Button variant="tertiary" icon={<IconCheck />} disabled>
              Tertiary icon disabled
            </Button>
          </Variants>
        </Variants>

        <Variants>
          <VariantSubtitle>Button Text 1</VariantSubtitle>

          <Variants tw="flex space-x-2">
            <Button variant="text-1">Text-1</Button>
            <Button variant="text-1" disabled>
              Text-1 disabled
            </Button>

            <Button variant="text-1" icon={<IconCheck />}>
              Text-1 icon
            </Button>
            <Button variant="text-1" icon={<IconCheck />} disabled>
              Text-1 icon disabled
            </Button>
          </Variants>
        </Variants>

        <Variants>
          <VariantSubtitle>Button Text 2</VariantSubtitle>

          <Variants tw="flex space-x-2">
            <Button variant="text-2">Text-2</Button>
            <Button variant="text-2" disabled>
              Text-2 disabled
            </Button>

            <Button variant="text-2" icon={<IconCheck />}>
              Text-2 icon
            </Button>
            <Button variant="text-2" icon={<IconCheck />} disabled>
              Text-2 icon disabled
            </Button>
          </Variants>
        </Variants>

        <Variants>
          <VariantSubtitle>Button Text 3</VariantSubtitle>

          <Variants tw="flex space-x-2">
            <Button variant="text-3">Text-3</Button>
            <Button variant="text-3" disabled>
              Text-3 disabled
            </Button>

            <Button variant="text-3" icon={<IconCheck />}>
              Text-3 icon
            </Button>
            <Button variant="text-3" icon={<IconCheck />} disabled>
              Text-3 icon disabled
            </Button>
          </Variants>
        </Variants>

        <Variants>
          <VariantSubtitle>Large</VariantSubtitle>

          <Variants tw="flex space-x-2">
            <Button variant="large">large</Button>
            <Button variant="large" disabled>
              large disabled
            </Button>

            <Button variant="large" icon={<IconCheck />}>
              large icon
            </Button>
            <Button variant="large" icon={<IconCheck />} disabled>
              large icon disabled
            </Button>
          </Variants>
        </Variants>
      </Variants>

      <Variants>
        <VariantSubtitle>Modifiers</VariantSubtitle>
        <Text block>
          Props that could be used to modify the button behavior:
        </Text>

        <Variants>
          <VariantSubtitle>Inline</VariantSubtitle>

          <Variants tw="flex space-x-2">
            <Button>Primary</Button>
            <Button variant="secondary">Secondary</Button>
            <Button variant="tertiary">Tertiary</Button>
            <Button variant="text-1">Text-1</Button>
            <Button variant="text-2">Text-2</Button>
          </Variants>
        </Variants>

        <Variants>
          <VariantSubtitle>Block</VariantSubtitle>

          <Variants tw="space-y-2">
            <Button block>Primary block</Button>
            <Button variant="secondary" block>
              Secondary block
            </Button>
            <Button variant="tertiary" block>
              Tertiary block
            </Button>
            <Button variant="text-1" block>
              Text-1 block
            </Button>
            <Button variant="text-2" block>
              Text-2 block
            </Button>
          </Variants>
        </Variants>

        <Variants>
          <VariantSubtitle>Full Width</VariantSubtitle>

          <Variants tw="space-y-2">
            <Button fullWidth>Primary full width</Button>
            <Button variant="secondary" fullWidth>
              Secondary full width
            </Button>
            <Button variant="tertiary" fullWidth>
              Tertiary full width
            </Button>
            <Button variant="text-1" fullWidth>
              Text-1 full width
            </Button>
            <Button variant="text-2" fullWidth>
              Text-2 full width
            </Button>
          </Variants>
        </Variants>
      </Variants>

      <Variants>
        <VariantSubtitle>Icon Button</VariantSubtitle>
        <Text block>Could use any internal icon as content, example:</Text>

        <Variants tw="flex space-x-2">
          {Object.entries(ICONS).map(([name, Icon]) => (
            <IconButton key={name}>
              <Icon />
            </IconButton>
          ))}
        </Variants>
      </Variants>

      <Variants>
        <VariantSubtitle>Back Button</VariantSubtitle>
        <Text block>
          Button commonly used in the navigation headers to go back to the
          previous page/section
        </Text>

        <Variants>
          <BackButton>Back</BackButton>
        </Variants>
      </Variants>

      <Variants>
        <VariantSubtitle>Sync Button</VariantSubtitle>

        <Variants tw="flex space-x-2">
          <SyncButton />
          <SyncButton disabled />
          <SyncButton loading />
          <SyncButton loading disabled />
        </Variants>
      </Variants>

      <Variants>
        <VariantSubtitle>Sync Button And Text</VariantSubtitle>

        <Variants>
          <SyncButtonAndText
            syncDatePrefix="Last Sync"
            loading={isSyncLoading}
            onClick={handleSync}
            syncDate={dayjs()}
          />
        </Variants>
      </Variants>

      <Variants>
        <VariantSubtitle>Copy Button</VariantSubtitle>
        <Text block>
          The CopyButton component allows you to copy text to the clipboard.
        </Text>

        <Variants>
          <Variants tw="flex items-center space-x-4">
            <CopyButton text="Copy" />

            <CopyButton
              text="Copy: Hello World!"
              tooltipText="Click to copy: Hello World!"
              textToCopy="Hello World!"
            />
          </Variants>

          <VariantSubtitle>Modifiers</VariantSubtitle>
          <Variants tw="flex items-center space-x-4">
            <CopyButton
              text="Copy with font weight normal (default)"
              weight="normal"
            />

            <CopyButton text="Copy with font weight bold text" weight="bold" />
          </Variants>
        </Variants>
      </Variants>
    </div>
  );
}

function Tags() {
  return (
    <div id="tags">
      <VariantTitle>Tags</VariantTitle>

      <Variants>
        <VariantSubtitle>Tag</VariantSubtitle>

        <Variants>
          <Tag>Tag</Tag>
        </Variants>
      </Variants>

      <Variants>
        <VariantSubtitle>Closable Tag</VariantSubtitle>

        <Variants>
          <ClosableTag>Closable Tag</ClosableTag>
        </Variants>
      </Variants>
    </div>
  );
}

function Warnings() {
  return (
    <div id="warnings">
      <VariantTitle>Warnings</VariantTitle>

      <Variants>
        <VariantSubtitle>Types</VariantSubtitle>

        <Variants tw="space-y-2">
          <Warning>type: info (default)</Warning>
          <Warning type="error">type: error</Warning>
        </Variants>
      </Variants>

      <Variants>
        <VariantSubtitle>Icon</VariantSubtitle>

        <Variants tw="space-y-2">
          <Warning>icon: bell icon (default)</Warning>
          <Warning type="error">icon: bell icon (default)</Warning>
          <Warning Icon={IconCheck}>icon: custom, example: check icon</Warning>
          <Warning type="error" Icon={IconCheck}>
            icon: custom, example: check icon
          </Warning>
        </Variants>
      </Variants>
    </div>
  );
}

function Switches() {
  return (
    <div id="switches">
      <VariantTitle link="https://ant.design/components/switch">
        Switches
      </VariantTitle>

      <Variants tw="flex space-x-2">
        <div tw="flex flex-col items-center">
          <Switch defaultChecked />
          <Text block>Checked</Text>
        </div>
        <div tw="flex flex-col items-center">
          <Switch />
          <Text block>Unchecked</Text>
        </div>
      </Variants>
    </div>
  );
}

function Modals() {
  const { isModalOpen, openModal, closeModal } = useModal();

  return (
    <div id="modals">
      <VariantTitle link="https://ant.design/components/modal">
        Modals
      </VariantTitle>

      <Variants>
        <Button onClick={openModal}>Modal</Button>
        <Modal open={isModalOpen} onCancel={closeModal}>
          <div tw="h-full">
            <div tw="flex-1 flex items-center justify-center h-full">
              <Text>Modal</Text>
            </div>

            <div tw="flex items-center justify-end space-x-[19px] px-[48px] h-[80px] shadow-[0_-2px_8px_0_#00000029]">
              <Button variant="text-1" onClick={closeModal}>
                Cancel
              </Button>
              <Button icon={<IconCheck />} onClick={closeModal}>
                OK
              </Button>
            </div>
          </div>
        </Modal>
      </Variants>
    </div>
  );
}

function Checkboxes() {
  return (
    <div id="checkboxes">
      <VariantTitle link="https://ant.design/components/checkbox">
        Checkboxes
      </VariantTitle>

      <Variants tw="flex space-x-2">
        <div tw="flex flex-col items-center">
          <Checkbox defaultChecked />
          <Text block>Checked</Text>
        </div>
        <div tw="flex flex-col items-center">
          <Checkbox />
          <Text block>Unchecked</Text>
        </div>
        <div tw="flex flex-col items-center">
          <Checkbox defaultChecked disabled />
          <Text block>Checked disabled</Text>
        </div>
        <div tw="flex flex-col items-center">
          <Checkbox disabled />
          <Text block>Unchecked disabled</Text>
        </div>
      </Variants>
    </div>
  );
}

function Dropdowns() {
  const items: DropdownMenuOptions = [
    {
      key: "add",
      label: "Remove from Shopping List",
    },
    {
      key: "list",
      label: "Add to Shopping List",
    },
    {
      key: "stock",
      label: "In Stock",
    },
    {
      key: "processed",
      label: "Mark: Optimized",
    },
    {
      key: "delete",
      label: "Delete Item",
      icon: <IconX />,
    },
  ];

  return (
    <div id="dropdowns">
      <VariantTitle link="https://ant.design/components/dropdown">
        Dropdowns
      </VariantTitle>

      <Variants>
        <Dropdown
          menu={{
            items,
            onClick: (value) => console.log("dropdown option clicked:", value),
          }}
        >
          <Button>Dropdown</Button>
        </Dropdown>
      </Variants>
    </div>
  );
}

function Popovers() {
  const { isPopoverOpen, closePopover, handleOpenPopoverChange } =
    useIsPopoverOpen();

  return (
    <div id="popovers">
      <VariantTitle link="https://ant.design/components/popover">
        Popovers
      </VariantTitle>

      <Variants>
        <Popover
          open={isPopoverOpen}
          onOpenChange={handleOpenPopoverChange}
          content={() => (
            <div tw="flex flex-col space-y-2 p-4">
              <Title>Popover Title</Title>
              <Text>Popover content</Text>
              <Button icon={<IconX />} onClick={closePopover}>
                Close
              </Button>
            </div>
          )}
        >
          <Button>Popover</Button>
        </Popover>
      </Variants>
    </div>
  );
}

function Selects() {
  const options = new Array(10)
    .fill(0)
    .map((_, i) => ({ label: `Item ${i}`, value: i }));
  const [value, setValue] = useState(options[0]);
  const [sortDirection, setSortDirection] = useState(SortDirectionEnum.ASC);
  const [multiSelectValues, setMultiSelectValues] = useState([
    options[0].value,
  ]);

  return (
    <div id="selects">
      <VariantTitle link="https://ant.design/components/select">
        Selects
      </VariantTitle>

      <Variants>
        <VariantSubtitle>Select</VariantSubtitle>
        <Variants>
          <Select placeholder="Select" allowClear options={options} />
        </Variants>

        <VariantSubtitle>Vertical</VariantSubtitle>
        <Variants>
          <QtySelect options={options} vertical />
        </Variants>

        <VariantSubtitle>Qty Select</VariantSubtitle>
        <Variants>
          <VariantSubtitle>Options 1 to 120 (default)</VariantSubtitle>
          <Variants>
            <QtySelect />
          </Variants>

          <VariantSubtitle>
            Custom Options - Custom defined options, value should be a number,
            label could be anything
          </VariantSubtitle>

          <Variants>
            <QtySelect options={options} />
          </Variants>

          <VariantSubtitle>
            Quantities Config - Build the options using multiple modifiers like:
            bundleSize: number, includeZero: boolean, zeroLabel: string,
            multiplesOfBundleSize: boolean, directDealLabel: boolean
          </VariantSubtitle>

          <Variants>
            <QtySelect
              quantitiesConfig={{
                bundleSize: 10,
                includeZero: true,
                zeroLabel: "None",
                multiplesOfBundleSize: true,
                directDealLabel: true,
              }}
            />
          </Variants>
        </Variants>

        <VariantSubtitle>Sort By Select</VariantSubtitle>
        <Variants>
          <SortBySelect
            options={options}
            selectMinWidth={120}
            value={value}
            sortOrder={sortDirection}
            onSortOrderChange={setSortDirection}
            onChange={setValue}
          />
        </Variants>

        <VariantSubtitle>Multi Select Checkboxes</VariantSubtitle>
        <Variants>
          <MultiSelectCheckboxes
            title="Select Filters to Apply"
            filters={multiSelectValues}
            setFilters={setMultiSelectValues}
            options={options}
          />

          <div tw="mt-2">
            <MultiSelectCheckboxesSelectedTags
              filters={multiSelectValues}
              setFilters={setMultiSelectValues}
              options={options}
            />
          </div>
        </Variants>
      </Variants>
    </div>
  );
}

function DateRangePickers() {
  const [dates, setDates] = useState((): [Dayjs, Dayjs] => {
    return [dayjs(), dayjs().add(3, "days")];
  });

  return (
    <div id="date-range-pickers">
      <VariantTitle link="https://ant.design/components/date-picker#rangepicker">
        Date Range Picker
      </VariantTitle>

      <Variants>
        <DateRangePicker
          label="Date Range"
          defaultValue={dates}
          onChange={setDates}
          presets={[
            {
              label: "Last 90 Days",
              value: [dayjs().subtract(91, "day"), dayjs().subtract(1, "day")],
            },
            {
              label: "Last 60 Days",
              value: [dayjs().subtract(61, "day"), dayjs().subtract(1, "day")],
            },
            {
              label: "Last 30 Days",
              value: [dayjs().subtract(31, "day"), dayjs().subtract(1, "day")],
            },
            {
              label: "Now to end-of-week",
              value: [dayjs(), dayjs().endOf("week")],
            },
            {
              label: "Next 7 Days",
              value: [dayjs(), dayjs().add(7, "day")],
            },
            {
              label: "Next 30 Days",
              value: [dayjs(), dayjs().add(30, "day")],
            },
          ]}
        />
      </Variants>
    </div>
  );
}

function Grids() {
  return (
    <div id="grids">
      <VariantTitle link="https://ant.design/components/grid">
        Grid
      </VariantTitle>

      <Variants>
        <Row>
          <Col span={12}>
            <Text>col-12</Text>
          </Col>
          <Col span={6}>
            <Text>col-6</Text>
          </Col>
          <Col span={3}>
            <Text>col-3</Text>
          </Col>
          <Col span={2}>
            <Text>col-2</Text>
          </Col>
          <Col span={1}>
            <Text>col-1</Text>
          </Col>
        </Row>

        <Row>
          <Col tw="w-[200px]">
            <Text>200px</Text>
          </Col>
          <Col tw="flex-1">
            <Text>flex-1</Text>
          </Col>
          <Col tw="flex-1">
            <Text>flex-1</Text>
          </Col>
        </Row>

        <Row>
          <Col tw="w-[200px]" noBorder>
            <Text>200px</Text>
          </Col>
          <Col tw="flex-1" noBorder>
            <Text>flex-1</Text>
          </Col>
          <Col tw="flex-1" noBorder>
            <Text>flex-1</Text>
          </Col>
        </Row>

        <Row>
          <Col tw="w-[200px]" noBorder>
            <Text>200px</Text>
          </Col>

          <InnerRow tw="flex-1">
            <Col tw="flex-1">
              <Text>flex-1</Text>
            </Col>
            <Col tw="flex-1">
              <Text>flex-1</Text>
            </Col>
          </InnerRow>
        </Row>
      </Variants>
    </div>
  );
}

function Tables() {
  const row = {
    drugName: "Zilxi 1.5%",
    fillDate: "Aug 20",
    rxPatient: "123456, Ale S",
    manufacturer: "Any",
    purchaseByText: "Today",
    prescriberName: "Dr Science",
    dispenseQuantity: "60 Units",
  };
  const items = [row, row, row, row, row].map((r, i) => {
    return { ...r, id: i.toString() };
  });

  type Item = typeof items[number];

  function ExpandableHeader({ variant }: { variant: string }) {
    return (
      <div tw="flex">
        <div tw="flex-1 p-5">
          <Text weight="bold" block>
            Variant
          </Text>
        </div>
        <div tw="flex-1 p-5">
          <Text weight="bold" block>
            {variant}
          </Text>
        </div>
      </div>
    );
  }

  function ExpandableTableRow({
    item,
    itemProps,
  }: {
    item: Item;
    itemProps?: { variant: string };
  }) {
    const { variant } = itemProps as NonNullable<typeof itemProps>;

    return (
      <>
        {Object.entries(item).map(([key, value], i) => (
          <div key={i} tw="flex-1 space-y-1 p-5">
            <Text weight="bold" block>
              {key}
            </Text>
            <Text block>{value}</Text>
          </div>
        ))}
        <div tw="flex-1 space-y-1 p-5">
          <Text weight="bold" block>
            variant
          </Text>
          <Text block>{variant}</Text>
        </div>
      </>
    );
  }

  return (
    <div id="tables">
      <VariantTitle>Tables</VariantTitle>

      <Variants>
        <VariantSubtitle>Simple Table</VariantSubtitle>

        <Variants>
          <SimpleTable>
            <thead>
              <tr>
                <th>Item</th>
                <th>Manufacturer</th>
                <th>Dispensed Quantity</th>
                <th>Needed</th>
                <th>Rx # , Patient</th>
                <th>Date Filled</th>
                <th>Prescriber</th>
              </tr>
            </thead>

            <tbody>
              {items.map((row) => (
                <tr key={row.id}>
                  <td>{row.drugName}</td>
                  <td>{row.manufacturer}</td>
                  <td>{row.dispenseQuantity}</td>
                  <td>
                    <Text weight="bold">{row.purchaseByText}</Text>
                  </td>
                  <td>
                    <Text weight="bold">{row.rxPatient}</Text>
                  </td>
                  <td>{row.fillDate}</td>
                  <td>{row.prescriberName}</td>
                </tr>
              ))}
            </tbody>
          </SimpleTable>
        </Variants>
      </Variants>

      <Variants>
        <VariantSubtitle>Expandable Table</VariantSubtitle>

        <Variants>
          <VariantSubtitle>Variants</VariantSubtitle>

          <Variants>
            <VariantSubtitle>Variant: (default)</VariantSubtitle>
            <Variants>
              <ExpandableTable
                items={items}
                itemProps={{ variant: "(default)" }}
                header={<ExpandableHeader variant="(default)" />}
                Row={ExpandableTableRow}
              />
            </Variants>

            <VariantSubtitle>Variant: blueToggle</VariantSubtitle>
            <Variants>
              <ExpandableTable
                blueToggle
                items={items}
                itemProps={{ variant: "blueToggle" }}
                header={<ExpandableHeader variant="blueToggle" />}
                Row={ExpandableTableRow}
              />
            </Variants>
          </Variants>
        </Variants>
      </Variants>
    </div>
  );
}

function Paginations() {
  return (
    <div id="paginations">
      <VariantTitle>Pagination</VariantTitle>

      <Variants>
        <VariantSubtitle>Pagination</VariantSubtitle>
        <Variants>
          <Pagination
            pagination={{
              currentPage: 1,
              pageSize: 10,
              totalPageCount: 5,
              totalRows: 85,
            }}
            updatePagination={() => {}}
          />
        </Variants>

        <VariantSubtitle>Pagination without total rows</VariantSubtitle>
        <Variants>
          <Pagination
            pagination={{ currentPage: 1, pageSize: 10, totalPageCount: 5 }}
            updatePagination={() => {}}
          />
        </Variants>

        <VariantSubtitle>
          Pagination with custom page size options
        </VariantSubtitle>
        <Variants>
          <Pagination
            pagination={{ currentPage: 1, pageSize: 10, totalPageCount: 5 }}
            updatePagination={() => {}}
            sizeOptions={[1, 2, 3]}
          />
        </Variants>

        <VariantSubtitle>Pagination without page size options</VariantSubtitle>
        <Variants>
          <Pagination
            pagination={{ currentPage: 1, pageSize: 10, totalPageCount: 5 }}
            updatePagination={() => {}}
            sizeOptions={null}
          />
        </Variants>
      </Variants>
    </div>
  );
}

// ------------ Rx Library ------------
const Variants = tw.div`mb-4 px-4`;

function VariantTitle({
  children,
  link,
}: {
  children: ReactNode;
  link?: string;
}) {
  return (
    <div
      css={css`
        border-bottom: 1px solid #e5e5e5;
        border-top: 1px solid #e5e5e5;
        margin: 20px 0;
        padding: 8px 0;
        display: flex;
        align-items: baseline;
        column-gap: 10px;
      `}
    >
      <Title variant="h2">{children}</Title>
      {!!link && (
        <>
          {"|"}
          <a tw="underline" href={link}>
            {link}
          </a>
        </>
      )}
    </div>
  );
}

function VariantSubtitle({ children }: { children: ReactNode }) {
  return (
    <div
      css={css`
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 15px;
      `}
    >
      <Text weight="bold">{children}</Text>
    </div>
  );
}
