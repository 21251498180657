import pick from "lodash/pick";
import groupBy from "lodash/groupBy";
import type { CatalogDrug_All, SupplierOrder_All } from "../../services/utils";
import type { ShippingConfig, Supplier } from "../types";
import type {
  ShippingInfoItemTotals,
  ShippingInfo,
  ShippingFeeData,
} from "./shipping.constants";
import { isFoamDrug } from "../drugInfo/isFoamDrug";
import { getItemsCost } from "../prescriptions/getItemsCost";
import { getItemsNumPackagesTotal } from "../prescriptions/getItemsNumPackagesTotal";
import {
  BRAEBURN_MIN_ITEMS_QTY,
  MAYNE_MIN_FOAM_ITEMS_QTY,
  SUPPLIER_ID_BRAEBURN,
  SUPPLIER_ID_MAYNE,
} from "../config";

export function getShippingInfoItemsTotals(
  items: CatalogDrug_All[]
): ShippingInfoItemTotals {
  const { brandItems = [], genericItems = [] } = groupBy(items, (item) =>
    item.referenceData.drugInfo.isBrand ? "brandItems" : "genericItems"
  );
  const { brandRxItems = [], brandOtcItems = [] } = groupBy(
    brandItems,
    (item) =>
      item.referenceData.drugInfo.isRx ? "brandRxItems" : "brandOtcItems"
  );
  const { genericRxItems = [], genericOtcItems = [] } = groupBy(
    genericItems,
    (item) =>
      item.referenceData.drugInfo.isRx ? "genericRxItems" : "genericOtcItems"
  );

  const brandRxTotal = getItemsCost(brandRxItems);
  const brandOtcTotal = getItemsCost(brandOtcItems);
  const genericRxTotal = getItemsCost(genericRxItems);
  const genericOtcTotal = getItemsCost(genericOtcItems);
  const brandTotal = brandRxTotal + brandOtcTotal;
  const genericTotal = genericRxTotal + genericOtcTotal;
  const total = brandTotal + genericTotal;

  return {
    total,
    brandTotal,
    genericTotal,
    brandRxTotal,
    brandOtcTotal,
    genericRxTotal,
    genericOtcTotal,
  };
}

export function getShippingFeeData(
  itemsTotals: ShippingInfoItemTotals,
  shippingConfig?: ShippingConfig
): ShippingFeeData {
  if (!shippingConfig) return { shippingFee: 0, shippable: false };

  const { total, genericTotal, brandRxTotal, genericRxTotal } = itemsTotals;
  const { fee, minOrder, minGenericOrder, minBrandRxOrder, minGenericRxOrder } =
    shippingConfig;

  const hasAtLeastOneMinOrder = [
    minOrder,
    minGenericOrder,
    minBrandRxOrder,
    minGenericRxOrder,
  ].some(Boolean);
  if (!hasAtLeastOneMinOrder) {
    return { shippingFee: fee ?? 0, shippable: true };
  }

  const reachMinOrder = minOrder !== undefined && total >= minOrder;
  const reachMinGenericOrder =
    minGenericOrder !== undefined && genericTotal >= minGenericOrder;
  const reachMinBrandRxOrder =
    minBrandRxOrder !== undefined && brandRxTotal >= minBrandRxOrder;
  const reachMinGenericRxOrder =
    minGenericRxOrder !== undefined && genericRxTotal >= minGenericRxOrder;

  if (
    reachMinOrder ||
    reachMinGenericOrder ||
    reachMinBrandRxOrder ||
    reachMinGenericRxOrder
  ) {
    return { shippingFee: 0, shippable: true };
  }

  // if it doesn't have a fee it can be shipped without reach a min order
  return { shippingFee: fee ?? 0, shippable: !!fee };
}

export function recalculateOrderItemsTotals(
  itemsTotals: ShippingInfoItemTotals,
  item: CatalogDrug_All,
  action: "add" | "remove"
): ShippingInfoItemTotals {
  const drug = item.referenceData.drugInfo;
  const catalogInfo = item.referenceData.catalogInfo;
  const numPackages = item.numPackages;
  const { isBrand, isRx } = drug;
  const multiplierAction = action === "remove" ? -1 : 1;
  const priceVariation = catalogInfo.price * numPackages * multiplierAction;

  let brandRxTotal = itemsTotals.brandRxTotal;
  let brandOtcTotal = itemsTotals.brandOtcTotal;
  let genericRxTotal = itemsTotals.genericRxTotal;
  let genericOtcTotal = itemsTotals.genericOtcTotal;

  if (isRx) {
    if (isBrand) brandRxTotal += priceVariation;
    else genericRxTotal += priceVariation;
  } else {
    if (isBrand) brandOtcTotal += priceVariation;
    else genericOtcTotal += priceVariation;
  }

  const brandTotal = brandRxTotal + brandOtcTotal;
  const genericTotal = genericRxTotal + genericOtcTotal;
  const total = brandTotal + genericTotal;

  return {
    total,
    brandTotal,
    genericTotal,
    brandRxTotal,
    brandOtcTotal,
    genericRxTotal,
    genericOtcTotal,
  };
}

function hasMinFoamQuantity(minFoamQty: number, items: CatalogDrug_All[]) {
  const foamItems = items.filter((item) =>
    isFoamDrug(item.referenceData.drugInfo)
  );
  const foamItemsCount = getItemsNumPackagesTotal(foamItems);
  if (foamItemsCount === 0) return true;
  if (minFoamQty > foamItemsCount) return false;
  return true;
}

function hasMinQuantity(minQty: number, items: CatalogDrug_All[]) {
  if (!items.length) {
    return false;
  }

  return items.length >= minQty || getItemsNumPackagesTotal(items) >= minQty;
}

export function getShippingInfo(
  items: CatalogDrug_All[],
  supplier?: Supplier
): ShippingInfo {
  const itemsTotals = getShippingInfoItemsTotals(items);
  const { shippingFee, shippable } = getShippingFeeData(
    itemsTotals,
    supplier?.shippingConfig
  );

  const minFoamQtyError =
    supplier?.id === SUPPLIER_ID_MAYNE
      ? !hasMinFoamQuantity(MAYNE_MIN_FOAM_ITEMS_QTY, items)
      : false;
  const minQtyError =
    supplier?.id === SUPPLIER_ID_BRAEBURN
      ? !hasMinQuantity(BRAEBURN_MIN_ITEMS_QTY, items)
      : false;
  const isShippable = !minFoamQtyError && !minQtyError && shippable;

  return {
    shippable: isShippable,
    shippingFee,
    itemsTotals,
    minFoamQtyError,
    minQtyError,
  };
}

export function buildShippingFeeSimulation({
  supplier,
  itemToAdd,
  itemToRemove,
  supplierOrders,
}: {
  supplier: Supplier;
  itemToAdd?: CatalogDrug_All;
  itemToRemove?: CatalogDrug_All;
  supplierOrders?: SupplierOrder_All[];
}):
  | {
      shippingFeeDataSimulation: ShippingFeeData;
      initialShippingInfo?: ShippingFeeData;
    }
  | undefined {
  const { id: supplierId, shippingConfig } = supplier;
  if (!shippingConfig) return;

  const supplierOrder = supplierOrders?.find((so) => {
    return so.supplierId === supplierId;
  });

  if (!supplierOrder) {
    if (!itemToAdd) return;

    const { shippable, shippingFee } = getShippingInfo([itemToAdd], supplier);
    return { shippingFeeDataSimulation: { shippable, shippingFee } };
  }

  const { shippingInfo, items } = supplierOrder;
  let newItemsTotals = shippingInfo?.itemsTotals;
  if (!newItemsTotals) return;

  if (itemToRemove) {
    if (items.length <= 1 && !itemToAdd) return;

    newItemsTotals = recalculateOrderItemsTotals(
      newItemsTotals,
      itemToRemove,
      "remove"
    );
  }
  if (itemToAdd) {
    newItemsTotals = recalculateOrderItemsTotals(
      newItemsTotals,
      itemToAdd,
      "add"
    );
  }

  const initialShippingInfo: ShippingFeeData | undefined =
    shippingInfo && pick(shippingInfo, ["shippable", "shippingFee"]);
  const shippingFeeDataSimulation = getShippingFeeData(
    newItemsTotals,
    shippingConfig
  );

  return { shippingFeeDataSimulation, initialShippingInfo };
}
